import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    TextField,
    Typography,
} from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid-pro";
import React from "react";

import { RiskOption } from "@/lib/data/types";
import { getLabel } from "@/lib/data/utils";
import { track, TRACK_PREFIX } from "@/lib/track";

import { countryCodes } from "../../../../lib/data/CountryOptions";
import { NACEOptions } from "../../../../lib/data/NaceOptions";

const renderInput = (params: AutocompleteRenderInputParams) => {
    // eslint-disable-next-line jsx-a11y/no-autofocus
    return <TextField {...params} size="small" variant="outlined" autoFocus />;
};

/**
 * @deprecated For countries, use CountryEditCell instead.
 */
export function RiskAutocomplete(
    params: GridRenderCellParams<GridValidRowModel, unknown, unknown, GridTreeNodeWithRender>,
    onChange: (columnName: string, supplierId: string, newValue: string | undefined) => void
) {
    const { id, value, field, api, colDef } = params;

    const trackSelection = (value: string | undefined, newValue: string | undefined) => {
        if (field === "industry") {
            track(`${TRACK_PREFIX}: Updated Industry`, { initialValue: value, newValue });
        } else if (field === "country") {
            track(`${TRACK_PREFIX}: Updated Country`, { initialValue: value, newValue });
        }
    };

    const handleChange = (
        _event: React.SyntheticEvent<Element, Event>,
        value: RiskOption | null,
        reason: AutocompleteChangeReason
    ) => {
        const newValue = reason === "selectOption" ? value?.code : "";
        trackSelection(value?.code, newValue);
        api.setEditCellValue({ id, field, value: newValue });
        onChange(colDef.field, id.toString(), newValue);
        api.stopCellEditMode({ id, field });
    };

    let options: RiskOption[];
    if (field === "industry") {
        options = NACEOptions;
    } else if (field === "country") {
        options = countryCodes;
    } else {
        throw new Error(`Unknown field ${field}`);
    }
    return (
        <Autocomplete
            autoHighlight
            options={options}
            value={options.find((option) => option.code === value) ?? null}
            fullWidth
            getOptionLabel={(option) => `${getLabel(option)}`}
            renderOption={(props, option) => (
                <Typography variant="textSm" {...props}>
                    {getLabel(option)}
                </Typography>
            )}
            renderInput={renderInput}
            onChange={handleChange}
            openOnFocus
            sx={{
                "& .MuiOutlinedInput-root": {
                    border: "none",
                    marginTop: 0.5,
                    borderRadius: 0,
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
            }}
        />
    );
}
