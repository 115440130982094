import { SuggestedStatusLevel } from "@/components/RiskStatus";

export function getRiskValue(riskValue: SuggestedStatusLevel | string): SuggestedStatusLevel {
    if (typeof riskValue !== "string") return "MISSING";

    switch (riskValue) {
        case "veryLow":
        case "very_low":
        case "VERY_LOW":
            return "VERY_LOW";
        case "low":
        case "LOW":
            return "LOW";
        case "medium":
        case "MEDIUM":
            return "MEDIUM";
        case "high":
        case "HIGH":
            return "HIGH";
        case "veryHigh":
        case "very_high":
        case "VERY_HIGH":
            return "VERY_HIGH";
        default:
            return "MISSING";
    }
}
