import { useQuery } from "@apollo/client";
import { getLocale } from "@ignite-analytics/locale";
import React, { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { graphql } from "@/gql";

import { useUser } from "./UserContext";

const getCustomerQuery = graphql(`
    query GetCustomerSocialRisk {
        getCurrentCustomer {
            currency
        }
    }
`);

const cacheKey = (tenant: string) => `DISPLAY_CURRENCY_${tenant}`;

const validateCurrency = (currency: string | null): string | undefined => {
    if (!currency) return undefined;
    if (currency.length !== 3) return undefined;
    return currency;
};

interface ContextValue {
    currency: string | undefined;
    formatCompactCurrency: (value: number) => string;
}

const Context = createContext<ContextValue | null>(null);

export const CompanyCurrencyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const tenant = useUser().tenant;
    useQuery(getCustomerQuery, {
        onCompleted: (data) => {
            if (data.getCurrentCustomer.currency) {
                localStorage.setItem(cacheKey(tenant), data.getCurrentCustomer.currency);
                setFetchedCurrency(data.getCurrentCustomer.currency);
            }
        },
    });

    const [fetchedCurrency, setFetchedCurrency] = useState<string | undefined>(undefined);
    const cachedCurrency = validateCurrency(localStorage.getItem(cacheKey(tenant)));

    const currency = fetchedCurrency || cachedCurrency;

    const formatCompactCurrency = React.useCallback(
        (value: number) => {
            return value.toLocaleString(getLocale(), {
                style: "currency",
                currency,
                notation: "compact",
                currencyDisplay: "code",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },
        [currency]
    );

    const value = useMemo(() => ({ currency, formatCompactCurrency }), [currency, formatCompactCurrency]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCompanyCurrency = (): ContextValue => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useCompanyCurrency must be used within a CompanyCurrencyProvider");
    }
    return context;
};
