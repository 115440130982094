import { Box } from "@mui/material";
import React, { ReactNode } from "react";

export const ShadowIcon: React.FC<{ icon: ReactNode }> = ({ icon }) => {
    return (
        <Box
            width={40}
            height={40}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundColor: (theme) => theme.palette.tokens?.shapeIcon.shapeAccentA,
                borderRadius: "20px",
            }}
        >
            {icon}
        </Box>
    );
};
