import { Tooltip } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

type NoPermissionTooltipProps = {
    hasPermission: boolean;
    placement?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
    message?: React.ReactNode;
    children: React.ReactNode;
};

export const NoPermissionTooltip: React.FC<NoPermissionTooltipProps> = ({
    children,
    hasPermission,
    placement,
    message,
}) => {
    const { formatMessage } = useIntl();
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: "12px",
                        maxWidth: "149px",
                        whiteSpace: "normal",
                        textAlign: "center",
                    },
                },
            }}
            placement={placement}
            title={
                hasPermission
                    ? undefined
                    : message ||
                      formatMessage({
                          defaultMessage: "You must have editor permissions to perform this action.",
                      })
            }
        >
            <span>{children}</span>
        </Tooltip>
    );
};
