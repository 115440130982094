export const countryCodes = [
    {
        code: "AF",
        name_en: "Afghanistan",
        name_no: "Afghanistan",
        score: "6",
    },
    { code: "AL", name_en: "Albania", name_no: "Albania", score: "3" },
    { code: "DZ", name_en: "Algeria", name_no: "Algerie", score: "5" },
    {
        code: "AS",
        name_en: "American Samoa",
        name_no: "Amerikansk Samoa",
        score: null,
    },
    { code: "AD", name_en: "Andorra", name_no: "Andorra", score: null },
    { code: "AO", name_en: "Angola", name_no: "Angola", score: "4" },
    { code: "AI", name_en: "Anguilla", name_no: "Anguilla", score: null },
    { code: "AQ", name_en: "Antarctica", name_no: "Antarktis", score: null },
    {
        code: "AG",
        name_en: "Antigua and Barbuda",
        name_no: "Antigua og Barbuda",
        score: null,
    },
    { code: "AR", name_en: "Argentina", name_no: "Argentina", score: "3" },
    { code: "AM", name_en: "Armenia", name_no: "Armenia", score: "3" },
    { code: "AW", name_en: "Aruba", name_no: "Aruba", score: null },
    { code: "AU", name_en: "Australia", name_no: "Australia", score: "3" },
    { code: "AT", name_en: "Austria", name_no: "Østerrike", score: "1" },
    {
        code: "AZ",
        name_en: "Azerbaijan",
        name_no: "Aserbajdsjan",
        score: null,
    },
    { code: "BS", name_en: "Bahamas", name_no: "Bahamas", score: "3" },
    { code: "BH", name_en: "Bahrain", name_no: "Bahrain", score: "5" },
    { code: "BD", name_en: "Bangladesh", name_no: "Bangladesh", score: "5" },
    { code: "BB", name_en: "Barbados", name_no: "Barbados", score: "2" },
    { code: "BY", name_en: "Belarus", name_no: "Hviterussland", score: "5" },
    { code: "BE", name_en: "Belgium", name_no: "Belgia", score: "3" },
    { code: "BZ", name_en: "Belize", name_no: "Belize", score: "3" },
    { code: "BJ", name_en: "Benin", name_no: "Benin", score: "4" },
    { code: "BM", name_en: "Bermuda", name_no: "Bermuda", score: null },
    { code: "BT", name_en: "Bhutan", name_no: "Bhutan", score: null },
    { code: "BO", name_en: "Bolivia", name_no: "Bolivia", score: "3" },
    {
        code: "BQ",
        name_en: "Bonaire, Sint Eustatius and Saba",
        name_no: "Bonaire, Sint Eustatius og Saba",
        score: null,
    },
    {
        code: "BA",
        name_en: "Bosnia and Herzegovina",
        name_no: "Bosnia-Hercegovina",
        score: "3",
    },
    { code: "BW", name_en: "Botswana", name_no: "Botswana", score: "4" },
    {
        code: "BV",
        name_en: "Bouvet Island",
        name_no: "Bouvetøya",
        score: null,
    },
    { code: "BR", name_en: "Brazil", name_no: "Brasil", score: "4" },
    {
        code: "IO",
        name_en: "British Indian Ocean Territory",
        name_no: "Britiske territorier i Indiahavet",
        score: null,
    },
    {
        code: "BN",
        name_en: "Brunei Darussalam",
        name_no: "Brunei Darussalam",
        score: null,
    },
    { code: "BG", name_en: "Bulgaria", name_no: "Bulgaria", score: "3" },
    {
        code: "BF",
        name_en: "Burkina Faso",
        name_no: "Burkina Faso",
        score: "4",
    },
    { code: "BI", name_en: "Burundi", name_no: "Burundi", score: "6" },
    {
        code: "CV",
        name_en: "Cabo Verde",
        name_no: "Kapp Verde",
        score: null,
    },
    { code: "KH", name_en: "Cambodia", name_no: "Kambodsja", score: "5" },
    { code: "CM", name_en: "Cameroon", name_no: "Kamerun", score: "4" },
    { code: "CA", name_en: "Canada", name_no: "Canada", score: "3" },
    {
        code: "KY",
        name_en: "Cayman Islands",
        name_no: "Caymanøyene",
        score: null,
    },
    {
        code: "CF",
        name_en: "Central African Republic",
        name_no: "Den sentralafrikanske republikk",
        score: "6",
    },
    { code: "TD", name_en: "Chad", name_no: "Tsjad", score: "4" },
    { code: "CL", name_en: "Chile", name_no: "Chile", score: "3" },
    { code: "CN", name_en: "China", name_no: "Kina", score: "5" },
    {
        code: "CX",
        name_en: "Christmas Island",
        name_no: "Christmasøya",
        score: null,
    },
    {
        code: "CC",
        name_en: "Cocos (Keeling) Islands",
        name_no: "Kokosøyene",
        score: null,
    },
    { code: "CO", name_en: "Colombia", name_no: "Colombia", score: "5" },
    { code: "KM", name_en: "Comoros", name_no: "Komorene", score: null },
    { code: "CG", name_en: "Congo", name_no: "Kongo", score: "2" },
    {
        code: "CD",
        name_en: "Congo, Democratic Republic of the",
        name_no: "Den demokratiske republikken Kongo",
        score: "3",
    },
    {
        code: "CK",
        name_en: "Cook Islands",
        name_no: "Cookøyene",
        score: null,
    },
    { code: "CR", name_en: "Costa Rica", name_no: "Costa Rica", score: "3" },
    { code: "HR", name_en: "Croatia", name_no: "Kroatia", score: "2" },
    { code: "CU", name_en: "Cuba", name_no: "Cuba", score: null },
    { code: "CW", name_en: "Curaçao", name_no: "Curaçao", score: null },
    { code: "CY", name_en: "Cyprus", name_no: "Kypros", score: null },
    { code: "CZ", name_en: "Czechia", name_no: "Tsjekkia", score: "2" },
    {
        code: "CI",
        name_en: "Côte d'Ivoire",
        name_no: "Elfenbenskysten",
        score: "3",
    },
    { code: "DK", name_en: "Denmark", name_no: "Danmark", score: "1" },
    { code: "DJ", name_en: "Djibouti", name_no: "Djibouti", score: "4" },
    { code: "DM", name_en: "Dominica", name_no: "Dominica", score: null },
    {
        code: "DO",
        name_en: "Dominican Republic",
        name_no: "Den dominikanske republikk",
        score: "2",
    },
    { code: "EC", name_en: "Ecuador", name_no: "Ecuador", score: "5" },
    { code: "EG", name_en: "Egypt", name_no: "Egypt", score: "5" },
    {
        code: "SV",
        name_en: "El Salvador",
        name_no: "El Salvador",
        score: "4",
    },
    {
        code: "GQ",
        name_en: "Equatorial Guinea",
        name_no: "Ekvatorial-Guinea",
        score: null,
    },
    { code: "ER", name_en: "Eritrea", name_no: "Eritrea", score: "5" },
    { code: "EE", name_en: "Estonia", name_no: "Estland", score: "2" },
    { code: "SZ", name_en: "Eswatini", name_no: "Eswatini", score: "5" },
    { code: "ET", name_en: "Ethiopia", name_no: "Etiopia", score: "4" },
    {
        code: "FK",
        name_en: "Falkland Islands (Malvinas)",
        name_no: "Falklandsøyene",
        score: null,
    },
    {
        code: "FO",
        name_en: "Faroe Islands",
        name_no: "Færøyene",
        score: null,
    },
    { code: "FJ", name_en: "Fiji", name_no: "Fiji", score: "4" },
    { code: "FI", name_en: "Finland", name_no: "Finland", score: "2" },
    { code: "FR", name_en: "France", name_no: "Frankrike", score: "2" },
    {
        code: "GF",
        name_en: "French Guiana",
        name_no: "Fransk Guyana",
        score: null,
    },
    {
        code: "PF",
        name_en: "French Polynesia",
        name_no: "Fransk Polynesia",
        score: null,
    },
    {
        code: "TF",
        name_en: "French Southern Territories",
        name_no: "Franske sørterritorier",
        score: null,
    },
    { code: "GA", name_en: "Gabon", name_no: "Gabon", score: "3" },
    { code: "GM", name_en: "Gambia", name_no: "Gambia", score: null },
    { code: "GE", name_en: "Georgia", name_no: "Georgia", score: "3" },
    { code: "DE", name_en: "Germany", name_no: "Tyskland", score: "1" },
    { code: "GH", name_en: "Ghana", name_no: "Ghana", score: "2" },
    { code: "GI", name_en: "Gibraltar", name_no: "Gibraltar", score: null },
    { code: "GR", name_en: "Greece", name_no: "Hellas", score: "4" },
    { code: "GL", name_en: "Greenland", name_no: "Grønland", score: null },
    { code: "GD", name_en: "Grenada", name_no: "Grenada", score: null },
    {
        code: "GP",
        name_en: "Guadeloupe",
        name_no: "Guadeloupe",
        score: null,
    },
    { code: "GU", name_en: "Guam", name_no: "Guam", score: null },
    { code: "GT", name_en: "Guatemala", name_no: "Guatemala", score: "5" },
    { code: "GG", name_en: "Guernsey", name_no: "Guernsey", score: null },
    { code: "GN", name_en: "Guinea", name_no: "Guinea", score: "4" },
    {
        code: "GW",
        name_en: "Guinea-Bissau",
        name_no: "Guinea-Bissau",
        score: null,
    },
    { code: "GY", name_en: "Guyana", name_no: "Guyana", score: null },
    { code: "HT", name_en: "Haiti", name_no: "Haiti", score: "6" },
    {
        code: "HM",
        name_en: "Heard Island and McDonald Islands",
        name_no: "Heard- og McDonaldøyene",
        score: null,
    },
    {
        code: "VA",
        name_en: "Holy See",
        name_no: "Vatikanstaten",
        score: null,
    },
    { code: "HN", name_en: "Honduras", name_no: "Honduras", score: "5" },
    { code: "HK", name_en: "Hong Kong", name_no: "Hongkong", score: "5" },
    { code: "HU", name_en: "Hungary", name_no: "Ungarn", score: "4" },
    { code: "IS", name_en: "Iceland", name_no: "Island", score: "1" },
    { code: "IN", name_en: "India", name_no: "India", score: "5" },
    { code: "ID", name_en: "Indonesia", name_no: "Indonesia", score: "5" },
    {
        code: "IR",
        name_en: "Iran, Islamic Republic of",
        name_no: "Iran",
        score: "5",
    },
    { code: "IQ", name_en: "Iraq", name_no: "Irak", score: "5" },
    { code: "IE", name_en: "Ireland", name_no: "Irland", score: "1" },
    { code: "IM", name_en: "Isle of Mann", name_no: "Man", score: null },
    { code: "IL", name_en: "Israel", name_no: "Israel", score: "4" },
    { code: "IT", name_en: "Italy", name_no: "Italia", score: "1" },
    { code: "JM", name_en: "Jamaica", name_no: "Jamaica", score: "3" },
    { code: "JP", name_en: "Japan", name_no: "Japan", score: "2" },
    { code: "JE", name_en: "Jersey", name_no: "Jersey", score: null },
    { code: "JO", name_en: "Jordan", name_no: "Jordan", score: "5" },
    { code: "KZ", name_en: "Kazakhstan", name_no: "Kasakhstan", score: "5" },
    { code: "KE", name_en: "Kenya", name_no: "Kenya", score: "4" },
    { code: "KI", name_en: "Kiribati", name_no: "Kiribati", score: null },
    {
        code: "KP",
        name_en: "Korea, Democratic People's Republic of",
        name_no: "Nord-Korea",
        score: null,
    },
    {
        code: "KR",
        name_en: "Korea, Republic of",
        name_no: "Sør-Korea",
        score: "5",
    },
    { code: "KW", name_en: "Kuwait", name_no: "Kuwait", score: "5" },
    {
        code: "KG",
        name_en: "Kyrgyzstan",
        name_no: "Kirgisistan",
        score: "5",
    },
    {
        code: "LA",
        name_en: "Lao People's Democratic Republic",
        name_no: "Laos",
        score: "5",
    },
    { code: "LV", name_en: "Latvia", name_no: "Latvia", score: "2" },
    { code: "LB", name_en: "Lebanon", name_no: "Libanon", score: "4" },
    { code: "LS", name_en: "Lesotho", name_no: "Lesotho", score: "4" },
    { code: "LR", name_en: "Liberia", name_no: "Liberia", score: "4" },
    { code: "LY", name_en: "Libya", name_no: "Libya", score: "6" },
    {
        code: "LI",
        name_en: "Liechtenstein",
        name_no: "Liechtenstein",
        score: null,
    },
    { code: "LT", name_en: "Lithuania", name_no: "Litauen", score: "2" },
    { code: "LU", name_en: "Luxembourg", name_no: "Luxemburg", score: null },
    { code: "MO", name_en: "Macao", name_no: "Macao", score: null },
    { code: "MG", name_en: "Madagascar", name_no: "Madagaskar", score: "4" },
    { code: "MW", name_en: "Malawi", name_no: "Malawi", score: "2" },
    { code: "MY", name_en: "Malaysia", name_no: "Malaysia", score: "5" },
    { code: "MV", name_en: "Maldives", name_no: "Maldivene", score: null },
    { code: "ML", name_en: "Mali", name_no: "Mali", score: "4" },
    { code: "MT", name_en: "Malta", name_no: "Malta", score: null },
    {
        code: "MH",
        name_en: "Marshall Islands",
        name_no: "Marshalløyene",
        score: null,
    },
    {
        code: "MQ",
        name_en: "Martinique",
        name_no: "Martinique",
        score: null,
    },
    { code: "MR", name_en: "Mauritania", name_no: "Mauritania", score: "4" },
    { code: "MU", name_en: "Mauritius", name_no: "Mauritius", score: "3" },
    { code: "YT", name_en: "Mayotte", name_no: "Mayotte", score: null },
    { code: "MX", name_en: "Mexico", name_no: "Mexico", score: "4" },
    {
        code: "FM",
        name_en: "Micronesia (Federated States of)",
        name_no: "Mikronesiaføderasjonen",
        score: null,
    },
    {
        code: "MD",
        name_en: "Moldova, Republic of",
        name_no: "Moldova",
        score: "2",
    },
    { code: "MC", name_en: "Monaco", name_no: "Monaco", score: null },
    { code: "MN", name_en: "Mongolia", name_no: "Mongolia", score: null },
    { code: "ME", name_en: "Montenegro", name_no: "Montenegro", score: "3" },
    {
        code: "MS",
        name_en: "Montserrat",
        name_no: "Montserrat",
        score: null,
    },
    { code: "MA", name_en: "Morocco", name_no: "Marokko", score: "3" },
    { code: "MZ", name_en: "Mozambique", name_no: "Mosambik", score: "3" },
    { code: "MM", name_en: "Myanmar", name_no: "Myanmar", score: "6" },
    { code: "NA", name_en: "Namibia", name_no: "Namibia", score: "3" },
    { code: "NR", name_en: "Nauru", name_no: "Nauru", score: null },
    { code: "NP", name_en: "Nepal", name_no: "Nepal", score: "3" },
    { code: "NL", name_en: "Netherlands", name_no: "Nederland", score: "2" },
    {
        code: "NC",
        name_en: "New Caledonia",
        name_no: "Ny-Caledonia",
        score: null,
    },
    {
        code: "NZ",
        name_en: "New Zealand",
        name_no: "New Zealand",
        score: "2",
    },
    { code: "NI", name_en: "Nicaragua", name_no: "Nicaragua", score: null },
    { code: "NE", name_en: "Niger", name_no: "Niger", score: "3" },
    { code: "NG", name_en: "Nigeria", name_no: "Nigeria", score: "5" },
    { code: "NU", name_en: "Niue", name_no: "Niue", score: null },
    {
        code: "NF",
        name_en: "Norfolk Island",
        name_no: "Norfolkøya",
        score: null,
    },
    {
        code: "MP",
        name_en: "Northern Mariana Islands",
        name_no: "Nord-Marianene",
        score: null,
    },
    { code: "NO", name_en: "Norway", name_no: "Norge", score: "1" },
    { code: "OM", name_en: "Oman", name_no: "Oman", score: "4" },
    { code: "PK", name_en: "Pakistan", name_no: "Pakistan", score: "5" },
    { code: "PW", name_en: "Palau", name_no: "Palau", score: null },
    {
        code: "PS",
        name_en: "Palestine, State of",
        name_no: "Palestina",
        score: "6",
    },
    { code: "PA", name_en: "Panama", name_no: "Panama", score: "3" },
    {
        code: "PG",
        name_en: "Papua New Guinea",
        name_no: "Papua Ny-Guinea",
        score: null,
    },
    { code: "PY", name_en: "Paraguay", name_no: "Paraguay", score: "3" },
    { code: "PE", name_en: "Peru", name_no: "Peru", score: "4" },
    {
        code: "PH",
        name_en: "Philippines",
        name_no: "Filippinene",
        score: "5",
    },
    {
        code: "PN",
        name_en: "Pitcairn",
        name_no: "Pitcairnøyene",
        score: null,
    },
    { code: "PL", name_en: "Poland", name_no: "Polen", score: "3" },
    { code: "PT", name_en: "Portugal", name_no: "Portugal", score: "2" },
    {
        code: "PR",
        name_en: "Puerto Rico",
        name_no: "Puerto Rico",
        score: null,
    },
    { code: "QA", name_en: "Qatar", name_no: "Qatar", score: "5" },
    { code: "RE", name_en: "Réunion", name_no: "Réunion", score: null },
    { code: "RO", name_en: "Romania", name_no: "Romania", score: "3" },
    {
        code: "RU",
        name_en: "Russian Federation",
        name_no: "Russland",
        score: "5",
    },
    { code: "RW", name_en: "Rwanda", name_no: "Rwanda", score: "3" },
    {
        code: "BL",
        name_en: "Saint Barthélemy",
        name_no: "Saint-Barthélemy",
        score: null,
    },
    {
        code: "SH",
        name_en: "Saint Helena, Ascension and Tristan da Cunha",
        name_no: "St. Helena",
        score: null,
    },
    {
        code: "KN",
        name_en: "Saint Kitts and Nevis",
        name_no: "St. Kitts og Nevis",
        score: null,
    },
    {
        code: "LC",
        name_en: "Saint Lucia",
        name_no: "St. Lucia",
        score: null,
    },
    {
        code: "MF",
        name_en: "Saint Martin (French part)",
        name_no: "Saint-Martin",
        score: null,
    },
    {
        code: "PM",
        name_en: "Saint Pierre and Miquelon",
        name_no: "Saint-Pierre-et-Miquelon",
        score: null,
    },
    {
        code: "VC",
        name_en: "Saint Vincent and the Grenadines",
        name_no: "St. Vincent og Grenadinene",
        score: null,
    },
    { code: "WS", name_en: "Samoa", name_no: "Samoa", score: null },
    {
        code: "SM",
        name_en: "San Marino",
        name_no: "San Marino",
        score: null,
    },
    {
        code: "ST",
        name_en: "Sao Tome and Principe",
        name_no: "São Tomé og Príncipe",
        score: null,
    },
    {
        code: "SA",
        name_en: "Saudi Arabia",
        name_no: "Saudi-Arabia",
        score: "5",
    },
    { code: "SN", name_en: "Senegal", name_no: "Senegal", score: "4" },
    { code: "RS", name_en: "Serbia", name_no: "Serbia", score: "4" },
    {
        code: "SC",
        name_en: "Seychelles",
        name_no: "Seychellene",
        score: null,
    },
    {
        code: "SL",
        name_en: "Sierra Leone",
        name_no: "Sierra Leone",
        score: "4",
    },
    { code: "SG", name_en: "Singapore", name_no: "Singapore", score: "2" },
    {
        code: "SX",
        name_en: "Sint Maarten (Dutch part)",
        name_no: "Sint Maarten",
        score: null,
    },
    { code: "SK", name_en: "Slovakia", name_no: "Slovakia", score: "2" },
    { code: "SI", name_en: "Slovenia", name_no: "Slovenia", score: null },
    {
        code: "SB",
        name_en: "Solomon Islands",
        name_no: "Salomonøyene",
        score: null,
    },
    { code: "SO", name_en: "Somalia", name_no: "Somalia", score: "6" },
    {
        code: "ZA",
        name_en: "South Africa",
        name_no: "Sør-Afrika",
        score: "3",
    },
    {
        code: "GS",
        name_en: "South Georgia and the South Sandwich Islands",
        name_no: "Sør-Georgia og Sør-Sandwichøyene",
        score: null,
    },
    { code: "SS", name_en: "South Sudan", name_no: "Sør-Sudan", score: "6" },
    { code: "ES", name_en: "Spain", name_no: "Spania", score: "2" },
    { code: "LK", name_en: "Sri Lanka", name_no: "Sri Lanka", score: "4" },
    { code: "SD", name_en: "Sudan", name_no: "Sudan", score: "6" },
    { code: "SR", name_en: "Suriname", name_no: "Surinam", score: null },
    {
        code: "SJ",
        name_en: "Svalbard and Jan Mayen",
        name_no: "Svalbard og Jan Mayen",
        score: null,
    },
    { code: "SE", name_en: "Sweden", name_no: "Sverige", score: "1" },
    { code: "CH", name_en: "Switzerland", name_no: "Sveits", score: "3" },
    {
        code: "SY",
        name_en: "Syrian Arab Republic",
        name_no: "Syria",
        score: "6",
    },
    { code: "TW", name_en: "Taiwan", name_no: "Taiwan", score: "2" },
    {
        code: "TJ",
        name_en: "Tajikistan",
        name_no: "Tadsjikistan",
        score: null,
    },
    {
        code: "TZ",
        name_en: "Tanzania, United Republic of",
        name_no: "Tanzania",
        score: "4",
    },
    { code: "TH", name_en: "Thailand", name_no: "Thailand", score: "5" },
    {
        code: "TL",
        name_en: "Timor-Leste",
        name_no: "Øst-Timor",
        score: null,
    },
    { code: "TG", name_en: "Togo", name_no: "Togo", score: "3" },
    { code: "TK", name_en: "Tokelau", name_no: "Tokelau", score: null },
    { code: "TO", name_en: "Tonga", name_no: "Tonga", score: null },
    {
        code: "TT",
        name_en: "Trinidad and Tobago",
        name_no: "Trinidad og Tobago",
        score: "4",
    },
    { code: "TN", name_en: "Tunisia", name_no: "Tunisia", score: "5" },
    { code: "TR", name_en: "Turkey", name_no: "Tyrkia", score: "5" },
    {
        code: "TM",
        name_en: "Turkmenistan",
        name_no: "Turkmenistan",
        score: null,
    },
    {
        code: "TC",
        name_en: "Turks and Caicos Islands",
        name_no: "Turks- og Caicosøyene",
        score: null,
    },
    { code: "TV", name_en: "Tuvalu", name_no: "Tuvalu", score: null },
    { code: "UG", name_en: "Uganda", name_no: "Uganda", score: "4" },
    { code: "UA", name_en: "Ukraine", name_no: "Ukraina", score: null },
    {
        code: "AE",
        name_en: "United Arab Emirates",
        name_no: "De forente arabiske emirater",
        score: "5",
    },
    {
        code: "GB",
        name_en: "United Kingdom of Great Britain and Northern Ireland",
        name_no: "Storbritannia",
        score: "4",
    },
    {
        code: "US",
        name_en: "United States of America",
        name_no: "USA",
        score: "4",
    },
    { code: "UY", name_en: "Uruguay", name_no: "Uruguay", score: "2" },
    {
        code: "UZ",
        name_en: "Uzbekistan",
        name_no: "Usbekistan",
        score: null,
    },
    { code: "VU", name_en: "Vanuatu", name_no: "Vanuatu", score: null },
    { code: "VE", name_en: "Venezuela", name_no: "Venezuela", score: "5" },
    { code: "VN", name_en: "Viet Nam", name_no: "Vietnam", score: "4" },
    {
        code: "VG",
        name_en: "Virgin Islands (British)",
        name_no: "De britiske jomfruøyene",
        score: null,
    },
    {
        code: "VI",
        name_en: "Virgin Islands (U.S.)",
        name_no: "De amerikanske jomfruøyene",
        score: null,
    },
    {
        code: "WF",
        name_en: "Wallis and Futuna",
        name_no: "Wallis- og Futunaøyene",
        score: null,
    },
    {
        code: "EH",
        name_en: "Western Sahara",
        name_no: "Vest-Sahara",
        score: null,
    },
    { code: "YE", name_en: "Yemen", name_no: "Jemen", score: "6" },
    { code: "ZM", name_en: "Zambia", name_no: "Zambia", score: "4" },
    { code: "ZW", name_en: "Zimbabwe", name_no: "Zimbabwe", score: "5" },
];
