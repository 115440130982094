import { ChevronDown } from "@ignite-analytics/icons";
import { Box, Chip, IconButton, Paper, Popper, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";

import { getLocalName } from "../../utils";
import { countryCodes } from "../values/CountryOptions";

import { ProdCountryChip } from "./ProdCountryChip";

interface ProdCountriesCellProps {
    params: GridRenderCellParams<GridValidRowModel, unknown, unknown, GridTreeNodeWithRender>;
    isEditor: boolean;
}

export const ProdCountriesCell: React.FC<ProdCountriesCellProps> = ({ params, isEditor }) => {
    const { api, id, field } = params;
    const value = params.value as string[];

    const { formatMessage } = useIntl();

    const sortedCountries = countryCodes
        .filter((country) => value.includes(country.code))
        .sort(
            (a, b) =>
                (b.score !== null ? parseInt(b.score) : -Infinity) - (a.score !== null ? parseInt(a.score) : -Infinity)
        );

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            height="100%"
            sx={{ position: "relative" }}
        >
            {sortedCountries.length > 0 ? (
                <Stack direction="row" spacing={1}>
                    <ProdCountryChip score={sortedCountries[0].score} label={sortedCountries[0].code} />
                    {sortedCountries.length > 1 && (
                        <>
                            <Chip
                                color="neutral"
                                size="medium"
                                label={`+${sortedCountries.length - 1}`}
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                sx={{
                                    cursor: "pointer",
                                }}
                            />
                            <Popper
                                open={open}
                                anchorEl={anchorEl}
                                modifiers={[
                                    {
                                        name: "preventOverflow",
                                        options: {
                                            boundary: "scrollParent",
                                        },
                                    },
                                ]}
                                sx={{ zIndex: 1501 }}
                            >
                                <Box width="300px" height="auto">
                                    <Paper>
                                        <Stack spacing={2} padding={2}>
                                            <Typography variant="textMd" fontWeight={500}>
                                                <FormattedMessage
                                                    defaultMessage="Production countries"
                                                    description="Production countries explainer popper title"
                                                />
                                            </Typography>
                                            <Stack direction="row" flexWrap="wrap" gap={1} display="flex">
                                                {sortedCountries.map((country) => {
                                                    return (
                                                        <ProdCountryChip
                                                            label={getLocalName(country)}
                                                            score={country.score}
                                                            key={country.code}
                                                        />
                                                    );
                                                })}
                                            </Stack>
                                            <Typography variant="textSm">
                                                <FormattedMessage
                                                    defaultMessage="Production countries are listed by criticality; those with the same risk are ordered alphabetically."
                                                    description="Production countries popper explainer text"
                                                ></FormattedMessage>
                                            </Typography>
                                        </Stack>
                                    </Paper>
                                </Box>
                            </Popper>
                        </>
                    )}
                </Stack>
            ) : (
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Select" />
                </Typography>
            )}
            <Box flexGrow={3} />
            <Box>
                <NoPermissionTooltip
                    hasPermission={!!isEditor}
                    message={formatMessage({
                        defaultMessage: "Your permissions do not allow editing",
                    })}
                >
                    <IconButton
                        disabled={!isEditor}
                        onClick={() => api.startCellEditMode({ id, field })}
                        sx={{
                            position: "absolute",
                            right: 0,
                            transform: "translateY(-50%)",
                        }}
                    >
                        <ChevronDown />
                    </IconButton>
                </NoPermissionTooltip>
            </Box>
        </Stack>
    );
};
