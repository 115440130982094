import { ShapeIcon } from "@ignite-analytics/components";
import { GridPlus } from "@ignite-analytics/icons";
import { Paper, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface EmptyStateProps {
    handleCreateGroup: () => void;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ handleCreateGroup }) => {
    return (
        <Stack alignItems="center" spacing={3} mb={5}>
            <ShapeIcon color="accent" size="medium">
                <GridPlus color="inherit" />
            </ShapeIcon>
            <Stack alignItems="center">
                <Typography variant="textXl" fontWeight={500}>
                    <FormattedMessage defaultMessage="No supplier groups available" />
                </Typography>
                <Typography variant="textSm" color="GrayText">
                    <FormattedMessage defaultMessage="To prioritize and manage social risk effectively, start by selecting the suppliers you want to focus on." />
                </Typography>
            </Stack>
            <Paper onClick={handleCreateGroup} sx={{ cursor: "pointer", width: "70%" }}>
                <Stack spacing={1} padding={2}>
                    <Typography variant="textMd" fontWeight={500}>
                        <FormattedMessage defaultMessage="Create supplier group" />
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                        <FormattedMessage defaultMessage="Customize your supplier group to specific needs by defining custom criteria" />
                    </Typography>
                </Stack>
            </Paper>
        </Stack>
    );
};
