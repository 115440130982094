import { CircleSolid } from "@ignite-analytics/icons";
import { Autocomplete, InputAdornment, Stack, TextField, Typography, useTheme } from "@mui/material";
import React from "react";

import { countryCodes } from "@/lib/data/CountryOptions";
import { RiskOption } from "@/lib/data/types";
import { getLabel } from "@/lib/data/utils";
import { track, TRACK_PREFIX } from "@/lib/track";

interface RiskAutocompleteProps {
    columnId: string;
    value: string;
    onChange: (columnName: string, newValue: string | undefined) => void;
    isEditor: boolean;
}

export const CountryAutocomplete: React.FC<RiskAutocompleteProps> = ({ columnId, value, onChange, isEditor }) => {
    const theme = useTheme();
    const handleChange = (_event: React.SyntheticEvent<Element, Event>, newValue: RiskOption | null) => {
        track(`${TRACK_PREFIX}: Updated Country`, { initialValue: value, newValue });
        onChange(columnId, newValue?.code);
    };
    const options = countryCodes;
    const getColors = (value: string) => {
        switch (value) {
            case "1":
            case "2":
                return theme.palette.tokens?.icon.iconSuccess;
            case "3":
            case "4":
                return theme.palette.tokens?.icon.iconWarning;
            case "5":
            case "6":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    };

    return (
        <Autocomplete
            size="small"
            options={options}
            value={options.find((option) => option.code === value) ?? null}
            fullWidth
            getOptionLabel={getLabel}
            renderOption={(props, option) => (
                <Stack component="li" {...props} direction="row" spacing={1} fontSize="8px">
                    <CircleSolid fontSize="inherit" sx={{ color: getColors?.(option.score ?? "") }} />
                    <Typography variant="textSm">{getLabel(option)}</Typography>
                </Stack>
            )}
            disabled={!isEditor}
            renderInput={(params) => (
                <TextField
                    {...params}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start" sx={{ fontSize: "0.5rem" }}>
                                    <CircleSolid
                                        fontSize="inherit"
                                        sx={{
                                            color: `${getColors?.(
                                                options.find(
                                                    (o) =>
                                                        params.inputProps.value &&
                                                        typeof params.inputProps.value === "string" &&
                                                        o.code === params.inputProps.value.split(":")[0]
                                                )?.score ?? ""
                                            )}`,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            )}
            onChange={handleChange}
        />
    );
};
