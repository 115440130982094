import { graphql } from "@/gql";

export const getBusinessUnits = graphql(`
    query getBusinessUnits($input: GetBusinessUnitsInput!) {
        getBusinessUnits(input: $input) {
            businessUnits {
                id
                name
                level
                hasChildren
            }
        }
    }
`);
