import { ChevronDown, CircleSolid } from "@ignite-analytics/icons";
import { Box, Chip, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { track, TRACK_PREFIX } from "@/lib/track";

const SignedChip: React.FC<{ value: boolean }> = ({ value }) => {
    return value ? (
        <Chip
            icon={<CircleSolid />}
            iconSize="small"
            color="success"
            variant="status"
            label={<FormattedMessage defaultMessage="Signed" description="Code of conduct status chip true" />}
        />
    ) : (
        <Chip
            icon={<CircleSolid />}
            iconSize="small"
            color="error"
            variant="status"
            label={<FormattedMessage defaultMessage="Not signed" description="Code of conduct status chip false" />}
        />
    );
};

export const CoCCell: React.FC<{
    supplierId: string;
    value: boolean | null;
    onChange: (columnName: string, supplierId: string, newValue: string | undefined) => void;
}> = ({ value, supplierId, onChange }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value: boolean | undefined) => {
        track(`${TRACK_PREFIX}: Updated CoC Value`, { status: value });
        onChange("has_code_of_conduct", supplierId, value?.toString());
        handleClose();
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="flex-end" height="100%">
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value === null ? (
                    <Typography variant="textSm" width="50%">
                        <FormattedMessage defaultMessage="Select" />
                    </Typography>
                ) : (
                    <SignedChip value={value as boolean} />
                )}
            </Box>

            <IconButton onClick={handleClick}>
                <ChevronDown sx={{ color: (theme) => theme.palette.tokens?.icon.iconGray }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={() => handleSelect(true)}>
                    <SignedChip value={true} />
                </MenuItem>
                <MenuItem onClick={() => handleSelect(false)}>
                    <SignedChip value={false} />
                </MenuItem>
                <MenuItem
                    onClick={() => handleSelect(undefined)}
                    sx={{ borderTop: "1px solid", borderColor: "divider" }}
                >
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Reset status" />
                    </Typography>
                </MenuItem>
            </Menu>
        </Stack>
    );
};
