import { Chip } from "@mui/material";
import { useIntl } from "react-intl";

interface AssessmentFieldProps {
    status: "notSent" | "sent" | "submitted";
}

type StatusValue = "submitted" | "sent" | "notSent";

export const AssessmentField: React.FC<AssessmentFieldProps> = ({ status }) => {
    const { formatMessage } = useIntl();

    const getChipColor = (status: StatusValue) => {
        switch (status) {
            case "submitted":
                return "success";
            case "sent":
                return "primary";
            case "notSent":
                return "warning";
            default:
                return "neutral";
        }
    };

    const statusText = {
        submitted: formatMessage({ defaultMessage: "Submitted" }),
        sent: formatMessage({ defaultMessage: "Pending" }),
        notSent: formatMessage({ defaultMessage: "Not sent" }),
    };

    if (!statusText[status]) {
        return null;
    }

    return (
        <Chip
            variant="status"
            key={statusText[status]}
            label={statusText[status]}
            size="small"
            color={getChipColor(status)}
            sx={{
                cursor: "pointer",
            }}
        />
    );
};
