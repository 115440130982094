import { GetSpendCategoriesQuery } from "@/gql/graphql";

import { LOADING_CHILDREN_LABEL } from "./constants";
import { Category } from "./types";

export function replaceChildrenInTree(tree: Category[], targetId: string, newChildren: Category[]): Category[] {
    return tree.map((category) => {
        if (category.id === targetId) {
            // Replace the children of the category with the given id
            return { ...category, children: newChildren, hasChildren: newChildren.length > 0 };
        }

        // If the category has children, we recursively search within them
        if (category.hasChildren) {
            return {
                ...category,
                children: replaceChildrenInTree(category.children, targetId, newChildren),
            };
        }

        // If no match, return the category as is
        return category;
    });
}

export function addNewChildrenDataToTree(
    data: GetSpendCategoriesQuery,
    parentId: string,
    categoryTree: Category[]
): Category[] {
    const newChildren = data.getSpendCategories.categories.map((category) => {
        return {
            id: category.id,
            label: category.name,
            level: category.level,
            hasChildren: category.hasChildren,
            children: category.hasChildren
                ? [
                      // The component needs a dummy child to display the expand icon
                      {
                          id: `${category.id}-child`,
                          label: LOADING_CHILDREN_LABEL,
                          level: category.level + 1,
                          hasChildren: false,
                          children: [],
                      },
                  ]
                : [],
        };
    });
    const newTree = replaceChildrenInTree(categoryTree, parentId, newChildren);
    return newTree;
}

export function mapInitialDataToTree(data: GetSpendCategoriesQuery): Category[] {
    return data.getSpendCategories.categories.map((category) => {
        return {
            id: category.id,
            label: category.name,
            level: category.level,
            hasChildren: category.hasChildren,
            children: category.hasChildren
                ? [
                      // The component needs a dummy child to display the expand icon
                      {
                          id: `${category.id}-child`,
                          label: LOADING_CHILDREN_LABEL,
                          level: category.level + 1,
                          hasChildren: false,
                          children: [],
                      },
                  ]
                : [],
        };
    });
}
