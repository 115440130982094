import { useQuery } from "@apollo/client";
import { ChevronDown, ChevronUp, X } from "@ignite-analytics/icons";
import { Button, Chip, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { CategoryPopper } from "./CategoryPopper";
import { getSpendCategories } from "./queries";
import { Category } from "./types";
import { mapInitialDataToTree } from "./utils";

interface Props {
    categoryIDs: string[];
    setCategoryIDs: React.Dispatch<React.SetStateAction<string[]>>;
    categoryTree: Category[];
    setCategoryTree: React.Dispatch<React.SetStateAction<Category[]>>;
}

export const SpendCategorySelect: React.FC<Props> = ({
    categoryIDs,
    setCategoryIDs,
    categoryTree,
    setCategoryTree,
}) => {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleCategoryID = useCallback(
        (categoryID: string) => {
            setCategoryIDs((prev) => {
                if (prev.includes(categoryID)) {
                    return prev.filter((id) => id !== categoryID);
                }
                const findCategory = (categories: Category[], id: string): Category | undefined => {
                    const found = categories.find((category) => category.id === id);
                    if (found) return found;
                    for (const category of categories) {
                        const child = findCategory(category.children, id);
                        if (child) return child;
                    }
                };
                const toggledCategory = findCategory(categoryTree, categoryID);
                if (!toggledCategory?.children) {
                    return [...prev, categoryID];
                }

                const childrenIDs: string[] = [];
                const addCategoryIDs = (category: Category) => {
                    childrenIDs.push(category.id);
                    for (const child of category.children) {
                        addCategoryIDs(child);
                    }
                };
                addCategoryIDs(toggledCategory);

                return [...prev.filter((oldCategoryID) => !childrenIDs.includes(oldCategoryID)), categoryID];
            });
        },
        [setCategoryIDs, categoryTree]
    );

    useQuery(getSpendCategories, {
        variables: {
            input: {
                parentId: null,
            },
        },
        skip: categoryTree.length > 0,
        onCompleted: (data) => {
            setCategoryTree((prevTree) => {
                if (prevTree.length > 0) return prevTree;
                return mapInitialDataToTree(data);
            });
        },
    });

    const categoryNames = useMemo(() => {
        const names: Record<string, { name: string; level: number }> = {};
        const addNames = (categories: Category[]) => {
            for (const category of categories) {
                names[category.id] = { name: category.label, level: category.level };
                if (category.children) {
                    addNames(category.children);
                }
            }
        };
        addNames(categoryTree);
        return names;
    }, [categoryTree]);

    return (
        <Stack direction="row" width={590}>
            <Button
                onClick={() => setOpen(true)}
                ref={buttonRef}
                endIcon={open ? <ChevronUp /> : <ChevronDown />}
                color="secondary"
                fullWidth
                sx={{ height: "fit-content", paddingY: 0.5 }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    {categoryIDs.length > 0 ? (
                        <Stack direction="row" gap={1} flexWrap="wrap" sx={{ overflowX: "hidden" }}>
                            {categoryIDs.map((id) => (
                                <Chip
                                    key={id}
                                    label={
                                        <FormattedMessage
                                            defaultMessage="L{level} {name}"
                                            description="Category chip label"
                                            values={{ name: categoryNames[id].name, level: categoryNames[id].level }}
                                        />
                                    }
                                    onDelete={() => toggleCategoryID(id)}
                                    color="neutral"
                                    size="small"
                                />
                            ))}
                        </Stack>
                    ) : (
                        <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                            <FormattedMessage defaultMessage="Select categories" />
                        </Typography>
                    )}
                    <IconButton
                        onClick={(event) => {
                            setCategoryIDs([]);
                            event.stopPropagation();
                        }}
                        size="2xsmall"
                    >
                        <X />
                    </IconButton>
                </Stack>
            </Button>
            <CategoryPopper
                open={open}
                anchorEl={buttonRef.current}
                value={categoryIDs}
                onChange={toggleCategoryID}
                onClose={() => setOpen(false)}
                categoryTree={categoryTree}
                setCategoryTree={setCategoryTree}
            />
        </Stack>
    );
};
