import { RiskScore } from "@/gql/graphql";

export function riskScoreToColor(score: RiskScore | null | undefined): "error" | "warning" | "success" | null {
    switch (score) {
        case RiskScore.VeryHigh:
        case RiskScore.High: {
            return "error";
        }
        case RiskScore.Medium: {
            return "warning";
        }
        case RiskScore.Low:
        case RiskScore.VeryLow: {
            return "success";
        }
        case undefined:
        case null: {
            return null;
        }
    }
}
