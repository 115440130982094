import { DotsVertical, InformationCircle } from "@ignite-analytics/icons";
import { Box, Button, Card, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { GroupsList_SupplierGroupFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { EmptyState } from "../EmptyState";
import { GroupProgress } from "../LinerProgress/GroupProgress";

import { EditMenu } from "./EditGroup";

graphql(`
    fragment GroupsList_SupplierGroup on SupplierGroup {
        id
        name
        progress {
            ManualEnrichment
            IgniteEnrichment
            EmptyEnrichment
        }
    }
`);

interface GroupsListProps {
    isEditor: boolean;
    groups: GroupsList_SupplierGroupFragment[];
    handleCreateGroup: () => void;
}

export const GroupsList: React.FC<GroupsListProps> = ({ isEditor, groups, handleCreateGroup }) => {
    const { formatMessage } = useIntl();
    const [groupId, setGroupId] = useState<string | undefined>(undefined);

    const navigate = useOutgoingNavigate();
    const handleClickViewGroup = (group: GroupsList_SupplierGroupFragment) => {
        track(`${TRACK_PREFIX}: Viewed Group`, { groupId: group.id, progress: group.progress });
        navigate(`/social-risk/${group.id}`);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>, groupId: string) => {
        setGroupId(groupId);
        setAnchorEl(event.currentTarget);
    };

    return (
        <Paper sx={{ padding: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                <Typography variant="textLg" fontWeight="medium">
                    <FormattedMessage
                        defaultMessage="Prioritized supplier groups"
                        description="Supplier groups overview header"
                    ></FormattedMessage>
                </Typography>
            </Stack>

            {groups.length === 0 ? (
                <EmptyState handleCreateGroup={handleCreateGroup} />
            ) : (
                <Stack>
                    <Stack direction="row" justifyContent="flex-start" px={2} pb={0.5}>
                        <Typography variant="caption" fontWeight={500} sx={{ width: "70%" }}>
                            <FormattedMessage defaultMessage="Name" description="Supplier group list naame header" />
                        </Typography>
                        <Typography variant="caption" fontWeight={500} sx={{ width: "30%" }}>
                            <FormattedMessage
                                defaultMessage="Assessment progress"
                                description="Supplier group list naame header"
                            />
                        </Typography>
                    </Stack>
                    {groups.map((group) => (
                        <Box
                            padding={1}
                            key={group.id}
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleClickViewGroup(group)}
                        >
                            <Card>
                                <Stack
                                    key={`group-${group.id}`}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    px={2}
                                    py={1}
                                >
                                    <Typography variant="textLg" fontWeight={500}>
                                        {group.name}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        width="30%"
                                        alignItems="center"
                                        justifyContent="space-around"
                                        spacing={3}
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1.5} width="100%">
                                            <GroupProgress
                                                manualEnrichmentProgress={group.progress.ManualEnrichment}
                                                igniteEnrichmentProgress={group.progress.IgniteEnrichment}
                                                emptyEnrichmentProgress={group.progress.EmptyEnrichment}
                                                cursor="pointer"
                                            />
                                            <Tooltip
                                                title={formatMessage({
                                                    defaultMessage:
                                                        "Share of estimate that is Ignite risk assessment vs. your risk assessment",
                                                    description: "Enrichment progress tooltip",
                                                })}
                                                placement="top"
                                            >
                                                <InformationCircle fontSize="small" />
                                            </Tooltip>
                                        </Stack>
                                        <Button color="secondary" size="small">
                                            <FormattedMessage
                                                defaultMessage="View"
                                                description="Social Risk group view button"
                                            />
                                        </Button>
                                        <IconButton
                                            size="small"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleClick(event, group.id);
                                            }}
                                        >
                                            <DotsVertical />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Box>
                    ))}
                    {groupId && (
                        <EditMenu
                            anchorEl={anchorEl}
                            groupId={groupId}
                            isEditor={isEditor}
                            setAnchorEl={setAnchorEl}
                            onClose={() => {
                                setAnchorEl(null);
                                setGroupId(undefined);
                            }}
                        />
                    )}
                </Stack>
            )}
        </Paper>
    );
};
