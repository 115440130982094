import { Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";

import { DeleteModal } from "./DeleteModal";

interface EditMenuProps {
    groupId: string;
    isEditor: boolean;
    showGroupDetails: () => void;
    addSuppliersModal: () => void;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}
export const EditMenu: React.FC<EditMenuProps> = ({
    anchorEl,
    groupId,
    addSuppliersModal,
    showGroupDetails,
    handleClose,
    isEditor,
}) => {
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);

    return (
        <Stack justifyContent="center" alignItems="center" height="100%" onClick={(e) => e.stopPropagation()}>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            boxShadow: "none",
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        showGroupDetails();
                        handleClose();
                    }}
                >
                    <FormattedMessage
                        defaultMessage="View group details"
                        description="View group details menu button"
                    />
                </MenuItem>
                <NoPermissionTooltip
                    hasPermission={isEditor}
                    message={
                        <FormattedMessage defaultMessage="You must have editor permissions to perform this action." />
                    }
                >
                    <MenuItem
                        onClick={() => {
                            setOpenModal(true);
                            handleClose();
                        }}
                        disabled={!isEditor}
                    >
                        <FormattedMessage defaultMessage="Delete group" />
                    </MenuItem>
                </NoPermissionTooltip>
                <MenuItem
                    onClick={() => {
                        addSuppliersModal();
                        handleClose();
                    }}
                >
                    <FormattedMessage defaultMessage="Add suppliers" />
                </MenuItem>
            </Menu>
            {openModal && <DeleteModal open={openModal} onClose={() => setOpenModal(false)} groupId={groupId} />}
        </Stack>
    );
};
