import { ArrowRight } from "@ignite-analytics/icons";
import { Chip, ChipProps, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { getRiskValue } from "../GroupPageTable/Components/EstimatedRiskCell/utils";

export type SuggestedStatusLevel = "VERY_LOW" | "LOW" | "MEDIUM" | "HIGH" | "VERY_HIGH" | "MISSING";

interface SuggestedStatusChipProps extends ChipProps {
    status: SuggestedStatusLevel;
    // adjusted: boolean;   // (possibly 'overridden' instead) - "solidifies" the chip
}

const LevelText: React.FC<{ status: SuggestedStatusLevel }> = ({ status }) => {
    switch (status) {
        case "MISSING":
            return <FormattedMessage defaultMessage="N/A" description="Missing risk chip label" />;
        case "VERY_LOW":
            return <FormattedMessage defaultMessage="Very low" description="Very low risk chip label" />;
        case "LOW":
            return <FormattedMessage defaultMessage="Low" description="Low risk chip label" />;
        case "MEDIUM":
            return <FormattedMessage defaultMessage="Medium" description="Medium risk chip label" />;
        case "HIGH":
            return <FormattedMessage defaultMessage="High" description="High risk chip label" />;
        case "VERY_HIGH":
            return <FormattedMessage defaultMessage="Very high" description="Very high risk chip label" />;
    }
};

const getLevelColor = (status: SuggestedStatusLevel) => {
    switch (status) {
        case "MISSING":
            return "infoGray";
        case "VERY_LOW":
            return "success";
        case "LOW":
            return "success";
        case "MEDIUM":
            return "warning";
        case "HIGH":
            return "error";
        case "VERY_HIGH":
            return "error";
    }
};

export const SuggestedStatusChip = ({ status, ...props }: SuggestedStatusChipProps) => {
    return (
        <Chip
            label={<LevelText status={status} />}
            variant="status"
            color={getLevelColor(status)}
            sx={{ border: "1px, dashed" }}
            {...props}
        />
    );
};

export const StatusChip = ({ status, ...props }: SuggestedStatusChipProps) => {
    return (
        <Chip
            label={<LevelText status={status} />}
            variant="status"
            color={getLevelColor(status)}
            sx={{ border: "1px" }}
            {...props}
        />
    );
};

export const RiskChange = ({
    prevVal,
    newVal,
    socialRisk,
}: {
    prevVal?: SuggestedStatusLevel;
    newVal?: SuggestedStatusLevel;
    socialRisk?: SuggestedStatusLevel;
}) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography variant="textXs" sx={{ color: (theme) => theme.palette.tokens?.text.textBody }}>
                <FormattedMessage defaultMessage="Changed status from" />
            </Typography>
            <Stack direction="row" alignItems="center" spacing={0.5}>
                {prevVal ? (
                    <StatusChip status={prevVal} size="small" />
                ) : (
                    <SuggestedStatusChip status={getRiskValue(socialRisk ?? "MISSING")} size="small" />
                )}
                <ArrowRight />
                {newVal ? (
                    <StatusChip status={newVal} size="small" />
                ) : (
                    <SuggestedStatusChip status={getRiskValue(socialRisk ?? "MISSING")} size="small" />
                )}
            </Stack>
        </Stack>
    );
};
