import { FolderExclamation } from "@ignite-analytics/icons";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { FormattedMessage } from "react-intl";

const ErrorFallback = () => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "80vh" }}>
            <Alert
                severity="error"
                sx={{ textAlign: "center", alignItems: "center", justifyContent: "center", width: "60%" }}
                icon={false}
            >
                <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                        <FolderExclamation fontSize="large" sx={{ marginBottom: "10px" }} />
                        <AlertTitle sx={{ fontSize: 38 }}>
                            <FormattedMessage
                                defaultMessage="Something went wrong!"
                                description="Something went wrong message"
                            />
                        </AlertTitle>
                    </Stack>
                    <Typography sx={{ fontSize: 22 }}>
                        <FormattedMessage
                            defaultMessage="Our team has been informed. You can attempt to refresh the page.
                                If the issue persists, please reach out to our support team for assistance."
                            description="Team informed message"
                        />
                    </Typography>
                    <Button size="large" onClick={() => window.location.reload()}>
                        <FormattedMessage defaultMessage="Refresh" description="Refresh message" />
                    </Button>
                </Stack>
            </Alert>
        </Stack>
    );
};

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
    return (
        <SentryErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("app", "social-risk-app");
            }}
            fallback={<ErrorFallback />}
        >
            {children}
        </SentryErrorBoundary>
    );
};
