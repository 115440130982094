import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import { GroupPageTable } from "@/components/GroupPageTable";
import { graphql } from "@/gql";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/permissions";

import { GroupPageHeader } from "../../components/GroupPageHeader";

const getGroupQuery = graphql(`
    query GroupPage_GetGroup($input: GetSupplierGroupInput!) {
        getSupplierGroup(input: $input) {
            supplierGroup {
                ...GroupPageHeader_SupplierGroup
                ...GroupPageTable_SupplierGroup
                ...GroupDetailsModal_SupplierGroup
            }
        }
    }
`);

export const GroupPage: React.FC = () => {
    const isEditor = usePermission({ object: "general", relation: "write" });

    const { id: groupId } = useParams<{ id: string }>();

    const { data: groupData } = useQuery(getGroupQuery, {
        variables: {
            input: {
                id: groupId ?? "",
            },
        },
        skip: !groupId,
    });
    const group = groupData?.getSupplierGroup.supplierGroup;
    useSetBreadcrumbs(group?.name ?? "");

    // should return empty state/failed loading here
    if (!group) {
        return null;
    }

    return (
        <Stack spacing={2}>
            <GroupPageHeader isEditor={isEditor} group={group} />
            <GroupPageTable isEditor={isEditor} group={group} />
        </Stack>
    );
};
