import { Divider, Stack } from "@mui/material";
import { DataGridPro, GridToolbarContainer } from "@mui/x-data-grid-pro";

import { GridToolbarSearchField } from "./GridToolbarSearchField";
import { GridToolbarTotalItemsContainer } from "./GridToolbarTotalItemsContainer";
import { columns } from "./GroupPageDefinitions";

const groupedSuppliers = [
    {
        id: 1,
        spend: 343425,
        name: "Supplier 1",
        productionCountry: "Type 1",
        country: "Country 1",
        industry: "34.124: Crop and animal production",
        assessmentStatus: "Submitted",
        score: 100,
        estimatedRisk: "veryhigh",
    },
    {
        id: 2,
        spend: 142523,
        name: "Supplier 2",
        productionCountry: "",
        industry: "36.1: Mining of asphalt",
        country: "Country 2",
        assessmentStatus: "Not submitted",
        score: 58,
        estimatedRisk: "low",
    },
    {
        id: 3,
        spend: 23434,
        name: "Supplier 3",
        productionCountry: "Type 3",
        industry: "34.124: Crop and animal production",
        country: "Country 3",
        assessmentStatus: "Submitted",
        score: 34,
        estimatedRisk: "medium",
    },
    {
        id: 4,
        spend: 1344,
        name: "Supplier 4",
        productionCountry: "Type 4",
        industry: "34.124: Crop and animal production",
        country: "Country 4",
        assessmentStatus: "Submitted",
        score: 20,
        estimatedRisk: "high",
    },
    {
        id: 5,
        spend: 12385,
        name: "Supplier 5",
        productionCountry: "Type 5",
        industry: "34.124: Crop and animal production",
        country: "Country 5",
        assessmentStatus: "Not submitted",
        score: 25,
        estimatedRisk: "verylow",
    },
    {
        id: 6,
        spend: 12385,
        name: "Supplier 6",
        productionCountry: "Type 6",
        industry: "34.124: Crop and animal production",
        country: "Country 6",
        assessmentStatus: "Submitted",
        score: 45,
        estimatedRisk: "medium",
    },
    {
        id: 7,
        spend: 12385,
        name: "Supplier 7",
        productionCountry: "Type 7",
        industry: "34.124: Crop and animal production",
        country: "Country 7",
        assessmentStatus: "Submitted",
        score: 80,
        estimatedRisk: "high",
    },
    {
        id: 8,
        spend: 12385,
        name: "Supplier 8",
        productionCountry: "Type 8",
        industry: "34.124: Crop and animal production",
        country: "Country 8",
        assessmentStatus: "Not submitted",
        score: 10,
        estimatedRisk: "verylow",
    },
];

export const GroupPageTable: React.FC = () => {
    return (
        <DataGridPro
            rows={groupedSuppliers}
            columns={columns}
            initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
            }}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
            slots={{ toolbar: () => <CustomToolbar /> }}
            pagination
        />
    );
};

const CustomToolbar: React.FC = () => {
    return (
        <GridToolbarContainer>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" p={2}>
                <Stack direction="row" columnGap={1} width="50%" alignItems="center">
                    <GridToolbarTotalItemsContainer />
                    <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                    <GridToolbarSearchField />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
};
