import * as Sentry from "@sentry/react";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";

export type User = {
    id: string;
    email: string;
    tenant: string;
    firstName: string;
    lastName: string;
};

const initialUserProps: User = {
    id: "",
    email: "",
    tenant: "",
    firstName: "",
    lastName: "",
};

export const userContext = createContext<User>(initialUserProps);

type Props = { children?: ReactNode };

export const UserContextProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<User>(initialUserProps);

    const getUserInfo = async (): Promise<User | undefined> => {
        const user: User | undefined = await fetch(`${process.env.REACT_APP_ORY_URL}/sessions/whoami`, {
            credentials: "include",
        })
            .then(async (res) => {
                const j = await res.json();
                if (j.error) {
                    throw new Error(j.error.reason ?? "Failed to fetch ory session");
                }
                return {
                    id: j.identity.id,
                    email: j.identity.traits.email,
                    tenant: j.identity.metadata_public.tenant,
                    firstName: j.identity.traits?.name?.first,
                    lastName: j.identity.traits?.name?.last,
                };
            })
            .catch((err) => {
                Sentry.captureException(err, {
                    tags: { app: "social-risk-app", message: "Failed to fetch ory session" },
                });
                return undefined;
            });
        if (user) return user;
    };

    useEffect(() => {
        getUserInfo().then((userInfo) => userInfo && setUser(userInfo));
    }, []);

    const contextValue = useMemo(() => user, [user]);

    if (!user) return null;
    return <userContext.Provider value={contextValue}>{children}</userContext.Provider>;
};

export function useUser(): User {
    const context = useContext(userContext);
    if (context === undefined) {
        throw new Error("Missing user context");
    }
    return context;
}
