import React from "react";
import { createBrowserRouter, Navigate, NavigateFunction, RouterProvider } from "react-router-dom";

import { Layout } from "@/components/Layout";
import { GroupPage } from "@/pages/GroupPage";
import { OverviewPage } from "@/pages/OverviewPage";
import { NavigationContextProvider } from "@/providers/NavigationContext";

interface SocialRiskRouteParams {
    navigate?: NavigateFunction;
}

export const AppRoutes: React.FC<SocialRiskRouteParams> = ({ navigate }) => {
    const router = createBrowserRouter([
        {
            id: "root",
            path: "/",
            element: (
                <NavigationContextProvider navigateFunc={navigate}>
                    <Layout />
                </NavigationContextProvider>
            ),
            children: [
                { path: "/", element: <Navigate to="/social-risk" replace /> },
                { path: "/social-risk", element: <OverviewPage /> },
                { path: "social-risk/:id", element: <GroupPage /> },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};
