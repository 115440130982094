import { useMutation, useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { InformationCircle } from "@ignite-analytics/icons";
import { formatDateAsUTC, formatValue } from "@ignite-analytics/locale";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import {
    ColumnType,
    GroupPageTable_SupplierFragmentDoc,
    ReasonTooltip_SupplierFragmentDoc,
    Supplier,
    UseGetColumn_SupplierCountryFragment,
} from "@/gql/graphql";
import { useAlert } from "@/providers";
import { useCompanyCurrency } from "@/providers/CompanyCurrencyContext";

import { OnboardingField } from "../OnboardingField";
import { useCountries } from "../SupplierDrawer/RiskPicker/countries";

import { AssessmentField } from "./Components/AssessmentsCell";
import { ClassificationField } from "./Components/ClassificationCell";
import { EstimatedRiskCell } from "./Components/EstimatedRiskCell/EstimatedRiskCell";
import { MonetaryAmount } from "./Components/MonetaryAmountCell";
import { CountryCell } from "./Components/RiskCell/CountryCell";
import { CountryEditCell } from "./Components/RiskCell/CountryEditCell";
import { ProdCountriesAutocomplete } from "./Components/RiskCell/ProdCountriesAutocomplete";
import { ProdCountriesCell } from "./Components/RiskCell/ProdCountriesCell";
import { ProductionCountriesCell } from "./Components/RiskCell/ProductionCountriesCell";
import { ProductionCountriesEditCell } from "./Components/RiskCell/ProductionCountriesEditCell";
import { RenderCountry } from "./Components/RiskCell/RenderCountry";
import { RenderNace } from "./Components/RiskCell/RenderNace";
import { RiskAutocomplete } from "./Components/RiskCell/RiskAutocomplete";
import { SupplierNameField } from "./Components/SupplierNameField";
import { TableData } from "./utils";

export const ONBOARDING_COLUMN_ID = "ONBOARDING_COLUMN";
export const ONBOARDING_APPROVER_COLUMN_ID = "ONBOARDING_APPROVER_COLUMN";

export type ColumnDefinition = GridColDef<TableData>;

const getSupplierTableColumnsQuery = graphql(`
    query GetSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                ...GroupPageTable_SupplierTableColumn
            }
        }
    }

    fragment GroupPageTable_SupplierTableColumn on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on SelectOptions {
                choices
            }
            ... on ClassificationOptions {
                groups {
                    id
                    level
                    value
                }
            }
        }
    }
`);

export const updateSupplierFieldMutation = graphql(`
    mutation UpdateSupplierField($input: UpdateSupplierFieldInput!) {
        updateSupplierField(input: $input) {
            supplier {
                ...GroupPageTable_Supplier
                ...ReasonTooltip_Supplier
            }
        }
    }
`);

graphql(`
    fragment useGetColumn_SupplierCountry on SupplierCountry {
        ...ProductionCountriesCell_SupplierCountry
        ...ProductionCountriesEditCell_SupplierCountry
    }
`);

export const useGetColumns = (
    groupColumnIdsAdded: string[],
    campaignId: string | null | undefined,
    groupId: string,
    spendColumnName: string | undefined,
    startPolling: (pollInterval: number) => void,
    stopPolling: () => void,
    isEditor: boolean
): ColumnDefinition[] => {
    const alert = useAlert();
    const { data: columnsData } = useQuery(getSupplierTableColumnsQuery);

    /**
     * @todo When this toggle is removed, remove
     * * `./Components/RiskCell/ProdCountriesAutocomplete`
     * * `./Components/RiskCell/ProdCountrisCell`
     * * `./Components/RiskCell/RenderCountry`
     * * The country aspects of `./Components/RiskCell/RiskAutocomplete`
     */
    const multipleRiskIndicesEnabled = useFeatureToggle("suppliers-multiple-geography-risk-indices");
    const { data: countryData, loading: countriesLoading, error: countriesError } = useCountries();
    const countries = countryData?.getCountries.countries;
    const { currency } = useCompanyCurrency();
    const { formatMessage } = useIntl();

    const columns = columnsData?.getSupplierTableMeta.columns;
    const [update] = useMutation(updateSupplierFieldMutation);

    const onChange = useCallback(
        (columnName: string, supplierId: string, newValue: string[] | string | undefined, updateReason?: string) => {
            if (!columns) return;
            const columnId = (() => {
                switch (columnName) {
                    case "industry":
                        return columns.find((column) => column.type === ColumnType.Nace)?.id;
                    case "productionCountries":
                        return "production_countries";
                    default:
                        return columnName;
                }
            })();

            // start polling for 10 seconds to update the estimated risk
            startPolling(1000);
            setTimeout(() => {
                stopPolling();
            }, 10000);

            update({
                variables: {
                    input: {
                        fieldData: JSON.stringify(newValue ?? "null"),
                        fieldId: columnId ?? "",
                        id: supplierId,
                        updateReason,
                    },
                },
                update: (cache, { data }) => {
                    cache.writeFragment({
                        id: `Supplier:${supplierId}`,
                        fragment: GroupPageTable_SupplierFragmentDoc,
                        fragmentName: "GroupPageTable_Supplier",
                        data: data?.updateSupplierField.supplier,
                    });
                    cache.writeFragment({
                        id: `Supplier:${supplierId}`,
                        fragment: ReasonTooltip_SupplierFragmentDoc,
                        fragmentName: "ReasonTooltip_Supplier",
                        data: data?.updateSupplierField.supplier,
                    });
                },
                onCompleted: () => {
                    if (columnName !== "social_risk_score") return;
                    alert.alertUser(
                        {
                            value: formatMessage({
                                defaultMessage: "Updated risk score has been saved",
                                description: "Success message for updated supplier",
                            }),
                            severity: "info",
                        },
                        { SnackbarProps: { anchorOrigin: { vertical: "bottom", horizontal: "right" } } }
                    );
                },
                onError: (error) => {
                    alert.alertUser({
                        title: formatMessage({
                            defaultMessage: "Failed to update supplier",
                            description: "Error message for failed updating of supplier",
                        }),
                        value: formatMessage({
                            defaultMessage: "Try again or contact support",
                            description: "Error message for failed updating of supplier",
                        }),
                        severity: "error",
                    });
                    Sentry.captureException(error, {
                        tags: { app: "social-risk-app", message: "Failed to update supplier" },
                        extra: { supplierId, columnId, newValue },
                    });
                },
                refetchQueries: ["GroupPage_GetGroup"],
            });
        },
        [update, alert, formatMessage, columns, startPolling, stopPolling]
    );

    return useMemo(() => {
        if (!columns) return [];
        const nameColumn = columns?.find((column) => column.id === "name");
        const countryColumn = columns?.find((column) => column.id === "country");

        if (!nameColumn || !currency || !countryColumn) {
            return [];
        }

        const minWidth = 160;
        const maxWidth = 220;

        // adding the added_column to test functionality until we have created group ready
        // TODO: remove when created group is ready
        const columnsAdded: ColumnDefinition[] = columns
            .filter((column) => groupColumnIdsAdded.includes(column.id))
            .map((column): ColumnDefinition | null => {
                switch (column.id) {
                    case ONBOARDING_APPROVER_COLUMN_ID: {
                        return {
                            field: "onboardingApprover",
                            sortable: false,
                            filterable: false,
                            editable: false,
                            headerName: column.name,
                            renderCell(params) {
                                if (!params.row.onboardingApprover) {
                                    return null;
                                }
                                return <Chip label={params.row.onboardingApprover.fullName} size="small" />;
                            },
                            hideable: true,
                            pinnable: true,
                            flex: 1,
                            minWidth,
                            maxWidth,
                        };
                    }
                    case ONBOARDING_COLUMN_ID: {
                        return {
                            field: "onboardingStatus",
                            sortable: false,
                            filterable: false,
                            editable: false,
                            headerName: column.name,
                            renderCell(params) {
                                return (
                                    <OnboardingField
                                        onboardingStatus={params.value ?? undefined}
                                        supplierId={params.row.id}
                                    />
                                );
                            },
                            hideable: true,
                            pinnable: true,
                            flex: 1,
                            minWidth,
                            maxWidth,
                            valueGetter(_, row) {
                                return row.supplier.onboarding?.status;
                            },
                        };
                    }
                }

                return {
                    sortable: false,
                    minWidth,
                    field: column.id,
                    maxWidth,
                    headerName: column.name,
                    filterable: false,
                    editable: false,
                    renderCell: (params) => {
                        if (params.value === null || params.value === undefined) {
                            return;
                        }
                        switch (column.type) {
                            case "AGGREGATION":
                            case "SPEND": {
                                return (
                                    <Typography sx={{ textAlign: "right", width: "100%" }} variant="textSm">
                                        {formatValue(params.value, 0)}
                                    </Typography>
                                );
                            }
                            case "MONETARY_AMOUNT": {
                                return <MonetaryAmount value={params.value} />;
                            }
                            case "DATE": {
                                return <div style={{ cursor: "pointer" }}>{formatDateAsUTC(params.value)}</div>;
                            }
                            case "CLASSIFICATION": {
                                if (column.typeOptions?.__typename === "ClassificationOptions") {
                                    const valueById = new Map(column.typeOptions.groups?.map((g) => [g.id, g.value]));
                                    return (
                                        <Tooltip
                                            title={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        gap: "2px",
                                                    }}
                                                >
                                                    <ClassificationField ids={params.value} valueById={valueById} />
                                                </div>
                                            }
                                        >
                                            <div
                                                style={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <ClassificationField ids={params.value} valueById={valueById} />
                                            </div>
                                        </Tooltip>
                                    );
                                }
                                return;
                            }
                            case "ASSESSMENT_STATUS": {
                                return <AssessmentField status={params.value} />;
                            }
                            case "ASSESSMENT_SCORE": {
                                return params.value;
                            }
                            case "SELECT": {
                                return <Typography variant="textSm">{params.value}</Typography>;
                            }
                            case "NUMBER": {
                                return <Typography variant="textSm">{formatValue(params.value, 2)}</Typography>;
                            }
                            case "TEXT": {
                                return (
                                    <Tooltip title={params.value}>
                                        <Typography
                                            variant="textSm"
                                            sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {params.value}
                                        </Typography>
                                    </Tooltip>
                                );
                            }
                        }
                    },
                };
            })
            .filter((column) => column != null);

        const assessmentColumns: ColumnDefinition[] = campaignId
            ? [
                  {
                      field: "assessmentStatus",
                      headerName: formatMessage({
                          defaultMessage: "Assessment status",
                          description: "Assessment status column header",
                      }),
                      flex: 1,
                      minWidth: 160,
                      maxWidth: 180,
                      renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
                  },
                  {
                      field: "assessmentScore",
                      headerName: formatMessage({
                          defaultMessage: "Assessment score",
                          description: "Assessment score column header",
                      }),
                      flex: 1,
                      minWidth: 160,
                      maxWidth: 180,
                      renderCell: (params) => (
                          <Chip
                              label={
                                  params.value ??
                                  formatMessage({ defaultMessage: "N/A", description: "Assessments score placeholder" })
                              }
                              size="small"
                          />
                      ),
                  },
              ]
            : [];

        function riskComparator(s1: Supplier, s2: Supplier) {
            const riskOrder: Record<string, number> = {
                VERY_HIGH: 5,
                veryHigh: 5,
                HIGH: 4,
                high: 4,
                MEDIUM: 3,
                medium: 3,
                LOW: 2,
                low: 2,
                VERY_LOW: 1,
                veryLow: 1,
                null: 0,
                MISSING: 0,
            } as const;

            const getRiskOrder = (supplier: Supplier): number => {
                return supplier.socialRiskScore?.value
                    ? riskOrder[supplier.socialRiskScore?.value]
                    : supplier.risk?.social
                      ? riskOrder[supplier.risk?.social]
                      : 0;
            };

            return getRiskOrder(s2) - getRiskOrder(s1);
        }
        const spendColumnDefinition: ColumnDefinition[] = spendColumnName
            ? [
                  {
                      field: "spend",
                      headerName: `${spendColumnName} (${currency})`,
                      flex: 1,
                      minWidth: 200,
                      maxWidth: 220,
                      renderCell: (params) => (
                          <Stack justifyContent="center" alignItems="flex-end" height="100%">
                              <Typography variant="textSm">{formatValue(params.value, 0)}</Typography>
                          </Stack>
                      ),
                      valueGetter(_, row) {
                          return row.supplier.spend.lastAvailableYear.value;
                      },
                  },
              ]
            : [];

        let productionCountriesColumnDefinition: ColumnDefinition = {
            field: "productionCountries",
            headerName: formatMessage({
                defaultMessage: "Production countries",
                description: "Production countries header",
            }),
            flex: 1,
            minWidth: 180,
            maxWidth: 200,
            headerClassName: "risk-header",
            renderCell: (params) => <ProdCountriesCell params={params} isEditor={isEditor} />,
            renderEditCell: (params) => <ProdCountriesAutocomplete params={params} onChange={onChange} />,
            editable: isEditor,
            valueGetter(_, row) {
                return row.supplier.productionCountries;
            },
        };
        let countryColumnDefinition: ColumnDefinition = {
            field: "country",
            headerName: countryColumn.name,
            flex: 1,
            minWidth: 140,
            maxWidth: 160,
            headerClassName: "risk-header",
            renderCell: RenderCountry,
            renderEditCell: (params) => RiskAutocomplete(params, onChange),
            editable: isEditor,
            valueGetter(_, row) {
                return row.supplier.country;
            },
        };
        if (multipleRiskIndicesEnabled) {
            productionCountriesColumnDefinition = {
                field: "productionCountries",
                headerName: formatMessage({
                    defaultMessage: "Production countries",
                    description: "Production countries header",
                }),
                renderHeader(params) {
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="inherit">{params.colDef.headerName}</Typography>
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        defaultMessage="Production country risk is based on two risk indices. See details by clicking the arrow button on the supplier"
                                        description="Production country column header tooltip"
                                    />
                                }
                            >
                                <InformationCircle fontSize="small" />
                            </Tooltip>
                        </Stack>
                    );
                },
                flex: 1,
                minWidth: 180,
                maxWidth: 200,
                headerClassName: "risk-header",
                display: "flex",
                cellClassName: "autocomplete",
                sortable: false,
                renderCell(params) {
                    return <ProductionCountriesCell params={params} disabled={!isEditor} />;
                },
                renderEditCell(params) {
                    return (
                        <Stack width="max-content" height="100%" alignItems="flex-start" justifyContent="flex-start">
                            <ProductionCountriesEditCell
                                countries={countries ?? []}
                                params={params}
                                loading={countriesLoading}
                                error={Boolean(countriesError)}
                            />
                        </Stack>
                    );
                },
                valueGetter(_, row) {
                    return countries?.filter((country) => row.supplier.productionCountries?.includes(country.iso2Code));
                },
                valueSetter(value, row) {
                    return {
                        ...row,
                        supplier: {
                            ...row.supplier,
                            productionCountries: value?.map((country) => country.iso2Code) ?? [],
                        },
                    };
                },
                editable: isEditor,
            } satisfies GridColDef<TableData, UseGetColumn_SupplierCountryFragment[] | undefined>;
            countryColumnDefinition = {
                field: "country",
                headerName: countryColumn.name,
                flex: 1,
                minWidth: 140,
                maxWidth: 160,
                headerClassName: "risk-header",
                display: "flex",
                cellClassName: "autocomplete",
                renderHeader(params) {
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="inherit">{params.colDef.headerName}</Typography>
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        defaultMessage="Country risk is based on two risk indices. See details by clicking the arrow button on the supplier"
                                        description="Country column header tooltip"
                                    />
                                }
                            >
                                <InformationCircle fontSize="small" />
                            </Tooltip>
                        </Stack>
                    );
                },
                renderCell(params) {
                    const overridenByProductionCountries =
                        params.row.supplier.productionCountries && params.row.supplier.productionCountries.length > 0;

                    return (
                        <Tooltip
                            title={
                                overridenByProductionCountries && (
                                    <FormattedMessage
                                        defaultMessage="This country is not used in risk calculation since at least one production country is specified. {hasPermission, select, true {} other { Your permissions do not allow editing. }}"
                                        description="Ignored country risk combined with permission tooltip"
                                        values={{
                                            hasPermission: isEditor,
                                        }}
                                    />
                                )
                            }
                        >
                            <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
                                <CountryCell params={params} disabled={!isEditor} />
                            </Stack>
                        </Tooltip>
                    );
                },
                renderEditCell(params) {
                    return (
                        <Stack width="max-content" height="100%" alignItems="flex-start" justifyContent="flex-start">
                            <CountryEditCell
                                countries={countries ?? []}
                                params={params}
                                loading={countriesLoading}
                                error={Boolean(countriesError)}
                            />
                        </Stack>
                    );
                },
                valueGetter(_, { supplier: { country: value } }) {
                    return value ? countries?.find((country) => country.iso2Code === value) : undefined;
                },
                valueSetter(value, row) {
                    return {
                        ...row,
                        supplier: {
                            ...row.supplier,
                            country: value?.iso2Code,
                        },
                    };
                },
                editable: isEditor,
            } satisfies GridColDef<TableData, UseGetColumn_SupplierCountryFragment | undefined>;
        }

        return [
            {
                field: "name",
                headerName: nameColumn.name,
                flex: 1.5,
                minWidth: 160,
                maxWidth: 400,
                headerClassName: "name-header",
                renderCell: (params) => <SupplierNameField params={params} />,
                valueGetter: (_, row) => {
                    return row.supplier.name;
                },
            },
            ...spendColumnDefinition,
            countryColumnDefinition,
            productionCountriesColumnDefinition,
            {
                field: "industry",
                headerName: formatMessage({ defaultMessage: "Industry", description: "Industry column header" }),
                flex: 1.5,
                minWidth: 260,
                maxWidth: 400,
                headerClassName: "risk-header",
                renderCell: RenderNace,
                renderEditCell: (params) => RiskAutocomplete(params, onChange),
                editable: isEditor,
                valueGetter(_, row) {
                    return row.supplier.nace;
                },
            },
            ...assessmentColumns,
            ...columnsAdded,
            {
                field: "actions",
                renderHeader: () => (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Estimated risk"
                                description="Estimated risk column header"
                            />
                        </Typography>
                        <Tooltip
                            title={formatMessage({
                                defaultMessage:
                                    "The estimated risk score is based on country/prod. country and industry risk",
                                description: "Estimated risk column header tooltip explanation",
                            })}
                        >
                            <InformationCircle fontSize="small" />
                        </Tooltip>
                    </Stack>
                ),
                flex: 1,
                minWidth: 290,
                sortable: true,
                sortComparator: riskComparator,
                maxWidth: 290,
                valueGetter(_, row) {
                    return row.supplier;
                },
                renderCell: (params) => (
                    <EstimatedRiskCell
                        supplier={params.value}
                        groupId={groupId}
                        groupColumnIdsAdded={groupColumnIdsAdded}
                        onChange={onChange}
                        isEditor={isEditor}
                        campaignId={campaignId}
                        startPolling={startPolling}
                        stopPolling={stopPolling}
                    />
                ),
            },
        ];
    }, [
        columns,
        spendColumnName,
        currency,
        campaignId,
        formatMessage,
        groupColumnIdsAdded,
        onChange,
        groupId,
        isEditor,
        startPolling,
        stopPolling,
        multipleRiskIndicesEnabled,
        countries,
        countriesError,
        countriesLoading,
    ]);
};
