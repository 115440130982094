import { Button, Card, CardActions, CardHeader, Dialog, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import SelectSupplier from "../SelectSupplier";

interface GroupCreationDialogProps {
    open: boolean;
    onClose: () => void;
}

export const GroupCreationDialog: React.FC<GroupCreationDialogProps> = ({ open, onClose }) => {
    const [supplierList, setSupplierList] = React.useState<string[]>([]);

    const handleSupplierChoice = (supplierId: string) => {
        setSupplierList([...supplierList, supplierId]);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Card>
                <CardHeader title="Create a supplier group" />
                <SelectSupplier refetch={() => {}} handleSupplierChoice={handleSupplierChoice} />

                <Typography variant="textSm" fontWeight={500}>
                    {supplierList.join(", ")}
                </Typography>
                <CardActions>
                    <Button color="primary">
                        <FormattedMessage defaultMessage="Next" description="Create supplier group primary action" />
                    </Button>
                </CardActions>
            </Card>
        </Dialog>
    );
};
