import { Button, ButtonProps, Tooltip } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

interface Props {
    missingGroupName: boolean;
    noSuppliersFound: boolean;
    onClick: ButtonProps["onClick"];
    children: React.ReactNode;
}

const TooltipTitle: React.FC<Pick<Props, "missingGroupName" | "noSuppliersFound">> = ({
    missingGroupName,
    noSuppliersFound,
}) => {
    if (noSuppliersFound) {
        return (
            <FormattedMessage
                defaultMessage="Your filters returned no suppliers. Try adjusting them to find relevant suppliers."
                description="No suppliers tooltip (next button disabled)"
            />
        );
    }
    if (missingGroupName) {
        return (
            <FormattedMessage
                defaultMessage="You need to add a group name to proceed"
                description="Missing group name tooltip (next button disabled)"
            />
        );
    }

    return undefined;
};

export const ValidGroupButton: React.FC<Props> = ({ missingGroupName, noSuppliersFound, onClick, children }) => {
    return (
        <Tooltip title={<TooltipTitle missingGroupName={missingGroupName} noSuppliersFound={noSuppliersFound} />}>
            {/* Need something non-disabled to hover for the tooltip to work */}
            <div>
                <Button onClick={onClick} disabled={missingGroupName || noSuppliersFound} size="small">
                    {children}
                </Button>
            </div>
        </Tooltip>
    );
};
