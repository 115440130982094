import { InformationCircle, Tag } from "@ignite-analytics/icons";
import { Autocomplete, Grid2, List, ListItem, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ProductionCountriesAutocomplete_SupplierCountryFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";

import { InfoRow } from "../InfoRow";

import { useCountries } from "./countries";
import { CountryItem } from "./CountryItem";

interface ProductionCountriesAutocompleteProps {
    value: string[];
    onChange: (countries: string[]) => void;
    disabled: boolean;
    onViewRiskDetails: (id: string) => void;
}

graphql(`
    fragment ProductionCountriesAutocomplete_SupplierCountry on SupplierCountry {
        id
        iso2Code
        name(language: $language)
    }
`);

export const ProductionCountriesAutocomplete: React.FC<ProductionCountriesAutocompleteProps> = ({
    value,
    onChange,
    disabled,
    onViewRiskDetails,
}) => {
    const { data } = useCountries();

    const countries = data?.getCountries.countries ?? [];
    const { formatMessage } = useIntl();

    const handleChange = (
        _event: React.SyntheticEvent<Element, Event>,
        value: ProductionCountriesAutocomplete_SupplierCountryFragment[] | null
    ) => {
        const selectedCodes = value ? value.map((option) => option.id) : [];
        track(`${TRACK_PREFIX}: Updated Production Countries`, {
            initialValue: value,
            newValue: selectedCodes,
        });
        onChange(selectedCodes);
    };
    const selectedCountries = countries.filter((option) => value.includes(option?.id));

    return (
        <>
            <InfoRow
                icon={<Tag />}
                name="Production countries"
                infoComponent={
                    <Tooltip
                        title={formatMessage({
                            defaultMessage: "Additional countries where the supplier is operating or producing",
                        })}
                    >
                        <InformationCircle fontSize="small" />
                    </Tooltip>
                }
                valueComponent={
                    <Autocomplete
                        multiple
                        options={countries}
                        value={countries.filter((option) => value.includes(option.id)) ?? null}
                        fullWidth
                        disableClearable
                        disabled={disabled}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={(option) => `${option.iso2Code}: ${option.name}`}
                        renderOption={(props, option) => (
                            <Typography variant="textSm" {...props}>
                                {option.iso2Code}: {option.name}
                            </Typography>
                        )}
                        disableCloseOnSelect
                        renderTags={() => null}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                onKeyDown={(e) => {
                                    if (e.key === "Backspace" || e.key === "Delete") {
                                        e.stopPropagation();
                                    }
                                }}
                                variant="outlined"
                                placeholder={formatMessage({
                                    defaultMessage: "Add production countries",
                                    description: "Placeholder text for production countries autocomplete",
                                })}
                            />
                        )}
                        onChange={handleChange}
                        slotProps={{ listbox: { sx: { maxHeight: "250px", overflowY: "auto" } } }}
                    />
                }
            >
                {selectedCountries.length > 0 && (
                    <>
                        <Grid2 size={6} />
                        <Grid2 size={6}>
                            <List>
                                {selectedCountries.map((country) => {
                                    return (
                                        <ListItem key={country.id} disableGutters component="li" sx={{ paddingY: 0.5 }}>
                                            <CountryItem
                                                country={country}
                                                onClick={(id) => onViewRiskDetails(id)}
                                                onRemove={(e, id) =>
                                                    handleChange(
                                                        e,
                                                        selectedCountries.filter(
                                                            (selectedCountry) => selectedCountry.id !== id
                                                        )
                                                    )
                                                }
                                                disabled={disabled}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Grid2>
                    </>
                )}
            </InfoRow>
        </>
    );
};
