import { useMutation, useQuery } from "@apollo/client";
import { InformationCircle } from "@ignite-analytics/icons";
import { formatDateAsUTC, formatValue } from "@ignite-analytics/locale";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import { useCallback, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

import { graphql } from "@/gql";
import {
    ColumnType,
    GroupPageTable_SupplierFragmentDoc,
    ReasonTooltip_SupplierFragmentDoc,
    Supplier,
} from "@/gql/graphql";
import { useAlert } from "@/providers";
import { useCompanyCurrency } from "@/providers/CompanyCurrencyContext";

import { OnboardingField } from "../OnboardingField";

import { AssessmentField } from "./Components/AssessmentsCell";
import { ClassificationField } from "./Components/ClassificationCell";
import { CoCCell } from "./Components/CodeOfConduct/CoCCell";
import { EstimatedRiskCell } from "./Components/EstimatedRiskCell/EstimatedRiskCell";
import { MonetaryAmount } from "./Components/MonetaryAmountCell";
import { ProdCountriesAutocomplete } from "./Components/RiskCell/ProdCountriesAutocomplete";
import { ProdCountriesCell } from "./Components/RiskCell/ProdCountriesCell";
import { RenderCountry } from "./Components/RiskCell/RenderCountry";
import { RenderNace } from "./Components/RiskCell/RenderNace";
import { RiskAutocomplete } from "./Components/RiskCell/RiskAutocomplete";
import { SupplierNameField } from "./Components/SupplierNameField";
import { TableData } from "./utils";

export const ONBOARDING_COLUMN_ID = "ONBOARDING_COLUMN";
export const ONBOARDING_APPROVER_COLUMN_ID = "ONBOARDING_APPROVER_COLUMN";

export type ColumnDefinition = GridColDef<TableData>;

const getSupplierTableColumnsQuery = graphql(`
    query GetSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                ...GroupPageTable_SupplierTableColumn
            }
        }
    }

    fragment GroupPageTable_SupplierTableColumn on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on SelectOptions {
                choices
            }
            ... on ClassificationOptions {
                groups {
                    id
                    level
                    value
                }
            }
        }
    }
`);

const updateSupplierFieldMutation = graphql(`
    mutation UpdateSupplierField($input: UpdateSupplierFieldInput!) {
        updateSupplierField(input: $input) {
            supplier {
                ...GroupPageTable_Supplier
                ...ReasonTooltip_Supplier
            }
        }
    }
`);

export const columnGroupingModel = (formatMessage: IntlShape["formatMessage"]) => [
    {
        groupId: "riskFactors",
        headerName: formatMessage({ defaultMessage: "Risk factors" }),
        children: [{ field: "country" }, { field: "productionCountries" }, { field: "industry" }],
        renderHeaderGroup: () => (
            <Stack direction="row" alignItems="center" spacing={1} pb={1}>
                <Chip label={formatMessage({ defaultMessage: "Risk factors" })} size="xsmall" />
                <Tooltip
                    title={formatMessage({
                        defaultMessage: "These are the risk factors that make up the estimated risk score.",
                    })}
                >
                    <InformationCircle fontSize="small" />
                </Tooltip>
            </Stack>
        ),
    },
];

export const useGetColumns = (
    groupColumnIdsAdded: string[],
    campaignId: string | null | undefined,
    groupId: string,
    spendColumnName: string | undefined,
    startPolling: (pollInterval: number) => void,
    stopPolling: () => void,
    isEditor: boolean
): ColumnDefinition[] => {
    const alert = useAlert();
    const { data: columnsData } = useQuery(getSupplierTableColumnsQuery);

    const { currency } = useCompanyCurrency();
    const { formatMessage } = useIntl();

    const columns = columnsData?.getSupplierTableMeta.columns;
    const [update] = useMutation(updateSupplierFieldMutation);

    const onChange = useCallback(
        (columnName: string, supplierId: string, newValue: string[] | string | undefined, updateReason?: string) => {
            if (!columns) return;
            const columnId = (() => {
                switch (columnName) {
                    case "industry":
                        return columns.find((column) => column.type === ColumnType.Nace)?.id;
                    case "productionCountries":
                        return "production_countries";
                    default:
                        return columnName;
                }
            })();

            // start polling fot 10 seconds to update the estimated risk
            startPolling(1000);
            setTimeout(() => {
                stopPolling();
            }, 10000);

            update({
                variables: {
                    input: {
                        fieldData: JSON.stringify(newValue ?? "null"),
                        fieldId: columnId ?? "",
                        id: supplierId,
                        updateReason,
                    },
                },
                update: (cache, { data }) => {
                    cache.writeFragment({
                        id: `Supplier:${supplierId}`,
                        fragment: GroupPageTable_SupplierFragmentDoc,
                        fragmentName: "GroupPageTable_Supplier",
                        data: data?.updateSupplierField.supplier,
                    });
                    cache.writeFragment({
                        id: `Supplier:${supplierId}`,
                        fragment: ReasonTooltip_SupplierFragmentDoc,
                        fragmentName: "ReasonTooltip_Supplier",
                        data: data?.updateSupplierField.supplier,
                    });
                },
                onCompleted: () => {
                    if (columnName !== "social_risk_score") return;
                    alert.alertUser(
                        {
                            value: formatMessage({
                                defaultMessage: "Updated risk score has been saved",
                                description: "Success message for updated supplier",
                            }),
                            severity: "info",
                        },
                        { SnackbarProps: { anchorOrigin: { vertical: "bottom", horizontal: "right" } } }
                    );
                },
                onError: (error) => {
                    alert.alertUser({
                        title: formatMessage({
                            defaultMessage: "Failed to update supplier",
                            description: "Error message for failed updating of supplier",
                        }),
                        value: formatMessage({
                            defaultMessage: "Try again or contact support",
                            description: "Error message for failed updating of supplier",
                        }),
                        severity: "error",
                    });
                    Sentry.captureException(error, {
                        tags: { app: "social-risk-app", message: "Failed to update supplier" },
                        extra: { supplierId, columnId, newValue },
                    });
                },
                refetchQueries: ["GroupPage_GetGroup"],
            });
        },
        [update, alert, formatMessage, columns, startPolling, stopPolling]
    );

    return useMemo(() => {
        if (!columns) return [];
        const nameColumn = columns?.find((column) => column.id === "name");
        const countryColumn = columns?.find((column) => column.id === "country");

        if (!nameColumn || !currency || !countryColumn) {
            return [];
        }

        const minWidth = 160;
        const maxWidth = 220;

        // adding the added_column to test functionality until we have created group ready
        // TODO: remove when created group is ready
        const columnsAdded: ColumnDefinition[] = columns
            .filter((column) => groupColumnIdsAdded.includes(column.id))
            .map((column): ColumnDefinition | null => {
                switch (column.id) {
                    case ONBOARDING_APPROVER_COLUMN_ID: {
                        return {
                            field: "onboardingApprover",
                            sortable: false,
                            filterable: false,
                            editable: false,
                            headerName: column.name,
                            renderCell(params) {
                                if (!params.row.onboardingApprover) {
                                    return null;
                                }
                                return <Chip label={params.row.onboardingApprover.fullName} size="small" />;
                            },
                            hideable: true,
                            pinnable: true,
                            flex: 1,
                            minWidth,
                            maxWidth,
                        };
                    }
                    case ONBOARDING_COLUMN_ID: {
                        return {
                            field: "onboardingStatus",
                            sortable: false,
                            filterable: false,
                            editable: false,
                            headerName: column.name,
                            renderCell(params) {
                                return (
                                    <OnboardingField
                                        onboardingStatus={params.row.onboardingStatus ?? undefined}
                                        supplierId={params.row.id}
                                    />
                                );
                            },
                            hideable: true,
                            pinnable: true,
                            flex: 1,
                            minWidth,
                            maxWidth,
                        };
                    }
                }

                // Handled explicitly later
                if (column.id === "has_code_of_conduct") return null;

                return {
                    sortable: false,
                    minWidth,
                    field: column.id,
                    maxWidth,
                    headerName: column.name,
                    filterable: false,
                    editable: false,
                    renderCell: (params) => {
                        if (params.value === null || params.value === undefined) {
                            return;
                        }
                        switch (column.type) {
                            case "AGGREGATION":
                            case "SPEND": {
                                return (
                                    <Typography sx={{ textAlign: "right", width: "100%" }} variant="textSm">
                                        {formatValue(params.value, 0)}
                                    </Typography>
                                );
                            }
                            case "MONETARY_AMOUNT": {
                                return <MonetaryAmount value={params.value} />;
                            }
                            case "DATE": {
                                return <div style={{ cursor: "pointer" }}>{formatDateAsUTC(params.value)}</div>;
                            }
                            case "CLASSIFICATION": {
                                if (column.typeOptions?.__typename === "ClassificationOptions") {
                                    const valueById = new Map(column.typeOptions.groups?.map((g) => [g.id, g.value]));
                                    return (
                                        <Tooltip
                                            title={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        gap: "2px",
                                                    }}
                                                >
                                                    <ClassificationField ids={params.value} valueById={valueById} />
                                                </div>
                                            }
                                        >
                                            <div
                                                style={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <ClassificationField ids={params.value} valueById={valueById} />
                                            </div>
                                        </Tooltip>
                                    );
                                }
                                return;
                            }
                            case "ASSESSMENT_STATUS": {
                                return <AssessmentField status={params.value} />;
                            }
                            case "ASSESSMENT_SCORE": {
                                return params.value;
                            }
                            case "SELECT": {
                                return <Typography variant="textSm">{params.value}</Typography>;
                            }
                            case "NUMBER": {
                                return <Typography variant="textSm">{formatValue(params.value, 2)}</Typography>;
                            }
                        }
                    },
                };
            })
            .filter((column) => column != null);

        const assessmentColumns: ColumnDefinition[] = campaignId
            ? [
                  {
                      field: "assessmentStatus",
                      headerName: formatMessage({
                          defaultMessage: "Assessment status",
                          description: "Assessment status column header",
                      }),
                      flex: 1,
                      minWidth: 160,
                      maxWidth: 180,
                      renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
                  },
                  {
                      field: "assessmentScore",
                      headerName: formatMessage({
                          defaultMessage: "Assessment score",
                          description: "Assessment score column header",
                      }),
                      flex: 1,
                      minWidth: 160,
                      maxWidth: 180,
                      renderCell: (params) => (
                          <Chip
                              label={
                                  params.value ??
                                  formatMessage({ defaultMessage: "N/A", description: "Assessments score placeholder" })
                              }
                              size="small"
                          />
                      ),
                  },
              ]
            : [];

        const cocColumnDefinition: ColumnDefinition = {
            field: "hasCodeOfConduct",
            headerName: formatMessage({
                defaultMessage: "Code of conduct",
                description: "Code of conduct column header",
            }),
            flex: 1,
            minWidth: 180,
            maxWidth: 200,
            renderCell: (params) => (
                <CoCCell value={params.value} supplierId={params.id.toString()} onChange={onChange} />
            ),
        };

        function riskComparator(s1: Supplier, s2: Supplier) {
            const riskOrder: Record<string, number> = {
                VERY_HIGH: 5,
                veryHigh: 5,
                HIGH: 4,
                high: 4,
                MEDIUM: 3,
                medium: 3,
                LOW: 2,
                low: 2,
                VERY_LOW: 1,
                veryLow: 1,
                null: 0,
                MISSING: 0,
            } as const;

            const getRiskOrder = (supplier: Supplier): number => {
                return supplier.socialRiskScore?.value
                    ? riskOrder[supplier.socialRiskScore?.value]
                    : supplier.risk?.social
                      ? riskOrder[supplier.risk?.social]
                      : 0;
            };

            return getRiskOrder(s2) - getRiskOrder(s1);
        }
        const spendColumnDefinition: ColumnDefinition[] = spendColumnName
            ? [
                  {
                      field: "spend",
                      headerName: `${spendColumnName} (${currency})`,
                      flex: 1,
                      minWidth: 200,
                      maxWidth: 220,
                      renderCell: (params) => (
                          <Stack justifyContent="center" alignItems="flex-end" height="100%">
                              <Typography variant="textSm">{formatValue(params.value, 0)}</Typography>
                          </Stack>
                      ),
                  },
              ]
            : [];

        return [
            {
                field: "name",
                headerName: nameColumn.name,
                flex: 1.5,
                minWidth: 160,
                maxWidth: 400,
                headerClassName: "name-header",
                renderCell: (params) => <SupplierNameField params={params} />,
            },
            ...spendColumnDefinition,
            {
                field: "country",
                headerName: countryColumn.name,
                flex: 1,
                minWidth: 140,
                maxWidth: 160,
                headerClassName: "risk-header",
                renderCell: RenderCountry,
                renderEditCell: (params) => RiskAutocomplete(params, onChange),
                editable: isEditor,
            },
            {
                field: "productionCountries",
                headerName: formatMessage({
                    defaultMessage: "Production countries",
                    description: "Production countries header",
                }),
                flex: 1,
                minWidth: 180,
                maxWidth: 200,
                headerClassName: "risk-header",
                renderCell: (params) => <ProdCountriesCell params={params} isEditor={isEditor} />,
                renderEditCell: (params) => <ProdCountriesAutocomplete params={params} onChange={onChange} />,
                editable: isEditor,
            },
            {
                field: "industry",
                headerName: formatMessage({ defaultMessage: "Industry", description: "Industry column header" }),
                flex: 1.5,
                minWidth: 260,
                maxWidth: 400,
                headerClassName: "risk-header",
                renderCell: RenderNace,
                renderEditCell: (params) => RiskAutocomplete(params, onChange),
                editable: isEditor,
            },
            ...assessmentColumns,
            ...(groupColumnIdsAdded.includes("has_code_of_conduct") ? [cocColumnDefinition] : []),
            ...columnsAdded,
            {
                field: "estimatedRisk",
                headerName: formatMessage({
                    defaultMessage: "Estimated risk",
                    description: "Estimated risk column header",
                }),
                flex: 1,
                minWidth: 230,
                sortable: true,
                sortComparator: riskComparator,
                maxWidth: 240,
                renderCell: (params) => (
                    <EstimatedRiskCell
                        supplier={params.value}
                        groupId={groupId}
                        onChange={onChange}
                        isEditor={isEditor}
                        campaignId={campaignId}
                    />
                ),
            },
        ];
    }, [
        columns,
        spendColumnName,
        currency,
        campaignId,
        formatMessage,
        groupColumnIdsAdded,
        onChange,
        groupId,
        isEditor,
    ]);
};
