export const NACEOptions = [
    {
        code: "01.11",
        score: "high",
        name_no: "Dyrking av korn (unntatt ris), belgvekster og oljeholdige vekster",
        name_en: "Growing of cereals (except rice), leguminous crops and oil seeds",
    },
    { code: "01.12", score: "high", name_no: "Dyrking av ris", name_en: "Growing of rice" },
    {
        code: "01.13",
        score: "high",
        name_no: "Dyrking av grønnsaker, meloner, rot- og knollvekster",
        name_en: "Growing of vegetables and melons, roots and tubers",
    },
    { code: "01.14", score: "high", name_no: "Dyrking av sukkerrør", name_en: "Growing of sugar cane" },
    { code: "01.15", score: "high", name_no: "Dyrking av tobakk", name_en: "Growing of tobacco" },
    { code: "01.16", score: "high", name_no: "Dyrking av fibervekster", name_en: "Growing of fibre crops" },
    {
        code: "01.19",
        score: "high",
        name_no: "Dyrking av ettårige vekster ellers",
        name_en: "Growing of other non-perennial crops",
    },
    { code: "01.21", score: "high", name_no: "Dyrking av druer", name_en: "Growing of grapes" },
    {
        code: "01.22",
        score: "high",
        name_no: "Dyrking av tropiske og subtropiske frukter",
        name_en: "Growing of tropical and subtropical fruits",
    },
    { code: "01.23", score: "high", name_no: "Dyrking av sitrusfrukter", name_en: "Growing of citrus fruits" },
    {
        code: "01.24",
        score: "high",
        name_no: "Dyrking av kjernefrukter og steinfrukter",
        name_en: "Growing of pome fruits and stone fruits",
    },
    {
        code: "01.25",
        score: "high",
        name_no: "Dyrking av annen frukt som vokser på trær eller busker samt nøtter",
        name_en: "Growing of other tree and bush fruits and nuts",
    },
    {
        code: "01.26",
        score: "high",
        name_no: "Dyrking av oljeholdige frukter",
        name_en: "Growing of oleaginous fruits",
    },
    {
        code: "01.27",
        score: "high",
        name_no: "Dyrking av vekster for produksjon av drikkevarer",
        name_en: "Growing of beverage crops",
    },
    {
        code: "01.28",
        score: "high",
        name_no: "Dyrking av krydder og aromatiske, medisinske og farmasøytiske vekster",
        name_en: "Growing of spices, aromatic, drug and pharmaceutical crops",
    },
    {
        code: "01.29",
        score: "high",
        name_no: "Dyrking av flerårige vekster ellers",
        name_en: "Growing of other perennial crops",
    },
    { code: "01.30", score: "medium", name_no: "Planteformering", name_en: "Plant propagation" },
    { code: "01.41", score: "medium", name_no: "Melkeproduksjon på storfe", name_en: "Raising of dairy cattle" },
    {
        code: "01.42",
        score: "medium",
        name_no: "Oppdrett av annet storfe",
        name_en: "Raising of other cattle and buffaloes",
    },
    {
        code: "01.43",
        score: "medium",
        name_no: "Oppdrett av hester og andre dyr av hestefamilien",
        name_en: "Raising of horses and other equines",
    },
    {
        code: "01.44",
        score: "medium",
        name_no: "Oppdrett av kameler og andre kameldyr",
        name_en: "Raising of camels and camelids",
    },
    { code: "01.45", score: "medium", name_no: "Saue- og geitehold", name_en: "Raising of sheep and goats" },
    { code: "01.46", score: "medium", name_no: "Svinehold", name_en: "Raising of swine/pigs" },
    { code: "01.47", score: "medium", name_no: "Fjørfehold", name_en: "Raising of poultry" },
    { code: "01.49", score: "low", name_no: "Husdyrhold ellers", name_en: "Raising of other animals" },
    { code: "01.50", score: "high", name_no: "Kombinert husdyrhold og planteproduksjon", name_en: "Mixed farming" },
    {
        code: "01.61",
        score: "medium",
        name_no: "Tjenester tilknyttet planteproduksjon",
        name_en: "Support activities for crop production",
    },
    {
        code: "01.62",
        score: "medium",
        name_no: "Tjenester tilknyttet husdyrhold",
        name_en: "Support activities for animal production",
    },
    {
        code: "01.63",
        score: "medium",
        name_no: "Etterbehandling av vekster etter innhøsting",
        name_en: "Post-harvest crop activities",
    },
    { code: "01.64", score: "medium", name_no: "Behandling av såfrø", name_en: "Seed processing for propagation" },
    {
        code: "01.70",
        score: "medium",
        name_no: "Jakt, viltstell og tjenester tilknyttet jakt og viltstell",
        name_en: "Hunting, trapping and related service activities",
    },
    {
        code: "02.10",
        score: "high",
        name_no: "Skogskjøtsel og andre skogbruksaktiviteter",
        name_en: "Silviculture and other forestry activities",
    },
    { code: "02.20", score: "high", name_no: "Avvirkning", name_en: "Logging" },
    {
        code: "02.30",
        score: "medium",
        name_no: "Innsamling av viltvoksende produkter av annet enn tre",
        name_en: "Gathering of wild growing non-wood products",
    },
    {
        code: "02.40",
        score: "medium",
        name_no: "Tjenester tilknyttet skogbruk",
        name_en: "Support services to forestry",
    },
    { code: "03.11", score: "high", name_no: "Hav- og kystfiske og fangst", name_en: "Marine fishing" },
    { code: "03.12", score: "medium", name_no: "Ferskvannsfiske", name_en: "Freshwater fishing" },
    { code: "03.21", score: "medium", name_no: "Hav- og kystbasert akvakultur", name_en: "Marine aquaculture" },
    { code: "03.22", score: "medium", name_no: "Ferskvannsbasert akvakultur", name_en: "Freshwater aquaculture" },
    { code: "05.10", score: "high", name_no: "Bryting av steinkull", name_en: "Mining of hard coal" },
    { code: "05.20", score: "high", name_no: "Bryting av brunkull", name_en: "Mining of lignite" },
    { code: "06.10", score: "high", name_no: "Utvinning av råolje", name_en: "Extraction of crude petroleum" },
    { code: "06.20", score: "high", name_no: "Utvinning av naturgass", name_en: "Extraction of natural gas" },
    { code: "07.10", score: "high", name_no: "Bryting av jernmalm", name_en: "Mining of iron ores" },
    {
        code: "07.21",
        score: "high",
        name_no: "Bryting av uran- og thoriummalm",
        name_en: "Mining of uranium and thorium ores",
    },
    {
        code: "07.29",
        score: "high",
        name_no: "Bryting av ikke-jernholdig malm ellers",
        name_en: "Mining of other non-ferrous metal ores",
    },
    {
        code: "08.11",
        score: "high",
        name_no: "Bryting av stein til bygge- og anleggsvirksomhet, kalkstein, gips, kritt og skifer",
        name_en: "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate",
    },
    {
        code: "08.12",
        score: "high",
        name_no: "Utvinning fra grus- og sandtak, og utvinning av leire og kaolin",
        name_en: "Operation of gravel and sand pits; mining of clays and kaolin",
    },
    {
        code: "08.91",
        score: "high",
        name_no: "Bryting og utvinning av kjemiske mineraler og gjødselsmineraler",
        name_en: "Mining of chemical and fertiliser minerals",
    },
    { code: "08.92", score: "high", name_no: "Stikking av torv", name_en: "Extraction of peat" },
    { code: "08.93", score: "high", name_no: "Utvinning av salt", name_en: "Extraction of salt" },
    {
        code: "08.99",
        score: "high",
        name_no: "Annen bryting og utvinning ikke nevnt annet sted",
        name_en: "Other mining and quarrying n.e.c.",
    },
    {
        code: "09.10",
        score: "high",
        name_no: "Tjenester tilknyttet utvinning av råolje og naturgass",
        name_en: "Support activities for petroleum and natural gas extraction",
    },
    {
        code: "09.90",
        score: "high",
        name_no: "Tjenester tilknyttet annen bergverksdrift",
        name_en: "Support activities for other mining and quarrying",
    },
    {
        code: "10.11",
        score: "high",
        name_no: "Bearbeiding og konservering av kjøtt",
        name_en: "Processing and preserving of meat",
    },
    {
        code: "10.12",
        score: "high",
        name_no: "Bearbeiding og konservering av fjørfekjøtt",
        name_en: "Processing and preserving of poultry meat",
    },
    {
        code: "10.13",
        score: "high",
        name_no: "Produksjon av kjøtt- og fjørfevarer",
        name_en: "Production of meat and poultry meat products",
    },
    {
        code: "10.20",
        score: "medium",
        name_no: "Bearbeiding og konservering av fisk, skalldyr og bløtdyr",
        name_en: "Processing and preserving of fish, crustaceans and molluscs",
    },
    {
        code: "10.31",
        score: "medium",
        name_no: "Bearbeiding og konservering av poteter",
        name_en: "Processing and preserving of potatoes",
    },
    {
        code: "10.32",
        score: "medium",
        name_no: "Produksjon av juice av frukt og grønnsaker",
        name_en: "Manufacture of fruit and vegetable juice",
    },
    {
        code: "10.39",
        score: "medium",
        name_no: "Bearbeiding og konservering av frukt og grønnsaker ellers",
        name_en: "Other processing and preserving of fruit and vegetables",
    },
    {
        code: "10.41",
        score: "medium",
        name_no: "Produksjon av oljer og fettstoffer",
        name_en: "Manufacture of oils and fats",
    },
    {
        code: "10.42",
        score: "medium",
        name_no: "Produksjon av margarin og lignende spiselige fettstoffer",
        name_en: "Manufacture of margarine and similar edible fats",
    },
    {
        code: "10.51",
        score: "medium",
        name_no: "Produksjon av meierivarer",
        name_en: "Operation of dairies and cheese making",
    },
    { code: "10.52", score: "medium", name_no: "Produksjon av iskrem", name_en: "Manufacture of ice cream" },
    {
        code: "10.61",
        score: "medium",
        name_no: "Produksjon av kornvarer",
        name_en: "Manufacture of grain mill products",
    },
    {
        code: "10.62",
        score: "medium",
        name_no: "Produksjon av stivelse og stivelsesprodukter",
        name_en: "Manufacture of starches and starch products",
    },
    {
        code: "10.71",
        score: "medium",
        name_no: "Produksjon av brød og ferske konditorvarer",
        name_en: "Manufacture of bread; manufacture of fresh pastry goods and cakes",
    },
    {
        code: "10.72",
        score: "medium",
        name_no: "Produksjon av kavringer, kjeks og konserverte konditorvarer",
        name_en: "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes",
    },
    {
        code: "10.73",
        score: "medium",
        name_no: "Produksjon av makaroni, nudler, couscous og lignende pastavarer",
        name_en: "Manufacture of macaroni, noodles, couscous and similar faricodeous products",
    },
    { code: "10.81", score: "medium", name_no: "Produksjon av sukker", name_en: "Manufacture of sugar" },
    {
        code: "10.82",
        score: "medium",
        name_no: "Produksjon av kakao, sjokolade og sukkervarer",
        name_en: "Manufacture of cocoa, chocolate and sugar confectionery",
    },
    { code: "10.83", score: "medium", name_no: "Bearbeiding av te og kaffe", name_en: "Processing of tea and coffee" },
    {
        code: "10.84",
        score: "medium",
        name_no: "Produksjon av smakstilsettingsstoffer og krydderier",
        name_en: "Manufacture of condiments and seasonings",
    },
    {
        code: "10.85",
        score: "medium",
        name_no: "Produksjon av ferdigmat",
        name_en: "Manufacture of prepared meals and dishes",
    },
    {
        code: "10.86",
        score: "medium",
        name_no: "Produksjon av homogeniserte matprodukter og diettmat",
        name_en: "Manufacture of homogenised food preparations and dietetic food",
    },
    {
        code: "10.89",
        score: "medium",
        name_no: "Produksjon av næringsmidler ikke nevnt annet sted",
        name_en: "Manufacture of other food products n.e.c.",
    },
    {
        code: "10.91",
        score: "medium",
        name_no: "Produksjon av fôrvarer til husdyrhold",
        name_en: "Manufacture of prepared feeds for farm animals",
    },
    {
        code: "10.92",
        score: "medium",
        name_no: "Produksjon av fôrvarer til kjæledyr",
        name_en: "Manufacture of prepared pet foods",
    },
    {
        code: "11.01",
        score: "medium",
        name_no: "Destillering, rektifisering og blanding av sprit",
        name_en: "Distilling, rectifying and blending of spirits",
    },
    { code: "11.02", score: "medium", name_no: "Produksjon av vin", name_en: "Manufacture of wine from grape" },
    {
        code: "11.03",
        score: "medium",
        name_no: "Produksjon av sider og annen fruktvin",
        name_en: "Manufacture of cider and other fruit wines",
    },
    {
        code: "11.04",
        score: "medium",
        name_no: "Produksjon av andre ikke-destillerte gjærede drikkevarer",
        name_en: "Manufacture of other non-distilled fermented beverages",
    },
    { code: "11.05", score: "medium", name_no: "Produksjon av øl", name_en: "Manufacture of beer" },
    { code: "11.06", score: "medium", name_no: "Produksjon av malt", name_en: "Manufacture of malt" },
    {
        code: "11.07",
        score: "medium",
        name_no: "Produksjon av mineralvann, leskedrikker og annet vann på flaske",
        name_en: "Manufacture of soft drinks; production of mineral waters and other bottled waters",
    },
    {
        code: "12.00",
        score: "medium",
        name_no: "Produksjon av tobakksvarer",
        name_en: "Manufacture of tobacco products",
    },
    {
        code: "13.10",
        score: "high",
        name_no: "Bearbeiding og spinning av tekstilfibrer",
        name_en: "Preparation and spinning of textile fibres",
    },
    { code: "13.20", score: "high", name_no: "Veving av tekstiler", name_en: "Weaving of textiles" },
    { code: "13.30", score: "high", name_no: "Etterbehandling av tekstiler", name_en: "Finishing of textiles" },
    {
        code: "13.91",
        score: "high",
        name_no: "Produksjon av stoffer av trikotasje",
        name_en: "Manufacture of knitted and crocheted fabrics",
    },
    {
        code: "13.92",
        score: "high",
        name_no: "Produksjon av tekstilvarer, unntatt klær",
        name_en: "Manufacture of made-up textile articles, except apparel",
    },
    {
        code: "13.93",
        score: "high",
        name_no: "Produksjon av gulvtepper, -matter og -ryer",
        name_en: "Manufacture of carpets and rugs",
    },
    {
        code: "13.94",
        score: "high",
        name_no: "Produksjon av tauverk og nett",
        name_en: "Manufacture of cordage, rope, twine and rugs",
    },
    {
        code: "13.95",
        score: "high",
        name_no: "Produksjon av ikke-vevde tekstiler og tekstilvarer, unntatt klær",
        name_en: "Manufacture of non-wovens and articles made from non-wovens, except apparel",
    },
    {
        code: "13.96",
        score: "high",
        name_no: "Produksjon av tekstiler til teknisk og industriell bruk",
        name_en: "Manufacture of other technical and industrial textiles",
    },
    {
        code: "13.99",
        score: "high",
        name_no: "Produksjon av tekstiler ikke nevnt annet sted",
        name_en: "Manufacture of other textiles n.e.c.",
    },
    { code: "14.11", score: "high", name_no: "Produksjon av klær av lær", name_en: "Manufacture of leather clothes" },
    { code: "14.12", score: "high", name_no: "Produksjon av arbeidstøy", name_en: "Manufacture of workwear" },
    {
        code: "14.13",
        score: "high",
        name_no: "Produksjon av annet yttertøy",
        name_en: "Manufacture of other outerwear",
    },
    {
        code: "14.14",
        score: "high",
        name_no: "Produksjon av undertøy og innertøy",
        name_en: "Manufacture of underwear",
    },
    {
        code: "14.19",
        score: "high",
        name_no: "Produksjon av klær og tilbehør ellers",
        name_en: "Manufacture of other wearing apparel and accessories",
    },
    { code: "14.20", score: "high", name_no: "Produksjon av pelsvarer", name_en: "Manufacture of articles of fur" },
    {
        code: "14.31",
        score: "high",
        name_no: "Produksjon av strømpevarer",
        name_en: "Manufacture of knitted and crocheted hosiery",
    },
    {
        code: "14.39",
        score: "high",
        name_no: "Produksjon av andre klær av trikotasje",
        name_en: "Manufacture of other knitted and crocheted apparel",
    },
    {
        code: "15.11",
        score: "high",
        name_no: "Beredning av lær, og beredning og farging av pelsskinn",
        name_en: "Tanning and dressing of leather; dressing and dyeing of fur",
    },
    {
        code: "15.12",
        score: "high",
        name_no: "Produksjon av reiseeffekter og salmakerartikler",
        name_en: "Manufacture of luggage, handbags and the like, saddlery and harness",
    },
    { code: "15.20", score: "high", name_no: "Produksjon av skotøy", name_en: "Manufacture of footwear" },
    {
        code: "16.10",
        score: "medium",
        name_no: "Saging, høvling og impregnering av tre",
        name_en: "Sawing and planing of wood",
    },
    {
        code: "16.21",
        score: "medium",
        name_no: "Produksjon av finerplater og andre bygnings- og møbelplater av tre",
        name_en: "Manufacture of veneer sheets and wood-based materials",
    },
    {
        code: "16.22",
        score: "medium",
        name_no: "Produksjon av sammensatte parkettstaver",
        name_en: "Manufacture of assembled parquet floors",
    },
    {
        code: "16.23",
        score: "medium",
        name_no: "Produksjon av andre bygningsartikler",
        name_en: "Manufacture of other builders' carpentry and joinery",
    },
    {
        code: "16.24",
        score: "medium",
        name_no: "Produksjon av treemballasje",
        name_en: "Manufacture of wooden containers",
    },
    {
        code: "16.29",
        score: "medium",
        name_no: "Produksjon av andre trevarer og varer av kork, strå og flettematerialer",
        name_en: "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
    },
    { code: "17.11", score: "high", name_no: "Produksjon av papirmasse", name_en: "Manufacture of pulp" },
    {
        code: "17.12",
        score: "medium",
        name_no: "Produksjon av papir og papp",
        name_en: "Manufacture of paper and paperboard",
    },
    {
        code: "17.21",
        score: "medium",
        name_no: "Produksjon av bølgepapp og emballasje av papir og papp",
        name_en: "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard",
    },
    {
        code: "17.22",
        score: "medium",
        name_no: "Produksjon av husholdnings-, sanitær- og toalettartikler av papir",
        name_en: "Manufacture of household and sanitary goods and of toilet requisites",
    },
    {
        code: "17.23",
        score: "medium",
        name_no: "Produksjon av kontorartikler av papir",
        name_en: "Manufacture of paper stationery",
    },
    { code: "17.24", score: "medium", name_no: "Produksjon av tapeter", name_en: "Manufacture of wallpaper" },
    {
        code: "17.29",
        score: "medium",
        name_no: "Produksjon av varer av papir og papp ellers",
        name_en: "Manufacture of other articles of paper and paperboard",
    },
    { code: "18.11", score: "low", name_no: "Trykking av aviser", name_en: "Printing of newspapers" },
    { code: "18.12", score: "low", name_no: "Trykking ellers", name_en: "Other printing" },
    {
        code: "18.13",
        score: "low",
        name_no: "Ferdiggjøring før trykking og publisering",
        name_en: "Pre-press and pre-media services",
    },
    {
        code: "18.14",
        score: "low",
        name_no: "Bokbinding og tilknyttede tjenester",
        name_en: "Binding and related services",
    },
    {
        code: "18.20",
        score: "low",
        name_no: "Reproduksjon av innspilte opptak",
        name_en: "Reproduction of recorded media",
    },
    {
        code: "19.10",
        score: "high",
        name_no: "Produksjon av kullprodukter",
        name_en: "Manufacture of coke oven products",
    },
    {
        code: "19.20",
        score: "high",
        name_no: "Produksjon av raffinerte petroleumsprodukter",
        name_en: "Manufacture of refined petroleum products",
    },
    {
        code: "20.11",
        score: "high",
        name_no: "Produksjon av industrigasser",
        name_en: "Manufacture of industrial gases",
    },
    {
        code: "20.12",
        score: "high",
        name_no: "Produksjon av fargestoffer og pigmenter",
        name_en: "Manufacture of dyes and pigments",
    },
    {
        code: "20.13",
        score: "high",
        name_no: "Produksjon av andre uorganiske kjemikalier",
        name_en: "Manufacture of other inorganic basic chemicals",
    },
    {
        code: "20.14",
        score: "high",
        name_no: "Produksjon av andre organiske kjemiske råvarer",
        name_en: "Manufacture of other organic basic chemicals",
    },
    {
        code: "20.15",
        score: "high",
        name_no: "Produksjon av gjødsel, nitrogenforbindelser og vekstjord",
        name_en: "Manufacture of fertilisers and nitrogen compounds",
    },
    {
        code: "20.16",
        score: "high",
        name_no: "Produksjon av basisplast",
        name_en: "Manufacture of plastics in primary forms",
    },
    {
        code: "20.17",
        score: "high",
        name_no: "Produksjon av syntetisk gummi",
        name_en: "Manufacture of synthetic rubber in primary forms",
    },
    {
        code: "20.20",
        score: "high",
        name_no: "Produksjon av plantevern- og skadedyrmidler og andre landbrukskjemiske produkter",
        name_en: "Manufacture of pesticides and other agrochemical products",
    },
    {
        code: "20.30",
        score: "high",
        name_no: "Produksjon av maling og lakk, trykkfarger og tetningsmidler",
        name_en: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
    },
    {
        code: "20.41",
        score: "high",
        name_no: "Produksjon av såpe og vaskemidler, rense- og polermidler",
        name_en: "Manufacture of soap and detergents, cleaning and polishing preparations",
    },
    {
        code: "20.42",
        score: "high",
        name_no: "Produksjon av parfyme og toalettartikler",
        name_en: "Manufacture of perfumes and toilet preparations",
    },
    { code: "20.51", score: "high", name_no: "Produksjon av eksplosiver", name_en: "Manufacture of explosives" },
    { code: "20.52", score: "high", name_no: "Produksjon av lim", name_en: "Manufacture of glues" },
    { code: "20.53", score: "high", name_no: "Produksjon av eteriske oljer", name_en: "Manufacture of essential oils" },
    {
        code: "20.59",
        score: "high",
        name_no: "Produksjon av kjemiske produkter ikke nevnt annet sted",
        name_en: "Manufacture of other chemical products n.e.c",
    },
    { code: "20.60", score: "high", name_no: "Produksjon av kunstfibrer", name_en: "Manufacture of man-made fibres" },
    {
        code: "21.10",
        score: "high",
        name_no: "Produksjon av farmasøytiske råvarer",
        name_en: "Manufacture of basic pharmaceutical products",
    },
    {
        code: "21.20",
        score: "high",
        name_no: "Produksjon av farmasøytiske preparater",
        name_en: "Manufacture of pharmaceutical preparations",
    },
    {
        code: "22.11",
        score: "high",
        name_no: "Produksjon av gummidekk og slanger til gummidekk, og regummiering og vulkanisering av gummidekk",
        name_en: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
    },
    {
        code: "22.19",
        score: "high",
        name_no: "Produksjon av gummiprodukter ellers",
        name_en: "Manufacture of other rubber products",
    },
    {
        code: "22.21",
        score: "high",
        name_no: "Produksjon av halvfabrikater av plast",
        name_en: "Manufacture of plastic plates, sheets, tubes and profiles",
    },
    {
        code: "22.22",
        score: "high",
        name_no: "Produksjon av plastemballasje",
        name_en: "Manufacture of plastic packing goods",
    },
    {
        code: "22.23",
        score: "high",
        name_no: "Produksjon av byggevarer av plast",
        name_en: "Manufacture of builders' ware of plastic",
    },
    {
        code: "22.29",
        score: "high",
        name_no: "Produksjon av plastprodukter ellers",
        name_en: "Manufacture of other plastic products",
    },
    { code: "23.11", score: "medium", name_no: "Produksjon av planglass", name_en: "Manufacture of flat glass" },
    {
        code: "23.12",
        score: "medium",
        name_no: "Bearbeiding av planglass",
        name_en: "Shaping and processing of flat glass",
    },
    {
        code: "23.13",
        score: "medium",
        name_no: "Produksjon av emballasje og husholdningsartikler av glass og krystall",
        name_en: "Manufacture of hollow glass",
    },
    { code: "23.14", score: "medium", name_no: "Produksjon av glassfibrer", name_en: "Manufacture of glass fibres" },
    {
        code: "23.19",
        score: "medium",
        name_no: "Produksjon av teknisk glass og andre glassvarer",
        name_en: "Manufacture and processing of other glass, including technical glassware",
    },
    {
        code: "23.20",
        score: "medium",
        name_no: "Produksjon av ildfaste produkter",
        name_en: "Manufacture of refractory products",
    },
    {
        code: "23.31",
        score: "medium",
        name_no: "Produksjon av keramiske vegg- og gulvfliser",
        name_en: "Manufacture of ceramic tiles and flags",
    },
    {
        code: "23.32",
        score: "medium",
        name_no: "Produksjon av murstein, teglstein og andre byggevarer av brent leire",
        name_en: "Manufacture of bricks, tiles and construction products, in baked clay",
    },
    {
        code: "23.41",
        score: "medium",
        name_no: "Produksjon av keramiske husholdningsartikler og dekorasjonsgjenstander",
        name_en: "Manufacture of ceramic household and ornamental articles",
    },
    {
        code: "23.42",
        score: "medium",
        name_no: "Produksjon av sanitærutstyr av keramisk materiale",
        name_en: "Manufacture of ceramic sanitary fixtures",
    },
    {
        code: "23.43",
        score: "medium",
        name_no: "Produksjon av isolatorer og isoleringsdeler av keramisk materiale",
        name_en: "Manufacture of ceramic insulators and insulating fittings",
    },
    {
        code: "23.44",
        score: "medium",
        name_no: "Produksjon av andre keramiske produkter for teknisk bruk",
        name_en: "Manufacture of other technical ceramic products",
    },
    {
        code: "23.49",
        score: "medium",
        name_no: "Produksjon av andre keramiske produkter",
        name_en: "Manufacture of other ceramic products",
    },
    { code: "23.51", score: "medium", name_no: "Produksjon av sement", name_en: "Manufacture of cement" },
    {
        code: "23.52",
        score: "medium",
        name_no: "Produksjon av kalk og gips",
        name_en: "Manufacture of lime and plaster",
    },
    {
        code: "23.61",
        score: "medium",
        name_no: "Produksjon av betongprodukter for bygge- og anleggsvirksomhet",
        name_en: "Manufacture of concrete products for construction purposes",
    },
    {
        code: "23.62",
        score: "medium",
        name_no: "Produksjon av gipsprodukter for bygge- og anleggsvirksomhet",
        name_en: "Manufacture of plaster products for construction purposes",
    },
    {
        code: "23.63",
        score: "medium",
        name_no: "Produksjon av ferdigblandet betong",
        name_en: "Manufacture of ready-mixed concrete",
    },
    { code: "23.64", score: "medium", name_no: "Produksjon av mørtel", name_en: "Manufacture of mortars" },
    { code: "23.65", score: "medium", name_no: "Produksjon av fibersement", name_en: "Manufacture of fibre cement" },
    {
        code: "23.69",
        score: "medium",
        name_no: "Produksjon av betong-, sement- og gipsprodukter ellers",
        name_en: "Manufacture of other articles of concrete, plaster and cement",
    },
    {
        code: "23.70",
        score: "medium",
        name_no: "Hogging og bearbeiding av monument- og bygningsstein",
        name_en: "Cutting, shaping and finishing of stone",
    },
    {
        code: "23.91",
        score: "medium",
        name_no: "Produksjon av slipestoffer",
        name_en: "Production of abrasive products",
    },
    {
        code: "23.99",
        score: "medium",
        name_no: "Produksjon av ikke-metallholdige mineralprodukter ikke nevnt annet sted",
        name_en: "Manufacture of other non-metallic mineral products n.e.c.",
    },
    {
        code: "24.10",
        score: "high",
        name_no: "Produksjon av jern og stål, samt ferrolegeringer",
        name_en: "Manufacture of basic iron and steel and of ferro-alloys",
    },
    {
        code: "24.20",
        score: "high",
        name_no: "Produksjon av andre rør og rørdeler av stål",
        name_en: "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel",
    },
    { code: "24.31", score: "high", name_no: "Kaldtrekking av stenger og profiler", name_en: "Cold drawing of bars" },
    { code: "24.32", score: "high", name_no: "Kaldvalsing av bånd", name_en: "Cold rolling of narrow strips" },
    {
        code: "24.33",
        score: "high",
        name_no: "Kaldvalsing og pressing av profilerte plater og profiler",
        name_en: "Cold forming or folding",
    },
    { code: "24.34", score: "high", name_no: "Kaldtrekking av tråd", name_en: "Cold drawing of wire" },
    { code: "24.41", score: "high", name_no: "Produksjon av edelmetaller", name_en: "Precious metals production" },
    { code: "24.42", score: "high", name_no: "Produksjon av aluminium", name_en: "Aluminium production" },
    {
        code: "24.43",
        score: "high",
        name_no: "Produksjon av bly, sink og tinn",
        name_en: "Lead, zinc and tin production",
    },
    { code: "24.44", score: "high", name_no: "Produksjon av kobber", name_en: "Copper production" },
    {
        code: "24.45",
        score: "high",
        name_no: "Produksjon av ikke-jernholdige metaller ellers",
        name_en: "Other non-ferrous metal production",
    },
    { code: "24.46", score: "high", name_no: "Produksjon av kjernebrensel", name_en: "Processing of nuclear fuel" },
    { code: "24.51", score: "high", name_no: "Støping av jern", name_en: "Casting of iron" },
    { code: "24.52", score: "high", name_no: "Støping av stål", name_en: "Casting of steel" },
    { code: "24.53", score: "high", name_no: "Støping av lettmetaller", name_en: "Casting of light metals" },
    {
        code: "24.54",
        score: "high",
        name_no: "Støping av andre ikke-jernholdige metaller",
        name_en: "Casting of other non-ferrous metals",
    },
    {
        code: "25.11",
        score: "medium",
        name_no: "Produksjon av metallkonstruksjoner og deler",
        name_en: "Manufacture of metal structures and parts of structures",
    },
    {
        code: "25.12",
        score: "medium",
        name_no: "Produksjon av dører og vinduer av metall",
        name_en: "Manufacture of doors and windows of metal",
    },
    {
        code: "25.21",
        score: "medium",
        name_no: "Produksjon av radiatorer og kjeler til sentralvarmeanlegg",
        name_en: "Manufacture of central heating radiators and boilers",
    },
    {
        code: "25.29",
        score: "medium",
        name_no: "Produksjon av andre tanker, cisterner og beholdere av metall",
        name_en: "Manufacture of other tanks, reservoirs and containers of metal",
    },
    {
        code: "25.30",
        score: "medium",
        name_no: "Produksjon av dampkjeler, unntatt kjeler til sentralvarmeanlegg",
        name_en: "Manufacture of steam generators, except central heating hot water boilers",
    },
    {
        code: "25.40",
        score: "medium",
        name_no: "Produksjon av våpen og ammunisjon",
        name_en: "Manufacture of weapons and ammunition",
    },
    {
        code: "25.50",
        score: "medium",
        name_no: "Smiing, stansing og valsing av metall, og pulvermetallurgi",
        name_en: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
    },
    {
        code: "25.61",
        score: "high",
        name_no: "Overflatebehandling av metaller",
        name_en: "Treatment and coating of metals",
    },
    { code: "25.62", score: "medium", name_no: "Bearbeiding av metaller", name_en: "Machining" },
    {
        code: "25.71",
        score: "medium",
        name_no: "Produksjon av kjøkkenredskaper og skjære- og klipperedskaper",
        name_en: "Manufacture of cutlery",
    },
    {
        code: "25.72",
        score: "medium",
        name_no: "Produksjon av låser og beslag",
        name_en: "Manufacture of locks and hinges",
    },
    { code: "25.73", score: "medium", name_no: "Produksjon av håndverktøy", name_en: "Manufacture of tools" },
    {
        code: "25.91",
        score: "medium",
        name_no: "Produksjon av stålfat og lignende beholdere av jern og stål",
        name_en: "Manufacture of steel drums and similar containers",
    },
    {
        code: "25.92",
        score: "medium",
        name_no: "Produksjon av emballasje av lettmetall",
        name_en: "Manufacture of light metal packaging",
    },
    {
        code: "25.93",
        score: "medium",
        name_no: "Produksjon av varer av metalltråd, kjetting og fjærer",
        name_en: "Manufacture of wire products, chain and springs",
    },
    {
        code: "25.94",
        score: "medium",
        name_no: "Produksjon av bolter og skruer",
        name_en: "Manufacture of fasteners and screw machine products",
    },
    {
        code: "25.99",
        score: "medium",
        name_no: "Produksjon av metallvarer ikke nevnt annet sted",
        name_en: "Manufacture of other fabricated metal products n.e.c.",
    },
    {
        code: "26.11",
        score: "low",
        name_no: "Produksjon av elektroniske komponenter",
        name_en: "Manufacture of electronic components",
    },
    {
        code: "26.12",
        score: "low",
        name_no: "Produksjon av kretskort",
        name_en: "Manufacture of loaded electronic boards",
    },
    {
        code: "26.20",
        score: "low",
        name_no: "Produksjon av datamaskiner og tilleggsutstyr",
        name_en: "Manufacture of computers and peripheral equipment",
    },
    {
        code: "26.30",
        score: "low",
        name_no: "Produksjon av kommunikasjonsutstyr",
        name_en: "Manufacture of communication equipment",
    },
    {
        code: "26.40",
        score: "low",
        name_no: "Produksjon av elektronikk til husholdningsbruk",
        name_en: "Manufacture of consumer electronics",
    },
    {
        code: "26.51",
        score: "low",
        name_no: "Produksjon av måle-, kontroll- og navigasjonsinstrumenter",
        name_en: "Manufacture of instruments and appliances for measuring, testing and navigation",
    },
    {
        code: "26.52",
        score: "low",
        name_no: "Produksjon av klokker og ur",
        name_en: "Manufacture of watches and clocks",
    },
    {
        code: "26.60",
        score: "low",
        name_no: "Produksjon av strålingsutstyr, elektromedisinsk og elektroterapeutisk utstyr",
        name_en: "Manufacture of irridation, electromedical and electrotherapeutic equipment",
    },
    {
        code: "26.70",
        score: "low",
        name_no: "Produksjon av optiske instrumenter og fotografisk utstyr",
        name_en: "Manufacture of optical instruments and photographic equipment",
    },
    {
        code: "26.80",
        score: "low",
        name_no: "Produksjon av magnetiske og optiske media",
        name_en: "Manufacture of magnetic and optical media",
    },
    {
        code: "27.11",
        score: "medium",
        name_no: "Produksjon av elektromotorer, generatorer og transformatorer",
        name_en: "Manufacture of electric motors, generators and transformers",
    },
    {
        code: "27.12",
        score: "medium",
        name_no: "Produksjon av elektriske fordelings- og kontrolltavler og paneler",
        name_en: "Manufacture of electricity distribution and control apparatus",
    },
    {
        code: "27.20",
        score: "medium",
        name_no: "Produksjon av batterier og akkumulatorer",
        name_en: "Manufacture of batteries and accumulators",
    },
    {
        code: "27.31",
        score: "medium",
        name_no: "Produksjon av optiske fiberkabler",
        name_en: "Manufacture of fibre optic cables",
    },
    {
        code: "27.32",
        score: "medium",
        name_no: "Produksjon av andre elektroniske og elektriske ledninger og kabler",
        name_en: "Manufacture of other electronic and electric wires and cables",
    },
    {
        code: "27.33",
        score: "medium",
        name_no: "Produksjon av ledningsmateriell",
        name_en: "Manufacture of wiring devices",
    },
    {
        code: "27.40",
        score: "medium",
        name_no: "Produksjon av belysningsutstyr",
        name_en: "Manufacture of electric lighting equipment",
    },
    {
        code: "27.51",
        score: "medium",
        name_no: "Produksjon av elektriske husholdningsmaskiner og apparater",
        name_en: "Manufacture of electric domestic appliances",
    },
    {
        code: "27.52",
        score: "medium",
        name_no: "Produksjon av ikke-elektriske husholdningsmaskiner og apparater",
        name_en: "Manufacture of non-electric domestic appliances",
    },
    {
        code: "27.90",
        score: "medium",
        name_no: "Produksjon av annet elektrisk utstyr",
        name_en: "Manufacture of other electrical equipment",
    },
    {
        code: "28.11",
        score: "medium",
        name_no: "Produksjon av motorer og turbiner, unntatt motorer til luftfartøyer og motorvogner",
        name_en: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
    },
    {
        code: "28.12",
        score: "medium",
        name_no: "Produksjon av komponenter til hydraulisk og pneumatisk utstyr",
        name_en: "Manufacture of fluid power equipment",
    },
    {
        code: "28.13",
        score: "medium",
        name_no: "Produksjon av pumper og kompressorer ellers",
        name_en: "Manufacture of other pumps and compressors",
    },
    {
        code: "28.14",
        score: "medium",
        name_no: "Produksjon av kraner og ventiler ellers",
        name_en: "Manufacture of taps and valves",
    },
    {
        code: "28.15",
        score: "medium",
        name_no: "Produksjon av lagre, gir, tannhjulsutvekslinger og andre innretninger for kraftoverføring",
        name_en: "Manufacture of bearings, gears, gearing and driving elements",
    },
    {
        code: "28.21",
        score: "medium",
        name_no: "Produksjon av industri- og laboratorieovner samt brennere",
        name_en: "Manufacture of ovens, furcodes and furcode burners",
    },
    {
        code: "28.22",
        score: "medium",
        name_no: "Produksjon av løfte- og håndteringsutstyr",
        name_en: "Manufacture of lifting and handling equipment",
    },
    {
        code: "28.23",
        score: "medium",
        name_no: "Produksjon av kontormaskiner og utstyr (unntatt datamaskiner og tilleggsutstyr)",
        name_en: "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
    },
    {
        code: "28.24",
        score: "medium",
        name_no: "Produksjon av motordrevet håndverktøy",
        name_en: "Manufacture of power-driven equipment",
    },
    {
        code: "28.25",
        score: "medium",
        name_no: "Produksjon av kjøle- og ventilasjonsanlegg, unntatt til husholdningsbruk",
        name_en: "Manufacture of non-domestic cooling and ventilation equipment",
    },
    {
        code: "28.29",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til generell bruk, ikke nevnt annet sted",
        name_en: "Manufacture of other general-purpose machinery n.e.c.",
    },
    {
        code: "28.30",
        score: "medium",
        name_no: "Produksjon av jordbruks- og skogbruksmaskiner",
        name_en: "Manufacture of agricultural and forestry machinery",
    },
    {
        code: "28.41",
        score: "medium",
        name_no: "Produksjon av maskinverktøy til metallbearbeiding",
        name_en: "Manufacture of metal forming machinery",
    },
    {
        code: "28.49",
        score: "medium",
        name_no: "Produksjon av maskinverktøy ikke nevnt annet sted",
        name_en: "Manufacture of other machine tools",
    },
    {
        code: "28.91",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til metallurgisk industri",
        name_en: "Manufacture of machinery for metallurgy",
    },
    {
        code: "28.92",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til bergverksdrift og bygge- og anleggsvirksomhet",
        name_en: "Manufacture of machinery for mining, quarrying and construction",
    },
    {
        code: "28.93",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til nærings- og nytelsesmiddelindustri",
        name_en: "Manufacture of machinery for food, beverage and tobacco processing",
    },
    {
        code: "28.94",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til tekstil-, konfeksjons- og lærvareindustri",
        name_en: "Manufacture of machinery for textile, apparel and leather production",
    },
    {
        code: "28.95",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til papir- og pappvareindustri",
        name_en: "Manufacture of machinery for paper and paperboard production",
    },
    {
        code: "28.96",
        score: "medium",
        name_no: "Produksjon av maskiner og utstyr til plast- og gummiindustri",
        name_en: "Manufacture of plastics and rubber machinery",
    },
    {
        code: "28.99",
        score: "medium",
        name_no: "Produksjon av spesialmaskiner ikke nevnt annet sted",
        name_en: "Manufacture of other special-purpose machinery n.e.c.",
    },
    { code: "29.10", score: "medium", name_no: "Produksjon av motorvogner", name_en: "Manufacture of motor vehicles" },
    {
        code: "29.20",
        score: "medium",
        name_no: "Produksjon av karosserier og tilhengere",
        name_en: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
    },
    {
        code: "29.31",
        score: "medium",
        name_no: "Produksjon av elektrisk og elektronisk utstyr til motorvogner",
        name_en: "Manufacture of electrical and electronic equipment for motor vehicles",
    },
    {
        code: "29.32",
        score: "medium",
        name_no: "Produksjon av andre deler og annet utstyr til motorvogner",
        name_en: "Manufacture of other parts and accessories for motor vehicles",
    },
    {
        code: "30.11",
        score: "high",
        name_no: "Bygging av skip og flytende materiell",
        name_en: "Building of ships and floating structures",
    },
    {
        code: "30.12",
        score: "medium",
        name_no: "Bygging av fritidsbåter",
        name_en: "Building of pleasure and sporting boats",
    },
    {
        code: "30.20",
        score: "medium",
        name_no: "Produksjon av lokomotiver og annet rullende materiell til jernbane og sporvei",
        name_en: "Manufacture of railway locomotives and rolling stock",
    },
    {
        code: "30.30",
        score: "medium",
        name_no: "Produksjon av luftfartøyer og romfartøyer og lignende utstyr",
        name_en: "Manufacture of air and spacecraft and related machinery",
    },
    {
        code: "30.40",
        score: "medium",
        name_no: "Produksjon av militære stridskjøretøyer",
        name_en: "Manufacture of military fighting machinery",
    },
    { code: "30.91", score: "medium", name_no: "Produksjon av motorsykler", name_en: "Manufacture of motorcycles" },
    {
        code: "30.92",
        score: "medium",
        name_no: "Produksjon av sykler og invalidevogner",
        name_en: "Manufacture of bicycles and invalid carriages",
    },
    {
        code: "30.99",
        score: "medium",
        name_no: "Produksjon av andre transportmidler ikke nevnt annet sted",
        name_en: "Manufacture of other transport equipment n.e.c.",
    },
    {
        code: "31.01",
        score: "medium",
        name_no: "Produksjon av kontor- og butikkmøbler",
        name_en: "Manufacture of office and shop furniture",
    },
    {
        code: "31.02",
        score: "medium",
        name_no: "Produksjon av kjøkkenmøbler",
        name_en: "Manufacture of kitchen furniture",
    },
    { code: "31.03", score: "medium", name_no: "Produksjon av madrasser", name_en: "Manufacture of mattresses" },
    {
        code: "31.09",
        score: "medium",
        name_no: "Produksjon av møbler ellers",
        name_en: "Manufacture of other furniture",
    },
    { code: "32.11", score: "medium", name_no: "Preging av mynter og medaljer", name_en: "Striking of coins" },
    {
        code: "32.12",
        score: "medium",
        name_no: "Produksjon av gull- og sølvvarer og lignende artikler",
        name_en: "Manufacture of jewellery and related articles",
    },
    {
        code: "32.13",
        score: "medium",
        name_no: "Produksjon av bijouteri og lignende artikler",
        name_en: "Manufacture of imitation jewellery and related articles",
    },
    {
        code: "32.20",
        score: "medium",
        name_no: "Produksjon av musikkinstrumenter",
        name_en: "Manufacture of musical instruments",
    },
    { code: "32.30", score: "medium", name_no: "Produksjon av sportsartikler", name_en: "Manufacture of sports goods" },
    {
        code: "32.40",
        score: "medium",
        name_no: "Produksjon av spill og leker",
        name_en: "Manufacture of games and toys",
    },
    {
        code: "32.50",
        score: "medium",
        name_no: "Produksjon av medisinske og tanntekniske instrumenter og utstyr",
        name_en: "Manufacture of medical and dental instruments and supplies",
    },
    {
        code: "32.91",
        score: "medium",
        name_no: "Produksjon av koster og børster",
        name_en: "Manufacture of brooms and brushes",
    },
    {
        code: "32.99",
        score: "medium",
        name_no: "Annen industriproduksjon ikke nevnt annet sted",
        name_en: "Other manufacturing n.e.c.",
    },
    {
        code: "33.11",
        score: "medium",
        name_no: "Reparasjon av bearbeidede metallprodukter",
        name_en: "Repair of fabricated metal products",
    },
    { code: "33.12", score: "medium", name_no: "Reparasjon av maskiner", name_en: "Repair of machinery" },
    {
        code: "33.13",
        score: "medium",
        name_no: "Reparasjon av elektronisk og optisk utstyr",
        name_en: "Repair of electronic and optical equipment",
    },
    {
        code: "33.14",
        score: "medium",
        name_no: "Reparasjon av elektrisk utstyr",
        name_en: "Repair of electrical equipment",
    },
    {
        code: "33.15",
        score: "medium",
        name_no: "Reparasjon og vedlikehold av skip og båter",
        name_en: "Repair and maintenance of ships and boats",
    },
    {
        code: "33.16",
        score: "medium",
        name_no: "Reparasjon og vedlikehold av luftfartøyer og romfartøyer",
        name_en: "Repair and maintenance of aircraft and spacecraft",
    },
    {
        code: "33.17",
        score: "medium",
        name_no: "Reparasjon og vedlikehold av andre transportmidler",
        name_en: "Repair and maintenance of other transport equipment",
    },
    { code: "33.19", score: "medium", name_no: "Reparasjon av annet utstyr", name_en: "Repair of other equipment" },
    {
        code: "33.20",
        score: "medium",
        name_no: "Installasjon av industrimaskiner og -utstyr",
        name_en: "Installation of industrial machinery and equipment",
    },
    { code: "35.11", score: "high", name_no: "Produksjon av elektrisitet", name_en: "Production of electricity" },
    { code: "35.12", score: "high", name_no: "Overføring av elektrisitet", name_en: "Transmission of electricity" },
    { code: "35.13", score: "high", name_no: "Distribusjon av elektrisitet", name_en: "Distribution of electricity" },
    { code: "35.14", score: "low", name_no: "Handel med elektrisitet", name_en: "Trade of electricity" },
    { code: "35.21", score: "high", name_no: "Produksjon av gass", name_en: "Manufacture of gas" },
    {
        code: "35.22",
        score: "medium",
        name_no: "Distribusjon av gass gjennom ledningsnett",
        name_en: "Distribution of gaseous fuels through mains",
    },
    {
        code: "35.23",
        score: "low",
        name_no: "Handel med gass gjennom ledningsnett",
        name_en: "Trade of gas through mains",
    },
    {
        code: "35.30",
        score: "medium",
        name_no: "Damp- og varmtvannsforsyning",
        name_en: "Steam and air conditioning supply",
    },
    {
        code: "36.00",
        score: "medium",
        name_no: "Uttak fra kilde, rensing og distribusjon av vann",
        name_en: "Water collection, treatment and supply",
    },
    { code: "37.00", score: "high", name_no: "Oppsamling og behandling av avløpsvann", name_en: "Sewerage" },
    {
        code: "38.11",
        score: "medium",
        name_no: "Innsamling av ikke-farlig avfall",
        name_en: "Collection of non-hazardous waste",
    },
    { code: "38.12", score: "high", name_no: "Innsamling av farlig avfall", name_en: "Collection of hazardous waste" },
    {
        code: "38.21",
        score: "high",
        name_no: "Behandling og disponering av ikke-farlig avfall",
        name_en: "Treatment and disposal of non-hazardous waste",
    },
    {
        code: "38.22",
        score: "high",
        name_no: "Behandling og disponering av farlig avfall",
        name_en: "Treatment and disposal of hazardous waste",
    },
    { code: "38.31", score: "high", name_no: "Demontering av vrakede gjenstander", name_en: "Dismantling of wrecks" },
    {
        code: "38.32",
        score: "high",
        name_no: "Sortering og bearbeiding av avfall for materialgjenvinning",
        name_en: "Recovering of sorted materials",
    },
    {
        code: "39.00",
        score: "high",
        name_no: "Miljørydding, miljørensing og lignende virksomhet",
        name_en: "Remediation activities and other waste management services",
    },
    {
        code: "41.10",
        score: "low",
        name_no: "Utvikling av byggeprosjekter",
        name_en: "Development of building projects",
    },
    {
        code: "41.20",
        score: "high",
        name_no: "Oppføring av bygninger",
        name_en: "Construction of residential and non-residential buildings",
    },
    {
        code: "42.11",
        score: "high",
        name_no: "Bygging av veier og motorveier",
        name_en: "Construction of roads and motorways",
    },
    {
        code: "42.12",
        score: "high",
        name_no: "Bygging av jernbaner og undergrunnsbaner",
        name_en: "Construction of railways and underground railways",
    },
    {
        code: "42.13",
        score: "high",
        name_no: "Bygging av bruer og tunneler",
        name_en: "Construction of bridges and tunnels",
    },
    {
        code: "42.21",
        score: "high",
        name_no: "Bygging av vann- og kloakkanlegg",
        name_en: "Construction of utility projects for fluids",
    },
    {
        code: "42.22",
        score: "high",
        name_no: "Bygging av anlegg for elektrisitet og telekommunikasjon",
        name_en: "Construction of utility projects for electricity and telecommunications",
    },
    {
        code: "42.91",
        score: "high",
        name_no: "Bygging av havne- og damanlegg",
        name_en: "Construction of water projects",
    },
    {
        code: "42.99",
        score: "high",
        name_no: "Bygging av andre anlegg ikke nevnt annet sted",
        name_en: "Construction of other civil engineering projects n.e.c.",
    },
    { code: "43.11", score: "high", name_no: "Riving av bygninger og andre konstruksjoner", name_en: "Demolition" },
    { code: "43.12", score: "high", name_no: "Grunnarbeid", name_en: "Site preparation" },
    { code: "43.13", score: "high", name_no: "Prøveboring", name_en: "Test drilling and boring" },
    { code: "43.21", score: "medium", name_no: "Elektrisk installasjonsarbeid", name_en: "Electrical installation" },
    {
        code: "43.22",
        score: "medium",
        name_no: "VVS-arbeid",
        name_en: "Plumbing, heat and air-conditioning installation",
    },
    {
        code: "43.29",
        score: "medium",
        name_no: "Annet installasjonsarbeid",
        name_en: "Other construction installation",
    },
    { code: "43.31", score: "medium", name_no: "Stukkatørarbeid og pussing", name_en: "Plastering" },
    { code: "43.32", score: "medium", name_no: "Snekkerarbeid", name_en: "Joinery installation" },
    { code: "43.33", score: "medium", name_no: "Gulvlegging og tapetsering", name_en: "Floor and wall covering" },
    { code: "43.34", score: "medium", name_no: "Maler- og glassarbeid", name_en: "Painting and glazing" },
    {
        code: "43.39",
        score: "medium",
        name_no: "Annen ferdiggjøring av bygninger",
        name_en: "Other building completion and finishing",
    },
    { code: "43.91", score: "high", name_no: "Takarbeid", name_en: "Roofing activities" },
    {
        code: "43.99",
        score: "high",
        name_no: "Annen spesialisert bygge- og anleggsvirksomhet",
        name_en: "Other specialised construction activities n.e.c.",
    },
    {
        code: "45.11",
        score: "low",
        name_no: "Handel med biler og lette motorvogner, unntatt motorsykler",
        name_en: "Sale of cars and light motor vehicles",
    },
    {
        code: "45.19",
        score: "low",
        name_no: "Handel med andre motorvogner, unntatt motorsykler",
        name_en: "Sale of other motor vehicles",
    },
    {
        code: "45.20",
        score: "low",
        name_no: "Vedlikehold og reparasjon av motorvogner, unntatt motorsykler",
        name_en: "Maintenance and repair of motor vehicles",
    },
    {
        code: "45.31",
        score: "low",
        name_no: "Agentur- og engroshandel med deler og utstyr til motorvogner, unntatt motorsykler",
        name_en: "Wholesale trade of motor vehicle parts and accessories",
    },
    {
        code: "45.32",
        score: "low",
        name_no: "Detaljhandel med deler og utstyr til motorvogner, unntatt motorsykler",
        name_en: "Retail trade of motor vehicle parts and accessories",
    },
    {
        code: "45.40",
        score: "low",
        name_no: "Handel med motorsykler, deler og utstyr. Vedlikehold og reparasjon av motorsykler",
        name_en: "Sale, maintenance and repair of motorcycles and related parts and accessories",
    },
    {
        code: "46.11",
        score: "low",
        name_no: "Agenturhandel med jordbruksråvarer, levende dyr, tekstilråvarer og innsatsvarer",
        name_en:
            "Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods",
    },
    {
        code: "46.12",
        score: "low",
        name_no: "Agenturhandel med brensel, drivstoff, malm, metaller og industrikjemikalier",
        name_en: "Agents involved in the sale of fuels, ores, metals and industrial chemicals",
    },
    {
        code: "46.13",
        score: "low",
        name_no: "Agenturhandel med tømmer, trelast og byggevarer",
        name_en: "Agents involved in the sale of timber and building materials",
    },
    {
        code: "46.14",
        score: "low",
        name_no: "Agenturhandel med maskiner, produksjonsutstyr, båter og luftfartøyer",
        name_en: "Agents involved in the sale of machinery, industrial equipment, ships and aircraft",
    },
    {
        code: "46.15",
        score: "low",
        name_no: "Agenturhandel med møbler, husholdningsvarer og jernvarer",
        name_en: "Agents involved in the sale of furniture, household goods, hardware and ironmongery",
    },
    {
        code: "46.16",
        score: "low",
        name_no: "Agenturhandel med tekstiler, klær, pelsskinn, skotøy og lærvarer",
        name_en: "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods",
    },
    {
        code: "46.17",
        score: "low",
        name_no: "Agenturhandel med nærings- og nytelsesmidler",
        name_en: "Agents involved in the sale of food, beverages and tobacco",
    },
    {
        code: "46.18",
        score: "low",
        name_no: "Agenturhandel med spesialisert vareutvalg ellers",
        name_en: "Agents specialised in the sale of other particular products",
    },
    {
        code: "46.19",
        score: "low",
        name_no: "Agenturhandel med bredt vareutvalg",
        name_en: "Agents involved in the sale of a variety of goods",
    },
    {
        code: "46.21",
        score: "low",
        name_no: "Engroshandel med korn, råtobakk, såvarer og fôrvarer",
        name_en: "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds",
    },
    {
        code: "46.22",
        score: "low",
        name_no: "Engroshandel med blomster og planter",
        name_en: "Wholesale of flowers and plants",
    },
    { code: "46.23", score: "low", name_no: "Engroshandel med levende dyr", name_en: "Wholesale of live animals" },
    {
        code: "46.24",
        score: "low",
        name_no: "Engroshandel med huder, skinn og lær",
        name_en: "Wholesale of hides, skins and leather",
    },
    {
        code: "46.31",
        score: "low",
        name_no: "Engroshandel med frukt og grønnsaker",
        name_en: "Wholesale of fruit and vegetables",
    },
    {
        code: "46.32",
        score: "low",
        name_no: "Engroshandel med kjøtt og kjøttvarer",
        name_en: "Wholesale of meat and meat products",
    },
    {
        code: "46.33",
        score: "low",
        name_no: "Engroshandel med meierivarer, egg, matolje og -fett",
        name_en: "Wholesale of dairy products, eggs and edible oils and fats",
    },
    { code: "46.34", score: "low", name_no: "Engroshandel med drikkevarer", name_en: "Wholesale of beverages" },
    { code: "46.35", score: "low", name_no: "Engroshandel med tobakksvarer", name_en: "Wholesale of tobacco products" },
    {
        code: "46.36",
        score: "low",
        name_no: "Engroshandel med sukker, sjokolade og sukkervarer",
        name_en: "Wholesale of sugar and chocolate and sugar confectionery",
    },
    {
        code: "46.37",
        score: "low",
        name_no: "Engroshandel med kaffe, te, kakao og krydder",
        name_en: "Wholesale of coffee, tea, cocoa and spices",
    },
    {
        code: "46.38",
        score: "low",
        name_no: "Engroshandel med andre næringsmidler, herunder fisk, skalldyr og bløtdyr",
        name_en: "Wholesale of other food, including fish, crustaceans and molluscs",
    },
    {
        code: "46.39",
        score: "low",
        name_no: "Engroshandel med bredt utvalg av nærings- og nytelsesmidler",
        name_en: "Non-specialised wholesale of food, beverages and tobacco",
    },
    {
        code: "46.41",
        score: "low",
        name_no: "Engroshandel med tekstiler og utstyrsvarer",
        name_en: "Wholesale of textiles",
    },
    {
        code: "46.42",
        score: "low",
        name_no: "Engroshandel med klær og skotøy",
        name_en: "Wholesale of clothing and footwear",
    },
    {
        code: "46.43",
        score: "low",
        name_no: "Engroshandel med elektriske husholdningsapparater",
        name_en: "Wholesale of electrical household appliances",
    },
    {
        code: "46.44",
        score: "low",
        name_no: "Engroshandel med kjøkkenutstyr, glass, steintøy og rengjøringsmidler",
        name_en: "Wholesale of china and glassware and cleaning materials",
    },
    {
        code: "46.45",
        score: "low",
        name_no: "Engroshandel med parfyme og kosmetikk",
        name_en: "Wholesale of perfume and cosmetics",
    },
    {
        code: "46.46",
        score: "low",
        name_no: "Engroshandel med sykepleie- og apotekvarer",
        name_en: "Wholesale of pharmaceutical goods",
    },
    {
        code: "46.47",
        score: "low",
        name_no: "Engroshandel med møbler, gulvbelegg, gulvtepper og belysningsutstyr",
        name_en: "Wholesale of furniture, carpets and lighting equipment",
    },
    {
        code: "46.48",
        score: "low",
        name_no: "Engroshandel med klokker, ur, gull- og sølvvarer",
        name_en: "Wholesale of watches and jewellery",
    },
    {
        code: "46.49",
        score: "low",
        name_no: "Engroshandel med husholdningsvarer og varer til personlig bruk ellers",
        name_en: "Wholesale of other household goods",
    },
    {
        code: "46.51",
        score: "low",
        name_no: "Engroshandel med datamaskiner, tilleggsutstyr til datamaskiner samt programvare",
        name_en: "Wholesale of computers, computer peripheral equipment and software",
    },
    {
        code: "46.52",
        score: "low",
        name_no: "Engroshandel med elektronikkutstyr og telekommunikasjonsutstyr samt deler",
        name_en: "Wholesale of electronic and telecommunications equipment and parts",
    },
    {
        code: "46.61",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr til jordbruk og skogbruk",
        name_en: "Wholesale of agricultural machinery, equipment and supplies",
    },
    { code: "46.62", score: "low", name_no: "Engroshandel med maskinverktøy", name_en: "Wholesale of machine tools" },
    {
        code: "46.63",
        score: "low",
        name_no:
            "Engroshandel med maskiner og utstyr til bergverksdrift, olje- og gassutvinning og bygge- og anleggsvirksomhet",
        name_en: "Wholesale of mining, construction and civil engineering machinery",
    },
    {
        code: "46.64",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr til tekstilproduksjon",
        name_en: "Wholesale of machinery for textile industry and of sewing and knitting machines",
    },
    { code: "46.65", score: "low", name_no: "Engroshandel med kontormøbler", name_en: "Wholesale of office furniture" },
    {
        code: "46.66",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr til kontor ellers",
        name_en: "Wholesale of other office machinery and equipment",
    },
    {
        code: "46.69",
        score: "low",
        name_no: "Engroshandel med maskiner og utstyr ellers",
        name_en: "Wholesale of other machinery and equipment",
    },
    {
        code: "46.71",
        score: "high",
        name_no: "Engroshandel med drivstoff og brensel",
        name_en: "Wholesale of solid, liquid and gaseous fuels and related products",
    },
    {
        code: "46.72",
        score: "high",
        name_no: "Engroshandel med metaller og metallholdig malm",
        name_en: "Wholesale of metals and metal ores",
    },
    {
        code: "46.73",
        score: "low",
        name_no: "Engroshandel med tømmer, trelast, byggevarer og sanitærutstyr",
        name_en: "Wholesale of wood, construction materials and sanitary equipment",
    },
    {
        code: "46.74",
        score: "low",
        name_no: "Engroshandel med jernvarer, rørleggerartikler og oppvarmingsutstyr",
        name_en: "Wholesale of hardware, plumbing and heating equipment and supplies",
    },
    {
        code: "46.75",
        score: "high",
        name_no: "Engroshandel med kjemiske produkter",
        name_en: "Wholesale of chemical products",
    },
    {
        code: "46.76",
        score: "low",
        name_no: "Engroshandel med innsatsvarer ellers",
        name_en: "Wholesale of other intermediate products",
    },
    {
        code: "46.77",
        score: "high",
        name_no: "Engroshandel med avfall og skrap",
        name_en: "Wholesale of waste and scrap",
    },
    { code: "46.90", score: "low", name_no: "Uspesifisert engroshandel", name_en: "Non-specialised wholesale trade" },
    {
        code: "47.11",
        score: "medium",
        name_no: "Butikkhandel med bredt vareutvalg med hovedvekt på nærings- og nytelsesmidler",
        name_en: "Retail sale in non-specialised stores with food, beverages or tobacco predominating",
    },
    {
        code: "47.19",
        score: "low",
        name_no: "Butikkhandel med bredt vareutvalg ellers",
        name_en: "Other retail sale in non-specialised stores",
    },
    {
        code: "47.21",
        score: "low",
        name_no: "Butikkhandel med frukt og grønnsaker",
        name_en: "Retail sale of fruit and vegetables in specialised stores",
    },
    {
        code: "47.22",
        score: "low",
        name_no: "Butikkhandel med kjøtt og kjøttvarer",
        name_en: "Retail sale of meat and meat products in specialised stores",
    },
    {
        code: "47.23",
        score: "low",
        name_no: "Butikkhandel med fisk, skalldyr og bløtdyr",
        name_en: "Retail sale of fish, crustaceans and molluscs in specialised stores",
    },
    {
        code: "47.24",
        score: "low",
        name_no: "Butikkhandel med bakervarer, konditorvarer og sukkervarer",
        name_en: "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores",
    },
    {
        code: "47.25",
        score: "low",
        name_no: "Butikkhandel med drikkevarer",
        name_en: "Retail sale of beverages in specialised stores",
    },
    {
        code: "47.26",
        score: "low",
        name_no: "Butikkhandel med tobakksvarer",
        name_en: "Retail sale of tobacco products in specialised stores",
    },
    {
        code: "47.29",
        score: "low",
        name_no: "Butikkhandel med nærings- og nytelsesmidler ellers",
        name_en: "Other retail sale of food in specialised stores",
    },
    {
        code: "47.30",
        score: "medium",
        name_no: "Detaljhandel med drivstoff til motorvogner",
        name_en: "Retail sale of automotive fuel in specialised stores",
    },
    {
        code: "47.41",
        score: "low",
        name_no: "Butikkhandel med datamaskiner og utstyr til datamaskiner",
        name_en: "Retail sale of computers, peripheral units and software in specialised stores",
    },
    {
        code: "47.42",
        score: "low",
        name_no: "Butikkhandel med telekommunikasjonsutstyr",
        name_en: "Retail sale of telecommunications equipment in specialised stores",
    },
    {
        code: "47.43",
        score: "low",
        name_no: "Butikkhandel med audio- og videoutstyr",
        name_en: "Retail sale of audio and video equipment in specialised stores",
    },
    {
        code: "47.51",
        score: "low",
        name_no: "Butikkhandel med tekstiler og utstyrsvarer",
        name_en: "Retail sale of textiles in specialised stores",
    },
    {
        code: "47.52",
        score: "low",
        name_no: "Butikkhandel med jernvarer, fargevarer og glass",
        name_en: "Retail sale of hardware, paints and glass in specialised stores",
    },
    {
        code: "47.53",
        score: "low",
        name_no: "Butikkhandel med tapeter, gulvtepper og gardiner",
        name_en: "Retail sale of carpets, rugs, wall and floor coverings in specialised stores",
    },
    {
        code: "47.54",
        score: "low",
        name_no: "Butikkhandel med elektriske husholdningsapparater",
        name_en: "Retail sale of electrical household appliances in specialised stores",
    },
    {
        code: "47.59",
        score: "low",
        name_no: "Butikkhandel med møbler, belysningsutstyr og andre innredningsartikler",
        name_en: "Retail sale of furniture, lighting equipment and other household articles in specialised stores",
    },
    {
        code: "47.61",
        score: "low",
        name_no: "Butikkhandel med bøker",
        name_en: "Retail sale of books in specialised stores",
    },
    {
        code: "47.62",
        score: "low",
        name_no: "Butikkhandel med aviser og papirvarer",
        name_en: "Retail sale of newspapers and stationery in specialised stores",
    },
    {
        code: "47.63",
        score: "low",
        name_no: "Butikkhandel med innspillinger av musikk og video",
        name_en: "Retail sale of music and video recordings in specialised stores",
    },
    {
        code: "47.64",
        score: "low",
        name_no: "Butikkhandel med sportsutstyr",
        name_en: "Retail sale of sporting equipment in specialised stores",
    },
    {
        code: "47.65",
        score: "low",
        name_no: "Butikkhandel med spill og leker",
        name_en: "Retail sale of games and toys in specialised stores",
    },
    {
        code: "47.71",
        score: "low",
        name_no: "Butikkhandel med klær",
        name_en: "Retail sale of clothing in specialised stores",
    },
    {
        code: "47.72",
        score: "low",
        name_no: "Butikkhandel med skotøy og lærvarer",
        name_en: "Retail sale of footwear and leather goods in specialised stores",
    },
    {
        code: "47.73",
        score: "medium",
        name_no: "Butikkhandel med apotekvarer",
        name_en: "Dispensing chemist in specialised stores",
    },
    {
        code: "47.74",
        score: "medium",
        name_no: "Butikkhandel med medisinske og ortopediske artikler",
        name_en: "Retail sale of medical and orthopaedic goods in specialised stores",
    },
    {
        code: "47.75",
        score: "medium",
        name_no: "Butikkhandel med kosmetikk og toalettartikler",
        name_en: "Retail sale of cosmetic and toilet articles in specialised stores",
    },
    {
        code: "47.76",
        score: "low",
        name_no: "Butikkhandel med blomster og planter, kjæledyr og fôrvarer til kjæledyr",
        name_en: "Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores",
    },
    {
        code: "47.77",
        score: "low",
        name_no: "Butikkhandel med ur, gull- og sølvvarer",
        name_en: "Retail sale of watches and jewellery in specialised stores",
    },
    {
        code: "47.78",
        score: "low",
        name_no: "Annen butikkhandel med andre nye varer i spesialforretninger",
        name_en: "Other retail sale of new goods in specialised stores",
    },
    {
        code: "47.79",
        score: "low",
        name_no: "Butikkhandel med brukte varer",
        name_en: "Retail sale of second-hand goods in stores",
    },
    {
        code: "47.81",
        score: "low",
        name_no: "Torghandel med næringsmidler, drikkevarer og tobakksvarer",
        name_en: "Retail sale via stalls and markets of food, beverages and tobacco products",
    },
    {
        code: "47.82",
        score: "low",
        name_no: "Torghandel med tekstiler, klær, skotøy og utstyrsvarer",
        name_en: "Retail sale via stalls and markets of textiles, clothing and footwear",
    },
    {
        code: "47.89",
        score: "low",
        name_no: "Torghandel med andre varer",
        name_en: "Retail sale via stalls and markets of other goods",
    },
    {
        code: "47.91",
        score: "low",
        name_no: "Postordrehandel og handel via Internett",
        name_en: "Retail sale via mail order houses or via Internet",
    },
    {
        code: "47.99",
        score: "low",
        name_no: "Detaljhandel utenom utsalgssted ellers",
        name_en: "Other retail sale not in stores, stalls or markets",
    },
    {
        code: "49.10",
        score: "low",
        name_no: "Passasjertransport med jernbane",
        name_en: "Passenger rail transport, interurban",
    },
    { code: "49.20", score: "low", name_no: "Godstransport med jernbane", name_en: "Freight rail transport" },
    {
        code: "49.31",
        score: "medium",
        name_no: "Transport med rutebil og sporvei i by- og forstadsområde",
        name_en: "Urban and suburban passenger land transport",
    },
    { code: "49.32", score: "medium", name_no: "Drosjebiltransport", name_en: "Taxi operation" },
    {
        code: "49.39",
        score: "medium",
        name_no: "Landtransport med passasjerer ikke nevnt annet sted",
        name_en: "Other passenger land transport n.e.c.",
    },
    { code: "49.41", score: "medium", name_no: "Godstransport på vei", name_en: "Freight transport by road" },
    { code: "49.42", score: "medium", name_no: "Flyttetransport", name_en: "Removal services" },
    { code: "49.50", score: "medium", name_no: "Rørtransport", name_en: "Transport via pipeline" },
    {
        code: "50.10",
        score: "medium",
        name_no: "Sjøfart og kysttrafikk med passasjerer",
        name_en: "Sea and coastal passenger water transport",
    },
    {
        code: "50.20",
        score: "medium",
        name_no: "Sjøfart og kysttrafikk med gods",
        name_en: "Sea and coastal freight water transport",
    },
    {
        code: "50.30",
        score: "low",
        name_no: "Passasjertransport på elver og innsjøer",
        name_en: "Inland passenger water transport",
    },
    {
        code: "50.40",
        score: "medium",
        name_no: "Godstransport på elver og innsjøer",
        name_en: "Inland freight water transport",
    },
    { code: "51.10", score: "low", name_no: "Lufttransport med passasjerer", name_en: "Passenger air transport" },
    { code: "51.21", score: "low", name_no: "Lufttransport med gods", name_en: "Freight air transport" },
    { code: "51.22", score: "high", name_no: "Romfart", name_en: "Space transport" },
    { code: "52.10", score: "medium", name_no: "Lagring", name_en: "Warehousing and storage" },
    {
        code: "52.21",
        score: "medium",
        name_no: "Andre tjenester tilknyttet landtransport",
        name_en: "Service activities incidental to land transportation",
    },
    {
        code: "52.22",
        score: "medium",
        name_no: "Andre tjenester tilknyttet sjøtransport",
        name_en: "Service activities incidental to water transportation",
    },
    {
        code: "52.23",
        score: "medium",
        name_no: "Andre tjenester tilknyttet lufttransport",
        name_en: "Service activities incidental to air transportation",
    },
    { code: "52.24", score: "medium", name_no: "Lasting og lossing", name_en: "Cargo handling" },
    {
        code: "52.29",
        score: "medium",
        name_no: "Andre tjenester tilknyttet transport",
        name_en: "Other transportation supply services",
    },
    {
        code: "53.10",
        score: "low",
        name_no: "Landsdekkende posttjenester",
        name_en: "Postal activities under universal service obligation",
    },
    {
        code: "53.20",
        score: "low",
        name_no: "Andre post- og budtjenester",
        name_en: "Other postal and courier activities",
    },
    { code: "55.10", score: "medium", name_no: "Hotellvirksomhet", name_en: "Hotels and similar accommodation" },
    {
        code: "55.20",
        score: "medium",
        name_no: "Drift av vandrerhjem og ferieleiligheter",
        name_en: "Holiday and short-stay accommodation",
    },
    {
        code: "55.30",
        score: "medium",
        name_no: "Drift av campingplasser",
        name_en: "Camping grounds, recreational vehicle parks and trailer parks",
    },
    { code: "55.90", score: "medium", name_no: "Annen overnatting", name_en: "Other accommodation" },
    {
        code: "56.10",
        score: "medium",
        name_no: "Restaurantvirksomhet",
        name_en: "Restaurants and mobile food service activities",
    },
    { code: "56.21", score: "medium", name_no: "Cateringvirksomhet", name_en: "Event catering activities" },
    {
        code: "56.29",
        score: "medium",
        name_no: "Kantiner drevet som selvstendig virksomhet",
        name_en: "Other food services",
    },
    { code: "56.30", score: "medium", name_no: "Drift av barer", name_en: "Beverage serving activities" },
    { code: "58.11", score: "low", name_no: "Utgivelse av bøker", name_en: "Book publishing" },
    {
        code: "58.12",
        score: "low",
        name_no: "Utgivelse av kataloger og adresselister",
        name_en: "Publishing of directories and mailing lists",
    },
    { code: "58.13", score: "low", name_no: "Utgivelse av aviser", name_en: "Publishing of newspapers" },
    {
        code: "58.14",
        score: "low",
        name_no: "Utgivelse av blader og tidsskrifter",
        name_en: "Publishing of journals and periodicals",
    },
    { code: "58.19", score: "low", name_no: "Forlagsvirksomhet ellers", name_en: "Other publishing activities" },
    {
        code: "58.21",
        score: "low",
        name_no: "Utgivelse av programvare for dataspill",
        name_en: "Publishing of computer games",
    },
    { code: "58.29", score: "low", name_no: "Utgivelse av annen programvare", name_en: "Other software publishing" },
    {
        code: "59.11",
        score: "low",
        name_no: "Produksjon av film, video og fjernsynsprogrammer",
        name_en: "Motion picture, video and television programme production activities",
    },
    {
        code: "59.12",
        score: "low",
        name_no: "Etterarbeid knyttet til produksjon av film, video og fjernsynsprogrammer",
        name_en: "Motion picture, video and television programme post-production activities",
    },
    {
        code: "59.13",
        score: "low",
        name_no: "Distribusjon av film, video og fjernsynsprogrammer",
        name_en: "Motion picture, video and television programme distribution activities",
    },
    { code: "59.14", score: "low", name_no: "Filmframvisning", name_en: "Motion picture projection activities" },
    {
        code: "59.20",
        score: "low",
        name_no: "Produksjon og utgivelse av musikk- og lydopptak",
        name_en: "Sound recording and music publishing activities",
    },
    { code: "60.10", score: "low", name_no: "Radiokringkasting", name_en: "Radio broadcasting" },
    {
        code: "60.20",
        score: "low",
        name_no: "Fjernsynskringkasting",
        name_en: "Television programming and broadcasting activities",
    },
    {
        code: "61.10",
        score: "medium",
        name_no: "Kabelbasert telekommunikasjon",
        name_en: "Wired telecommunications activities",
    },
    {
        code: "61.20",
        score: "medium",
        name_no: "Trådløs telekommunikasjon",
        name_en: "Wireless telecommunications activities",
    },
    {
        code: "61.30",
        score: "medium",
        name_no: "Satellittbasert telekommunikasjon",
        name_en: "Satellite telecommunications activities",
    },
    {
        code: "61.90",
        score: "medium",
        name_no: "Telekommunikasjon ellers",
        name_en: "Other telecommunications activities",
    },
    { code: "62.01", score: "low", name_no: "Programmeringstjenester", name_en: "Computer programming activities" },
    {
        code: "62.02",
        score: "low",
        name_no: "Konsulentvirksomhet tilknyttet informasjonsteknologi",
        name_en: "Computer consultancy activities",
    },
    {
        code: "62.03",
        score: "low",
        name_no: "Forvaltning og drift av IT-systemer",
        name_en: "Computer facilities management activities",
    },
    {
        code: "62.09",
        score: "low",
        name_no: "Andre tjenester tilknyttet informasjonsteknologi",
        name_en: "Other information technology and computer service activities",
    },
    {
        code: "63.11",
        score: "low",
        name_no: "Databehandling, datalagring og tilknyttede tjenester",
        name_en: "Data processing, hosting and related activities",
    },
    { code: "63.12", score: "low", name_no: "Drift av web-portaler", name_en: "Web portals" },
    { code: "63.91", score: "low", name_no: "Nyhetsbyråer", name_en: "News agency activities" },
    {
        code: "63.99",
        score: "low",
        name_no: "Andre informasjonstjenester ikke nevnt annet sted",
        name_en: "Other information service activities n.e.c.",
    },
    { code: "64.11", score: "low", name_no: "Sentralbankvirksomhet", name_en: "Central banking" },
    { code: "64.19", score: "low", name_no: "Bankvirksomhet ellers", name_en: "Other monetary intermediation" },
    { code: "64.20", score: "low", name_no: "Holdingselskaper", name_en: "Activities of holding companies" },
    {
        code: "64.30",
        score: "low",
        name_no: "Verdipapirfond, investeringsselskaper o.l.",
        name_en: "Trusts, funds and similar financial entities",
    },
    { code: "64.91", score: "low", name_no: "Finansiell leasing", name_en: "Financial leasing" },
    { code: "64.92", score: "low", name_no: "Annen kredittgivning", name_en: "Other credit granting" },
    {
        code: "64.99",
        score: "low",
        name_no: "Annen finansieringsvirksomhet ikke nevnt annet sted",
        name_en: "Other financial service activities, except insurance and pension funding n.e.c.",
    },
    { code: "65.11", score: "low", name_no: "Livsforsikring", name_en: "Life insurance" },
    { code: "65.12", score: "low", name_no: "Skadeforsikring", name_en: "Non-life insurance" },
    { code: "65.20", score: "low", name_no: "Gjenforsikring", name_en: "Reinsurance" },
    { code: "65.30", score: "low", name_no: "Pensjonskasser", name_en: "Pension funding" },
    {
        code: "66.11",
        score: "low",
        name_no: "Administrasjon av finansmarkeder",
        name_en: "Administration of financial markets",
    },
    {
        code: "66.12",
        score: "low",
        name_no: "Verdipapirmegling",
        name_en: "Security and commodity contracts brokerage",
    },
    {
        code: "66.19",
        score: "low",
        name_no: "Andre tjenester tilknyttet finansieringsvirksomhet",
        name_en: "Other activities auxiliary to financial services, except insurance and pension funding",
    },
    { code: "66.21", score: "low", name_no: "Risiko- og skadevurdering", name_en: "Risk and damage evaluation" },
    {
        code: "66.22",
        score: "low",
        name_no: "Forsikringsformidling",
        name_en: "Activities of insurance agents and brokers",
    },
    {
        code: "66.29",
        score: "low",
        name_no: "Andre tjenester tilknyttet forsikringsvirksomhet og pensjonskasser",
        name_en: "Other activities auxiliary to insurance and pension funding",
    },
    { code: "66.30", score: "low", name_no: "Fondsforvaltningsvirksomhet", name_en: "Fund management activities" },
    {
        code: "68.10",
        score: "low",
        name_no: "Kjøp og salg av egen fast eiendom",
        name_en: "Buying and selling of own real estate",
    },
    {
        code: "68.20",
        score: "medium",
        name_no: "Utleie av egen eller leid fast eiendom",
        name_en: "Renting and operating of own real estate",
    },
    { code: "68.31", score: "low", name_no: "Eiendomsmegling", name_en: "Real estate agencies" },
    {
        code: "68.32",
        score: "low",
        name_no: "Eiendomsforvaltning",
        name_en: "Management of real estate on a fee or contract basis",
    },
    { code: "69.10", score: "low", name_no: "Juridisk tjenesteyting", name_en: "Legal activities" },
    {
        code: "69.20",
        score: "low",
        name_no: "Regnskap, revisjon og skatterådgivning",
        name_en: "Accounting, bookkeeping and auditing activities; tax consultancy",
    },
    { code: "70.10", score: "low", name_no: "Hovedkontortjenester", name_en: "Activities of head offices" },
    {
        code: "70.21",
        score: "low",
        name_no: "PR og kommunikasjonstjenester",
        name_en: "Public relations and communication activities",
    },
    {
        code: "70.22",
        score: "low",
        name_no: "Bedriftsrådgivning og annen administrativ rådgivning",
        name_en: "Business and other management consultancy activities",
    },
    { code: "71.11", score: "low", name_no: "Arkitektvirksomhet", name_en: "Architectural activities" },
    {
        code: "71.12",
        score: "low",
        name_no: "Teknisk konsulentvirksomhet",
        name_en: "Engineering activities and related technical consultancy",
    },
    { code: "71.20", score: "low", name_no: "Teknisk prøving og analyse", name_en: "Technical testing and analysis" },
    {
        code: "72.11",
        score: "low",
        name_no: "Forskning og utviklingsarbeid innen bioteknologi",
        name_en: "Research and experimental development on biotechnology",
    },
    {
        code: "72.19",
        score: "low",
        name_no: "Annen forskning og annet utviklingsarbeid innen naturvitenskap og teknikk",
        name_en: "Other research and experimental development on natural sciences and engineering",
    },
    {
        code: "72.20",
        score: "low",
        name_no: "Forskning og utviklingsarbeid innen samfunnsvitenskap og humanistiske fag",
        name_en: "Research and experimental development on social sciences and humanities",
    },
    { code: "73.11", score: "low", name_no: "Reklamebyråer", name_en: "Advertising agencies" },
    { code: "73.12", score: "low", name_no: "Medieformidlingstjenester", name_en: "Media representation" },
    {
        code: "73.20",
        score: "low",
        name_no: "Markeds- og opinionsundersøkelser",
        name_en: "Market research and public opinion polling",
    },
    { code: "74.10", score: "low", name_no: "Spesialisert designvirksomhet", name_en: "Specialised design activities" },
    { code: "74.20", score: "low", name_no: "Fotografvirksomhet", name_en: "Photographic activities" },
    {
        code: "74.30",
        score: "low",
        name_no: "Oversettelses- og tolkevirksomhet",
        name_en: "Translation and interpretation activities",
    },
    {
        code: "74.90",
        score: "low",
        name_no: "Annen faglig, vitenskapelig og teknisk virksomhet",
        name_en: "Other professional, scientific and technical activities n.e.c.",
    },
    { code: "75.00", score: "low", name_no: "Veterinærtjenester", name_en: "Veterinary activities" },
    {
        code: "77.11",
        score: "low",
        name_no: "Utleie og leasing av biler og andre lette motorvogner",
        name_en: "Renting and leasing of cars and light motor vehicles",
    },
    {
        code: "77.12",
        score: "medium",
        name_no: "Utleie og leasing av lastebiler",
        name_en: "Renting and leasing of trucks",
    },
    {
        code: "77.21",
        score: "low",
        name_no: "Utleie og leasing av sports- og fritidsutstyr",
        name_en: "Renting and leasing of recreational and sports goods",
    },
    {
        code: "77.22",
        score: "low",
        name_no: "Utleie av videofilm, DVD og lignende",
        name_en: "Renting of video tapes and disks",
    },
    {
        code: "77.29",
        score: "low",
        name_no: "Utleie og leasing av andre husholdningsvarer og varer til personlig bruk",
        name_en: "Renting and leasing of other personal and household goods",
    },
    {
        code: "77.31",
        score: "medium",
        name_no: "Utleie og leasing av landbruksmaskiner og -utstyr",
        name_en: "Renting and leasing of agricultural machinery and equipment",
    },
    {
        code: "77.32",
        score: "medium",
        name_no: "Utleie og leasing av bygge- og anleggsmaskiner og -utstyr",
        name_en: "Renting and leasing of construction and civil engineering machinery and equipment",
    },
    {
        code: "77.33",
        score: "low",
        name_no: "Utleie og leasing av kontor- og datamaskiner",
        name_en: "Renting and leasing of office machinery and equipment (including computers)",
    },
    {
        code: "77.34",
        score: "medium",
        name_no: "Utleie og leasing av sjøtransportmateriell",
        name_en: "Renting and leasing of water transport equipment",
    },
    {
        code: "77.35",
        score: "medium",
        name_no: "Utleie og leasing av lufttransportmateriell",
        name_en: "Renting and leasing of air transport equipment",
    },
    {
        code: "77.39",
        score: "medium",
        name_no: "Utleie og leasing av andre maskiner og annet utstyr og materiell ikke nevnt annet sted",
        name_en: "Renting and leasing of other machinery, equipment and tangible goods n.e.c.",
    },
    {
        code: "77.40",
        score: "low",
        name_no: "Leasing av immateriell eiendom og lignende produkter, unntatt opphavsrettsbeskyttede verker",
        name_en: "Leasing of intellectual property and similar products, except copyrighted works",
    },
    {
        code: "78.10",
        score: "medium",
        name_no: "Rekruttering og formidling av arbeidskraft",
        name_en: "Activities of employment placement agencies",
    },
    {
        code: "78.20",
        score: "medium",
        name_no: "Utleie av arbeidskraft",
        name_en: "Temporary employment agency activities",
    },
    {
        code: "78.30",
        score: "medium",
        name_no: "Andre personaladministrative tjenester",
        name_en: "Other human resources provision",
    },
    { code: "79.11", score: "medium", name_no: "Reisebyråvirksomhet", name_en: "Travel agency activities" },
    { code: "79.12", score: "medium", name_no: "Reisearrangørvirksomhet", name_en: "Tour operator activities" },
    {
        code: "79.90",
        score: "low",
        name_no: "Annen arrangørvirksomhet og tilknyttede tjenester",
        name_en: "Other reservation service and related activities",
    },
    { code: "80.10", score: "high", name_no: "Private vakttjenester", name_en: "Private security activities" },
    {
        code: "80.20",
        score: "low",
        name_no: "Tjenester tilknyttet vakttjenester",
        name_en: "Security systems service activities",
    },
    { code: "80.30", score: "medium", name_no: "Etterforsking", name_en: "Investigation activities" },
    {
        code: "81.10",
        score: "medium",
        name_no: "Kombinerte tjenester tilknyttet eiendomsdrift",
        name_en: "Combined facilities support activities",
    },
    { code: "81.21", score: "medium", name_no: "Rengjøring av bygninger", name_en: "General cleaning of buildings" },
    {
        code: "81.22",
        score: "medium",
        name_no: "Utvendig rengjøring av bygninger og industriell rengjøring",
        name_en: "Other building and industrial cleaning activities",
    },
    { code: "81.29", score: "medium", name_no: "Annen rengjøringsvirksomhet", name_en: "Other cleaning activities" },
    {
        code: "81.30",
        score: "medium",
        name_no: "Beplantning av hager og parkanlegg",
        name_en: "Landscape service activities",
    },
    {
        code: "82.11",
        score: "low",
        name_no: "Kombinerte kontortjenester",
        name_en: "Combined office administrative activities",
    },
    {
        code: "82.19",
        score: "low",
        name_no: "Fotokopiering, forberedelse av dokumenter og andre spesialiserte kontortjenester",
        name_en: "Photocopying, document preparation and other specialised office support activities",
    },
    {
        code: "82.20",
        score: "low",
        name_no: "Telefonvakttjenester og telefonsalg",
        name_en: "Activities of call centres",
    },
    {
        code: "82.30",
        score: "low",
        name_no: "Kongress-, messe- og utstillingsvirksomhet",
        name_en: "Organisation of conventions and trade shows",
    },
    {
        code: "82.91",
        score: "high",
        name_no: "Inkasso- og kredittopplysningsvirksomhet",
        name_en: "Activities of collection agencies and credit bureaus",
    },
    { code: "82.92", score: "medium", name_no: "Pakkevirksomhet", name_en: "Packaging activities" },
    {
        code: "82.99",
        score: "low",
        name_no: "Annen forretningsmessig tjenesteyting ikke nevnt annet sted",
        name_en: "Other business service activities n.e.c.",
    },
    {
        code: "84.11",
        score: "low",
        name_no: "Generell offentlig administrasjon",
        name_en: "General public administration activities",
    },
    {
        code: "84.12",
        score: "low",
        name_no:
            "Offentlig administrasjon tilknyttet helsestell, sosial virksomhet, undervisning, kirke, kultur og miljøvern",
        name_en:
            "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security",
    },
    {
        code: "84.13",
        score: "low",
        name_no: "Offentlig administrasjon tilknyttet næringsvirksomhet og arbeidsmarked",
        name_en: "Regulation of and contribution to more efficient operation of businesses",
    },
    { code: "84.21", score: "low", name_no: "Utenrikssaker", name_en: "Foreign affairs" },
    { code: "84.22", score: "low", name_no: "Forsvar", name_en: "Defence activities" },
    { code: "84.23", score: "low", name_no: "Retts- og fengselsvesen", name_en: "Justice and judicial activities" },
    {
        code: "84.24",
        score: "low",
        name_no: "Politi- og påtalemyndighet",
        name_en: "Public order and safety activities",
    },
    { code: "84.25", score: "low", name_no: "Brannvern", name_en: "Fire service activities" },
    {
        code: "84.30",
        score: "low",
        name_no: "Trygdeordninger underlagt offentlig forvaltning",
        name_en: "Compulsory social security activities",
    },
    { code: "85.10", score: "low", name_no: "Førskoleundervisning", name_en: "Pre-primary education" },
    { code: "85.20", score: "low", name_no: "Grunnskoleundervisning", name_en: "Primary education" },
    {
        code: "85.31",
        score: "low",
        name_no: "Videregående opplæring innen allmennfaglige studieretninger",
        name_en: "General secondary education",
    },
    {
        code: "85.32",
        score: "low",
        name_no: "Videregående opplæring innen tekniske og andre yrkesfaglige studieretninger",
        name_en: "Technical and vocational secondary education",
    },
    {
        code: "85.41",
        score: "low",
        name_no: "Undervisning ved fagskoler",
        name_en: "Post-secondary non-tertiary education",
    },
    {
        code: "85.42",
        score: "low",
        name_no: "Undervisning på universitets- og høgskolenivå",
        name_en: "Tertiary education",
    },
    {
        code: "85.51",
        score: "low",
        name_no: "Undervisning innen idrett og rekreasjon",
        name_en: "Sports and recreation education",
    },
    { code: "85.52", score: "low", name_no: "Undervisning innen kultur", name_en: "Cultural education" },
    { code: "85.53", score: "low", name_no: "Trafikkskoleundervisning", name_en: "Driving school activities" },
    {
        code: "85.59",
        score: "low",
        name_no: "Annen undervisning ikke nevnt annet sted",
        name_en: "Other education n.e.c.",
    },
    {
        code: "85.60",
        score: "low",
        name_no: "Tjenester tilknyttet undervisning",
        name_en: "Educational support activities",
    },
    { code: "86.10", score: "medium", name_no: "Sykehustjenester", name_en: "Hospital activities" },
    { code: "86.21", score: "medium", name_no: "Legetjeneste", name_en: "General medical practice activities" },
    {
        code: "86.22",
        score: "medium",
        name_no: "Spesialisert legetjeneste",
        name_en: "Specialist medical practice activities",
    },
    { code: "86.23", score: "medium", name_no: "Tannhelsetjenester", name_en: "Dental practice activities" },
    { code: "86.90", score: "medium", name_no: "Andre helsetjenester", name_en: "Other human health activities" },
    {
        code: "87.10",
        score: "medium",
        name_no: "Pleie- og omsorgstjenester i institusjon",
        name_en: "Residential nursing care activities",
    },
    {
        code: "87.20",
        score: "medium",
        name_no:
            "Institusjoner og bofellesskap innen omsorg for psykisk utviklingshemmede, psykisk helsearbeid og rusmiddelomsorg",
        name_en: "Residential care activities for mental retardation, mental health and substance abuse",
    },
    {
        code: "87.30",
        score: "medium",
        name_no: "Institusjoner og bofellesskap innen omsorg for eldre og funksjonshemmede",
        name_en: "Residential care activities for the elderly and disabled",
    },
    {
        code: "87.90",
        score: "medium",
        name_no: "Andre omsorgsinstitusjoner",
        name_en: "Other residential care activities",
    },
    {
        code: "88.10",
        score: "medium",
        name_no: "Sosialtjenester uten botilbud for eldre og funksjonshemmede",
        name_en: "Social work activities without accommodation for the elderly and disabled",
    },
    {
        code: "88.91",
        score: "medium",
        name_no: "Sosialtjenester uten botilbud for barn og ungdom",
        name_en: "Child day-care activities",
    },
    {
        code: "88.99",
        score: "medium",
        name_no: "Andre sosialtjenester uten botilbud ikke nevnt annet sted",
        name_en: "Other social work activities without accommodation n.e.c.",
    },
    {
        code: "90.01",
        score: "low",
        name_no: "Utøvende kunstnere og underholdningsvirksomhet",
        name_en: "Performing arts",
    },
    {
        code: "90.02",
        score: "low",
        name_no: "Tjenester tilknyttet underholdningsvirksomhet",
        name_en: "Support activities to performing arts",
    },
    { code: "90.03", score: "low", name_no: "Selvstendig kunstnerisk virksomhet", name_en: "Artistic creation" },
    {
        code: "90.04",
        score: "low",
        name_no: "Drift av lokaler tilknyttet kunstnerisk virksomhet",
        name_en: "Operation of arts facilities",
    },
    {
        code: "91.01",
        score: "low",
        name_no: "Drift av biblioteker og arkiver",
        name_en: "Libraries and archives activities",
    },
    { code: "91.02", score: "low", name_no: "Drift av museer", name_en: "Museums activities" },
    {
        code: "91.03",
        score: "low",
        name_no: "Drift av historiske steder og bygninger og lignende severdigheter",
        name_en: "Operation of historical sites and buildings and similar visitor attractions",
    },
    {
        code: "91.04",
        score: "low",
        name_no: "Drift av botaniske og zoologiske hager og naturreservater",
        name_en: "Botanical and zoological gardens and nature reserves activities",
    },
    {
        code: "92.00",
        score: "medium",
        name_no: "Lotteri og totalisatorspill",
        name_en: "Gambling and betting activities",
    },
    { code: "93.11", score: "low", name_no: "Drift av idrettsanlegg", name_en: "Operation of sports facilities" },
    { code: "93.12", score: "low", name_no: "Idrettslag og -klubber", name_en: "Activities of sport clubs" },
    { code: "93.13", score: "low", name_no: "Treningssentre", name_en: "Fitness facilities" },
    { code: "93.19", score: "low", name_no: "Andre sportsaktiviteter", name_en: "Other sports activities" },
    {
        code: "93.21",
        score: "low",
        name_no: "Drift av fornøyelses- og temaparker",
        name_en: "Activities of amusement parks and theme parks",
    },
    {
        code: "93.29",
        score: "low",
        name_no: "Andre fritidsaktiviteter",
        name_en: "Other amusement and recreation activities",
    },
    {
        code: "94.11",
        score: "low",
        name_no: "Næringslivs- og arbeidsgiverorganisasjoner",
        name_en: "Activities of business and employers membership organisations",
    },
    {
        code: "94.12",
        score: "low",
        name_no: "Yrkessammenslutninger",
        name_en: "Activities of professional membership organisations",
    },
    { code: "94.20", score: "low", name_no: "Arbeidstakerorganisasjoner", name_en: "Activities of trade unions" },
    {
        code: "94.91",
        score: "low",
        name_no: "Religiøse organisasjoner",
        name_en: "Activities of religious organisations",
    },
    {
        code: "94.92",
        score: "low",
        name_no: "Partipolitiske organisasjoner",
        name_en: "Activities of political organisations",
    },
    {
        code: "94.99",
        score: "low",
        name_no: "Aktiviteter i andre medlemsorganisasjoner ikke nevnt annet sted",
        name_en: "Activities of other membership organisations n.e.c.",
    },
    {
        code: "95.11",
        score: "low",
        name_no: "Reparasjon av datamaskiner og tilleggsutstyr",
        name_en: "Repair of computers and peripheral equipment",
    },
    {
        code: "95.12",
        score: "low",
        name_no: "Reparasjon av kommunikasjonsutstyr",
        name_en: "Repair of communication equipment",
    },
    {
        code: "95.21",
        score: "low",
        name_no: "Reparasjon av elektronikk til husholdningsbruk",
        name_en: "Repair of consumer electronics",
    },
    {
        code: "95.22",
        score: "low",
        name_no: "Reparasjon av husholdningsvarer og hageredskaper",
        name_en: "Repair of household appliances and home and garden equipment",
    },
    {
        code: "95.23",
        score: "low",
        name_no: "Reparasjon av skotøy og lærvarer",
        name_en: "Repair of footwear and leather goods",
    },
    {
        code: "95.24",
        score: "low",
        name_no: "Reparasjon av møbler og boliginnredning",
        name_en: "Repair of furniture and home furnishings",
    },
    {
        code: "95.25",
        score: "low",
        name_no: "Reparasjon av ur, gull- og sølvvarer",
        name_en: "Repair of watches, clocks and jewellery",
    },
    {
        code: "95.29",
        score: "low",
        name_no: "Reparasjon av andre husholdningsvarer og varer til personlig bruk",
        name_en: "Repair of other personal and household goods",
    },
    {
        code: "96.01",
        score: "medium",
        name_no: "Vaskeri- og renserivirksomhet",
        name_en: "Washing and (dry-)cleaning of textile and fur products",
    },
    {
        code: "96.02",
        score: "low",
        name_no: "Frisering og annen skjønnhetspleie",
        name_en: "Hairdressing and other beauty treatment",
    },
    {
        code: "96.03",
        score: "low",
        name_no: "Begravelsesbyråvirksomhet og drift av kirkegårder og krematorier",
        name_en: "Funeral and related activities",
    },
    {
        code: "96.04",
        score: "low",
        name_no: "Virksomhet knyttet til kroppspleie og fysisk velvære",
        name_en: "Physical well-being activities",
    },
    {
        code: "96.09",
        score: "low",
        name_no: "Personlig tjenesteyting ikke nevnt annet sted",
        name_en: "Other personal service activities n.e.c.",
    },
    {
        code: "97.00",
        score: "medium",
        name_no: "Lønnet arbeid i private husholdninger",
        name_en: "Activities of households as employers of domestic personnel",
    },
    {
        code: "99.00",
        score: "low",
        name_no: "Internasjonale organisasjoner og organer",
        name_en: "Activities of extraterritorial organisations and bodies",
    },
];
