import { Grid2, Stack, Typography } from "@mui/material";
import React from "react";

import { ShadowIcon } from "./ShadowIcon";

interface InfoRowProps {
    icon: React.ReactNode;
    name: string;
    infoComponent: React.ReactNode;
    valueComponent: React.ReactNode;
}

export const InfoRow: React.FC<InfoRowProps> = ({ icon, name, infoComponent, valueComponent }) => {
    return (
        <>
            <Grid2 direction="row" size={6} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <ShadowIcon icon={icon} />
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Typography variant="textSm">{name}</Typography>
                        {infoComponent}
                    </Stack>
                </Stack>
            </Grid2>
            <Grid2 size={6} alignItems="flex-start">
                {valueComponent}
            </Grid2>
        </>
    );
};
