import { X } from "@ignite-analytics/icons";
import { Grid, IconButton, Stack } from "@mui/material";

import { SupplierTableColumn } from "@/gql/graphql";

import { FilterComponent, SelectColumn } from "./Components";
import { Filter } from "./types";

interface FilterLineProps {
    columns: SupplierTableColumn[];
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
    handleDeleteFilter: (id: string) => void;
}

export const FilterLine: React.FC<FilterLineProps> = ({ columns, filter, handleUpdateFilter, handleDeleteFilter }) => {
    return (
        <Stack direction="row" alignItems="end">
            <Grid container direction="row" spacing={1} sx={{ alignItems: "end" }}>
                <SelectColumn columns={columns} filter={filter} handleUpdateFilter={handleUpdateFilter} />
                <FilterComponent filter={filter} handleUpdateFilter={handleUpdateFilter} />
            </Grid>
            <IconButton onClick={() => handleDeleteFilter(filter.id)}>
                <X sx={{ height: 14, width: 14, color: (theme) => theme.palette.text.primary }} />
            </IconButton>
        </Stack>
    );
};
