import { ApolloProvider as Provider } from "@apollo/client";

import { getGraphqlClient } from "./client";

interface Props {
    children?: React.ReactNode;
}
export const ApolloProvider: React.FC<Props> = ({ children }) => {
    const graphqlClient = getGraphqlClient();

    return <Provider client={graphqlClient}>{children}</Provider>;
};
