import { CircleSolid, X } from "@ignite-analytics/icons";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { CountryItem_SupplierCountryFragment } from "@/gql/graphql";

import { riskScoreToColor } from "./riskScoreToColor";

type CountryItemProps = {
    country: CountryItem_SupplierCountryFragment;
    disabled: boolean;
    onRemove(e: React.SyntheticEvent, countryId: string): void;
    onClick(countryId: string): void;
    active?: boolean;
};

graphql(`
    fragment CountryItem_SupplierCountry on SupplierCountry {
        id
        iso2Code
        name
        risk {
            score
        }
    }
`);

export const CountryItem: React.FC<CountryItemProps> = ({ country, onRemove, onClick, disabled, active = true }) => {
    const { formatMessage } = useIntl();

    return (
        <Stack direction="row" width="100%" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
                <Stack justifyContent="center" alignItems="center" fontSize="0.5rem">
                    <CircleSolid
                        fontSize="inherit"
                        color={riskScoreToColor(country.risk?.score) ?? "inherit"}
                        sx={{
                            color: (t) => (active ? undefined : t.palette.tokens?.icon.iconGray),
                        }}
                    />
                </Stack>
                <Typography variant="textSm" color={disabled ? "textDisabled" : "textPrimary"}>
                    {country.iso2Code}: {country.name}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
                <Button color="secondary" size="2xsmall" onClick={() => onClick(country.id)}>
                    <FormattedMessage
                        defaultMessage="Details"
                        description="Button label for detail button to view production country risk details"
                    />
                </Button>
                <IconButton
                    disabled={disabled}
                    size="2xsmall"
                    color="ghostGray"
                    onClick={(e) => onRemove(e, country.id)}
                    aria-label={formatMessage(
                        {
                            defaultMessage: "Remove {country}",
                            description: "Aria label for remove button in production countries list",
                        },
                        {
                            country: country.name,
                        }
                    )}
                >
                    <X />
                </IconButton>
            </Stack>
        </Stack>
    );
};
