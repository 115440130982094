import { useMutation, useQuery } from "@apollo/client";
import { ArrowLeft } from "@ignite-analytics/icons";
import { Box, Button, Drawer, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useCallback, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import {
    ColumnType,
    GroupPageTable_SupplierFragmentDoc,
    ReasonTooltip_SupplierFragmentDoc,
    SupplierDrawer_SupplierFragment,
} from "@/gql/graphql";
import { useAlert } from "@/providers";

// import { useOutgoingNavigate } from "@/providers/NavigationContext"; // Expand & navigate to supplier profile coming
import { ActivitiesTab } from "./ActivitiesTab";
import { AdditionalColsCard } from "./AdditionalColsCard";
import { AssessmentsTab } from "./AssessmentsTab";
import { CategoryBar } from "./CategoryBar";
import { DocumentsTab } from "./DocumentsTab";
import { EstimatedRiskCard } from "./EstimatedRiskCard";

graphql(`
    fragment SupplierDrawer_Supplier on Supplier {
        id
        name
        ...EstimatedRiskCard_Supplier
        onboarding {
            status
            createdAt
            evaluatedAt
            approverId
        }
        notes {
            ...Activity_Note
        }
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query SupplierDrawer_GetSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                ...SupplierDrawer_SupplierTableColumn
            }
        }
    }

    fragment SupplierDrawer_SupplierTableColumn on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on SelectOptions {
                choices
            }
            ... on ClassificationOptions {
                groups {
                    id
                    level
                    value
                }
            }
        }
    }
`);

const updateSupplierFieldMutation = graphql(`
    mutation SupplierDrawer_UpdateSupplierField($input: UpdateSupplierFieldInput!) {
        updateSupplierField(input: $input) {
            supplier {
                ...GroupPageTable_Supplier
                ...ReasonTooltip_Supplier
            }
        }
    }
`);

export interface SupplierDrawerProps {
    supplier: SupplierDrawer_SupplierFragment;
    open: boolean;
    onClose: () => void;
    startPolling: (pollInterval: number) => void;
    stopPolling: () => void;
    isEditor: boolean;
    groupColumnIdsAdded: string[];
    onRiskChange: (value: string | undefined) => void;
}

export const SupplierDrawer: React.FC<SupplierDrawerProps> = ({
    supplier,
    open,
    onClose,
    startPolling,
    stopPolling,
    isEditor,
    groupColumnIdsAdded,
    onRiskChange,
}) => {
    const alert = useAlert();
    const { formatMessage } = useIntl();
    // const navigate = useOutgoingNavigate();
    const [value, setValue] = useState(0);

    const { data: columnsData } = useQuery(getSupplierTableColumnsQuery, { errorPolicy: "ignore" });

    const columns = useMemo(() => columnsData?.getSupplierTableMeta.columns ?? [], [columnsData]);
    const [update] = useMutation(updateSupplierFieldMutation, {
        errorPolicy: "ignore",
    });

    const handleOnChange = useCallback(
        (columnName: string, newValue: string[] | string | undefined, updateReason?: string) => {
            if (!columns) return;
            const columnId = (() => {
                switch (columnName) {
                    case "industry":
                        return columns.find((column) => column.type === ColumnType.Nace)?.id;
                    default:
                        return columnName;
                }
            })();

            // start polling for 10 seconds to update the estimated risk
            startPolling(1000);
            setTimeout(() => {
                stopPolling();
            }, 10000);

            update({
                variables: {
                    input: {
                        fieldData: JSON.stringify(newValue ?? "null"),
                        fieldId: columnId ?? "",
                        id: supplier.id,
                        updateReason,
                    },
                },
                update: (cache, { data }) => {
                    cache.writeFragment({
                        id: `Supplier:${supplier.id}`,
                        fragment: GroupPageTable_SupplierFragmentDoc,
                        fragmentName: "GroupPageTable_Supplier",
                        data: data?.updateSupplierField.supplier,
                    });
                    cache.writeFragment({
                        id: `Supplier:${supplier.id}`,
                        fragment: ReasonTooltip_SupplierFragmentDoc,
                        fragmentName: "ReasonTooltip_Supplier",
                        data: data?.updateSupplierField.supplier,
                    });
                },

                onCompleted: () => {
                    const columnAlertMessages: Record<string, string> = {
                        social_risk_score: formatMessage({
                            defaultMessage: "Updated risk score has been saved",
                            description: "Success message for updated supplier",
                        }),
                        industry: formatMessage({
                            defaultMessage: "Updated industry has been saved",
                            description: "Success message for updated supplier",
                        }),
                        production_countries: formatMessage({
                            defaultMessage: "Updated production countries saved",
                            description: "Success message for updated supplier",
                        }),
                    };

                    const alertMessage =
                        columnAlertMessages[columnName] ||
                        formatMessage({
                            defaultMessage: "Update has been saved",
                            description: "Generic success message for updated supplier",
                        });
                    alert.alertUser(
                        {
                            value: alertMessage,
                            severity: "info",
                        },
                        { SnackbarProps: { anchorOrigin: { vertical: "bottom", horizontal: "right" } } }
                    );
                },
                onError: (error) => {
                    alert.alertUser({
                        title: formatMessage({
                            defaultMessage: "Failed to update supplier",
                            description: "Error message for failed updating of supplier",
                        }),
                        value: formatMessage({
                            defaultMessage: "Try again or contact support",
                            description: "Error message for failed updating of supplier",
                        }),
                        severity: "error",
                    });
                    Sentry.captureException(error, {
                        tags: { app: "social-risk-app", message: "Failed to update supplier" },
                        extra: { supplierId: supplier.id, columnId, newValue },
                    });
                },
                refetchQueries: ["GroupPage_GetGroup"],
            });
        },
        [columns, startPolling, update, supplier.id, stopPolling, alert, formatMessage]
    );

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Drawer
            anchor="right"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 800,
                    maxWidth: 1000,
                    width: "40vw",
                    overflowY: "hidden",
                },
            }}
        >
            <Stack spacing={3} padding={3} flex={1} sx={{ overflowY: "auto" }}>
                <Stack
                    spacing={1}
                    sx={{
                        width: "100%",
                        position: "sticky",
                        top: -24,
                        zIndex: 1,
                        backgroundColor: "white",
                        pt: 1,
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack spacing={0.5} direction="row" alignItems="center">
                            <IconButton onClick={onClose}>
                                <ArrowLeft />
                            </IconButton>
                            {/* <IconButton
                                onClick={() =>
                                    navigate(
                                        supplier.onboarding?.status === "APPROVED"
                                            ? `/supplier-profile/${supplier.id}/overview/`
                                            : `${location.pathname}/onboarding/${supplier.id}`
                                    )
                                }
                            >
                                <ArrowsExpand />
                            </IconButton> */}
                            <Typography variant="textXl" fontWeight={700}>
                                {supplier.name}
                            </Typography>
                        </Stack>
                    </Stack>
                    <CategoryBar supplierId={supplier.id} />
                </Stack>
                <EstimatedRiskCard
                    supplier={supplier}
                    columns={columns}
                    onChange={handleOnChange}
                    onRiskChange={onRiskChange}
                    isEditor={isEditor}
                />
                <AdditionalColsCard supplier={supplier} columns={columns} groupColumnIdsAdded={groupColumnIdsAdded} />
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleTabChange}>
                        <Tab label={formatMessage({ defaultMessage: "Notes & activity" })} />
                        <Tab label={formatMessage({ defaultMessage: "Assessments" })} />
                        <Tab label={formatMessage({ defaultMessage: "Documents" })} />
                    </Tabs>
                </Box>
                {value === 0 && (
                    <Stack width="100%" height={200}>
                        <ActivitiesTab supplier={supplier} isEditor={isEditor} />
                    </Stack>
                )}
                {value === 1 && (
                    <Stack width="100%" height={200}>
                        <AssessmentsTab supplierId={supplier.id} />
                    </Stack>
                )}
                {value === 2 && (
                    <Stack width="100%" height={200}>
                        <DocumentsTab supplierId={supplier.id} isEditor={isEditor} />
                    </Stack>
                )}
            </Stack>
            <Box
                sx={{
                    position: "sticky",
                    width: "100%",
                    bottom: 0,
                    background: "white",
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    padding: 2,
                }}
            >
                <Stack direction="row" justifyContent="flex-end">
                    <Button color="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Close" />
                    </Button>
                </Stack>
            </Box>
        </Drawer>
    );
};
