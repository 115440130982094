import { InformationCircle } from "@ignite-analytics/icons";
import { Chip, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type RiskLevel = "verylow" | "low" | "medium" | "high" | "veryhigh";

interface EstimatedRiskScoreProps {
    level: RiskLevel;
}

export const EstimatedRiskScore: React.FC<EstimatedRiskScoreProps> = () => {
    return (
        <Paper sx={{ padding: 2, height: 200 }}>
            <Stack spacing={4} alignContent="center">
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="textSm" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Estimated risk score"
                            description="Estimated risk score title"
                        />
                    </Typography>
                    <Tooltip title="Your estimated risk score is based on Ignite data and your added components">
                        <InformationCircle fontSize="inherit" />
                    </Tooltip>
                </Stack>
                <Typography variant="displayMd" fontWeight={500}>
                    <FormattedMessage defaultMessage="Medium" description="Estimated risk score title" />
                </Typography>
                <Stack>
                    <Typography variant="textXs" color="text.text-helper">
                        <FormattedMessage
                            defaultMessage="Based on"
                            description="Estimated risk score basis help text"
                        />
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Chip size="small" label="Production country" />
                        <Chip size="small" label="Industry" />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};
