import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { SupplierTableColumn } from "@/gql/graphql";

import { Filter } from "../types";

graphql(`
    fragment SelectColumn_SupplierTableColumn on SupplierTableColumn {
        id
        type
        name
        globalType
    }
`);

interface SelectColumnProps {
    filter: Filter;
    columns: SupplierTableColumn[];
    handleUpdateFilter: (filter: Filter) => void;
}

function getFilterType(column: SupplierTableColumn) {
    switch (column.type) {
        case "DATE":
            return "date";
        case "NUMBER":
        case "ASSESSMENT_SCORE":
        case "AGGREGATION":
        case "SPEND":
            return "range";
        default:
            return null;
    }
}

export const SelectColumn: React.FC<SelectColumnProps> = ({ filter, columns, handleUpdateFilter }) => {
    const { formatMessage } = useIntl();
    return (
        <Grid item xs={4}>
            <Autocomplete
                id={`column-select-${filter.id}`}
                size="small"
                fullWidth
                value={columns.find((column) => column.id === filter?.column?.id) || null}
                options={columns}
                getOptionLabel={(option) => option.name}
                autoHighlight
                renderOption={(props, option) => (
                    <MenuItem
                        {...props}
                        key={"column-select-" + filter.id + "-" + option.id}
                        value={option.id}
                        style={{ whiteSpace: "normal" }}
                    >
                        {option.name}
                    </MenuItem>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps }}
                        placeholder={formatMessage({ defaultMessage: "Select column" })}
                    />
                )}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === "clear") {
                        handleUpdateFilter({
                            ...filter,
                            type: null,
                            column: null,
                            includeExcludeValues: [],
                        });
                    }
                }}
                onChange={(event, newValue, reason) => {
                    if (reason === "selectOption" || reason === "removeOption") {
                        if (newValue === null) {
                            handleUpdateFilter({
                                ...filter,
                                type: null,
                                column: null,
                                includeExcludeValues: [],
                            });
                        } else {
                            handleUpdateFilter({
                                ...filter,
                                column: newValue,
                                type: getFilterType(newValue),
                                includeExcludeValues: [],
                            });
                        }
                    }
                }}
            />
        </Grid>
    );
};
