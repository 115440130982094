import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";

import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";

import { AlertSnackBar } from "../AlertSnackBar";

export const Layout: React.FC = () => {
    useSetBreadcrumbs("Overview"); // set down the breadcrumb trail when
    // useRegisterVisit(group);  Same for particular groups only?

    return (
        <Container
            sx={{
                "--masthead-height": "120px",
                paddingX: 3,
                paddingTop: 2,
                width: "100%",
            }}
            maxWidth={false}
        >
            <Outlet />
            <AlertSnackBar />
        </Container>
    );
};
