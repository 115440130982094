import { useMutation, useQuery } from "@apollo/client";
import { Check, ExclamationTriangle, X } from "@ignite-analytics/icons";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { AddSuppliersModal_SupplierFragment } from "@/gql/graphql";

graphql(`
    fragment AddSuppliersModal_Supplier on Supplier {
        id
        name
    }
`);

const getSuppliers = graphql(`
    query AddSuppliersModal_GetSuppliers($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                ...AddSuppliersModal_Supplier
            }
        }
    }
`);

const addSuppliers = graphql(`
    mutation AddSuppliersModal_AddSuppliers($input: AddSuppliersToGroupInput!) {
        addSuppliersToGroup(input: $input) {
            addedIds
        }
    }
`);

interface AddSuppliersProps {
    open: boolean;
    groupId: string;
    handleClose: () => void;
    existingSupplierIDs: string[];
    hasCampaign: boolean;
}
export const AddSuppliersModal: React.FC<AddSuppliersProps> = ({
    open,
    groupId,
    handleClose,
    existingSupplierIDs,
    hasCampaign,
}) => {
    const { formatMessage } = useIntl();
    const [step, setStep] = useState<number>(hasCampaign ? 0 : 1);
    const [selected, setSelected] = useState<AddSuppliersModal_SupplierFragment[]>([]);
    const [search, setSearch] = useState<string>("");
    const handleOnInputChange = React.useMemo(
        () =>
            debounce((val) => {
                setSearch(val);
            }, 500),
        [setSearch]
    );

    const close = () => {
        handleClose();
        setStep(hasCampaign ? 0 : 1);
    };

    const [addSuppliersToGroup, { loading }] = useMutation(addSuppliers, {
        refetchQueries: ["GroupPage_GetGroup"],
        variables: {
            input: {
                groupId: groupId,
                ids: selected.map((s) => s.id),
            },
        },
        onCompleted: () => {
            close();
        },
    });

    const { data } = useQuery(getSuppliers, {
        variables: {
            input: {
                pageIndex: 0,
                pageRange: 20,
                fallbackToOldDMS: false,
                ...(search != "" ? { nameSearch: search } : {}),
            },
        },
    });

    const handleSelectionChange = (value: AddSuppliersModal_SupplierFragment[]) => {
        setSelected(value);
    };

    return (
        <Dialog open={open} onClose={close} sx={{ spacing: 3 }}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <X />
                </IconButton>
            </DialogTitle>
            {step === 0 ? (
                <DialogContent>
                    <Stack gap={2.5} direction="column" alignItems="center">
                        <Box
                            width={40}
                            height={40}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                backgroundColor: (theme) => theme.palette.tokens?.shapeIcon.shapeWarningA,
                                borderRadius: 20,
                            }}
                        >
                            <ExclamationTriangle color="warning" fontSize="large" />
                        </Box>
                        <Typography fontSize="textLg">
                            <FormattedMessage
                                defaultMessage="Add suppliers"
                                description="Add suppliers warning title"
                            />
                        </Typography>
                        <Typography fontSize="textSm" textAlign="center">
                            <FormattedMessage
                                defaultMessage="Adding suppliers to the group will not change the campaign you have created. All assessment data tied to this group is still pertained within the campaign and must be edited from there."
                                description="Add suppliers warning description"
                            />
                        </Typography>
                    </Stack>
                </DialogContent>
            ) : (
                <DialogContent>
                    <Stack gap={4} direction="column">
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Add suppliers"
                                description="View custom group details modal title"
                            />
                        </Typography>
                        <Autocomplete
                            key="select-supplier"
                            multiple
                            disableCloseOnSelect
                            disabled={loading}
                            options={data?.getSuppliers.suppliers || []}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => value.name === option.name}
                            renderOption={(props, sup) => {
                                const disabled = existingSupplierIDs.includes(sup.id);
                                return (
                                    <MenuItem component="li" {...props} key={sup.id} disabled={disabled}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="100%"
                                        >
                                            <Typography>{sup.name}</Typography>
                                            {disabled && <Check />}
                                        </Stack>
                                    </MenuItem>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={formatMessage({ defaultMessage: "Search for supplier" })}
                                />
                            )}
                            onInputChange={(_, phrase) => {
                                handleOnInputChange(phrase);
                            }}
                            onChange={(_, value) => {
                                handleSelectionChange(value);
                            }}
                            sx={{
                                width: 500,
                                "& .MuiAutocomplete-option": {
                                    minHeight: "50px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                },
                            }}
                        />
                    </Stack>
                </DialogContent>
            )}
            <DialogActions>
                {step === 0 ? (
                    <Stack justifyContent="space-between" direction="row" spacing={1} width="100%">
                        <Button color="secondary" onClick={close} size="small">
                            <FormattedMessage
                                defaultMessage="Close"
                                description="View supplier group details 'Close' button"
                            />
                        </Button>
                        <Button disabled={loading} color="primary" onClick={() => setStep(1)} size="small">
                            <FormattedMessage
                                defaultMessage="Next"
                                description="View supplier group details 'Next' button"
                            />
                        </Button>
                    </Stack>
                ) : (
                    <Stack justifyContent="space-between" direction="row" spacing={1} width="100%">
                        <Button color="secondary" onClick={close} size="small">
                            <FormattedMessage
                                defaultMessage="Close"
                                description="View supplier group details 'Close' button"
                            />
                        </Button>
                        <Button
                            disabled={selected.length === 0}
                            color="primary"
                            onClick={() => addSuppliersToGroup()}
                            size="small"
                        >
                            {loading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="Confirm"
                                    description="View supplier group details 'Confirm' button"
                                />
                            )}
                        </Button>
                    </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
};
