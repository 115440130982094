import { ShapeIcon } from "@ignite-analytics/components";
import { Grid2, Stack, Typography } from "@mui/material";
import React from "react";

interface InfoRowProps {
    icon: React.ReactNode;
    name: string;
    infoComponent?: React.ReactNode;
    valueComponent: React.ReactNode;
}

export const InfoRow: React.FC<React.PropsWithChildren<InfoRowProps>> = ({
    icon,
    name,
    infoComponent,
    valueComponent,
    children,
}) => {
    return (
        <>
            <Grid2 direction="row" size={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <ShapeIcon size="medium">{icon}</ShapeIcon>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="textSm">{name}</Typography>
                        {infoComponent}
                    </Stack>
                </Stack>
            </Grid2>
            <Grid2 size={6}>{valueComponent}</Grid2>
            {children}
        </>
    );
};
