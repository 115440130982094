import { gql, useQuery } from "@apollo/client";
import { CheckCircle, InformationCircle, User } from "@ignite-analytics/icons";
import { formatDateAsUTC, formatValue } from "@ignite-analytics/locale";
import { Button, Grid2, Paper, Stack, Tooltip, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
    ClassificationOptions,
    ColumnType,
    EstimatedRiskCard_SupplierFragment,
    RoleUser,
    SupplierCustomField,
    SupplierDrawer_SupplierTableColumnFragment,
} from "@/gql/graphql";

import { AssessmentField } from "../GroupPageTable/Components/AssessmentsCell";
import { ClassificationField } from "../GroupPageTable/Components/ClassificationCell";
import { MonetaryAmount } from "../GroupPageTable/Components/MonetaryAmountCell";
import { ONBOARDING_APPROVER_COLUMN_ID, ONBOARDING_COLUMN_ID } from "../GroupPageTable/GroupPageDefinitions";
import { OnboardingChip } from "../OnboardingField";

import { InfoRow } from "./InfoRow";

interface AdditionalColsCardProps {
    supplier: EstimatedRiskCard_SupplierFragment;
    columns: SupplierDrawer_SupplierTableColumnFragment[];
    groupColumnIdsAdded: string[];
}

type DrawerField = SupplierCustomField & {
    dataType: ColumnType;
    typeOptions: ClassificationOptions;
};

const renderField = (field: DrawerField) => {
    const value = JSON.parse(field.dataJson);
    switch (field.dataType) {
        case "AGGREGATION":
        case "SPEND": {
            return (
                <Typography sx={{ textAlign: "right", width: "100%" }} variant="textSm">
                    {formatValue(value, 0)}
                </Typography>
            );
        }
        case "MONETARY_AMOUNT": {
            return <MonetaryAmount value={value} />;
        }
        case "DATE": {
            if (!value) {
                return <div>-</div>;
            }
            return <div style={{ cursor: "pointer" }}>{formatDateAsUTC(value)}</div>;
        }
        case "CLASSIFICATION": {
            const valueById = new Map(field.typeOptions.groups?.map((g) => [g.id, g.value]));
            return (
                <Tooltip
                    title={
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "2px",
                            }}
                        >
                            <ClassificationField ids={value} valueById={valueById} />
                        </div>
                    }
                >
                    <div
                        style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <ClassificationField ids={value} valueById={valueById} />
                    </div>
                </Tooltip>
            );
        }
        case "ASSESSMENT_STATUS": {
            return <AssessmentField status={value} />;
        }
        case "ASSESSMENT_SCORE":
        case "SELECT": {
            return <Typography variant="textSm">{value}</Typography>;
        }
        case "NUMBER": {
            return <Typography variant="textSm">{formatValue(value, 2)}</Typography>;
        }
    }
};

const getUsersQuery = gql`
    query getOnboardingUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                id
                fullName
            }
        }
    }
`;

export const AdditionalColsCard: React.FC<AdditionalColsCardProps> = ({ supplier, columns, groupColumnIdsAdded }) => {
    const { formatMessage } = useIntl();
    const { data: usersData } = useQuery(getUsersQuery, {
        variables: { input: { terms: "" } },
        onError: (error) => {
            Sentry.captureException(error, { tags: { app: "social-risk-app", message: "Failed to get all users" } });
        },
    });
    const [expanded, setExpanded] = useState(false);

    const users = usersData?.getUsers?.result;
    const onboardingStatus = supplier.onboarding?.status;
    const onboardingApprover = users?.find((u: RoleUser) => u.id === supplier.onboarding?.approverId);
    const selectedCustomFields = supplier.customFields
        .filter((field) => groupColumnIdsAdded.includes(field.fieldId))
        .map((field) => ({
            ...field,
            dataType: columns.find((col) => col.id === field.fieldId)?.type,
            typeOptions: columns.find((col) => col.id === field.fieldId)?.typeOptions,
        }));

    if (groupColumnIdsAdded.length === 0) return null;
    return (
        <Paper>
            <Stack padding={3} spacing={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Additional information" />
                        </Typography>
                    </Stack>
                </Stack>
                <Grid2 container spacing={3} alignItems="center">
                    {(() => {
                        const infoRows = [];
                        if (groupColumnIdsAdded.includes(ONBOARDING_COLUMN_ID)) {
                            infoRows.push(
                                <InfoRow
                                    key="onboarding"
                                    icon={<CheckCircle />}
                                    name={formatMessage({ defaultMessage: "Onboarding status" })}
                                    infoComponent={
                                        <Tooltip
                                            title={formatMessage({
                                                defaultMessage: "This supplier's current onboarding status",
                                            })}
                                        >
                                            <InformationCircle fontSize="small" />
                                        </Tooltip>
                                    }
                                    valueComponent={
                                        <OnboardingChip onboardingStatus={onboardingStatus} supplierId={supplier.id} />
                                    }
                                />
                            );
                        }

                        if (groupColumnIdsAdded.includes(ONBOARDING_APPROVER_COLUMN_ID)) {
                            infoRows.push(
                                <InfoRow
                                    key="approver"
                                    icon={<User />}
                                    name={formatMessage({ defaultMessage: "Onboarding approver" })}
                                    infoComponent={
                                        <Tooltip
                                            title={formatMessage({
                                                defaultMessage: "This supplier's onboarding approver",
                                            })}
                                        >
                                            <InformationCircle fontSize="small" />
                                        </Tooltip>
                                    }
                                    valueComponent={
                                        <Typography variant="textSm">{onboardingApprover?.fullName}</Typography>
                                    }
                                />
                            );
                        }

                        selectedCustomFields.forEach((field) => {
                            infoRows.push(
                                <InfoRow
                                    key={field.fieldId}
                                    icon={<InformationCircle />}
                                    name={field.name}
                                    valueComponent={renderField(field as DrawerField)}
                                />
                            );
                        });

                        const showMoreButton = infoRows.length > 3 && (
                            <Grid2 size={12}>
                                <Button fullWidth color="secondary" onClick={() => setExpanded(!expanded)}>
                                    {expanded ? (
                                        <FormattedMessage defaultMessage="Show less" />
                                    ) : (
                                        <FormattedMessage defaultMessage="Show more" />
                                    )}
                                </Button>
                            </Grid2>
                        );

                        return (
                            <>
                                {expanded ? infoRows : infoRows.slice(0, 3)}
                                {showMoreButton}
                            </>
                        );
                    })()}
                </Grid2>
            </Stack>
        </Paper>
    );
};
