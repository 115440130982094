import { useEffect } from "react";
import { useIntl } from "react-intl";

export function useSetBreadcrumbs(groupName: string) {
    const { formatMessage } = useIntl();
    useEffect(() => {
        const basePayload = [
            {
                text: formatMessage({
                    defaultMessage: "Social risk",
                    description: "Breadcrumb for social risk module",
                }),
                href: "/social-risk",
            },
        ];

        const payload = [
            ...basePayload,
            {
                text: groupName,
            },
        ];

        const data = {
            source: "ignite-breadcrumbs",
            payload,
        };

        window.postMessage(data, window.location.origin);
    }, [formatMessage, groupName]);
}
