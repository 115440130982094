import { CircleSolid, X } from "@ignite-analytics/icons";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    Stack,
    Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { SuggestedStatusChip } from "@/components/RiskStatus";
import { graphql } from "@/gql";
import { CountryRiskDialog_SupplierCountryFragment, SupplierRiskIndex } from "@/gql/graphql";

import { riskScoreToColor } from "./riskScoreToColor";

type ProductionCountryRiskDialogProps = {
    country: CountryRiskDialog_SupplierCountryFragment | null;
    onClose: () => void;
};

graphql(`
    fragment CountryRiskDialog_SupplierCountry on SupplierCountry {
        iso2Code
        name(language: $language)
        risk {
            score
            indexScores {
                index
                score
            }
        }
    }
`);

const CountryRiskDialog: React.FC<ProductionCountryRiskDialogProps> = ({ country, onClose }) => {
    const { formatMessage } = useIntl();
    return (
        <Dialog open={country !== null} onClose={() => onClose()} fullWidth maxWidth="xs">
            <IconButton
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    "&:hover": {
                        bgcolor: "unset",
                    },
                }}
                aria-label={formatMessage({
                    defaultMessage: "Close dialog",
                    description: "Aria label for close button in production country risk details dialog",
                })}
                onClick={() => onClose()}
            >
                <X />
            </IconButton>
            <DialogTitle sx={{ mb: 3 }}>
                <Stack>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Basis for country risk" />
                    </Typography>
                    <Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Stack justifyContent="center" alignItems="center" fontSize="0.5rem">
                                <CircleSolid
                                    fontSize="inherit"
                                    color={riskScoreToColor(country?.risk?.score) ?? "inherit"}
                                />
                            </Stack>
                            <Typography>
                                {country?.iso2Code}: {country?.name}
                            </Typography>
                        </Stack>
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <List>
                    {country?.risk?.indexScores?.map(({ index, score }) => (
                        <ListItem key={index} disableGutters>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={1}
                                width="100%"
                            >
                                <Typography variant="textSm" color="textTextHelper">
                                    <RiskIndex index={index} />
                                </Typography>
                                <SuggestedStatusChip size="small" status={score} />
                            </Stack>
                        </ListItem>
                    ))}
                    <Divider sx={{ marginY: 1 }} />
                    <ListItem disableGutters>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            width="100%"
                        >
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="= Accumulated risk score"
                                    description="Line to indicate the total risk for a production country when viewing details for production country risk"
                                />
                            </Typography>
                            <SuggestedStatusChip size="small" status={country?.risk?.score ?? "MISSING"} />
                        </Stack>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="center" alignItems="center" width="100%">
                    <Typography component="span" variant="textXs" color="textTextBody">
                        <FormattedMessage
                            defaultMessage="Want more information? <a>Visit help center</a>"
                            description="Footer when viewing production country risk details"
                            values={{
                                a: (chunks) => (
                                    <Link href="https://help-eu.igniteprocurement.com/en/articles/71291">{chunks}</Link>
                                ),
                            }}
                        />
                    </Typography>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

type RiskIndexProps = {
    index: SupplierRiskIndex;
};

const RiskIndex: React.FC<RiskIndexProps> = ({ index }) => {
    switch (index) {
        case "CPI": {
            return (
                <FormattedMessage
                    defaultMessage="Corruption Perception<br></br>by Transparency International"
                    description="Corruption Perception Index Name"
                    values={{ br: () => <br /> }}
                />
            );
        }
        case "FIW": {
            return (
                <FormattedMessage
                    defaultMessage="Freedom in the World<br></br>by Freedom House"
                    description="Freedom in the World Index Name"
                    values={{ br: () => <br /> }}
                />
            );
        }
        case "ITUC": {
            return (
                <FormattedMessage
                    defaultMessage="ITUC Global Rights Index"
                    description="ITUC Global Rights Index Name"
                />
            );
        }
    }
};

export { CountryRiskDialog };
