import { useQuery } from "@apollo/client";
import { ChevronDown, ChevronUp, X } from "@ignite-analytics/icons";
import { Button, Chip, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { BusinessUnitPopper } from "./BusinessUnitPopper";
import { getBusinessUnits } from "./queries";
import { BusinessUnit } from "./types";
import { mapInitialDataToTree } from "./utils";

interface Props {
    businessUnitIDs: string[];
    setBusinessUnitIDs: React.Dispatch<React.SetStateAction<string[]>>;
    businessUnitTree: BusinessUnit[];
    setBusinessUnitTree: React.Dispatch<React.SetStateAction<BusinessUnit[]>>;
}

function isTreeEmpty(businessUnitTree: BusinessUnit[]): boolean {
    return businessUnitTree.length === 0;
}

export const BusinessUnitSelect: React.FC<Props> = ({
    businessUnitIDs,
    setBusinessUnitIDs,
    businessUnitTree,
    setBusinessUnitTree,
}) => {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleBusinessUnitID = useCallback(
        (businessUnitID: string) => {
            setBusinessUnitIDs((prev) => {
                if (prev.includes(businessUnitID)) {
                    return prev.filter((id) => id !== businessUnitID);
                }
                const findBusinessUnit = (businessUnits: BusinessUnit[], id: string): BusinessUnit | undefined => {
                    const found = businessUnits.find((businessUnit) => businessUnit.id === id);
                    if (found) return found;
                    for (const businessUnit of businessUnits) {
                        const child = findBusinessUnit(businessUnit.children, id);
                        if (child) return child;
                    }
                };
                const toggledBusinessUnit = findBusinessUnit(businessUnitTree, businessUnitID);
                if (!toggledBusinessUnit?.children) {
                    return [...prev, businessUnitID];
                }

                const childrenIDs: string[] = [];
                const addBusinessUnitIDs = (businessUnit: BusinessUnit) => {
                    childrenIDs.push(businessUnit.id);
                    for (const child of businessUnit.children) {
                        addBusinessUnitIDs(child);
                    }
                };
                addBusinessUnitIDs(toggledBusinessUnit);

                return [
                    ...prev.filter((oldBusinessUnitID) => !childrenIDs.includes(oldBusinessUnitID)),
                    businessUnitID,
                ];
            });
        },
        [setBusinessUnitIDs, businessUnitTree]
    );

    useQuery(getBusinessUnits, {
        variables: {
            input: {
                parentId: null,
            },
        },
        skip: businessUnitTree.length > 0,
        onCompleted: (data) => {
            setBusinessUnitTree((prevTree) => {
                if (prevTree.length > 0) return prevTree;
                return mapInitialDataToTree(data);
            });
        },
    });

    const businessUnitNames = useMemo(() => {
        const names: Record<string, { name: string; level: number }> = {};
        const addNames = (businessUnits: BusinessUnit[]) => {
            for (const businessUnit of businessUnits) {
                names[businessUnit.id] = { name: businessUnit.label, level: businessUnit.level };
                if (businessUnit.children) {
                    addNames(businessUnit.children);
                }
            }
        };
        addNames(businessUnitTree);
        return names;
    }, [businessUnitTree]);

    return (
        <Stack direction="row" width={590}>
            <Button
                disabled={isTreeEmpty(businessUnitTree)}
                onClick={() => setOpen(true)}
                ref={buttonRef}
                endIcon={open ? <ChevronUp /> : <ChevronDown />}
                color="secondary"
                fullWidth
                sx={{ paddingY: 0.5 }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    {businessUnitIDs.length > 0 ? (
                        <Stack direction="row" gap={1} flexWrap="wrap" sx={{ overflowX: "hidden" }}>
                            {businessUnitIDs.map((id) => (
                                <Chip
                                    key={id}
                                    label={
                                        <FormattedMessage
                                            defaultMessage="L{level} {name}"
                                            description="Business unit chip label"
                                            values={{
                                                name: businessUnitNames[id].name,
                                                level: businessUnitNames[id].level,
                                            }}
                                        />
                                    }
                                    onDelete={() => toggleBusinessUnitID(id)}
                                    color="neutral"
                                    size="small"
                                />
                            ))}
                        </Stack>
                    ) : (
                        <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                            {isTreeEmpty(businessUnitTree) ? (
                                <FormattedMessage defaultMessage="No available business units" />
                            ) : (
                                <FormattedMessage defaultMessage="Select business units" />
                            )}
                        </Typography>
                    )}
                    {!isTreeEmpty(businessUnitTree) && (
                        <IconButton
                            onClick={(event) => {
                                setBusinessUnitIDs([]);
                                event.stopPropagation();
                            }}
                            size="2xsmall"
                        >
                            <X />
                        </IconButton>
                    )}
                </Stack>
            </Button>
            <BusinessUnitPopper
                open={open}
                anchorEl={buttonRef.current}
                value={businessUnitIDs}
                onChange={toggleBusinessUnitID}
                onClose={() => setOpen(false)}
                businessUnitTree={businessUnitTree}
                setBusinessUnitTree={setBusinessUnitTree}
            />
        </Stack>
    );
};
