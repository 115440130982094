import { graphql } from "@/gql";

export const getSpendCategories = graphql(`
    query getSpendCategories($input: GetSpendCategoriesInput!) {
        getSpendCategories(input: $input) {
            categories {
                id
                name
                level
                hasChildren
            }
        }
    }
`);

export const getSpendCategory = graphql(`
    query getSpendCategory($input: GetSpendCategoryInput!) {
        getSpendCategory(input: $input) {
            category {
                id
                name
                level
                hasChildren
            }
        }
    }
`);
