import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";

import { AlertSnackBar } from "../AlertSnackBar";

export const Layout: React.FC = () => {
    // useRegisterVisit(group);  Same for particular groups only?

    return (
        <Container
            sx={{
                "--masthead-height": "120px",
                paddingX: 3,
                paddingTop: 2,
                width: "100%",
            }}
            maxWidth={false}
        >
            <Outlet />
            <AlertSnackBar />
        </Container>
    );
};
