import { useQuery } from "@apollo/client";
import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Avatar, Paper, Stack, Typography } from "@mui/material";

import { RiskChange } from "@/components/RiskStatus";
import { graphql } from "@/gql";
import { ReasonTooltip_NoteFragment, ReasonTooltip_SupplierFragment } from "@/gql/graphql";

import { getRiskValue } from "./utils";

graphql(`
    fragment ReasonTooltip_Supplier on Supplier {
        risk {
            social
        }
    }
    fragment ReasonTooltip_Note on Note {
        id
        note
        createdBy
        createdAt
        type
        metadata {
            prevValue
            newValue
        }
    }

    fragment ReasonTooltip_User on RoleUser {
        id
        firstName
        initials
    }
`);

const getReasonUser = graphql(`
    query User($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...ReasonTooltip_User
            }
        }
    }
`);

interface ReasonTooltipProps {
    note: ReasonTooltip_NoteFragment;
    supplier: ReasonTooltip_SupplierFragment;
}

export const ReasonTooltip: React.FC<ReasonTooltipProps> = ({ supplier, note }) => {
    const { data: userData, loading: userLoading } = useQuery(getReasonUser, {
        variables: { input: { userIds: [note?.createdBy ?? ""] } },
        skip: !note,
    });

    const user = userData?.getUsers.result[0];

    if (userLoading || !note || !user) {
        return null;
    }

    const prevVal = note.metadata?.prevValue ? getRiskValue(note.metadata?.prevValue) : undefined;
    const newVal = note.metadata?.newValue ? getRiskValue(note.metadata?.newValue) : undefined;

    return (
        <Stack spacing={2}>
            <Paper sx={{ borderRadius: 1, p: 2, rowGap: 1 }}>
                <Stack direction="row" alignItems="center" spacing={1} paddingBottom={1}>
                    <Avatar src="" sx={{ width: 32, height: 32 }}>
                        <Typography variant="textXs">{`${user.initials}`}</Typography>
                    </Avatar>
                    <Stack>
                        <Typography variant="textSm">{`${user.firstName}`}</Typography>
                        <Typography variant="textXs" color="text.secondary">
                            {formatDateAsUTC(note.createdAt)}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack paddingLeft={5}>
                    <Typography
                        variant="textXs"
                        sx={{
                            color: (theme) => theme.palette.tokens?.text.textHelper,
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {note.note}
                    </Typography>
                </Stack>
            </Paper>

            <RiskChange prevVal={prevVal} newVal={newVal} socialRisk={supplier.risk?.social ?? undefined} />
        </Stack>
    );
};
