import {
    Autocomplete,
    CircularProgress,
    inputBaseClasses,
    outlinedInputClasses,
    paperClasses,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import popperClasses from "@mui/material/Popper/popperClasses";
import { GridRenderEditCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid-pro";
import React from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { CountryEditCell_SupplierCountryFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";

import { TableData } from "../../utils";

interface CountryEditCellProps {
    params: GridRenderEditCellParams<
        TableData,
        CountryEditCell_SupplierCountryFragment | undefined,
        unknown,
        GridTreeNodeWithRender
    >;
    countries: CountryEditCell_SupplierCountryFragment[];
    loading?: boolean;
    error?: boolean;
}

graphql(`
    fragment CountryEditCell_SupplierCountry on SupplierCountry {
        id
        iso2Code
        name(language: $language)
    }
`);

export const CountryEditCell: React.FC<CountryEditCellProps> = ({ params, countries, loading, error }) => {
    const { id, field, api, value, hasFocus } = params;
    const ref = React.useRef<HTMLDivElement>(null);

    /**
     * It's important to also handle the accessibility of custom edit components.
     * When a cell enters edit mode, an element must be focused to provide access via keyboard and for screen readers.
     * Since multiple cells may be in edit mode at the same time, the hasFocus prop will be true on the cell that
     * should have focus. Use this prop to focus the appropriate element.
     *
     * From: https://mui.com/x/react-data-grid/editing/#create-your-own-edit-component
     */
    React.useLayoutEffect(() => {
        if (hasFocus) {
            ref.current?.focus();
        }
    }, [hasFocus]);

    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        country: CountryEditCell_SupplierCountryFragment | null
    ) => {
        track(`${TRACK_PREFIX}: Updated Country`, { initialValue: value, newValue: country?.iso2Code });

        api.setEditCellValue({ id, field, value: country });
        event.stopPropagation();
    };

    return (
        <Autocomplete
            sx={{
                zIndex: 10,
                minWidth: "var(--width)",
                height: "100%",
                [`& .${inputBaseClasses.root}`]: {
                    borderRadius: 0,
                },
                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                    {
                        boxShadow: "none",
                    },
            }}
            loading={loading}
            loadingText={
                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                    <CircularProgress size={14} />
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Loading..."
                            description="Placeholder text when countries are loading in an autocomplete"
                        />
                    </Typography>
                </Stack>
            }
            getOptionKey={(option) => option.id}
            autoHighlight
            options={countries}
            value={value}
            fullWidth
            disableClearable
            onClose={() => api.stopCellEditMode({ id, field })}
            getOptionLabel={(option) => `${option.iso2Code}: ${option.name}`}
            renderOption={(props, option) => (
                <Typography variant="textSm" {...props}>
                    {option.iso2Code}: {option.name}
                </Typography>
            )}
            renderInput={(inputParams) => (
                <TextField
                    sx={{ height: "100%" }}
                    inputRef={ref}
                    {...inputParams}
                    fullWidth
                    variant="outlined"
                    error={error}
                />
            )}
            openOnFocus
            onChange={handleChange}
            slotProps={{
                listbox: { sx: { maxHeight: "200px", overflowY: "auto" } },
                popper: {
                    sx: {
                        [`&.${popperClasses.root}[data-popper-placement="bottom"] .${paperClasses.root}`]: {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginTop: 0,
                        },
                        [`&.${popperClasses.root}[data-popper-placement="top"] .${paperClasses.root}`]: {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                    },
                },
            }}
        />
    );
};
