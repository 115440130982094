import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid-pro";
import React from "react";

import { track, TRACK_PREFIX } from "@/lib/track";

import { getLabel } from "../../utils";
import { countryCodes } from "../values/CountryOptions";

import { ProdCountryChip } from "./ProdCountryChip";
import { RiskOption } from "./RiskAutocomplete";

const renderInput = (params: AutocompleteRenderInputParams) => {
    // eslint-disable-next-line jsx-a11y/no-autofocus
    return <TextField {...params} size="small" variant="outlined" autoFocus />;
};

interface ProdCountriesAutocompleteProps {
    params: GridRenderCellParams<GridValidRowModel, unknown, unknown, GridTreeNodeWithRender>;
    onChange: (columnName: string, supplierId: string, newValue: string[] | undefined) => void;
}

export const ProdCountriesAutocomplete: React.FC<ProdCountriesAutocompleteProps> = ({ params, onChange }) => {
    const { id, field, api, colDef } = params;
    const value = params.value as string[];
    const options = countryCodes;
    const [open, setOpen] = React.useState(false);

    const handleChange = (_event: React.SyntheticEvent<Element, Event>, value: RiskOption[] | null) => {
        setOpen(true);

        const selectedCodes = value ? value.map((option) => option.code) : [];

        track(`${TRACK_PREFIX}: Updated Production Countries`, {
            initialValue: value,
            newValue: selectedCodes,
        });

        api.setEditCellValue({ id, field, value: selectedCodes });
        onChange(colDef.field, id.toString(), selectedCodes);
    };

    return (
        <Autocomplete
            autoHighlight
            multiple
            options={options}
            value={options.filter((option) => value.includes(option.code)) ?? null}
            fullWidth
            getOptionLabel={(option) => `${getLabel(option)}`}
            renderOption={(props, option) => (
                <Typography variant="textSm" {...props}>
                    {getLabel(option)}
                </Typography>
            )}
            renderInput={renderInput}
            onChange={handleChange}
            openOnFocus
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <ProdCountryChip key={key} label={getLabel(option)} score={option.score} {...tagProps} />;
                })
            }
            open={open}
            onOpen={() => setOpen(true)}
            onClose={(e, reason) => {
                if (reason === "selectOption" || reason === "removeOption") {
                    setOpen(true);
                } else {
                    setOpen(false);
                }
            }}
            slotProps={{ listbox: { sx: { maxHeight: "200px", overflowY: "auto" } } }}
            sx={{
                "& .MuiOutlinedInput-root": {
                    border: "none",
                    marginTop: "1px",
                    borderRadius: 0,
                    minHeight: "46px",
                    minWidth: "250px",
                    alignItems: "flex-start",
                    zIndex: 1501,
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: 1,
                    zIndex: 1501,
                },
            }}
            blurOnSelect={false}
        />
    );
};
