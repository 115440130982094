import React from "react";
import { createRoot } from "react-dom/client";

import { setupProxyAuth } from "@/setupProxyAuth";
import { AppProps } from "@/types";

import App from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { loadMessages } from "./lib/i18n";
import { AppProvider } from "./providers/app";

window.renderSocialRisk = (containerId, props) => {
    renderApp(containerId, props);
};

let root: ReturnType<typeof createRoot> | null = null;

window.unmountSocialRisk = () => {
    root?.unmount();
};

// running locally
if (!document.getElementById("SocialRisk-container")) {
    // check
    const isProxyToProductionBackend = import.meta.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";
    if (isProxyToProductionBackend) {
        setupProxyAuth().then(() => {
            renderApp("root", {
                locale: "en-US",
                theme: "ignite-riddle",
            });
        });
    } else {
        renderApp("root", {
            locale: "en-US",
            theme: "ignite-riddle",
        });
    }
}

function renderApp(rootNode = "root", props: AppProps = { locale: "en-US", theme: "ignite-riddle" }) {
    const messages = loadMessages(props.locale);
    const container = document.getElementById(rootNode);

    if (!container) {
        return;
    }

    const navigate = props.navigate;
    if (!navigate && process.env.NODE_ENV === "production") {
        throw new Error("navigate is required");
    }

    root = createRoot(container);

    root.render(
        <React.StrictMode>
            <AppProvider {...props} messages={messages}>
                <ErrorBoundary>
                    <App navigate={navigate} />
                </ErrorBoundary>
            </AppProvider>
        </React.StrictMode>
    );
}
