import { ArrowLeft, X } from "@ignite-analytics/icons";
import {
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ColumnType, SupplierTableColumnFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";

import { SearchField } from "../GroupPageTable/SearchField";

import { Step } from ".";

interface Props {
    onClose: () => void;
    onComplete: () => void;
    setStep: (newStep: Step) => void;
    additionalColumnIDs: string[];
    setAdditionalColumnIDs: React.Dispatch<React.SetStateAction<string[]>>;
    columns: SupplierTableColumnFragment[];
    spendColumnID: string | undefined;
    riskColumnID: string | undefined;
    naceColumnID: string | undefined;
}

const toggleColumnIDEventHandler = (
    columnID: string,
    setAdditionalColumnIDs: React.Dispatch<React.SetStateAction<string[]>>
) => {
    return (_: unknown, checked: boolean) => {
        if (checked) {
            setAdditionalColumnIDs((oldColumn) => [...oldColumn, columnID]);
        } else {
            setAdditionalColumnIDs((oldColumn) => oldColumn.filter((id) => id !== columnID));
        }
    };
};

export const SelectColumnsDialogContent: React.FC<Props> = ({
    onClose,
    onComplete,
    setStep,
    additionalColumnIDs,
    setAdditionalColumnIDs,
    columns: allColumns,
    spendColumnID,
    riskColumnID,
    naceColumnID,
}) => {
    const [showAdditionalColumns, setShowAdditionalColumns] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const alwaysEnabledColumns = React.useMemo(() => {
        // TODO: Verify that the "column" we get for production country is called production_country
        const knownColumns = ["name", "country", "production_country"];
        if (naceColumnID) {
            knownColumns.push(naceColumnID);
        }
        if (spendColumnID) {
            knownColumns.push(spendColumnID);
        }
        if (riskColumnID) {
            knownColumns.push(riskColumnID);
        }
        return knownColumns;
    }, [naceColumnID, spendColumnID, riskColumnID]);

    const columns = React.useMemo(() => {
        const normalize = (s: string) => s.toLowerCase().replace(/[^a-z0-9]/g, "");
        const searchedColumns = allColumns.filter((column) => normalize(column.name).includes(normalize(search)));
        searchedColumns.sort((a, b) => {
            const aAlwaysEnabled = alwaysEnabledColumns.includes(a.id);
            const bAlwaysEnabled = alwaysEnabledColumns.includes(b.id);
            if (aAlwaysEnabled && !bAlwaysEnabled) {
                return -1;
            }
            if (!aAlwaysEnabled && bAlwaysEnabled) {
                return 1;
            }
            if (aAlwaysEnabled && bAlwaysEnabled) {
                return alwaysEnabledColumns.indexOf(a.id) - alwaysEnabledColumns.indexOf(b.id);
            }
            return a.name.localeCompare(b.name);
        });
        return searchedColumns;
    }, [allColumns, search, alwaysEnabledColumns]);

    const importedColumns = React.useMemo(() => {
        // NOTE: The toggle button columns on the main page do not count towards this count
        return additionalColumnIDs.filter((id) => id !== "has_code_of_conduct").length;
    }, [additionalColumnIDs]);

    if (showAdditionalColumns) {
        return (
            <>
                <DialogTitle component={Stack} direction="row" alignItems="center" sx={{ paddingTop: 2 }}>
                    <IconButton
                        sx={{ marginRight: 1 }}
                        size="xsmall"
                        color="secondary"
                        onClick={() => setShowAdditionalColumns(false)}
                    >
                        <ArrowLeft />
                    </IconButton>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Import columns" description="Import columns modal title" />
                    </Typography>
                </DialogTitle>
                <div /> {/* Hack to get the padding of the dialogcontent to appear */}
                <DialogContent>
                    <Stack direction="column" gap={4}>
                        <Stack direction="column" gap={1}>
                            <Typography variant="textSm" fontWeight={400} color="textTextSecondary">
                                <FormattedMessage
                                    defaultMessage="Find column"
                                    description="Find column search bar title"
                                />
                            </Typography>
                            <SearchField value={search} onChange={(_, newValue) => setSearch(newValue)} />
                        </Stack>
                        <Typography variant="textSm" fontWeight={400} color="textTextSecondary">
                            <FormattedMessage defaultMessage="Find column" description="Find column search bar title" />
                        </Typography>
                        <Stack direction="column" gap={2} overflow="auto" maxHeight="300px">
                            {columns
                                .filter((column) => column.type != ColumnType.Risk) // We provide risk columns by default
                                .map((column) => {
                                    const isAlwaysEnabled = alwaysEnabledColumns.includes(column.id);
                                    return (
                                        <Stack direction="row" gap={1.25} key={column.id} alignItems="center">
                                            <Switch
                                                size="small"
                                                disabled={isAlwaysEnabled}
                                                checked={isAlwaysEnabled || additionalColumnIDs.includes(column.id)}
                                                onChange={toggleColumnIDEventHandler(column.id, setAdditionalColumnIDs)}
                                            />
                                            <Typography variant="textSm" fontWeight={500} color="textTextSecondary">
                                                {column.name}
                                            </Typography>
                                        </Stack>
                                    );
                                })}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowAdditionalColumns(false)} fullWidth>
                        <FormattedMessage defaultMessage="Confirm selection" description="Confirm selection button" />
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <DialogTitle component={Stack} direction="column" gap={1}>
                <Typography variant="textLg" fontWeight={500}>
                    <FormattedMessage
                        defaultMessage="Supplier risk data"
                        description="Define column structure modal title"
                    />
                </Typography>
                <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                    <FormattedMessage
                        defaultMessage="Choose the information you want to gather about your suppliers to get the necessary foundation in place to make an informed decision about your suppliers risk."
                        description="Define column structure modal subtitle"
                    />
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500], // TODO: `close button tokens/close-secondary`
                    })}
                >
                    <X />
                </IconButton>
            </DialogTitle>
            <div /> {/* Hack to get the padding of the dialogcontent to appear */}
            <DialogContent>
                <Stack direction="column" gap={4}>
                    <Stack direction="column" gap={1}>
                        <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                            <FormattedMessage
                                defaultMessage="Default data points"
                                description="Default data points section title"
                            />
                        </Typography>
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            {[
                                <FormattedMessage
                                    key="name"
                                    defaultMessage="Supplier name"
                                    description="Supplier name column chip name"
                                />,
                                <FormattedMessage
                                    key="spend"
                                    defaultMessage="Spend"
                                    description="Spend column chip name"
                                />,
                                <FormattedMessage
                                    key="hq"
                                    defaultMessage="Country"
                                    description="Country column chip name"
                                />,
                                <FormattedMessage
                                    key="industry"
                                    defaultMessage="Industry"
                                    description="Industry column chip name"
                                />,
                                <FormattedMessage
                                    key="productionCountries"
                                    defaultMessage="Production countries"
                                    description="Production countries column chip name"
                                />,
                            ].map((name) => (
                                <Chip key={name.key} label={name} size="large" />
                            ))}
                        </Stack>
                    </Stack>

                    <Stack direction="column">
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Add more fields" description="Add more fields title" />
                        </Typography>
                        <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                            <FormattedMessage
                                defaultMessage="Add more fields from your supplier table as needed, they can be added or removed at any time. Additional data fields will not affect the social risk estimate."
                                description="Additional data fields section subtitle"
                            />
                        </Typography>
                        <Stack direction="row" paddingTop={4} alignItems="center" gap={1.5}>
                            <Button color="secondary" onClick={() => setShowAdditionalColumns(true)} size="small">
                                <FormattedMessage
                                    defaultMessage="Add more fields"
                                    description="Import columns button"
                                />
                            </Button>

                            {importedColumns > 0 && (
                                <Typography variant="textSm" fontWeight={400} color="primary">
                                    <FormattedMessage
                                        defaultMessage="{numColumns} {numColumns, plural, one {field} other {fields}} added"
                                        description="Number of imported columns helper text"
                                        values={{ numColumns: importedColumns }}
                                    />
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack justifyContent="flex-end" direction="row" width="100%">
                    <Stack alignItems="center" direction="row" gap={1.5}>
                        <Button color="secondary" onClick={() => setStep("filter")} size="small">
                            <FormattedMessage defaultMessage="Back" description="Back button" />
                        </Button>
                        <Button
                            onClick={() => {
                                track(`${TRACK_PREFIX}: Defined Column Structure`, {
                                    hasCodeOfConduct: additionalColumnIDs.includes("has_code_of_conduct"),
                                    additionalImportedColumns: importedColumns,
                                });
                                onComplete();
                            }}
                            size="small"
                        >
                            <FormattedMessage defaultMessage="Create group" description="Create group button" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </>
    );
};
