import * as Sentry from "@sentry/react";
import { IntlShape } from "react-intl";

import {
    EstimatedRiskCell_SupplierFragment,
    GroupPageTable_AssessmentFragment,
    GroupPageTable_SupplierFragment,
    GroupPageTable_UserFragment,
} from "@/gql/graphql";

import { SuggestedStatusLevel } from "../RiskStatus";

export interface RiskValues {
    columnName: "socialRisk" | "manualRisk";
    value: SuggestedStatusLevel | string | null | undefined;
}

export interface TableData {
    id: string;
    supplier: GroupPageTable_SupplierFragment;
    assessmentStatus?: string | null;
    assessmentScore?: number | undefined | null;
    onboardingApprover?: { fullName: string } | null;
    [key: string]:
        | { fullName: string }
        | string[]
        | string
        | number
        | boolean
        | EstimatedRiskCell_SupplierFragment
        | undefined
        | null;
}

export const NON_MISSING_RISK_STATUSES: Exclude<SuggestedStatusLevel, "MISSING">[] = [
    "VERY_LOW",
    "LOW",
    "MEDIUM",
    "HIGH",
    "VERY_HIGH",
];

export function toSupplierRows(
    suppliers: GroupPageTable_SupplierFragment[],
    assessments: GroupPageTable_AssessmentFragment[],
    users: Record<string, GroupPageTable_UserFragment | undefined>,
    formatMessage: IntlShape["formatMessage"]
): TableData[] {
    return suppliers.map((supplier) => {
        const assessment = assessments.find((a) => a.supplierId === supplier.id);
        const onboardingApprover = users[supplier.onboarding?.approverId ?? ""];
        const customFieldData = supplier.customFields.reduce(
            (acc, field) => {
                let data: string | number | boolean | null = null;
                try {
                    data = JSON.parse(field.dataJson);
                } catch (e) {
                    Sentry.captureException(e, {
                        tags: { app: "social-risk-app", message: "Failed to parse custom field data" },
                        extra: {
                            supplierId: supplier.id,
                            fieldId: field.fieldId,
                            dataJSON: field.dataJson,
                        },
                    });
                }
                acc[field.fieldId] = data;
                return acc;
            },
            {} as Record<string, string | number | boolean | null>
        );
        return {
            id: supplier.id,
            supplier: supplier,
            assessmentStatus: assessment?.submittedAt
                ? formatMessage({ defaultMessage: "Submitted" })
                : formatMessage({ defaultMessage: "Not submitted" }),
            assessmentScore: assessment?.submittedAt ? assessment.score : undefined,
            onboardingApprover,
            ...customFieldData,
        };
    });
}
