import { ArrowLeft, ArrowsExpand, DotsVertical } from "@ignite-analytics/icons";
import { Box, Drawer, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ProcurementEntityType, SupplierDrawer_SupplierFragment } from "@/gql/graphql";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { CategoryBar } from "./CategoryBar";
import { EstimatedRiskCard } from "./EstimatedRiskCard";

graphql(`
    fragment SupplierDrawer_Supplier on Supplier {
        id
        name
        ...EstimatedRiskCard_Supplier
    }
`);

export interface SupplierDrawerProps {
    supplier: SupplierDrawer_SupplierFragment;
    open: boolean;
    onClose: () => void;
}

export const SupplierDrawer: React.FC<SupplierDrawerProps> = ({ supplier, open, onClose }) => {
    const { formatMessage } = useIntl();
    const [headerAnchorEl, setHeaderAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useOutgoingNavigate();
    const [value, setValue] = useState(0);

    const handleHeaderMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHeaderAnchorEl(event.currentTarget);
    };
    const handleHeaderMenuClose = () => {
        setHeaderAnchorEl(null);
    };
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Drawer title={supplier.name} anchor="right" onClose={onClose} open={open}>
            <Stack minWidth={800} maxWidth={1000} width="40vw" spacing={3} padding={3}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack spacing={0.5} direction="row" alignItems="center">
                        <IconButton onClick={onClose}>
                            <ArrowLeft />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                navigate(
                                    supplier.onboarding?.status === "APPROVED"
                                        ? `/supplier-profile/${supplier.id}/overview/`
                                        : `${location.pathname}/onboarding/${supplier.id}`
                                )
                            }
                        >
                            <ArrowsExpand />
                        </IconButton>
                        <Typography variant="textXl" fontWeight={700}>
                            {supplier.name}
                        </Typography>
                    </Stack>
                    <IconButton color="secondary" onClick={handleHeaderMenuOpen}>
                        <DotsVertical />
                    </IconButton>
                    {headerAnchorEl && (
                        <Menu open anchorEl={headerAnchorEl}>
                            <MenuItem onClick={handleHeaderMenuClose}>Edit</MenuItem>
                            <MenuItem onClick={handleHeaderMenuClose}>Delete</MenuItem>
                        </Menu>
                    )}
                </Stack>
                <CategoryBar entities={mockCategories} />
                <EstimatedRiskCard supplier={supplier} />
                <EstimatedRiskCard supplier={supplier} />
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleTabChange}>
                        <Tab label={formatMessage({ defaultMessage: "Notes & activity" })} />
                        <Tab label={formatMessage({ defaultMessage: "Assessments" })} />
                        <Tab label={formatMessage({ defaultMessage: "Documents" })} />
                    </Tabs>
                </Box>
                {value === 0 && (
                    <Stack width="100%" height={200} alignItems="center" justifyContent="center">
                        <Typography variant="textXl" fontWeight={700}>
                            <FormattedMessage defaultMessage="Notes & activity" />
                        </Typography>
                    </Stack>
                )}
                {value === 1 && (
                    <Stack width="100%" height={200} alignItems="center" justifyContent="center">
                        <Typography variant="textXl" fontWeight={700}>
                            <FormattedMessage defaultMessage="Assessments" />
                        </Typography>
                    </Stack>
                )}
                {value === 2 && (
                    <Stack width="100%" height={200} alignItems="center" justifyContent="center">
                        <Typography variant="textXl" fontWeight={700}>
                            <FormattedMessage defaultMessage="Documents" />
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Drawer>
    );
};

const mockCategories = [
    { id: "1", type: ProcurementEntityType.Category, label: "L2 Category" },
    { id: "2", type: ProcurementEntityType.Category, label: "L2 Category 2" },
    { id: "3", type: ProcurementEntityType.Category, label: "L2 Category 3" },
    { id: "4", type: ProcurementEntityType.Department, label: "Business unit" },
    { id: "5", type: ProcurementEntityType.Department, label: "Business unit 2" },
];
