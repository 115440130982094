import { useQuery } from "@apollo/client";
import { Plus } from "@ignite-analytics/icons";
import { Button, Chip, LinearProgress, Paper, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { DocumentsList_AssessmentsFileFragment, DocumentsList_SupplierFileFragment } from "@/gql/graphql";
import { getNameAndContentType } from "@/lib/files";
import { useAlert } from "@/providers";

import { DocumentActions } from "./DocumentActions";
import { ExpirationChip } from "./ExpirationChip";
import { UploadModal } from "./UploadModal";
import { getShortDocumentTypeName } from "./UploadModal/types";

export type DocumentListFiles = DocumentsList_SupplierFileFragment | DocumentsList_AssessmentsFileFragment;

type Props = {
    supplierId: string;
    isEditor: boolean;
};

const DocumentsList_SupplierQuery = graphql(`
    query DocumentsList_SupplierQuery($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                id
                files {
                    ...DocumentsList_SupplierFile
                }
                assessmentsFiles {
                    ...DocumentsList_AssessmentsFile
                }
            }
        }
    }
`);

graphql(`
    fragment DocumentsList_SupplierFile on SupplierFile {
        name
        createdAt
        metaJson
        size
        supplierId
        ...DocumentsActions_SupplierFile
    }

    fragment DocumentsList_AssessmentsFile on AssessmentsFile {
        name
        createdAt
        metaJson
        size
        referenceId
    }
`);

const isScanning = (file: DocumentListFiles) => {
    if (!file.metaJson) return false;
    const { av_scanned } = JSON.parse(file.metaJson);
    return av_scanned === "FALSE";
};

export const DocumentsTab: React.FC<Props> = ({ supplierId, isEditor }) => {
    const { formatMessage, formatDate } = useIntl();
    const { alertUser } = useAlert();

    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const { data, loading, startPolling, stopPolling, refetch } = useQuery(DocumentsList_SupplierQuery, {
        variables: {
            input: {
                id: supplierId,
            },
        },
        onError: (error) => {
            alertUser({
                value: error.message,
                severity: "error",
            });
        },
        skip: !supplierId,
    });

    const files: DocumentListFiles[] = React.useMemo(() => {
        const supplierFiles = data?.getSupplier.supplier?.files || [];
        const assessmentsFiles = data?.getSupplier.supplier?.assessmentsFiles || [];
        return [...supplierFiles, ...assessmentsFiles];
    }, [data]);

    React.useEffect(() => {
        const hasScanning = files.some(isScanning);
        if (hasScanning) {
            startPolling(2000); // Poll every 2 seconds
        } else {
            stopPolling();
        }
        return () => {
            stopPolling();
        };
    }, [files, startPolling, stopPolling]);

    const handleRefetch = React.useCallback(() => {
        refetch();
        const checkFiles = async () => {
            const result = await refetch();
            const currentFiles = result.data?.getSupplier.supplier?.files || [];
            const currentAssessmentsFiles = result.data?.getSupplier.supplier?.assessmentsFiles || [];
            const allFiles = [...currentFiles, ...currentAssessmentsFiles];
            if (allFiles.some(isScanning)) {
                startPolling(2000);
            }
        };
        checkFiles();
    }, [refetch, startPolling]);

    const documentsSorted = React.useMemo(
        () =>
            [...files].sort((a, b) => {
                const createdAtA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
                const createdAtB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
                return createdAtB - createdAtA;
            }),
        [files]
    );

    const convertDocumentOrigin = (metaJson: string) => {
        const { origin } = JSON.parse(metaJson);
        switch (origin) {
            case "supplier-page":
                return formatMessage({
                    defaultMessage: "Upload",
                    description: "Documents uploaded from supplier-page",
                });
            case "assessment":
                return formatMessage({
                    defaultMessage: "Assessments",
                    description: "Documents uploaded from assessment answer",
                });
            default:
                return formatMessage({ defaultMessage: "Unknown", description: "Unknown document upload origin" });
        }
    };

    return (
        <Stack spacing={2} pb={6}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Documents" description="Documents header" />
                    </Typography>
                    {documentsSorted.length > 0 && (
                        <Chip label={`${documentsSorted.length}`} size="xsmall" clickable={false} color="neutral" />
                    )}
                </Stack>

                <Stack direction="row" spacing={1}>
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Button
                            color="ghostPrimary"
                            size="2xsmall"
                            onClick={() => setUploadModalOpen(true)}
                            startIcon={<Plus />}
                            disabled={!isEditor}
                        >
                            <FormattedMessage defaultMessage="Add new" />
                        </Button>
                    </NoPermissionTooltip>
                </Stack>
            </Stack>
            {loading ? (
                <>
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                </>
            ) : (
                <>
                    {documentsSorted?.map((file) => {
                        const { originalFileName } = getNameAndContentType(file);
                        const metaJson = file.metaJson ? JSON.parse(file.metaJson) : {};
                        const docType = metaJson.doctype;
                        const expiryDate = metaJson.expirydate;
                        return isScanning(file) ? (
                            <Paper sx={{ padding: 2, width: "100%" }}>
                                <Stack spacing={1}>
                                    <LinearProgress variant="indeterminate" />
                                    <Typography
                                        variant="textSm"
                                        fontWeight={500}
                                        sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Uploading {file}..."
                                            values={{ file: decodeURIComponent(originalFileName) }}
                                        />
                                    </Typography>
                                </Stack>
                            </Paper>
                        ) : (
                            <Paper key={`${file.name}+${file.metaJson}`} sx={{ padding: 2 }}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Stack spacing={1} pr={2}>
                                        <Typography
                                            variant="textSm"
                                            fontWeight={500}
                                            sx={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                                        >
                                            {decodeURIComponent(originalFileName)}
                                        </Typography>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            {getShortDocumentTypeName(docType) !== "" && (
                                                <Chip
                                                    label={getShortDocumentTypeName(docType)}
                                                    color="primary"
                                                    size="small"
                                                    clickable={false}
                                                />
                                            )}
                                            <Typography variant="textXs">
                                                {file.createdAt !== null ? formatDate(file.createdAt) : ""}
                                            </Typography>
                                            {file.metaJson && (
                                                <Typography variant="textXs">
                                                    {convertDocumentOrigin(file.metaJson)}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <ExpirationChip expiryDate={expiryDate} />
                                        <DocumentActions isEditor={isEditor} file={file} supplierId={supplierId} />
                                    </Stack>
                                </Stack>
                            </Paper>
                        );
                    })}
                </>
            )}
            {uploadModalOpen && (
                <UploadModal
                    open={uploadModalOpen}
                    onClose={() => setUploadModalOpen(false)}
                    supplierId={supplierId}
                    refetch={handleRefetch}
                />
            )}
        </Stack>
    );
};
