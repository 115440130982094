import { Minus, Plus, X } from "@ignite-analytics/icons";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SupplierTableColumn } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";
import { useCompanyCurrency } from "@/providers/CompanyCurrencyContext";

import { BusinessUnitSelect } from "../BusinessUnitSelect";
import { BusinessUnit } from "../BusinessUnitSelect/types";
import { InlineFilter } from "../InlineFilter";
import { FilterInput } from "../InlineFilter/types";
import { RiskEstimate, RiskEstimatesSelector } from "../RiskEstimatesSelector";
import { SpendCategorySelect } from "../SpendCategorySelect";
import { Category } from "../SpendCategorySelect/types";
import { SpendRangeSelector } from "../SpendRangeSelector";

import { NoSpendDataAvailableContent } from "./NoSpendDataAvailableContent";
import { ValidGroupButton } from "./ValidGroupButton";

import { Step } from ".";

interface Props {
    onClose: () => void;
    setStep: (newStep: Step) => void;
    groupName: string;
    setGroupName: React.Dispatch<React.SetStateAction<string>>;
    categoryIDs: string[];
    setCategoryIDs: React.Dispatch<React.SetStateAction<string[]>>;
    businessUnitIDs: string[];
    setBusinessUnitIDs: React.Dispatch<React.SetStateAction<string[]>>;
    riskEstimates: RiskEstimate[];
    setRiskEstimates: React.Dispatch<React.SetStateAction<RiskEstimate[]>>;
    spendRange: [number, number];
    setSpendRange: React.Dispatch<React.SetStateAction<[number, number]>>;
    additionalFilters: FilterInput[];
    setAdditionalFilters: React.Dispatch<React.SetStateAction<FilterInput[]>>;
    maxSpendRange: [number, number];
    supplierCountInfo: { total: number | undefined; loading: boolean; hasError: boolean };
    spendColumnName: string | undefined;
    hasSpend: boolean;
    spendYear: number | null | undefined;
    columns: SupplierTableColumn[];
    categoryTree: Category[];
    setCategoryTree: React.Dispatch<React.SetStateAction<Category[]>>;
    businessUnitTree: BusinessUnit[];
    setBusinessUnitTree: React.Dispatch<React.SetStateAction<BusinessUnit[]>>;
}

export const CreateFilterDialogContent: React.FC<Props> = ({
    onClose,
    setStep,
    groupName,
    setGroupName,
    categoryIDs,
    setCategoryIDs,
    businessUnitIDs,
    setBusinessUnitIDs,
    riskEstimates,
    setRiskEstimates,
    spendRange,
    setSpendRange,
    additionalFilters,
    setAdditionalFilters,
    maxSpendRange,
    supplierCountInfo,
    spendColumnName,
    hasSpend,
    spendYear,
    columns,
    categoryTree,
    setCategoryTree,
    businessUnitTree,
    setBusinessUnitTree,
}) => {
    const intl = useIntl();
    const { currency } = useCompanyCurrency();
    const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(false);

    const handleClickedNext = () => {
        track(`${TRACK_PREFIX}: Selected Supplier Group`, {
            noOfCategories: categoryIDs.length,
            noOfBusinessUnits: businessUnitIDs.length,
            riskEstimates: riskEstimates,
            spendRangeUsed: spendRange[0] !== maxSpendRange[0] || spendRange[1] !== maxSpendRange[1],
            spendRangeMin: spendRange[0],
            spendRangeMax: spendRange[1],
            usedAdvancedOptions: additionalFilters.length > 0,
            noOfSuppliers: supplierCountInfo.total,
        });
        setStep("column");
    };

    return (
        <Stack direction="column">
            <Stack direction="row">
                <Stack direction="column" flex="1 1 0px">
                    <DialogTitle component={Stack} direction="column" gap={1}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Create supplier group"
                                description="Create custom group modal title"
                            />
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={(theme) => ({
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: theme.palette.grey[500], // TODO: `close button tokens/close-secondary`
                            })}
                        >
                            <X />
                        </IconButton>
                    </DialogTitle>
                    <div /> {/* Hack to get the padding of the dialogcontent to appear */}
                    <DialogContent sx={{ overflowY: "auto", maxHeight: "60vh" }}>
                        <Stack gap={4} direction="column">
                            <TextField
                                fullWidth
                                placeholder={intl.formatMessage({
                                    defaultMessage: "My top suppliers",
                                    description: "Supplier group name placeholder",
                                })}
                                label={
                                    <FormattedMessage
                                        defaultMessage="Supplier group name"
                                        description="Supplier group name label"
                                    />
                                }
                                helperText={
                                    <FormattedMessage
                                        defaultMessage="Enter a unique name for this group of suppliers. (e.g. 'Top spend suppliers')"
                                        description="Supplier group name helper text"
                                    />
                                }
                                value={groupName}
                                onChange={(event) => setGroupName(event.target.value)}
                            />
                            <Divider />

                            <Stack spacing={1} direction="column">
                                <Typography variant="textMd" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Which suppliers do you want to see?"
                                        description="Supplier group filter title"
                                    />
                                </Typography>
                                <Typography variant="textSm" fontWeight={400} color="primary">
                                    <FormattedMessage
                                        defaultMessage="Define groups based on your filters. You can always remove suppliers as needed."
                                        description="Supplier group filter helper text"
                                    />
                                </Typography>
                            </Stack>

                            <Stack spacing={1} direction="column">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Categories"
                                        description="Categories selector title"
                                    />
                                </Typography>
                                <SpendCategorySelect
                                    categoryIDs={categoryIDs}
                                    setCategoryIDs={setCategoryIDs}
                                    categoryTree={categoryTree}
                                    setCategoryTree={setCategoryTree}
                                />
                            </Stack>

                            <Stack spacing={1} direction="column">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Business units"
                                        description="Business units selector title"
                                    />
                                </Typography>
                                <BusinessUnitSelect
                                    businessUnitIDs={businessUnitIDs}
                                    setBusinessUnitIDs={setBusinessUnitIDs}
                                    businessUnitTree={businessUnitTree}
                                    setBusinessUnitTree={setBusinessUnitTree}
                                />
                            </Stack>

                            <Stack spacing={1} direction="column">
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Ignite risk estimates"
                                        description="Ignite risk estimates selector title"
                                    />
                                </Typography>
                                <Typography variant="textSm" fontWeight={400} color="primary">
                                    <FormattedMessage
                                        defaultMessage="The suppliers with the selected estimated risk(s) will be included in your group."
                                        description="Risk estimates selector helper text"
                                    />
                                </Typography>

                                <RiskEstimatesSelector
                                    riskEstimates={riskEstimates}
                                    setRiskEstimates={setRiskEstimates}
                                />
                            </Stack>

                            {hasSpend ? (
                                <Stack spacing={1} direction="column">
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="Spend range ({currency})"
                                            values={{ currency }}
                                            description="Spend range selector title"
                                        />
                                    </Typography>
                                    <Stack spacing={0.5}>
                                        <Typography variant="textSm" fontWeight={400} color="primary">
                                            <FormattedMessage
                                                defaultMessage="The spend range shown corresponds to the last full year or latest spend."
                                                description="Spend range selector helper text"
                                            />
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={3}>
                                        <SpendRangeSelector
                                            range={spendRange}
                                            setRange={setSpendRange}
                                            maxRange={maxSpendRange}
                                        />
                                        {spendColumnName && spendYear && (
                                            <Typography variant="textSm" fontWeight={400} color="primary">
                                                <FormattedMessage
                                                    defaultMessage="Using column: {columnName} ({year})"
                                                    description="Spend column hint text"
                                                    values={{ columnName: spendColumnName, year: spendYear }}
                                                />
                                            </Typography>
                                        )}
                                    </Stack>
                                </Stack>
                            ) : (
                                <NoSpendDataAvailableContent />
                            )}

                            {!showAdvancedOptions && (
                                <Stack spacing={1} direction="row" flexShrink={1}>
                                    <Button
                                        color="linkPrimary"
                                        size="small"
                                        onClick={() => setShowAdvancedOptions(true)}
                                    >
                                        <Stack direction="row" gap={1}>
                                            <Plus />
                                            <FormattedMessage
                                                defaultMessage="Advanced options"
                                                description="Supplier group advanced options button"
                                            />
                                        </Stack>
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                    </DialogContent>
                </Stack>
                {showAdvancedOptions && (
                    <Card sx={{ marginTop: 4, marginRight: 8, flex: "1 1 0px", width: 700 }}>
                        <CardContent
                            component={Stack}
                            padding={3}
                            gap={2.5}
                            height="100%"
                            justifyContent="space-between"
                        >
                            <Typography variant="textLg" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="Advanced options"
                                    description="Advanced options card title"
                                />
                            </Typography>
                            <InlineFilter
                                columns={columns}
                                filterInput={additionalFilters}
                                setFilterInput={setAdditionalFilters}
                                hideButton={
                                    <Button
                                        color="linkPrimary"
                                        size="small"
                                        onClick={() => setShowAdvancedOptions(false)}
                                    >
                                        <Stack direction="row" gap={1}>
                                            <Minus />
                                            <FormattedMessage
                                                defaultMessage="Hide advanced options"
                                                description="Supplier group hide advanced options button"
                                            />
                                        </Stack>
                                    </Button>
                                }
                            />
                        </CardContent>
                    </Card>
                )}
            </Stack>
            <DialogActions
                sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "background.paper",
                    zIndex: 1,
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    paddingTop: 3,
                }}
            >
                <Stack justifyContent="space-between" direction="row" width="100%">
                    <Button color="secondary" onClick={onClose} size="small">
                        <FormattedMessage defaultMessage="Cancel" description="Supplier group 'Cancel' button" />
                    </Button>
                    <Stack justifyContent="flex-end" alignItems="center" direction="row" gap={1.5}>
                        {supplierCountInfo.loading ? (
                            <CircularProgress size={20} />
                        ) : (
                            !supplierCountInfo.hasError && (
                                <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                    <FormattedMessage
                                        defaultMessage="{numSuppliers} {numSuppliers, plural, one {supplier} other {suppliers}} found"
                                        description="Suppliers found helper text"
                                        values={{ numSuppliers: supplierCountInfo.total }}
                                    />
                                </Typography>
                            )
                        )}
                        <Button color="secondary" onClick={() => setStep("preview")} size="small">
                            <FormattedMessage defaultMessage="Preview" description="Supplier group 'Preview' button" />
                        </Button>
                        <ValidGroupButton
                            missingGroupName={!groupName}
                            noSuppliersFound={supplierCountInfo.total === 0}
                            onClick={handleClickedNext}
                        >
                            <FormattedMessage defaultMessage="Next" description="Supplier group 'Next' button" />
                        </ValidGroupButton>
                    </Stack>
                </Stack>
            </DialogActions>
        </Stack>
    );
};
