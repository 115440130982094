import { Chip, chipClasses, ChipProps, Theme } from "@mui/material";

import { graphql } from "@/gql";
import { ProductionCountryRiskChip_SupplierCountryFragment, RiskScore } from "@/gql/graphql";

import { RiskIndicator } from "./RiskIndicator";

type ProductionCountryRiskChipProps = {
    country: ProductionCountryRiskChip_SupplierCountryFragment;
    label?: "name" | "code" | "full";
};

graphql(`
    fragment ProductionCountryRiskChip_SupplierCountry on SupplierCountry {
        id
        iso2Code
        name(language: $language)
        risk {
            score
        }
    }
`);

const ProductionCountryRiskChip: React.FC<ProductionCountryRiskChipProps & Omit<ChipProps, "label">> = ({
    country,
    label = "full",
    ...rest
}) => {
    let chipLabel: string;
    switch (label) {
        case "full":
            chipLabel = `${country.iso2Code}: ${country.name}`;
            break;
        case "name":
            chipLabel = country.name;
            break;
        case "code":
            chipLabel = country.iso2Code;
            break;
    }

    return (
        <Chip
            icon={<RiskIndicator riskScore={country.risk?.score} />}
            label={chipLabel}
            color="neutral"
            sx={{
                [`& .${chipClasses.icon}`]: {
                    color: (t) => getColorFromRisk(t, country.risk?.score),
                },
            }}
            {...rest}
        />
    );
};

function getColorFromRisk(t: Theme, riskScore: RiskScore | undefined | null) {
    switch (riskScore) {
        case RiskScore.VeryHigh:
        case RiskScore.High:
            return t.palette.error.main;
        case RiskScore.Medium:
            return t.palette.warning.main;
        case RiskScore.Low:
        case RiskScore.VeryLow:
            return t.palette.success.main;
        default:
            return t.palette.tokens?.icon.iconGray;
    }
}

export { ProductionCountryRiskChip };
