import { InformationCircle } from "@ignite-analytics/icons";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const BetaTagWithInfoHover: React.FC = () => {
    return (
        <Tooltip
            placement="right-end"
            title={
                <Stack spacing={1}>
                    <Typography variant="textXs">
                        <FormattedMessage defaultMessage="This feature is in beta so you might experience some bugs and inconsistencies." />
                    </Typography>

                    <Typography variant="textXs">
                        <FormattedMessage defaultMessage="Please share your feedback to help us improve!" />
                    </Typography>
                </Stack>
            }
        >
            <Chip
                icon={<InformationCircle />}
                label={<FormattedMessage defaultMessage="Beta" />}
                color="ignite"
                size="large"
                sx={{ cursor: "help" }}
            />
        </Tooltip>
    );
};
