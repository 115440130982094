import { ExclamationTriangle, X } from "@ignite-analytics/icons";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

export const DeleteModal: React.FC<{ open: boolean; onClose: () => void; onDelete: () => void }> = ({
    open,
    onClose,
    onDelete,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            maxWidth="xs"
            sx={{
                "& .MuiDialogContent-root": {
                    paddingTop: "20px !important",
                },
            }}
        >
            <DialogTitle id="delete-dialog-title">
                <Stack width="100%" justifyContent="center" alignItems="center">
                    <Box
                        width={40}
                        height={40}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            backgroundColor: (theme) => theme.palette.tokens?.shapeIcon.shapeWarningA,
                            borderRadius: 20,
                        }}
                    >
                        <ExclamationTriangle color="warning" />
                    </Box>
                </Stack>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500], // TODO: `close button tokens/close-secondary`
                    })}
                >
                    <X />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1} justifyContent="center">
                    <Typography variant="textLg" textAlign="center" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Remove supplier"
                            description="Remove supplier information header"
                        />
                    </Typography>
                    <Typography variant="textSm" textAlign="center">
                        <FormattedMessage
                            defaultMessage="The supplier will only be removed from the group. All assessment data associated with this supplier will remain within the campaign and must be deleted from there."
                            description="Remove supplier information"
                        />
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1.5} width="100%">
                    <Button color="secondary" onClick={onClose} fullWidth>
                        <FormattedMessage defaultMessage="Cancel" description="cancel button" />
                    </Button>
                    <Button color="primary" onClick={onDelete} fullWidth>
                        <FormattedMessage defaultMessage="Remove supplier" description="remove supplier button" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
