import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid-pro";
import { orderBy } from "lodash";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { graphql } from "@/gql";
import { ProductionCountriesCell_SupplierCountryFragment, RiskScore } from "@/gql/graphql";

import { TableData } from "../../utils";

import { ProductionCountryRiskChip } from "./ProductionCountryRiskChip";
import { SelectIconButton } from "./SelectIconButton";

interface ProductionCountriesCellProps {
    params: GridRenderCellParams<
        TableData,
        ProductionCountriesCell_SupplierCountryFragment[] | undefined,
        unknown,
        GridTreeNodeWithRender
    >;
    disabled: boolean;
}
graphql(`
    fragment ProductionCountriesCell_SupplierCountry on SupplierCountry {
        id
        iso2Code
        name(language: $language)
        risk {
            score
        }
    }
`);

export const ProductionCountriesCell: React.FC<ProductionCountriesCellProps> = ({ params, disabled }) => {
    const { api, id, field, value } = params;

    if (!value || value.length === 0) {
        return (
            <Stack justifyContent="space-between" alignItems="center" direction="row" width="100%">
                <Typography variant="inherit">
                    <FormattedMessage
                        defaultMessage="Select"
                        description="Placeholder value in production country cell"
                    />
                </Typography>
                <SelectIconButton disabled={disabled} onClick={() => api.startCellEditMode({ id, field })} />
            </Stack>
        );
    }

    const orderedCountries = orderBy(
        value,
        [
            (country) => {
                switch (country.risk?.score) {
                    case RiskScore.VeryHigh:
                        return 5;
                    case RiskScore.High:
                        return 4;
                    case RiskScore.Medium:
                        return 3;
                    case RiskScore.Low:
                        return 2;
                    case RiskScore.VeryLow:
                        return 1;
                    default:
                        return 0;
                }
            },
            "name",
        ],
        ["desc", "asc"]
    );

    const [first, ...rest] = orderedCountries;

    return (
        <Stack direction="row" width="100%" alignItems="center" flexWrap="nowrap" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
                <ProductionCountryRiskChip label="code" country={first} />
                {rest.length > 0 && (
                    <Tooltip
                        slotProps={{
                            tooltip: {
                                sx: {
                                    maxWidth: "325px",
                                },
                            },
                        }}
                        title={
                            <Stack gap={2} margin={2}>
                                <Typography variant="textMd" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Production countries"
                                        description="Production countries explainer popper title"
                                    />
                                </Typography>
                                <Stack direction="row" gap={1} flexWrap="wrap" width="100%">
                                    {orderedCountries.map((country) => (
                                        <ProductionCountryRiskChip key={country.id} country={country} />
                                    ))}
                                </Stack>
                                <Typography variant="textSm">
                                    <FormattedMessage
                                        defaultMessage="Production countries are listed by criticality; those with the same risk are ordered alphabetically."
                                        description="Production countries popper explainer text"
                                    />
                                </Typography>
                            </Stack>
                        }
                        enterDelay={200}
                    >
                        <Chip
                            clickable
                            label={<FormattedNumber value={rest.length} signDisplay="always" />}
                            color="neutral"
                        />
                    </Tooltip>
                )}
            </Stack>
            <SelectIconButton disabled={disabled} onClick={() => api.startCellEditMode({ id, field })} />
        </Stack>
    );
};
