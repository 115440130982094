import { MessageCircleBlank } from "@ignite-analytics/icons";
import { Box, Tooltip } from "@mui/material";

import { ReasonTooltip_NoteFragment, ReasonTooltip_SupplierFragment } from "@/gql/graphql";

import { ReasonTooltip } from "./ReasonTooltip";

export const ReasonIcon: React.FC<{ supplier: ReasonTooltip_SupplierFragment; note: ReasonTooltip_NoteFragment }> = ({
    supplier,
    note,
}) => {
    return (
        <Tooltip
            title={<ReasonTooltip supplier={supplier} note={note} />}
            placement="left"
            slotProps={{
                tooltip: {
                    sx: {
                        padding: 2,
                        backgroundColor: (theme) => theme.palette.background.backgroundSecondary,
                        maxWidth: 350,
                    },
                },
            }}
        >
            <Box
                width={30}
                height={30}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: (theme) => theme.palette.tokens?.shapeIcon.shapeWarningA,
                    borderRadius: 20,
                }}
            >
                <MessageCircleBlank color="warning" fontSize="small" />
            </Box>
        </Tooltip>
    );
};
