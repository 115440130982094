import { useMutation } from "@apollo/client";
import { ExclamationTriangle, X } from "@ignite-analytics/icons";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { graphql } from "@/gql";
import { OverviewPage_GetGroupsDocument } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";
import { useAlert } from "@/providers";

const deleteSupplierGroupMutation = graphql(`
    mutation deleteSupplierGroup($input: DeleteSupplierGroupInput!) {
        deleteSupplierGroup(input: $input) {
            id
        }
    }
`);

interface DeleteModalProps {
    open: boolean;
    onClose: () => void;
    groupId: string;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ open, onClose, groupId }) => {
    const navigate = useNavigate();
    const [deleteGroup] = useMutation(deleteSupplierGroupMutation);
    const { formatMessage } = useIntl();
    const alert = useAlert();
    const handleDelete = () => {
        track(`${TRACK_PREFIX}: Deleted group`);
        deleteGroup({
            variables: {
                input: {
                    id: groupId,
                },
            },
            refetchQueries: [OverviewPage_GetGroupsDocument],
            onCompleted: () => {
                navigate("/social-risk/");
            },
            onError: (error) => {
                alert.alertUser({
                    title: formatMessage({
                        defaultMessage: "Failed to delete group",
                        description: "Error message for failed deleting group",
                    }),
                    value: formatMessage({
                        defaultMessage: "Try again or contact support",
                        description: "Error message for failed deleting group",
                    }),
                    severity: "error",
                });
                reportError(error);
            },
        });
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            maxWidth="xs"
            sx={{
                "& .MuiDialogContent-root": {
                    paddingTop: "20px !important",
                },
            }}
        >
            <DialogTitle id="delete-dialog-title">
                <Stack width="100%" justifyContent="center" alignItems="center">
                    <Box
                        width={40}
                        height={40}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            backgroundColor: (theme) => theme.palette.tokens?.shapeIcon.shapeErrorA,
                            borderRadius: 20,
                        }}
                    >
                        <ExclamationTriangle color="error" />
                    </Box>
                </Stack>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <X />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1} justifyContent="center">
                    <Typography variant="textLg" textAlign="center" fontWeight={500}>
                        <FormattedMessage defaultMessage="Delete group" description="Delete group information header" />
                    </Typography>
                    <Typography variant="textSm" textAlign="center">
                        <FormattedMessage
                            defaultMessage="Deleting a supplier group is permanent and cannot be undone. Are you sure you want to proceed?"
                            description="Delete supplier group information. Part one."
                        />
                    </Typography>
                    <Typography variant="textSm" textAlign="center">
                        <FormattedMessage
                            defaultMessage="Any changes made to a supplier prior to deletion will remain valid."
                            description="Delete supplier group information. Part two."
                        />
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1.5} width="100%">
                    <Button color="secondary" onClick={onClose} fullWidth>
                        <FormattedMessage defaultMessage="Cancel" description="cancel button" />
                    </Button>
                    <Button color="primary" onClick={handleDelete} fullWidth>
                        <FormattedMessage defaultMessage="Delete group" description="delete group button" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
