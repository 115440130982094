/* Helper function to normalize progress to 100. 
   Why? When rounding to whole numbers, the sum can end up greater than 100. 
   E.g 76.5, 12.5, 11.5 => 77, 13, 12 = 102
   This function will reduce the highest value by 1 until the sum is 100.   
*/
export function normalizeProgressValues(
    igniteEnrichmentProgress: number,
    manualEnrichmentProgress: number,
    emptyEnrichmentProgress: number
) {
    let formattedIgniteEnrichmentProgress = Math.round(igniteEnrichmentProgress);
    let formattedManualEnrichmentProgress = Math.round(manualEnrichmentProgress);
    let formattedEmptyEnrichmentProgress = Math.round(emptyEnrichmentProgress);
    let sum = formattedIgniteEnrichmentProgress + formattedManualEnrichmentProgress + formattedEmptyEnrichmentProgress;

    while (sum > 100) {
        if (
            formattedIgniteEnrichmentProgress >= formattedManualEnrichmentProgress &&
            formattedIgniteEnrichmentProgress >= formattedEmptyEnrichmentProgress
        ) {
            formattedIgniteEnrichmentProgress -= 1;
        } else if (
            formattedManualEnrichmentProgress >= formattedIgniteEnrichmentProgress &&
            formattedManualEnrichmentProgress >= formattedEmptyEnrichmentProgress
        ) {
            formattedManualEnrichmentProgress -= 1;
        } else {
            formattedEmptyEnrichmentProgress -= 1;
        }
        sum = formattedIgniteEnrichmentProgress + formattedManualEnrichmentProgress + formattedEmptyEnrichmentProgress;
    }

    return {
        formattedIgniteEnrichmentProgress,
        formattedManualEnrichmentProgress,
        formattedEmptyEnrichmentProgress,
    };
}
