import { Grid, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { Filter } from "../types";

interface RangeFilterProps {
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
}

export const RangeFilter: React.FC<RangeFilterProps> = ({ filter, handleUpdateFilter }) => {
    return (
        <>
            <Grid item xs={4}>
                <TextField
                    id={`range-filter-min-${filter.id}`}
                    label={<FormattedMessage defaultMessage="Min" description="Filter detail 'Min' label" />}
                    size="small"
                    type="number"
                    value={filter.minRange}
                    fullWidth
                    onChange={(e) =>
                        handleUpdateFilter({
                            ...filter,
                            minRange: e.target.value,
                        })
                    }
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    id={`range-filter-max-${filter.id}`}
                    label={<FormattedMessage defaultMessage="Max" description="Filter detail 'Max' label" />}
                    size="small"
                    type="number"
                    value={filter.maxRange}
                    fullWidth
                    onChange={(e) =>
                        handleUpdateFilter({
                            ...filter,
                            maxRange: e.target.value,
                        })
                    }
                />
            </Grid>
        </>
    );
};
