import { Stack } from "@mui/material";

import { GroupPageTable } from "@/components/GroupPageTable";

import { GroupPageHeader } from "./GroupPageHeader";

export const GroupPage: React.FC = () => {
    return (
        <Stack spacing={2}>
            <GroupPageHeader />
            <GroupPageTable />
        </Stack>
    );
};
