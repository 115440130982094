import { GetBusinessUnitsQuery } from "@/gql/graphql";

import { LOADING_CHILDREN_LABEL } from "./constants";
import { BusinessUnit } from "./types";

export function replaceChildrenInTree(
    tree: BusinessUnit[],
    targetId: string,
    newChildren: BusinessUnit[]
): BusinessUnit[] {
    return tree.map((businessUnit) => {
        if (businessUnit.id === targetId) {
            // Replace the children of the business unit with the given id
            return { ...businessUnit, children: newChildren, hasChildren: newChildren.length > 0 };
        }

        // If the business unit has children, we recursively search within them
        if (businessUnit.hasChildren) {
            return {
                ...businessUnit,
                children: replaceChildrenInTree(businessUnit.children, targetId, newChildren),
            };
        }

        // If no match, return the business unit as is
        return businessUnit;
    });
}

export function addNewChildrenDataToTree(
    data: GetBusinessUnitsQuery,
    parentId: string,
    businessUnitTree: BusinessUnit[]
): BusinessUnit[] {
    const newChildren = data.getBusinessUnits.businessUnits.map((businessUnit) => {
        return {
            id: businessUnit.id,
            label: businessUnit.name,
            level: businessUnit.level,
            hasChildren: businessUnit.hasChildren,
            children: businessUnit.hasChildren
                ? [
                      // The component needs a dummy child to display the expand icon
                      {
                          id: `${businessUnit.id}-child`,
                          label: LOADING_CHILDREN_LABEL,
                          level: businessUnit.level + 1,
                          hasChildren: false,
                          children: [],
                      },
                  ]
                : [],
        };
    });
    const newTree = replaceChildrenInTree(businessUnitTree, parentId, newChildren);
    return newTree;
}

export function mapInitialDataToTree(data: GetBusinessUnitsQuery): BusinessUnit[] {
    return data.getBusinessUnits.businessUnits.map((businessUnit) => {
        return {
            id: businessUnit.id,
            label: businessUnit.name,
            level: businessUnit.level,
            hasChildren: businessUnit.hasChildren,
            children: businessUnit.hasChildren
                ? [
                      // The component needs a dummy child to display the expand icon
                      {
                          id: `${businessUnit.id}-child`,
                          label: LOADING_CHILDREN_LABEL,
                          level: businessUnit.level + 1,
                          hasChildren: false,
                          children: [],
                      },
                  ]
                : [],
        };
    });
}
