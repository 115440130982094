import { useQuery } from "@apollo/client";
import { orderBy } from "lodash";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { RiskScore, SupplierCountryLanguage } from "@/gql/graphql";

export type Country = {
    code: string;
    name: string;
    risk: {
        score: RiskScore;
        reason: {
            indexName: "CPI" | "FIW" | "ITUC";
            indexScore: RiskScore;
        }[];
    };
};

/**
 * @todo Implement data fetching, this is a placeholder.
 */
export function useCountries() {
    const language = useCountryLanguage();

    const { data, ...rest } = useQuery(
        graphql(`
            query useCountries_getCountries($language: SupplierCountryLanguage) {
                getCountries {
                    countries {
                        ...ProductionCountriesAutocomplete_SupplierCountry
                        ...CountryRiskDialog_SupplierCountry
                        ...useGetColumn_SupplierCountry
                    }
                }
            }
        `),
        {
            variables: {
                language,
            },
        }
    );
    if (data) {
        /**
         * @todo larwaa: consider server-side sorting.
         */
        const sortedCountryData = orderBy(data.getCountries.countries, ["name"], ["asc"]);
        return { ...rest, data: { getCountries: { countries: sortedCountryData } } };
    }
    return { ...rest, data };
}

export function useCountryLanguage() {
    const { locale } = useIntl();
    let language: SupplierCountryLanguage | null = null;
    switch (locale) {
        case "en-US":
            language = SupplierCountryLanguage.EnUs;
            break;
        case "nb-NO":
            language = SupplierCountryLanguage.NbNo;
            break;
    }
    return language;
}
