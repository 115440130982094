import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    Chip,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { ProdCountryChip } from "@/components/ProdCountryChip";
import { countryCodes } from "@/lib/data/CountryOptions";
import { RiskOption } from "@/lib/data/types";
import { getLabel, getLocalName } from "@/lib/data/utils";
import { track, TRACK_PREFIX } from "@/lib/track";

const renderInput = (params: AutocompleteRenderInputParams) => {
    // eslint-disable-next-line jsx-a11y/no-autofocus
    return <TextField {...params} size="small" variant="outlined" autoFocus />;
};

interface ProdCountriesAutocompleteProps {
    value: string[];
    onChange: (columnName: string, newValue: string[] | undefined) => void;
    isEditor: boolean;
}

export const ProdCountriesAutocomplete: React.FC<ProdCountriesAutocompleteProps> = ({ value, onChange, isEditor }) => {
    const options = countryCodes;
    const [isOpen, setIsOpen] = React.useState(false);

    const sortedCountries = countryCodes
        .filter((country) => value.includes(country.code))
        .sort(
            (a, b) =>
                (b.score !== null ? parseInt(b.score) : -Infinity) - (a.score !== null ? parseInt(a.score) : -Infinity)
        );

    const handleChange = (_event: React.SyntheticEvent<Element, Event>, value: RiskOption[] | null) => {
        const selectedCodes = value ? value.map((option) => option.code) : [];
        track(`${TRACK_PREFIX}: Updated Production Countries`, {
            initialValue: value,
            newValue: selectedCodes,
        });
        onChange("production_countries", selectedCodes);
    };

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const popperOpen = Boolean(anchorEl);

    return (
        <Autocomplete
            multiple
            options={options}
            value={options.filter((option) => value.includes(option.code)) ?? null}
            fullWidth
            open={isOpen}
            disabled={!isEditor}
            onOpen={() => isEditor && setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            getOptionLabel={(option) => `${getLabel(option)}`}
            renderOption={(props, option) => (
                <Typography variant="textSm" {...props}>
                    {getLabel(option)}
                </Typography>
            )}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            renderInput={renderInput}
            onChange={handleChange}
            renderTags={(tagValue, getTagProps) => {
                if (sortedCountries.length === 0) return null;

                if (isOpen) {
                    return (
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            {sortedCountries.map((country, index) => (
                                // eslint-disable-next-line react/jsx-key
                                <ProdCountryChip
                                    label={getLabel(country)}
                                    score={country.score}
                                    {...getTagProps({ index })}
                                />
                            ))}
                        </Stack>
                    );
                }

                return (
                    <Stack direction="row" alignItems="center" height="44px" spacing={0.5} flexWrap="wrap">
                        <ProdCountryChip
                            label={getLabel(sortedCountries[0])}
                            score={sortedCountries[0].score}
                            {...getTagProps({ index: 0 })}
                        />
                        {sortedCountries.length > 1 && (
                            <ProdCountryChip
                                label={getLabel(sortedCountries[1])}
                                score={sortedCountries[1].score}
                                {...getTagProps({ index: 1 })}
                            />
                        )}
                        {sortedCountries.length > 2 && (
                            <>
                                <Chip
                                    color="neutral"
                                    size="medium"
                                    label={`+${sortedCountries.length - 2}`}
                                    disabled={!isEditor}
                                    sx={{
                                        cursor: isEditor ? "pointer" : "default",
                                    }}
                                />
                                <Popper
                                    open={!isOpen && popperOpen && sortedCountries.length > 1}
                                    anchorEl={anchorEl}
                                    modifiers={[
                                        {
                                            name: "preventOverflow",
                                            options: {
                                                boundary: "scrollParent",
                                            },
                                        },
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 8],
                                            },
                                        },
                                    ]}
                                    sx={{ zIndex: 1501 }}
                                >
                                    <Box width="300px" height="auto">
                                        <Paper>
                                            <Stack spacing={2} padding={2}>
                                                <Typography variant="textMd" fontWeight={500}>
                                                    <FormattedMessage
                                                        defaultMessage="Production countries"
                                                        description="Production countries explainer popper title"
                                                    />
                                                </Typography>
                                                <Stack direction="row" flexWrap="wrap" gap={1} display="flex">
                                                    {sortedCountries.map((country) => {
                                                        return (
                                                            <ProdCountryChip
                                                                label={getLocalName(country)}
                                                                score={country.score}
                                                                key={country.code}
                                                            />
                                                        );
                                                    })}
                                                </Stack>
                                                <Typography variant="textSm">
                                                    <FormattedMessage
                                                        defaultMessage="Production countries are listed by criticality; those with the same risk are ordered alphabetically."
                                                        description="Production countries popper explainer text"
                                                    ></FormattedMessage>
                                                </Typography>
                                            </Stack>
                                        </Paper>
                                    </Box>
                                </Popper>
                            </>
                        )}
                    </Stack>
                );
            }}
            slotProps={{ listbox: { sx: { maxHeight: "250px", overflowY: "auto" } } }}
        />
    );
};
