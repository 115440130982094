import { ChevronDown, CircleSolid } from "@ignite-analytics/icons";
import { IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid-pro";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";

import { countryCodes } from "../values/CountryOptions";

export function RenderCountry(
    params: GridRenderCellParams<GridValidRowModel, unknown, unknown, GridTreeNodeWithRender>
) {
    const { value, api, id, field } = params;
    const isEditable = params.api.getColumn(field).editable;
    const theme = useTheme();
    const country = countryCodes.find((country) => country.code === value);
    const color = (() => {
        switch (country?.score) {
            case "1":
            case "2":
                return theme.palette.tokens?.icon.iconSuccess;
            case "3":
            case "4":
                return theme.palette.tokens?.icon.iconWarning;
            case "5":
            case "6":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    })();

    const content: React.ReactNode = (
        <Stack direction="row" alignItems="center" justifyContent="space-evenly" width="100%" height="100%">
            {country ? (
                <>
                    <Typography variant="textSm">{country?.code}</Typography>
                    <CircleSolid fontSize="medium" sx={{ color }} />
                </>
            ) : (
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Select" />
                </Typography>
            )}
            <NoPermissionTooltip hasPermission={!!isEditable} message="Your permissions do not allow editing">
                <IconButton disabled={!isEditable} onClick={() => api.startCellEditMode({ id, field })}>
                    <ChevronDown />
                </IconButton>
            </NoPermissionTooltip>
        </Stack>
    );

    if (params.row.productionCountries !== null && params.row.productionCountries.length > 0)
        return (
            <Tooltip
                title={
                    !isEditable ? (
                        <FormattedMessage
                            defaultMessage="This country is not used in risk calculation since at least one production country is specified. Your permissions do not allow editing."
                            description="Ignored country risk combined with permission tooltip"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="This country is not used in risk calculation since at least one production country is specified"
                            description="Ignored country risk tooltip"
                        />
                    )
                }
            >
                {content}
            </Tooltip>
        );
    else return content;
}
