import { Slider, Stack, TextField } from "@mui/material";
import React, { FormEvent } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { formatCompactNumber } from "@/lib/format";

type SpendRange = [number, number];

interface BaseProps {
    range: SpendRange;
    maxRange: readonly [number, number];
}

interface EditableProps extends BaseProps {
    disabled?: false;
    setRange: (range: SpendRange) => void;
}

interface DisabledProps extends BaseProps {
    disabled: true;
    setRange?: undefined;
}

type Props = EditableProps | DisabledProps;

interface InputProps {
    onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, InputProps>(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            style={{ maxWidth: "120px", textAlign: "right" }}
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange?.({ target: { value: values.floatValue ?? 0 } } as unknown as FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                >);
            }}
            thousandSeparator
        />
    );
});

export const SpendRangeSelector: React.FC<Props> = ({ range, setRange, maxRange, disabled }) => {
    const clamp = React.useCallback((number: number) => Math.max(0, Math.min(maxRange[1], number)), [maxRange]);

    return (
        <Stack gap={2} direction="row" alignItems="center">
            <TextField
                disabled={disabled}
                InputProps={{
                    inputComponent: NumericFormatCustom,
                }}
                value={range[0]}
                onChange={(event) => {
                    if (disabled) return;
                    const newMin = Number(event.target.value);
                    setRange([clamp(newMin), clamp(Math.max(range[1], newMin))]);
                }}
            />
            <Stack flexShrink={1} flexGrow={1} paddingX={2}>
                <Slider
                    disabled={disabled}
                    valueLabelDisplay="on"
                    valueLabelFormat={formatCompactNumber}
                    value={range}
                    min={maxRange[0]}
                    max={maxRange[1]}
                    onChange={(_, newRange) => {
                        if (disabled) return;
                        if (!Array.isArray(newRange)) return;
                        if (newRange.length !== 2) return;
                        const [min, max] = newRange;
                        setRange([min, max]);
                    }}
                />
            </Stack>
            <TextField
                disabled={disabled}
                InputProps={{
                    inputComponent: NumericFormatCustom,
                }}
                value={range[1]}
                onChange={(event) => {
                    if (disabled) return;
                    const newMax = Number(event.target.value);
                    setRange([clamp(Math.min(range[0], newMax)), clamp(newMax)]);
                }}
            />
        </Stack>
    );
};
