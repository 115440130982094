import { Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid-pro";

import { graphql } from "@/gql";
import { CountryCell_SupplierCountryFragment } from "@/gql/graphql";

import { TableData } from "../../utils";

import { RiskIndicator } from "./RiskIndicator";
import { SelectIconButton } from "./SelectIconButton";

interface CountryCellProps {
    params: GridRenderCellParams<
        TableData,
        CountryCell_SupplierCountryFragment | undefined,
        unknown,
        GridTreeNodeWithRender
    >;
    disabled: boolean;
}
graphql(`
    fragment CountryCell_SupplierCountry on SupplierCountry {
        id
        iso2Code
        name(language: $language)
        risk {
            score
        }
    }
`);

export const CountryCell: React.FC<CountryCellProps> = ({ params, disabled }) => {
    const { api, id, field, value } = params;

    if (!value) {
        return (
            <Stack justifyContent="flex-end" direction="row" width="100%">
                <SelectIconButton disabled={disabled} onClick={() => api.startCellEditMode({ id, field })} />
            </Stack>
        );
    }

    return (
        <Stack direction="row" width="100%" alignItems="center" flexWrap="nowrap" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" width="100%">
                <Typography variant="inherit">{value.iso2Code}</Typography>
                <RiskIndicator riskScore={value.risk?.score} />
            </Stack>
            <SelectIconButton disabled={disabled} onClick={() => api.startCellEditMode({ id, field })} />
        </Stack>
    );
};
