import { Menu, MenuItem, Stack } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";

import { DeleteModal } from "@/components/GroupPageHeader/EditGroup/DeleteModal";
import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";

interface EditMenuProps {
    groupId: string;
    isEditor: boolean;
    anchorEl: HTMLElement | null;
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
    onClose: () => void;
}

export const EditMenu: React.FC<EditMenuProps> = ({ anchorEl, groupId, setAnchorEl, onClose, isEditor }) => {
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);

    const handleModalClose = () => {
        setOpenModal(false);
        onClose();
    };

    return (
        <Stack justifyContent="center" alignItems="center" height="100%" onClick={(e) => e.stopPropagation()}>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                slotProps={{
                    paper: {
                        style: {
                            boxShadow: "none",
                        },
                    },
                }}
            >
                <NoPermissionTooltip
                    hasPermission={isEditor}
                    message={
                        <FormattedMessage defaultMessage="You must have editor permissions to perform this action." />
                    }
                >
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            setOpenModal(true);
                            setAnchorEl(null);
                        }}
                        disabled={!isEditor}
                    >
                        <FormattedMessage defaultMessage="Delete group" />
                    </MenuItem>
                </NoPermissionTooltip>
            </Menu>
            {openModal && <DeleteModal open={openModal} onClose={handleModalClose} groupId={groupId} />}
        </Stack>
    );
};
