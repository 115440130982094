import { ArrowLeft, InformationCircle } from "@ignite-analytics/icons";
import { Stack, IconButton, Typography, Box, LinearProgress, Chip, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

export const GroupPageHeader: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1} flexGrow={1}>
                <IconButton
                    sx={{ alignSelf: "start" }}
                    size="xsmall"
                    color="secondary"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <ArrowLeft />
                </IconButton>
                <Stack>
                    <Typography variant="textLg">
                        <FormattedMessage
                            defaultMessage="DemoGroup"
                            description="Group title header"
                        ></FormattedMessage>
                    </Typography>
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Group created Nov 11, 2024 (GroupID: {id})"
                            values={{ id }}
                        ></FormattedMessage>
                    </Typography>
                </Stack>
            </Stack>
            <Stack justifyContent="space-between" direction="row" alignItems="center" flexGrow={1}>
                <Stack direction="row" width="55%" alignItems="center" spacing={1.5}>
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Enrichment progress"
                            description="Risk score header"
                        ></FormattedMessage>
                    </Typography>
                    <Box width="38%">
                        <LinearProgress
                            sx={{ borderRadius: 2, height: 4 }}
                            variant="determinate"
                            color="secondary"
                            value={50}
                        />
                    </Box>
                    <Tooltip title="Share of estimate that is Ignite data vs. manual enrichments">
                        <InformationCircle fontSize="inherit" />
                    </Tooltip>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Group risk score"
                            description="Estimated risk level for group title"
                        />
                    </Typography>
                    <Chip label="Very high" color="error" size="small" variant="status" />
                    <Tooltip title="Estimated risk score for this group">
                        <InformationCircle fontSize="inherit" />
                    </Tooltip>
                </Stack>
            </Stack>
        </Stack>
    );
};
