import { SearchField as IgniteSearchField, SearchFieldProps } from "@ignite-analytics/components";
import React from "react";
import { useIntl } from "react-intl";

const SearchField = React.forwardRef<HTMLDivElement, SearchFieldProps>(function SearchField(props, ref) {
    const { formatMessage } = useIntl();

    return (
        <IgniteSearchField
            ref={ref}
            aria-label={formatMessage({
                defaultMessage: "Search",
                description: "Default aria label for search field",
            })}
            slotProps={{
                textField: {
                    placeholder: formatMessage({
                        defaultMessage: "Search",
                        description: "Default placeholder for search field",
                    }),
                },
                clearIndicator: {
                    "aria-label": formatMessage({
                        defaultMessage: "Clear search",
                        description: "Aria label for clear search icon",
                    }),
                },
            }}
            {...props}
        />
    );
});

export { SearchField };
