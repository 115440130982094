import { useQuery } from "@apollo/client";
import { ArrowLeft, InformationCircle } from "@ignite-analytics/icons";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGridPro, GridColDef, GridPaginationModel, GridSortItem, GridToolbarContainer } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SuggestedStatusChip } from "@/components/RiskStatus";
import { graphql } from "@/gql";
import { RiskScore, SortDirection, SupplierGroupFilterInput } from "@/gql/graphql";
import { formatNumber } from "@/lib/format";
import { useCompanyCurrency } from "@/providers/CompanyCurrencyContext";

import { getRiskValue } from "../GroupPageTable/Components/EstimatedRiskCell/utils";
import { GridToolbarTotalItemsContainer } from "../GroupPageTable/GridToolbarTotalItemsContainer";

import { ValidGroupButton } from "./ValidGroupButton";

import { Step } from ".";

interface Props {
    setStep: (newStep: Step) => void;
    supplierGroupFilter: SupplierGroupFilterInput;
    spendColumnID: string | undefined;
    spendColumnName: string | undefined;
    riskColumnID: string | undefined;
    missingGroupName: boolean;
    noSuppliersFound: boolean;
}

type PreviewGridSortItem = GridSortItem & { field: "name" | "spend" | "estimatedRisk" };

const PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const getSuppliersByGroupFilterQuery = graphql(`
    query PreviewGroupDialog_GetSuppliersByGroupFilter($input: GetSuppliersByGroupFilterInput!) {
        getSuppliersByGroupFilter(input: $input) {
            total
            suppliers {
                name
                spend {
                    lastAvailableYear {
                        value
                    }
                }
                risk {
                    social
                }
            }
        }
    }
`);

const PreviewTableToolbar: React.FC = () => {
    return (
        <GridToolbarContainer>
            <Stack direction="row" width="100%" alignItems="center" p={2} columnGap={1}>
                <GridToolbarTotalItemsContainer />
                {/* TODO: Name search
                <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                <GridToolbarSearchField />
                */}
            </Stack>
        </GridToolbarContainer>
    );
};

const slots = {
    toolbar: PreviewTableToolbar,
};

interface PreviewTableData {
    name?: string | null;
    spend?: number | null;
    estimatedRisk?: RiskScore | null;
}

export const PreviewGroupDialogContent: React.FC<Props> = ({
    setStep,
    supplierGroupFilter,
    spendColumnID,
    spendColumnName,
    riskColumnID,
    missingGroupName,
    noSuppliersFound,
}) => {
    const { currency } = useCompanyCurrency();
    const { formatMessage } = useIntl();
    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0 });
    const [sortModel, setSortModel] = React.useState<PreviewGridSortItem>({
        field: "spend",
        sort: "desc",
    });

    // NOTE: Had to store total rows in state instead of reading data...total directly due to it resetting to 0 on new fetches
    const [totalRows, setTotalRows] = React.useState(0);

    const gqlSort = React.useMemo(() => {
        if (!sortModel.sort) return undefined;
        const direction = sortModel.sort === "asc" ? SortDirection.Asc : SortDirection.Desc;

        let columnId: string | undefined = undefined;
        switch (sortModel.field) {
            case "name":
                columnId = "name";
                break;
            case "spend":
                if (!spendColumnID) {
                    return undefined;
                }
                columnId = spendColumnID;
                break;
            case "estimatedRisk":
                if (!riskColumnID) {
                    return undefined;
                }
                columnId = riskColumnID;
                break;
            default:
                sortModel.field satisfies never;
                break;
        }
        if (columnId === undefined) return undefined; // FIXME: Shouldn't need this, but typescript is upset :^(

        return {
            columnId,
            direction,
        };
    }, [sortModel, spendColumnID, riskColumnID]);

    const { data, loading } = useQuery(getSuppliersByGroupFilterQuery, {
        variables: {
            input: {
                filter: supplierGroupFilter,

                pageIndex: paginationModel.page * paginationModel.pageSize,
                pageRange: paginationModel.pageSize,
                sort: gqlSort,
            },
        },
        onCompleted: (data) => {
            setTotalRows(data.getSuppliersByGroupFilter.total);
        },
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to get suppliers in group for preview" },
            });
        },
    });

    const supplierRows = React.useMemo(() => {
        if (!data) return [];
        return data.getSuppliersByGroupFilter.suppliers.map((supplier, index) => ({
            id: index,
            name: supplier.name,
            spend: supplier.spend?.lastAvailableYear?.value,
            estimatedRisk: supplier.risk?.social,
        }));
    }, [data]);

    const columns: GridColDef<PreviewTableData>[] = React.useMemo(() => {
        const spendColumnDefinition: GridColDef<PreviewTableData>[] = spendColumnName
            ? [
                  {
                      field: "spend",
                      headerName: `${spendColumnName} (${currency})`,
                      flex: 1,
                      minWidth: 180,
                      renderCell: (params) => {
                          if (params.value === undefined || params.value === null || typeof params.value !== "number") {
                              return null;
                          }
                          return (
                              <Stack justifyContent="center" alignItems="flex-end" height="100%">
                                  <Typography variant="textSm">{formatNumber(params.value)}</Typography>
                              </Stack>
                          );
                      },
                  },
              ]
            : [];

        return [
            {
                field: "name",
                headerName: formatMessage({
                    defaultMessage: "Supplier name",
                    description: "Preview: Supplier column header",
                }),
                flex: 1,
                minWidth: 160,
                renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
            },
            ...spendColumnDefinition,
            {
                field: "estimatedRisk",
                renderHeader: () => (
                    <Stack direction="row" justifyContent="center" alignItems="center" gap={0.5}>
                        <Typography variant="textSm" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Estimated risk score"
                                description="Preview: Risk column header"
                            />
                        </Typography>
                        <Tooltip
                            title={formatMessage({
                                defaultMessage:
                                    "Your estimated risk score is based on Ignite data and your added enrichments.",
                                description: "Preview: Risk column header tooltip",
                            })}
                        >
                            <InformationCircle />
                        </Tooltip>
                    </Stack>
                ),
                flex: 1,
                minWidth: 160,
                renderCell: (params) => {
                    return (
                        <Stack justifyContent="center" alignItems="center" height="100%">
                            <SuggestedStatusChip size="small" status={getRiskValue(params.value)} />
                        </Stack>
                    );
                },
            },
        ];
    }, [currency, spendColumnName, formatMessage]);

    return (
        <>
            <DialogTitle component={Stack} direction="row" spacing={1.5} alignItems="center">
                <IconButton aria-label="back" onClick={() => setStep("filter")}>
                    <ArrowLeft />
                </IconButton>
                <Typography variant="textLg" fontWeight={500}>
                    <FormattedMessage defaultMessage="Preview" description="Preview step modal title" />
                </Typography>
            </DialogTitle>
            <div /> {/* Hack to get the padding of the dialogcontent to appear */}
            <DialogContent>
                <DataGridPro
                    rows={supplierRows}
                    columns={columns}
                    rowCount={totalRows}
                    loading={loading}
                    paginationMode="server"
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    paginationModel={paginationModel}
                    onPaginationModelChange={React.useCallback(
                        (newModel: GridPaginationModel) => setPaginationModel(newModel),
                        []
                    )}
                    onSortModelChange={React.useCallback((newModel: GridSortItem[]) => {
                        if (newModel.length === 0) {
                            setSortModel({ field: "name", sort: undefined });
                            return;
                        }
                        setSortModel(newModel[0] as PreviewGridSortItem);
                    }, [])}
                    sortModel={React.useMemo(() => [sortModel], [sortModel])}
                    slots={slots}
                    disableRowSelectionOnClick
                    disableColumnReorder
                    disableColumnResize
                    disableColumnPinning
                    disableColumnMenu
                    columnGroupHeaderHeight={28}
                    pagination
                />
            </DialogContent>
            <DialogActions>
                <Stack justifyContent="flex-end" alignItems="center" direction="row" gap={1.5}>
                    <Button color="secondary" onClick={() => setStep("filter")} size="small">
                        <FormattedMessage defaultMessage="Back" description="Preview supplier group 'Back' button" />
                    </Button>
                    <ValidGroupButton
                        missingGroupName={missingGroupName}
                        noSuppliersFound={noSuppliersFound}
                        onClick={() => setStep("column")}
                    >
                        <FormattedMessage
                            defaultMessage="Continue"
                            description="Preview supplier group 'Continue' button"
                        />
                    </ValidGroupButton>
                </Stack>
            </DialogActions>
        </>
    );
};
