import { CircleSolid, InformationCircle } from "@ignite-analytics/icons";
import { Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { normalizeProgressValues } from "./utils";

export const OverallProgress: React.FC<{
    manualEnrichmentProgress: number;
    igniteEnrichmentProgress: number;
    emptyEnrichmentProgress: number;
}> = ({ manualEnrichmentProgress, igniteEnrichmentProgress, emptyEnrichmentProgress }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const label = formatMessage({ defaultMessage: "Total Progress" });
    const igniteEstimates = formatMessage({
        defaultMessage: "Ignite risk assessment",
        description: "Ignite risk assessment distribution chart label",
    });
    const manualEnrichments = formatMessage({
        defaultMessage: "Your risk assessment",
        description: "Your risk assessment distribution chart label",
    });
    const missingDataLabel = formatMessage({
        defaultMessage: "Missing risk assessment",
        description: "Missing risk assessment distribution chart label",
    });

    const { formattedIgniteEnrichmentProgress, formattedManualEnrichmentProgress, formattedEmptyEnrichmentProgress } =
        normalizeProgressValues(igniteEnrichmentProgress, manualEnrichmentProgress, emptyEnrichmentProgress);

    const data = [
        {
            name: label,
            [igniteEstimates]: formattedIgniteEnrichmentProgress,
            [manualEnrichments]: formattedManualEnrichmentProgress,
            [missingDataLabel]: formattedEmptyEnrichmentProgress,
        },
    ];

    return (
        <Paper sx={{ padding: 3, height: 223 }}>
            <Stack height="100%" width="100%" justifyContent="space-between">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Estimated risk is based on"
                            description="Estimated risk distribution card title"
                        />
                    </Typography>
                    <Tooltip
                        title={
                            <FormattedMessage
                                defaultMessage="Your estimated risk score is based on Ignite data and your risk assessments."
                                description="Estimated risk distribution card info tooltip"
                            />
                        }
                    >
                        <InformationCircle />
                    </Tooltip>
                </Stack>
                <ResponsiveContainer maxHeight={40}>
                    <BarChart maxBarSize={5} data={data} layout="vertical">
                        <XAxis type="number" hide />
                        <YAxis type="category" dataKey="name" hide />
                        <Bar
                            dataKey={igniteEstimates}
                            stackId={label}
                            fill={theme.palette.tokens?.icon.iconAccent}
                            radius={[2, 0, 0, 2]}
                        />
                        <ReferenceLine x={formattedIgniteEnrichmentProgress} stroke="black" strokeDasharray="3 3" />
                        <Bar
                            dataKey={manualEnrichments}
                            stackId={label}
                            fill={theme.palette.tokens?.icon.iconSuccess}
                        />
                        <ReferenceLine
                            x={formattedManualEnrichmentProgress + formattedIgniteEnrichmentProgress}
                            stroke="black"
                            strokeDasharray="3 3"
                        />
                        <Bar
                            dataKey={missingDataLabel}
                            stackId={label}
                            fill={theme.palette.tokens?.progressBar.trackBackground1}
                            radius={[0, 2, 2, 0]}
                        />
                    </BarChart>
                </ResponsiveContainer>
                <Stack spacing={1} padding={1} width="100%">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <CircleSolid
                                sx={{ color: (theme) => theme.palette.tokens?.icon.iconAccent }}
                                fontSize="small"
                            />
                            <Typography variant="textSm">{igniteEstimates}</Typography>
                        </Stack>
                        <Typography variant="textSm">{formattedIgniteEnrichmentProgress + "%"}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <CircleSolid
                                sx={{ color: (theme) => theme.palette.tokens?.icon.iconSuccess }}
                                fontSize="small"
                            />
                            <Typography variant="textSm">{manualEnrichments}</Typography>
                        </Stack>
                        <Typography variant="textSm">{formattedManualEnrichmentProgress + "%"}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <CircleSolid
                                sx={{ color: (theme) => theme.palette.tokens?.progressBar.trackBackground1 }}
                                fontSize="small"
                            />
                            <Typography variant="textSm">{missingDataLabel}</Typography>
                        </Stack>
                        <Typography variant="textSm">{formattedEmptyEnrichmentProgress + "%"}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};
