import { Chip, ChipProps } from "@mui/material";

export type SuggestedStatusLevel = "verylow" | "low" | "medium" | "high" | "veryhigh";

interface SuggestedStatusChipProps extends ChipProps {
    status: SuggestedStatusLevel;
    // adjusted: boolean;   // (possibly 'overridden' instead) - "solidifies" the chip
}

export const SuggestedStatusChip = ({ status, ...props }: SuggestedStatusChipProps) => {
    const getLevelColor = (status: SuggestedStatusLevel) => {
        switch (status) {
            case "verylow":
                return "success";
            case "low":
                return "success";
            case "medium":
                return "warning";
            case "high":
                return "error";
            case "veryhigh":
                return "error";
        }
    };

    const getLevelText = (status: SuggestedStatusLevel) => {
        switch (status) {
            case "verylow":
                return "Very low";
            case "low":
                return "Low";
            case "medium":
                return "Medium";
            case "high":
                return "High";
            case "veryhigh":
                return "Very high";
        }
    };

    return (
        <Chip
            label={getLevelText(status)}
            variant="status"
            color={getLevelColor(status)}
            sx={{ border: "1px, dashed" }}
            {...props}
        />
    );
};
