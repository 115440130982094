import { useMutation, useQuery } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { GroupPageTable_SupplierGroupFragment, NewCampaignDialog_QuestionnaireFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";
import { useAlert } from "@/providers";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

const getQuestionnairesDocument = graphql(`
    query NewCampaignDialog_GetQuestionnaires {
        getQuestionnaires {
            questionnaires {
                ...NewCampaignDialog_Questionnaire
            }
        }
    }
`);

const createCampaignFromGroupDocument = graphql(`
    mutation CreateCampaignDialog_CreateCampaignFromGroup($input: CreateCampaignFromGroupInput!) {
        createCampaignFromGroup(input: $input) {
            campaignId
        }
    }
`);

graphql(`
    fragment NewCampaignDialog_Questionnaire on Questionnaire {
        id
        name
        status
    }
`);

interface CampaignCreationDialogProps {
    open: boolean;
    onClose: () => void;
    group: GroupPageTable_SupplierGroupFragment;
}

export const CampaignCreationDialog: React.FC<CampaignCreationDialogProps> = ({ open, onClose, group }) => {
    const navigate = useOutgoingNavigate();
    const { alertUser } = useAlert();
    const [error, setError] = React.useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState<
        NewCampaignDialog_QuestionnaireFragment | undefined
    >(undefined);
    const { formatMessage } = useIntl();

    const { data } = useQuery(getQuestionnairesDocument, {
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get questionnaires",
                    description: "Alert message when questionnaires fail to load",
                }),
                severity: "error",
            });
        },
    });
    const allQuestionnaires = data?.getQuestionnaires.questionnaires;

    const [createExternalCampaign] = useMutation(createCampaignFromGroupDocument, {
        onCompleted: (res) => {
            // consider some user success message before redirection
            navigate(`/assessments/campaigns/${res.createCampaignFromGroup.campaignId}/`);
        },
        onError: () =>
            alertUser({
                value: formatMessage({
                    defaultMessage: "An error occurred when creating the campaign.",
                    description: "Alert message when creating a campaign fails",
                }),
                severity: "error",
            }),
    });

    const createCampaign = () => {
        if (!selectedQuestionnaire || !selectedQuestionnaire.id) {
            setError(true);
        } else {
            setError(false);
            track(`${TRACK_PREFIX}: Invited to Campaign`);
            createExternalCampaign({
                variables: {
                    input: {
                        questionnaireId: selectedQuestionnaire.id,
                        groupId: group.id,
                    },
                },
            });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle sx={{ paddingBottom: 1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <FormattedMessage defaultMessage="Create campaign" />
                    <IconButton onClick={onClose}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3} width={450}>
                    <Divider />
                    <TextField
                        label={<FormattedMessage defaultMessage="Campaign name" description="Campaign name input" />}
                        fullWidth
                        size="small"
                        value={group.name}
                        disabled
                        required
                        helperText={<FormattedMessage defaultMessage="Automatically set to this group's name" />}
                    />
                    <Divider />
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Questionnaire" description="Select questionnaire title" />
                    </Typography>
                    <FormControl error={error && !selectedQuestionnaire} size="small">
                        <Select
                            value={selectedQuestionnaire?.id || ""}
                            onChange={(event) => {
                                setSelectedQuestionnaire(
                                    allQuestionnaires?.find(
                                        (questionnaire: { id: string }) => questionnaire.id === event.target.value
                                    )
                                );
                            }}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                <FormattedMessage
                                    defaultMessage="Select questionnaire"
                                    description="Questionnaire selection placeholder"
                                />
                            </MenuItem>
                            {allQuestionnaires
                                ?.filter(
                                    (questionnaire: NewCampaignDialog_QuestionnaireFragment) =>
                                        questionnaire.status != "archived"
                                )
                                ?.map((questionnaire: NewCampaignDialog_QuestionnaireFragment) => {
                                    return (
                                        <MenuItem value={questionnaire.id} key={questionnaire.id}>
                                            {questionnaire.name}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        {error && !selectedQuestionnaire && (
                            <FormHelperText>
                                <FormattedMessage
                                    defaultMessage="Select questionnaire"
                                    description="Questionnaire selection placeholder"
                                />
                            </FormHelperText>
                        )}
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button color="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Close" />
                    </Button>
                    <Button color="primary" onClick={createCampaign}>
                        <FormattedMessage defaultMessage="Next" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
