import { Chip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";

import { SuggestedStatusChip } from "@/components/SuggestedStatusChip";

export const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Supplier name",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "spend",
        headerName: "Spend (Currency)",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "country",
        headerName: "Country",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "productionCountry",
        headerName: "Production country",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "industry",
        headerName: "Industry",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "assessmentStatus",
        headerName: "Assessment status",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "score",
        headerName: "Assessments score",
        flex: 1,
        renderCell: (params) => <Chip label={params.value} size="small" />,
    },
    {
        field: "codeOfConduct",
        headerName: "Code of conduct",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "estimatedRisk",
        headerName: "Estimated risk score",
        flex: 1,
        renderCell: (params) => <SuggestedStatusChip size="small" status={params.value} />,
    },
];
