import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface RiskReasonModalProps {
    open: boolean;
    onClose: () => void;
    type: "approve" | "change";
    onChange: (reason: string) => void;
}

export const RiskReasonModal: React.FC<RiskReasonModalProps> = ({ open, onClose, type, onChange }) => {
    const [reason, setReason] = React.useState("");
    const { formatMessage } = useIntl();

    const handleSave = () => {
        onChange(reason);
        setReason("");
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Stack spacing={3}>
                    <Typography variant="textLg">
                        {type === "approve" ? (
                            <FormattedMessage defaultMessage="Approve estimated risk score" />
                        ) : (
                            <FormattedMessage defaultMessage="Change estimated risk score" />
                        )}
                    </Typography>
                    <Divider />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1} paddingTop={3}>
                    <Typography variant="textSm" fontWeight={500}>
                        {type === "approve" ? (
                            <FormattedMessage defaultMessage="Reason for approval" />
                        ) : (
                            <FormattedMessage defaultMessage="Reason for change" />
                        )}
                    </Typography>
                    <TextField
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        sx={{ "& .MuiInputBase-root": { padding: 0 } }}
                        variant="outlined"
                        placeholder={formatMessage({ defaultMessage: "Enter reason" })}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ paddingTop: 0 }}>
                <Stack direction="row" spacing={2} width="100%" alignItems="center">
                    <Button onClick={onClose} color="secondary" size="small" fullWidth>
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button onClick={handleSave} color="primary" size="small" fullWidth>
                        {type === "approve" ? (
                            <FormattedMessage defaultMessage="Approve status" />
                        ) : (
                            <FormattedMessage defaultMessage="Change status" />
                        )}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
