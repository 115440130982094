import { CircleSolid } from "@ignite-analytics/icons";
import { Autocomplete, InputAdornment, Stack, TextField, Typography, useTheme } from "@mui/material";
import React from "react";

import { NACEOptions } from "@/lib/data/NaceOptions";
import { RiskOption } from "@/lib/data/types";
import { getLabel } from "@/lib/data/utils";
import { track, TRACK_PREFIX } from "@/lib/track";

interface IndustryAutocompleteProps {
    value: string;
    onChange: (columnName: string, newValue: string | undefined) => void;
    isEditor: boolean;
}

export const IndustryAutocomplete: React.FC<IndustryAutocompleteProps> = ({ value, onChange, isEditor }) => {
    const theme = useTheme();
    const handleChange = (_event: React.SyntheticEvent<Element, Event>, newValue: RiskOption | null) => {
        track(`${TRACK_PREFIX}: Updated Industry`, { initialValue: value, newValue }); // TODO metric naming
        onChange("industry", newValue?.code);
    };
    const options = NACEOptions;
    const getColors = (value: string) => {
        switch (value) {
            case "low":
                return theme.palette.tokens?.icon.iconSuccess;
            case "medium":
                return theme.palette.tokens?.icon.iconWarning;
            case "high":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    };

    return (
        <Autocomplete
            size="small"
            options={options}
            value={options.find((option) => option.code === value) ?? null}
            fullWidth
            disabled={!isEditor}
            getOptionLabel={getLabel}
            renderOption={(props, option) => (
                <Stack component="li" {...props} direction="row" spacing={1}>
                    <Typography variant="textSm">{getLabel(option)}</Typography>
                </Stack>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start" sx={{ fontSize: "8px" }}>
                                    <CircleSolid
                                        fontSize="inherit"
                                        sx={{
                                            color: getColors?.(
                                                options.find(
                                                    (o) =>
                                                        params.inputProps.value &&
                                                        typeof params.inputProps.value === "string" &&
                                                        o.code === params.inputProps.value.split(":")[0]
                                                )?.score ?? ""
                                            ),
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            )}
            onChange={handleChange}
        />
    );
};
