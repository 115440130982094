import { useMutation } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { DotsHorizontal } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { SupplierDrawer } from "@/components/SupplierDrawer";
import { graphql } from "@/gql";
import { EditMenu_SupplierFragment } from "@/gql/graphql";
import { TRACK_PREFIX } from "@/lib/track";
import { useAlert } from "@/providers";

import { DeleteModal } from "./DeleteModal";

const removeSupplierMutation = graphql(`
    mutation RemoveSupplierFromGroup($input: RemoveSuppliersFromGroupInput!) {
        removeSuppliersFromGroup(input: $input) {
            deletedIds
        }
    }
    fragment EditMenu_Supplier on Supplier {
        id
        ...SupplierDrawer_Supplier
    }
`);

interface EditMenuProps {
    supplier: EditMenu_SupplierFragment;
    groupId: string;
    isEditor: boolean;
    campaignId: string | null | undefined;
}
export const EditMenu: React.FC<EditMenuProps> = ({ supplier, groupId, isEditor, campaignId }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [removeSupplier] = useMutation(removeSupplierMutation);
    const { formatMessage } = useIntl();
    const alert = useAlert();
    const developmentToggle = useFeatureToggle("social-risk-development", false);

    const handleDelete = () => {
        track(`${TRACK_PREFIX}: Removed Supplier From Group`);
        removeSupplier({
            variables: {
                input: {
                    groupId: groupId,
                    ids: [supplier.id],
                },
            },
            refetchQueries: ["GroupPage_GetGroup"],
            onError: (error) => {
                alert.alertUser({
                    title: formatMessage({
                        defaultMessage: "Failed to remove supplier",
                        description: "Error message for failed removing of supplier",
                    }),
                    value: formatMessage({
                        defaultMessage: "Try again or contact support",
                        description: "Error message for failed removing of supplier",
                    }),
                    severity: "error",
                });
                reportError(error);
            },
        });
        handleClose();
    };

    const handleRemove = () => {
        if (campaignId) {
            setOpenModal(true);
            setAnchorEl(null);
            return;
        }
        handleDelete();
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenModal(false);
    };

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
        setAnchorEl(null);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    return (
        <Stack justifyContent="center" alignItems="center" height="100%">
            <IconButton size="small" onClick={handleMenuOpen}>
                <DotsHorizontal />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <NoPermissionTooltip
                    hasPermission={isEditor}
                    message={
                        <FormattedMessage defaultMessage="You must have editor permissions to perform this action." />
                    }
                >
                    <MenuItem onClick={handleRemove} disabled={!isEditor}>
                        <FormattedMessage defaultMessage="Remove from list" />
                    </MenuItem>
                </NoPermissionTooltip>
                {developmentToggle && (
                    <MenuItem onClick={handleDrawerOpen}>
                        <FormattedMessage defaultMessage="View supplier" />
                    </MenuItem>
                )}
            </Menu>
            {openModal && <DeleteModal open={openModal} onClose={handleClose} onDelete={handleDelete} />}
            {openDrawer && <SupplierDrawer open={openDrawer} onClose={handleDrawerClose} supplier={supplier} />}
        </Stack>
    );
};
