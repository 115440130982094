import { Chip, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { RiskEstimate } from "@/gql/graphql";

interface EstimatedRiskScoreProps {
    level: RiskEstimate | undefined;
    loading: boolean;
}

const getRiskEstimateLabel = (level: RiskEstimate | undefined): React.ReactNode => {
    switch (level) {
        case RiskEstimate.Missing:
            return <FormattedMessage defaultMessage="Missing" description="Risk estimate missing" />;
        case RiskEstimate.VeryLow:
            return <FormattedMessage defaultMessage="Very Low" description="Risk estimate very low" />;
        case RiskEstimate.Low:
            return <FormattedMessage defaultMessage="Low" description="Risk estimate low" />;
        case RiskEstimate.Medium:
            return <FormattedMessage defaultMessage="Medium" description="Risk estimate medium" />;
        case RiskEstimate.High:
            return <FormattedMessage defaultMessage="High" description="Risk estimate high" />;
        case RiskEstimate.VeryHigh:
            return <FormattedMessage defaultMessage="Very High" description="Risk estimate very high" />;
        case undefined:
            return <FormattedMessage defaultMessage="Unknown" description="Risk estimate unknown" />;
        default:
            level satisfies never;
    }
};

export const EstimatedRiskScore: React.FC<EstimatedRiskScoreProps> = ({ level, loading }) => {
    const { formatMessage } = useIntl();
    return (
        <Paper sx={{ height: 223, borderWidth: 1 }}>
            <Stack padding={3} alignContent="center" justifyContent="space-between" height="100%">
                <Typography variant="textSm" fontWeight={500}>
                    <FormattedMessage
                        defaultMessage="Estimated risk score across all suppliers"
                        description="Estimated risk score title"
                    />
                </Typography>
                {loading ? (
                    <Skeleton width="20%" height="44px" variant="text" />
                ) : (
                    <Typography variant="displayMd" fontWeight={500}>
                        {getRiskEstimateLabel(level)}
                    </Typography>
                )}
                <Stack spacing={1}>
                    <Typography variant="textXs" color="text.text-helper">
                        <FormattedMessage
                            defaultMessage="Based on"
                            description="Estimated risk score basis help text"
                        />
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            size="small"
                            label={formatMessage({
                                defaultMessage: "Country",
                                description: "Risk estimate description chip: Country ",
                            })}
                        />
                        <Chip
                            size="small"
                            label={formatMessage({
                                defaultMessage: "Production countries",
                                description: "Risk estimate description chip: Production countries",
                            })}
                        />
                        <Chip
                            size="small"
                            label={formatMessage({
                                defaultMessage: "Industry",
                                description: "Risk estimate description chip: Industry ",
                            })}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};
