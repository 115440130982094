import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Plus } from "@ignite-analytics/icons";
import { Button, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ColumnType, SupplierTableColumn } from "@/gql/graphql";

import { FilterLine } from "./FilterLine";
import { Filter, FilterInput } from "./types";

interface FilterProps {
    columns: SupplierTableColumn[];
    setFilterInput: (filterInput: FilterInput[]) => void;
    filterInput: FilterInput[];
    hideButton: ReactNode;
}

const parseDate = (date: string | null) => {
    // Check if date is a valid time string

    if (date && !isNaN(Date.parse(date))) {
        return new Date(date).toISOString().split("T")[0];
    }

    return null;
};

const generateRandomID = () => {
    return Math.random().toString(36).slice(2, 9);
};

const newFilter = (): Filter => {
    return {
        id: generateRandomID(),
        type: null,
        column: null,
        includeExcludeValues: [],
        minDate: null,
        maxDate: null,
        hasChanged: false,
    };
};

const loadFilter = (filter: FilterInput[] | undefined, columns: SupplierTableColumn[]): Filter[] => {
    if (!filter || filter.length == 0) {
        return [newFilter()];
    }
    return filter.map((f) => ({
        id: generateRandomID(),
        type: f.type,
        column: columns.find((c) => c.id === f.column_id) ?? null,
        includeExcludeValues: [
            ...f.include_exclude_values,
            ...((f.type == "include" && f.include_blanks) || (f.type == "exclude" && !f.include_blanks)
                ? ["Empty fields"]
                : []),
        ],
        minRange: String(f.min_range),
        maxRange: String(f.max_range),
        minDate: parseDate(f.min_date),
        maxDate: parseDate(f.max_date),
        hasChanged: false,
    }));
};
const isValidFilter = (filter: Filter) => {
    if (!filter?.column?.id) {
        return false;
    }
    if (filter.type === "include" || filter.type === "exclude") {
        return !!filter.includeExcludeValues.length;
    } else if (filter.type === "range") {
        return !!filter.minRange || !!filter.maxRange;
    } else if (filter.type === "date") {
        return !!filter.minDate || !!filter.maxDate;
    }

    return false;
};

function isFilterableColumns(column: SupplierTableColumn) {
    const nonFilterableIds = ["ONBOARDING_COLUMN", "ONBOARDING_APPROVER_COLUMN", "normalized_into"];
    const filterableTypes = [
        "DATE",
        "AGGREGATION",
        "NUMBER",
        "SELECT",
        "TEXT",
        "CLASSIFICATION",
        "RISK",
        "NACE",
        "ASSESSMENT_SCORE",
        "ASSESSMENT_STATUS",
    ];

    if (nonFilterableIds.includes(column.id)) {
        return false;
    }

    return filterableTypes.includes(column.type);
}

const onboardingStatusColumn: SupplierTableColumn = {
    globalType: "",
    id: "onboarding.status",
    name: "Onboarding status",
    type: ColumnType.Text,
    __typename: "SupplierTableColumn",
};

export const InlineFilter: React.FC<FilterProps> = ({ columns, filterInput, setFilterInput, hideButton }) => {
    const onboardingEnabled = useFeatureToggle("onboarding-enabled", true);
    const { formatMessage } = useIntl();

    const [filters, setFilters] = useState<Filter[]>(() => loadFilter(filterInput, columns));

    const filterableColumns = useMemo(() => {
        return onboardingEnabled
            ? [...columns.filter((column) => isFilterableColumns(column)), onboardingStatusColumn]
            : columns.filter((column) => isFilterableColumns(column));
    }, [columns, onboardingEnabled]);

    const createFilterJSONString = useCallback(
        (filters: Filter[]): FilterInput[] => {
            let updatedFilters: Filter[] = [];
            const f = filters
                .filter((filter) => {
                    const valid = isValidFilter(filter);
                    if (!valid) {
                        updatedFilters = [...updatedFilters, filter];
                    }
                    return valid;
                })
                .map((filter) => {
                    updatedFilters = [...updatedFilters, { ...filter, hasChanged: false }];
                    // include blanks is true if the empty field value is in the includeExcludeValues array
                    const userSelectedEmptyFields = filter.includeExcludeValues.includes(
                        formatMessage({ defaultMessage: "Empty fields" })
                    );
                    return {
                        type: filter.type,
                        column_id: filter.column?.id ?? "",
                        include_exclude_values: filter.includeExcludeValues.filter(
                            (v) => v !== formatMessage({ defaultMessage: "Empty fields" })
                        ),
                        min_range: filter.minRange ? Number(filter.minRange) : null,
                        max_range: filter.maxRange ? Number(filter.maxRange) : null,
                        min_date: parseDate(filter.minDate),
                        max_date: parseDate(filter.maxDate),
                        include_blanks:
                            filter.includeBlanks !== undefined
                                ? filter.includeBlanks
                                : filter.type == "exclude"
                                  ? !userSelectedEmptyFields
                                  : userSelectedEmptyFields,
                    };
                });
            setFilters([...updatedFilters]);
            return f;
        },
        [formatMessage]
    );

    const handleAddFilter = () => {
        const newFilters = [...filters, newFilter()];
        setFilters([...newFilters]);
    };

    const handleUpdateFilter = (filter: Filter) => {
        const updatedFilters = filters.map((f) => {
            if (f.id === filter.id) {
                return { ...filter, hasChanged: true };
            }
            return f;
        });
        setFilterInput(createFilterJSONString(updatedFilters));
        setFilters([...updatedFilters]);
    };

    const handleDeleteFilter = (id: string) => {
        const updatedFilters = filters.filter((f) => f.id !== id);
        setFilters([...updatedFilters]);
        setFilterInput(createFilterJSONString(updatedFilters));
    };

    const handleClearFilters = () => {
        setFilters([newFilter()]);
        setFilterInput([]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="column" spacing={2} justifyContent="space-between" height="100%">
                <Stack direction="column" gap={2}>
                    <Stack direction="row" justifyContent="end" alignItems="center">
                        <Button
                            disabled={filterInput.length === 0}
                            onClick={handleClearFilters}
                            color="linkGray"
                            size="small"
                        >
                            <FormattedMessage defaultMessage="Clear all filters" description="Clear Filters button" />
                        </Button>
                    </Stack>
                    {filters.map((filter) => (
                        <FilterLine
                            filter={filter}
                            key={"filter-line-" + filter.id}
                            columns={filterableColumns}
                            handleUpdateFilter={handleUpdateFilter}
                            handleDeleteFilter={handleDeleteFilter}
                        />
                    ))}
                    <Stack direction="row" justifyContent="start">
                        <Button
                            color="linkGray"
                            size="small"
                            startIcon={<Plus fontSize="small" />}
                            onClick={handleAddFilter}
                        >
                            <FormattedMessage defaultMessage="Add filter" description="Add filter button" />
                        </Button>
                    </Stack>
                </Stack>
                <Stack justifyContent="space-between" direction="row" gap={2}>
                    {hideButton}
                </Stack>
            </Stack>
        </LocalizationProvider>
    );
};
