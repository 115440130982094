import { ReactNode, createContext, useContext } from "react";
import { NavigateFunction, useNavigate as useDevNavigate } from "react-router-dom";

type Props = { children: ReactNode; navigateFunc?: NavigateFunction };

interface ContextValue {
    navigateFunc: NavigateFunction;
}

export const navigationContext = createContext<ContextValue | null>(null);

export const NavigationContextProvider: React.FC<Props> = ({ children, navigateFunc }) => {
    const devNavigate = useDevNavigate();
    return (
        <navigationContext.Provider value={{ navigateFunc: navigateFunc ?? devNavigate }}>
            {children}
        </navigationContext.Provider>
    );
};
export const useOutgoingNavigate = (): NavigateFunction => {
    const context = useContext(navigationContext);
    if (!context) {
        throw new Error("useNavigate must be used within a NavigationContextProvider");
    }
    return context.navigateFunc;
};
