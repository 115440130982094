import { Company, DotsHorizontal, InformationCircle, LocationPin, Tag } from "@ignite-analytics/icons";
import {
    Autocomplete,
    Grid2,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { EstimatedRiskCard_SupplierFragment } from "@/gql/graphql";

import { getRiskValue } from "../GroupPageTable/Components/EstimatedRiskCell/utils";
import { countryCodes } from "../GroupPageTable/Components/values/CountryOptions";
import { RiskChange } from "../RiskStatus";

import { InfoRow } from "./InfoRow";

graphql(`
    fragment EstimatedRiskCard_Supplier on Supplier {
        id
        name
        country
        productionCountries
        nace
        onboarding {
            status
        }
        risk {
            social
        }
        notes {
            id
            type
            metadata {
                prevValue
                newValue
            }
        }
    }
`);

interface EstimatedRiskCardProps {
    supplier: EstimatedRiskCard_SupplierFragment;
}

export const EstimatedRiskCard: React.FC<EstimatedRiskCardProps> = ({ supplier }) => {
    const { formatMessage } = useIntl();
    const [riskAnchorEl, setRiskAnchorEl] = useState<null | HTMLElement>(null);

    const handleRiskMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setRiskAnchorEl(event.currentTarget);
    };

    const handleRiskMenuClose = () => {
        setRiskAnchorEl(null);
    };

    const riskNote = supplier.notes.find((n) => n.type === "risk");
    const prevVal = riskNote && riskNote.metadata?.prevValue ? getRiskValue(riskNote.metadata?.prevValue) : undefined;
    const newVal = riskNote && riskNote.metadata?.newValue ? getRiskValue(riskNote.metadata?.newValue) : undefined;
    const cc = countryCodes.map((c) => c.code);
    return (
        <Paper>
            <Stack padding={3} spacing={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Estimated risk score" />
                        </Typography>
                        <Tooltip
                            title={formatMessage({
                                defaultMessage: "TOOLTIP PLACEHOLDER",
                                description: "PLACEHOLDER",
                            })}
                        >
                            <InformationCircle fontSize="inherit" />
                        </Tooltip>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <RiskChange prevVal={prevVal} newVal={newVal} socialRisk={supplier.risk?.social ?? undefined} />
                        <IconButton color="secondary" onClick={handleRiskMenuOpen}>
                            <DotsHorizontal />
                        </IconButton>
                    </Stack>
                    {riskAnchorEl && (
                        <Menu open anchorEl={riskAnchorEl}>
                            <MenuItem onClick={handleRiskMenuClose}>Edit</MenuItem>
                            <MenuItem onClick={handleRiskMenuClose}>Delete</MenuItem>
                        </Menu>
                    )}
                </Stack>
                <Grid2 container spacing={3} alignItems="center">
                    <InfoRow
                        icon={<LocationPin />}
                        name="Country"
                        infoComponent={
                            <Tooltip title={formatMessage({ defaultMessage: "placeholder" })}>
                                <InformationCircle />
                            </Tooltip>
                        }
                        valueComponent={
                            <Autocomplete
                                options={cc}
                                renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                            />
                        }
                    />
                    <InfoRow
                        icon={<Tag />}
                        name="Production countries"
                        infoComponent={
                            <Tooltip title={formatMessage({ defaultMessage: "placeholder" })}>
                                <InformationCircle />
                            </Tooltip>
                        }
                        valueComponent={
                            <Autocomplete
                                options={cc}
                                renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                            />
                        }
                    />
                    <InfoRow
                        icon={<Company />}
                        name="Country"
                        infoComponent={
                            <Tooltip title={formatMessage({ defaultMessage: "placeholder" })}>
                                <InformationCircle />
                            </Tooltip>
                        }
                        valueComponent={
                            <Autocomplete
                                options={cc}
                                renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                            />
                        }
                    />
                </Grid2>
            </Stack>
        </Paper>
    );
};
