import { Stack, Tooltip, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { CustomTooltip } from "./CustomTooltip";
import { normalizeProgressValues } from "./utils";

export const GroupProgress: React.FC<{
    manualEnrichmentProgress: number;
    igniteEnrichmentProgress: number;
    emptyEnrichmentProgress: number;
    cursor?: CSSStyleDeclaration["cursor"];
}> = ({ manualEnrichmentProgress, igniteEnrichmentProgress, emptyEnrichmentProgress, cursor }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const label = formatMessage({ defaultMessage: "Total Progress" });
    const igniteEstimates = formatMessage({
        defaultMessage: "Ignite risk assessment",
        description: "Ignite risk assessment distribution chart label",
    });
    const manualEnrichments = formatMessage({
        defaultMessage: "Your risk assessment",
        description: "Your risk assessment distribution chart label",
    });
    const missingDataLabel = formatMessage({
        defaultMessage: "Missing risk assessment",
        description: "Missing risk assessment distribution chart label",
    });

    const { formattedIgniteEnrichmentProgress, formattedManualEnrichmentProgress, formattedEmptyEnrichmentProgress } =
        normalizeProgressValues(igniteEnrichmentProgress, manualEnrichmentProgress, emptyEnrichmentProgress);

    const data = [
        {
            name: label,
            [igniteEstimates]: formattedIgniteEnrichmentProgress,
            [manualEnrichments]: formattedManualEnrichmentProgress,
            [missingDataLabel]: formattedEmptyEnrichmentProgress,
        },
    ];

    const style = cursor ? { cursor } : {};

    return (
        <Tooltip
            followCursor={true}
            title={
                <CustomTooltip
                    igniteEnrichment={formattedIgniteEnrichmentProgress}
                    manualEnrichment={formattedManualEnrichmentProgress}
                    missing={formattedEmptyEnrichmentProgress}
                />
            }
        >
            <Stack height={40} width="100%" maxWidth={400} justifyContent="center" spacing={0.2}>
                <ResponsiveContainer height={25}>
                    <BarChart maxBarSize={4} data={data} layout="vertical" style={style}>
                        <XAxis type="number" hide />
                        <YAxis type="category" dataKey="name" hide />
                        <Bar
                            dataKey={igniteEstimates}
                            stackId={label}
                            fill={theme.palette.tokens?.icon.iconAccent}
                            radius={[2, 0, 0, 2]}
                        />
                        {formattedIgniteEnrichmentProgress > 0 && (
                            <ReferenceLine x={formattedIgniteEnrichmentProgress} stroke="black" strokeDasharray="3 3" />
                        )}
                        <Bar
                            dataKey={manualEnrichments}
                            stackId={label}
                            fill={theme.palette.tokens?.icon.iconSuccess}
                        />
                        {formattedManualEnrichmentProgress > 0 && formattedEmptyEnrichmentProgress > 0 && (
                            <ReferenceLine
                                x={formattedManualEnrichmentProgress + formattedIgniteEnrichmentProgress}
                                stroke="black"
                                strokeDasharray="3 3"
                            />
                        )}
                        <Bar
                            dataKey={missingDataLabel}
                            stackId={label}
                            fill={theme.palette.tokens?.progressBar.trackBackground1}
                            radius={[0, 2, 2, 0]}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Stack>
        </Tooltip>
    );
};
