import { useQuery } from "@apollo/client";
import { Chip, Skeleton } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";

const getBusinessUnitQuery = graphql(`
    query BusinessUnitChip_GetBusinessUnit($input: GetBusinessUnitInput!) {
        getBusinessUnit(input: $input) {
            businessUnit {
                name
                level
            }
        }
    }
`);

interface Props {
    businessUnitID: string;
}

export const BusinessUnitChip: React.FC<Props> = ({ businessUnitID }) => {
    const { data, loading } = useQuery(getBusinessUnitQuery, {
        variables: {
            input: {
                id: businessUnitID,
            },
        },
    });

    if (loading || !data) {
        return <Skeleton variant="rounded" width={120} height={24} />;
    }

    const { name, level } = data.getBusinessUnit.businessUnit;

    return (
        <Chip
            size="large"
            variant="tag"
            sx={{
                backgroundColor: (theme) => theme.palette.tokens?.shapeIcon.shapeAccentA,
                border: "1px solid",
                borderColor: (theme) => theme.palette.tokens?.shapeIcon.shapeAccentB,
                ":hover": {
                    // Default hover color is white, apparently. Set it to the same as the non-hover color here.
                    color: (theme) => theme.palette.tokens?.text?.primary,
                },
            }}
            label={
                <FormattedMessage
                    defaultMessage="L{level}: {name}"
                    description="Business unit chip label"
                    values={{
                        level,
                        name,
                    }}
                />
            }
        />
    );
};
