import { Chip } from "@mui/material";

interface ClassificationFieldProps {
    ids: string[];
    valueById?: Map<string, string>;
}

const colors = ["#ffd4e0", "#ffe0cc", "#ffeab6", "#c1f5f0", "#d1e2ff"];

export const ClassificationField: React.FC<ClassificationFieldProps> = ({ ids, valueById }) => {
    const numGroups = ids?.length;
    if (numGroups === 0 || valueById === undefined || valueById?.size === 0) {
        return <></>;
    }
    return (
        <>
            {ids?.map((id: string, i: number) => {
                return (
                    valueById?.get(id) && (
                        <Chip
                            key={id}
                            variant="status"
                            sx={{
                                background: colors[i % numGroups],
                                color: "rgba(0, 0, 0, 0.8)",
                                cursor: "pointer",
                                margin: "2px",
                            }}
                            size="small"
                            label={valueById?.get(id)}
                        />
                    )
                );
            })}
        </>
    );
};
