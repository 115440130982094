import { useApolloClient } from "@apollo/client";
import { Popover } from "@mui/material";
import { RichTreeView, TreeItemProps, useTreeViewApiRef } from "@mui/x-tree-view";
import { useMemo } from "react";

import { LOADING_CHILDREN_LABEL } from "./constants";
import { getBusinessUnits } from "./queries";
import { TreeItem } from "./TreeItem";
import { BusinessUnit } from "./types";
import { addNewChildrenDataToTree } from "./utils";

interface BusinessUnitPopoverProps {
    open: boolean;
    onClose: () => void;
    anchorEl: null | HTMLElement;
    value: string[];
    onChange: (id: string) => void;
    businessUnitTree: BusinessUnit[];
    setBusinessUnitTree: React.Dispatch<React.SetStateAction<BusinessUnit[]>>;
}

export const BusinessUnitPopper: React.FC<BusinessUnitPopoverProps> = ({
    open,
    onClose,
    anchorEl,
    value,
    onChange,
    businessUnitTree,
    setBusinessUnitTree,
}) => {
    const apiRef = useTreeViewApiRef();
    const client = useApolloClient();

    const handleItemExpand = (event: React.SyntheticEvent<Element, Event>, itemId: string, expanded: boolean) => {
        if (!expanded) {
            return;
        }

        const item = apiRef?.current?.getItem(itemId);
        if (!item) {
            return;
        }
        // Don't load if already loaded
        // NOTE: Will load multiple times if user spams while the first query is loading. Apollo seems to handle this though, and
        // only sends 1 network request, even though the promises resolves multiple times.
        if (item.children.length !== 1 || item.children[0].label !== LOADING_CHILDREN_LABEL) {
            return;
        }

        // NOTE: Using client.query here, as useLazyQuery was causing issues
        // ref: https://github.com/apollographql/apollo-client/issues/9755
        client
            .query({
                query: getBusinessUnits,
                variables: {
                    input: {
                        parentId: itemId,
                    },
                },
            })
            .then((result) => {
                setBusinessUnitTree((oldBusinessUnitTree) =>
                    addNewChildrenDataToTree(result.data, itemId, oldBusinessUnitTree)
                );
            })
            .catch(() => {
                // On error: Close the business unit
                apiRef?.current?.setItemExpansion(event, itemId, false);
            });
    };

    const disabledItems = useMemo(() => {
        const disabledItems: string[] = [];
        const addDisabledItems = (businessUnits: BusinessUnit[], parentToggled: boolean) => {
            if (parentToggled) {
                disabledItems.push(...businessUnits.map((businessUnit) => businessUnit.id));
            }

            for (const businessUnit of businessUnits) {
                addDisabledItems(businessUnit.children, parentToggled || value.includes(businessUnit.id));
            }
        };
        addDisabledItems(businessUnitTree, false);
        return disabledItems;
    }, [businessUnitTree, value]);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            slotProps={{
                paper: {
                    sx: {
                        maxHeight: `min(calc(100vh - 32px), 400px)`,
                    },
                },
            }}
        >
            <RichTreeView
                multiSelect
                items={businessUnitTree}
                onItemExpansionToggle={handleItemExpand}
                apiRef={apiRef}
                slots={{
                    item: (props: TreeItemProps) => (
                        <TreeItem
                            {...props}
                            selected={value.includes(props.itemId) || disabledItems.includes(props.itemId)}
                            disabled={disabledItems.includes(props.itemId)}
                            onSelectItem={onChange}
                        />
                    ),
                }}
                selectedItems={value}
                expansionTrigger="iconContainer"
                sx={{
                    width: anchorEl?.offsetWidth,
                }}
            />
        </Popover>
    );
};
