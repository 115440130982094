import { CircleSolid } from "@ignite-analytics/icons";
import { SvgIconProps } from "@mui/material";

import { RiskScore } from "@/gql/graphql";

interface RiskIndicatorProps extends SvgIconProps {
    riskScore: RiskScore | undefined | null;
}

/**
 * Circular icon with color based on the risk score.
 *
 * If used as a Chip icon, the colors must be set on the Chip component.
 * See https://github.com/mui/material-ui/issues/26676
 *
 * @example
 * ```tsx
 * <Chip icon={<RiskIndicator riskScore={riskScore} />} sx={{ [`& .${chipClasses.icon}`]: { color: "red" } }} />
 *
 * ```
 */
const RiskIndicator: React.FC<RiskIndicatorProps> = (props) => {
    const { riskScore, ...rest } = props;

    switch (riskScore) {
        case RiskScore.VeryHigh:
        case RiskScore.High:
            return <CircleSolid {...rest} color="error" />;
        case RiskScore.Medium:
            return <CircleSolid {...rest} color="warning" />;
        case RiskScore.Low:
        case RiskScore.VeryLow:
            return <CircleSolid {...rest} color="success" />;
        default:
            return <CircleSolid {...rest} sx={{ color: (t) => t.palette.tokens?.icon.iconGray }} />;
    }
};

export { RiskIndicator };
