import { useMutation, useQuery } from "@apollo/client";
import { Dialog } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { graphql } from "@/gql";
import {
    RiskEstimate as GQLRiskEstimate,
    SupplierFilterInput,
    SupplierFilterType,
    SupplierGroupFilterInput,
} from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";

import { BusinessUnit } from "../BusinessUnitSelect/types";
import { FilterInput } from "../InlineFilter/types";
import { RiskEstimate } from "../RiskEstimatesSelector";
import { Category } from "../SpendCategorySelect/types";

import { CreateFilterDialogContent } from "./CreateFilterDialogContent";
import { PreviewGroupDialogContent } from "./PreviewGroupDialogContent";
import { SelectColumnsDialogContent } from "./SelectColumnsDialogContent";

export type Step = "filter" | "preview" | "column";

interface GroupCreationDialogProps {
    onClose: () => void;
}

const getRiskColumnConfigQuery = graphql(`
    query GroupCreationDialog_GetRiskColumnConfig {
        getRiskColumnConfig {
            socialRiskColumn
            naceColumn
        }
    }
`);

const getLatestSpendInfoQuery = graphql(`
    query GroupCreationDialog_GetLatestSpendInfo {
        getLatestSpendInfo {
            latestSpendInfo {
                columnId
                columnName
                minSpend
                maxSpend
                year
            }
        }
    }
`);

graphql(`
    fragment SupplierTableColumn on SupplierTableColumn {
        id
        name
        type
        globalType
        typeOptions {
            ... on ClassificationOptions {
                groups {
                    id
                    value
                    level
                    __typename
                }
                __typename
            }
            ... on SelectOptions {
                choices
                __typename
            }
            __typename
        }
        __typename
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query GroupCreationDialog_GetSupplierTableMeta {
        getSupplierTableMeta {
            columns {
                ...SupplierTableColumn
            }
        }
    }
`);

const getSuppliersByGroupFilterQuery = graphql(`
    query GroupCreationDialog_GetSuppliersByGroupFilter($input: GetSuppliersByGroupFilterInput!) {
        getSuppliersByGroupFilter(input: $input) {
            total
        }
    }
`);

const createSupplierGroupMutation = graphql(`
    mutation GroupCreationDialog_CreateSupplierGroup($input: CreateSupplierGroupInput!) {
        createSupplierGroup(input: $input) {
            supplierGroup {
                id
            }
        }
    }
`);

export const GroupCreationDialog: React.FC<GroupCreationDialogProps> = ({ onClose }) => {
    const navigate = useNavigate();
    const [step, setStep] = React.useState<Step>("filter");

    const [groupName, setGroupName] = React.useState("");
    const [categoryIDs, setCategoryIDs] = React.useState<string[]>([]);
    const [businessUnitIDs, setBusinessUnitIDs] = React.useState<string[]>([]);
    const [riskEstimates, setRiskEstimates] = React.useState<RiskEstimate[]>([]);
    const [maxSpendRange, setMaxSpendRange] = React.useState<[number, number]>([0, 0]);
    const [spendRange, setSpendRange] = React.useState<[number, number]>([0, 0]);
    const [additionalColumnIDs, setAdditionalColumnIDs] = React.useState<string[]>([]);
    const [additionalFilters, setAdditionalFilters] = React.useState<FilterInput[]>([]);

    // Hack: Storing the spend category tree here to avoid dropping it when continuing from
    //       the filter step. FIXME: Refactor to avoid this.
    const [categoryTree, setCategoryTree] = React.useState<Category[]>([]);
    const [businessUnitTree, setBusinessUnitTree] = React.useState<BusinessUnit[]>([]);

    const { data: latestSpendData } = useQuery(getLatestSpendInfoQuery, {
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to get latest spend info" },
            });
        },
    });

    React.useEffect(() => {
        if (!latestSpendData?.getLatestSpendInfo.latestSpendInfo) return;

        const { minSpend: minSpendRaw, maxSpend: maxSpendRaw } = latestSpendData.getLatestSpendInfo.latestSpendInfo;
        // Drop decimals on spend
        const minSpend = Math.floor(minSpendRaw);
        const maxSpend = Math.ceil(maxSpendRaw);

        setMaxSpendRange([0, maxSpend]);
        // Set default selection
        setSpendRange([minSpend, maxSpend]);
    }, [latestSpendData?.getLatestSpendInfo.latestSpendInfo]);

    const spendColumnID = latestSpendData?.getLatestSpendInfo.latestSpendInfo.columnId;
    const spendColumnName = latestSpendData?.getLatestSpendInfo.latestSpendInfo.columnName;
    const spendYear = latestSpendData?.getLatestSpendInfo.latestSpendInfo.year;
    const hasSpend = spendColumnID !== undefined;

    const { data: riskConfigData } = useQuery(getRiskColumnConfigQuery, {
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to get risk column config" },
            });
        },
    });
    const riskColumnID = riskConfigData?.getRiskColumnConfig.socialRiskColumn;
    const naceColumnID = riskConfigData?.getRiskColumnConfig.naceColumn;

    const { data: supplierTableColumnsData } = useQuery(getSupplierTableColumnsQuery, {
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to get supplier table columns" },
            });
        },
    });

    const [createSupplierGroup] = useMutation(createSupplierGroupMutation, {
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to create supplier group" },
            });
        },
    });

    const supplierGroupFilter = React.useMemo(
        (): SupplierGroupFilterInput => ({
            categories: categoryIDs,
            businessUnits: businessUnitIDs,
            riskEstimates: riskEstimates.map((riskEstimate) => {
                switch (riskEstimate) {
                    case "MISSING":
                        return GQLRiskEstimate.Missing;
                    case "VERY_LOW":
                        return GQLRiskEstimate.VeryLow;
                    case "LOW":
                        return GQLRiskEstimate.Low;
                    case "MEDIUM":
                        return GQLRiskEstimate.Medium;
                    case "HIGH":
                        return GQLRiskEstimate.High;
                    case "VERY_HIGH":
                        return GQLRiskEstimate.VeryHigh;
                    default:
                        riskEstimate satisfies never; // Ensure exhaustive switch
                }
                return GQLRiskEstimate.Missing; // NOTE: Only for typechecking
            }),
            spendFilter: spendColumnID
                ? {
                      type: SupplierFilterType.Range,
                      columnId: spendColumnID,
                      minRange: spendRange[0],
                      maxRange: spendRange[1],
                  }
                : undefined,
            additionalFilters: additionalFilters
                .map((filter): SupplierFilterInput | null => {
                    if (!filter.column_id || !filter.type) return null;
                    let gqlFilterType: SupplierFilterType | undefined = undefined;
                    switch (filter.type) {
                        case "range":
                            gqlFilterType = SupplierFilterType.Range;
                            break;
                        case "date":
                            gqlFilterType = SupplierFilterType.Date;
                            break;
                        case "include":
                            gqlFilterType = SupplierFilterType.Include;
                            break;
                        case "exclude":
                            gqlFilterType = SupplierFilterType.Exclude;
                            break;
                        default:
                            filter.type satisfies never; // Ensure exhaustive switch
                            break;
                    }
                    if (gqlFilterType === undefined) return null;
                    return {
                        columnId: filter.column_id,
                        includeBlanks: filter.include_blanks,
                        includeExcludeValues: filter.include_exclude_values,
                        maxRange: filter.max_range,
                        minRange: filter.min_range,
                        minDateISOString: filter.min_date,
                        maxDateISOString: filter.max_date,
                        type: gqlFilterType,
                    };
                })
                .filter((filter) => filter !== null),
        }),
        [categoryIDs, businessUnitIDs, riskEstimates, spendRange, spendColumnID, additionalFilters]
    );

    const supplierCountResult = useQuery(getSuppliersByGroupFilterQuery, {
        variables: {
            input: {
                filter: supplierGroupFilter,

                pageIndex: 0,
                pageRange: 1,
            },
        },
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "social-risk-app", message: "Failed to get count of suppliers in group filter" },
            });
        },
    });

    const supplierCountInfo = React.useMemo(() => {
        return {
            total: supplierCountResult.data?.getSuppliersByGroupFilter.total,
            loading: supplierCountResult.loading,
            hasError: supplierCountResult.error !== undefined,
        };
    }, [supplierCountResult.data, supplierCountResult.loading, supplierCountResult.error]);

    switch (step) {
        case "filter":
            return (
                <Dialog open onClose={onClose} maxWidth="xl">
                    <CreateFilterDialogContent
                        onClose={onClose}
                        setStep={setStep}
                        groupName={groupName}
                        setGroupName={setGroupName}
                        categoryIDs={categoryIDs}
                        setCategoryIDs={setCategoryIDs}
                        businessUnitIDs={businessUnitIDs}
                        setBusinessUnitIDs={setBusinessUnitIDs}
                        riskEstimates={riskEstimates}
                        setRiskEstimates={setRiskEstimates}
                        spendRange={spendRange}
                        setSpendRange={setSpendRange}
                        additionalFilters={additionalFilters}
                        setAdditionalFilters={setAdditionalFilters}
                        maxSpendRange={maxSpendRange}
                        supplierCountInfo={supplierCountInfo}
                        spendColumnName={spendColumnName}
                        hasSpend={hasSpend}
                        spendYear={spendYear}
                        columns={supplierTableColumnsData?.getSupplierTableMeta.columns ?? []}
                        categoryTree={categoryTree}
                        setCategoryTree={setCategoryTree}
                        businessUnitTree={businessUnitTree}
                        setBusinessUnitTree={setBusinessUnitTree}
                    />
                </Dialog>
            );
        case "preview":
            return (
                <Dialog open onClose={onClose} maxWidth="xl">
                    <PreviewGroupDialogContent
                        setStep={setStep}
                        supplierGroupFilter={supplierGroupFilter}
                        spendColumnID={spendColumnID}
                        spendColumnName={spendColumnName}
                        riskColumnID={riskColumnID}
                        missingGroupName={groupName === ""}
                        noSuppliersFound={supplierCountInfo.total === 0}
                    />
                </Dialog>
            );
        case "column":
            return (
                <Dialog open onClose={onClose}>
                    <SelectColumnsDialogContent
                        onClose={onClose}
                        setStep={setStep}
                        additionalColumnIDs={additionalColumnIDs}
                        setAdditionalColumnIDs={setAdditionalColumnIDs}
                        onComplete={() =>
                            createSupplierGroup({
                                variables: {
                                    input: {
                                        name: groupName,
                                        additionalColumns: additionalColumnIDs,
                                        filter: supplierGroupFilter,
                                    },
                                },
                            }).then((result) => {
                                track(`${TRACK_PREFIX}: Clicked Create Group`);
                                navigate(`/social-risk/${result.data?.createSupplierGroup.supplierGroup.id}`);
                            })
                        }
                        columns={supplierTableColumnsData?.getSupplierTableMeta.columns ?? []}
                        spendColumnID={spendColumnID}
                        riskColumnID={riskColumnID}
                        naceColumnID={naceColumnID}
                    />
                </Dialog>
            );
        default:
            step satisfies never; // Ensure exhaustive switch
    }
};
