import { ShapeIcon } from "@ignite-analytics/components";
import { InformationCircleSolid } from "@ignite-analytics/icons";
import { Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
function openIntercom() {
    window.Intercom(
        "showNewMessage",
        "Hello, I'm currently working on creating a supplier filter that requires adding yearly spend. Can you guide me through this process?"
    );
}

export const NoSpendDataAvailableContent = () => (
    <Card sx={{ backgroundColor: "#EFF8FE", borderColor: "#7DD2FB", color: "#075884" }}>
        <CardContent>
            <Stack spacing={2.5} direction="row" justifyContent="flex-start">
                <ShapeIcon variant="outline" icon={<InformationCircleSolid />} color="accent" size="xsmall" />
                <Typography variant="textSm" fontWeight={400} color="inherit" maxWidth={500}>
                    <FormattedMessage
                        defaultMessage="We noticed that you do not have yearly spend data available. Contact customer support for assistance."
                        description="Spend range selector helper text"
                    />
                </Typography>
            </Stack>
        </CardContent>
        <CardActions sx={{ pt: 0, pl: 7.5 }}>
            <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={openIntercom}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    borderColor: "#7DD2FB",
                    color: "#075884",
                }}
            >
                <FormattedMessage
                    defaultMessage="Contact support"
                    description="Button to contact customer success manager"
                />
            </Button>
        </CardActions>
    </Card>
);
