import { ArrowLeft, ArrowRight, DotsVertical, InformationCircle } from "@ignite-analytics/icons";
import { Box, Button, Chip, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

import { GroupDetailsModal } from "@/components/GroupDetailsModal";
import { GroupProgress } from "@/components/LinerProgress/GroupProgress";
import { graphql } from "@/gql";
import {
    GroupDetailsModal_SupplierGroupFragment,
    GroupPageHeader_SupplierGroupFragment,
    RiskEstimate,
} from "@/gql/graphql";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { AddSuppliersModal } from "./AddSuppliers";
import { EditMenu } from "./EditGroup";

graphql(`
    fragment GroupPageHeader_SupplierGroup on SupplierGroup {
        id
        name
        createdAtISOString
        progress {
            ManualEnrichment
            IgniteEnrichment
            EmptyEnrichment
        }
        riskEstimate
        supplierIDs
        campaignId
    }
`);

interface GroupPageHeaderProps {
    isEditor: boolean;
    group: GroupPageHeader_SupplierGroupFragment & GroupDetailsModal_SupplierGroupFragment;
}

const getRiskEstimateChip = (level: RiskEstimate | undefined): React.ReactNode => {
    const levelText = (level: RiskEstimate | undefined) => {
        switch (level) {
            case "MISSING":
                return <FormattedMessage defaultMessage="N/A" description="Missing risk chip label" />;
            case "VERY_LOW":
                return <FormattedMessage defaultMessage="Very Low" description="Risk estimate very low" />;
            case "LOW":
                return <FormattedMessage defaultMessage="Low" description="Risk estimate low" />;
            case "MEDIUM":
                return <FormattedMessage defaultMessage="Medium" description="Risk estimate medium" />;
            case "HIGH":
                return <FormattedMessage defaultMessage="High" description="Risk estimate high" />;
            case "VERY_HIGH":
                return <FormattedMessage defaultMessage="Very High" description="Risk estimate very high" />;
            default:
                return <FormattedMessage defaultMessage="Unknown" description="Risk estimate unknown" />;
        }
    };

    const levelColor = (level: RiskEstimate | undefined) => {
        switch (level) {
            case "MISSING":
                return "infoGray";
            case "VERY_LOW":
                return "success";
            case "LOW":
                return "success";
            case "MEDIUM":
                return "warning";
            case "HIGH":
                return "error";
            case "VERY_HIGH":
                return "error";
            default:
                return "infoGray";
        }
    };

    return <Chip size="small" variant="status" label={levelText(level)} color={levelColor(level)} />;
};

export const GroupPageHeader: React.FC<GroupPageHeaderProps> = ({ isEditor, group }) => {
    const navigate = useOutgoingNavigate();
    const { formatMessage } = useIntl();

    const [groupDetailsModalOpen, setGroupDetailsModalOpen] = React.useState(false);
    const [addSuppliersModalOpen, setAddSuppliersModalOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Stack direction="row" justifyContent="space-between">
            <GroupDetailsModal
                open={groupDetailsModalOpen}
                onClose={() => setGroupDetailsModalOpen(false)}
                group={group}
            />
            <Stack direction="row" spacing={1.5} flexGrow={1}>
                <IconButton
                    sx={{ alignSelf: "start" }}
                    size="xsmall"
                    color="secondary"
                    onClick={() => {
                        navigate("/social-risk/");
                    }}
                >
                    <ArrowLeft />
                </IconButton>
                <Stack>
                    <Typography variant="displayXs" fontWeight="bold">
                        {group.name}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Group created {date}"
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={group.createdAtISOString}
                                            year="numeric"
                                            month="short"
                                            day="2-digit"
                                        />
                                    ),
                                }}
                            ></FormattedMessage>
                        </Typography>
                        <Divider orientation="vertical" />
                        <Button
                            variant="text"
                            color="linkPrimary"
                            onClick={() => setGroupDetailsModalOpen(true)}
                            endIcon={<ArrowRight />}
                            size="2xsmall"
                        >
                            <FormattedMessage defaultMessage="View group details" description="Group details button" />
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <Stack justifyContent="flex-end" direction="row" alignItems="center" flexGrow={1} spacing={3}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Assessment progress"
                                description="Risk score header"
                            ></FormattedMessage>
                        </Typography>
                        <Box width={175}>
                            <GroupProgress
                                manualEnrichmentProgress={group.progress.ManualEnrichment}
                                igniteEnrichmentProgress={group.progress.IgniteEnrichment}
                                emptyEnrichmentProgress={group.progress.EmptyEnrichment}
                            />
                        </Box>
                    </Stack>
                    <Tooltip
                        title={formatMessage({
                            defaultMessage:
                                "Share of estimate that is Ignite risk assessment, your risk assessment, and missing risk assessment",
                        })}
                    >
                        <InformationCircle fontSize="inherit" />
                    </Tooltip>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="textSm" noWrap>
                        <FormattedMessage
                            defaultMessage="Estimated group risk score"
                            description="Estimated risk level for group title"
                        />
                    </Typography>
                    {getRiskEstimateChip(group.riskEstimate ?? undefined)}
                    <Tooltip
                        title={formatMessage({
                            defaultMessage: "Estimated risk score for this group",
                            description: "Estimated group risk score tooltip",
                        })}
                    >
                        <InformationCircle fontSize="inherit" />
                    </Tooltip>
                    <IconButton size="small" onClick={handleClick}>
                        <DotsVertical />
                    </IconButton>
                </Stack>
            </Stack>
            <EditMenu
                anchorEl={anchorEl}
                groupId={group.id}
                isEditor={isEditor}
                showGroupDetails={() => {
                    setGroupDetailsModalOpen(true);
                }}
                addSuppliersModal={() => {
                    setAddSuppliersModalOpen(true);
                }}
                handleClose={() => {
                    setAnchorEl(null);
                }}
            />
            <AddSuppliersModal
                existingSupplierIDs={group.supplierIDs}
                open={addSuppliersModalOpen}
                handleClose={() => setAddSuppliersModalOpen(false)}
                groupId={group.id}
                hasCampaign={!!group.campaignId}
            />
        </Stack>
    );
};
