import { InformationCircle } from "@ignite-analytics/icons";
import { Chip, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

export type RiskEstimate = "MISSING" | "VERY_LOW" | "LOW" | "MEDIUM" | "HIGH" | "VERY_HIGH";

interface BaseProps {
    riskEstimates: RiskEstimate[];
}

interface EditableProps extends BaseProps {
    disabled?: false;
    setRiskEstimates: (estimates: RiskEstimate[]) => void;
}

interface DisabledProps extends BaseProps {
    disabled: true;
    setRiskEstimates?: undefined;
}

type Props = EditableProps | DisabledProps;

const riskEstimateButtonProps: { riskEstimate: RiskEstimate; labelNode: ReactNode }[] = [
    {
        riskEstimate: "VERY_HIGH",
        labelNode: <FormattedMessage defaultMessage="Very high" description="Risk estimate very high" />,
    },
    { riskEstimate: "HIGH", labelNode: <FormattedMessage defaultMessage="High" description="Risk estimate high" /> },
    {
        riskEstimate: "MEDIUM",
        labelNode: <FormattedMessage defaultMessage="Medium" description="Risk estimate medium" />,
    },
    { riskEstimate: "LOW", labelNode: <FormattedMessage defaultMessage="Low" description="Risk estimate low" /> },
    {
        riskEstimate: "VERY_LOW",
        labelNode: <FormattedMessage defaultMessage="Very low" description="Risk estimate very low" />,
    },
];

export const RiskEstimatesSelector: React.FC<Props> = ({ riskEstimates, setRiskEstimates, disabled }) => {
    const handleRiskEstimateChange = (riskEstimate: RiskEstimate) => {
        if (disabled) return;
        if (riskEstimates.includes(riskEstimate)) {
            setRiskEstimates(riskEstimates.filter((estimate) => estimate !== riskEstimate));
        } else {
            setRiskEstimates([...riskEstimates, riskEstimate]);
        }
    };

    const makeChipSx: (selected: boolean) => SxProps<Theme> = (selected) => (theme) => ({
        // Don't do any hover stuff when disabled
        pointerEvents: disabled ? "none" : undefined,
        "& .MuiSvgIcon-root": {
            // Proper icon size for the missing risk chip
            width: 16,
            height: 16,
            color: theme.palette.tokens?.icon?.iconPrimary,
        },
        ":hover": {
            "& .MuiSvgIcon-root": {
                // Invert the icon color on hover so it's visible on the background
                color: theme.palette.tokens?.text?.textInverseB,
            },
        },
        backgroundColor: selected ? theme.palette.tokens?.shapeIcon.shapeAccentA : undefined,
        border: "1px solid",
        borderColor: selected ? theme.palette.tokens?.shapeIcon.shapeAccentB : "transparent",
    });

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {riskEstimateButtonProps.map(({ riskEstimate, labelNode }) => {
                const selected = riskEstimates.includes(riskEstimate);
                return (
                    <Chip
                        size="large"
                        clickable={!disabled}
                        variant="tag"
                        sx={makeChipSx(selected)}
                        key={riskEstimate}
                        label={labelNode}
                        onClick={() => handleRiskEstimateChange(riskEstimate)}
                    />
                );
            })}

            <Tooltip
                title={
                    <FormattedMessage
                        defaultMessage="These suppliers don't have a risk estimate due to missing country/production country data, or missing industry"
                        description="Suppliers without risk info tooltip text"
                    />
                }
            >
                {/* Need a div to activate the tooltip since we set pointerevents none when disabled */}
                <div>
                    <Chip
                        icon={<InformationCircle />}
                        size="large"
                        clickable={!disabled}
                        variant="tag"
                        sx={makeChipSx(riskEstimates.includes("MISSING"))}
                        label={<FormattedMessage defaultMessage="Unknown risk" description="Risk estimate missing" />}
                        onClick={() => handleRiskEstimateChange("MISSING")}
                    />
                </div>
            </Tooltip>
        </Stack>
    );
};
