import { CircleSolid } from "@ignite-analytics/icons";
import { Chip, useTheme } from "@mui/material";
import React from "react";

interface ProdCountryChipProps {
    label: string;
    score: string | null;
}

export const ProdCountryChip: React.FC<ProdCountryChipProps> = ({ label, score, ...props }) => {
    const theme = useTheme();

    const scoreColor = (score: string | null) => {
        switch (score) {
            case "1":
            case "2":
                return theme.palette.tokens?.icon.iconSuccess;
            case "3":
            case "4":
                return theme.palette.tokens?.icon.iconWarning;
            case "5":
            case "6":
                return theme.palette.tokens?.icon.iconError;
            default:
                return theme.palette.tokens?.icon.iconGray;
        }
    };

    return (
        <Chip
            color="neutral"
            size="medium"
            icon={<CircleSolid style={{ color: scoreColor(score) }} />}
            sx={{
                "& .MuiChip-icon": {
                    width: "16px",
                    height: "16px",
                },
            }}
            label={label}
            {...props}
        />
    );
};
