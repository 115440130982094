import {
    Autocomplete,
    CircularProgress,
    inputBaseClasses,
    inputClasses,
    outlinedInputClasses,
    paperClasses,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import popperClasses from "@mui/material/Popper/popperClasses";
import { GridRenderEditCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid-pro";
import React from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { ProductionCountriesEditCell_SupplierCountryFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";

import { TableData } from "../../utils";

import { ProductionCountryRiskChip } from "./ProductionCountryRiskChip";

interface ProductionCountriesEditCellProps {
    params: GridRenderEditCellParams<
        TableData,
        ProductionCountriesEditCell_SupplierCountryFragment[] | undefined,
        unknown,
        GridTreeNodeWithRender
    >;
    countries: ProductionCountriesEditCell_SupplierCountryFragment[];
    loading?: boolean;
    error?: boolean;
}

graphql(`
    fragment ProductionCountriesEditCell_SupplierCountry on SupplierCountry {
        id
        iso2Code
        name(language: $language)
    }
`);

export const ProductionCountriesEditCell: React.FC<ProductionCountriesEditCellProps> = ({
    params,
    countries,
    loading,
    error,
}) => {
    const { id, field, api, value, hasFocus } = params;
    const ref = React.useRef<HTMLDivElement>(null);

    /**
     * It's important to also handle the accessibility of custom edit components.
     * When a cell enters edit mode, an element must be focused to provide access via keyboard and for screen readers.
     * Since multiple cells may be in edit mode at the same time, the hasFocus prop will be true on the cell that
     * should have focus. Use this prop to focus the appropriate element.
     *
     * From: https://mui.com/x/react-data-grid/editing/#create-your-own-edit-component
     */
    React.useLayoutEffect(() => {
        if (hasFocus) {
            ref.current?.focus();
        }
    }, [hasFocus]);

    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: ProductionCountriesEditCell_SupplierCountryFragment[] | null
    ) => {
        const selectedCodes = newValue ? newValue.map((option) => option.id) : [];

        track(`${TRACK_PREFIX}: Updated Production Countries`, {
            initialValue: newValue,
            newValue: selectedCodes,
        });

        api.setEditCellValue({ id, field, value: newValue });
        event.stopPropagation();
    };

    return (
        <Autocomplete
            loading={loading}
            loadingText={
                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                    <CircularProgress size={14} />
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Loading..."
                            description="Placeholder text when countries are loading in an autocomplete"
                        />
                    </Typography>
                </Stack>
            }
            sx={{
                zIndex: 10,
                minWidth: "var(--width)",
                [`& .${inputBaseClasses.root}`]: {
                    borderRadius: 0,
                },
                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                    {
                        boxShadow: "none",
                    },
            }}
            getOptionKey={(option) => option.id}
            autoHighlight
            multiple
            options={countries}
            value={value}
            fullWidth
            disableClearable
            onClose={() => api.stopCellEditMode({ id, field })}
            getOptionLabel={(option) => `${option.iso2Code}: ${option.name}`}
            renderOption={(props, option) => (
                <Typography variant="textSm" {...props}>
                    {option.iso2Code}: {option.name}
                </Typography>
            )}
            renderInput={(inputParams) => (
                <TextField
                    sx={{
                        height: "100%",
                        minHeight: "max-content",
                        [`&. ${inputClasses.root}`]: {
                            minHeight: "max-content",
                        },
                    }}
                    inputRef={ref}
                    {...inputParams}
                    fullWidth
                    variant="outlined"
                    error={error}
                />
            )}
            openOnFocus
            onChange={handleChange}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((country, index) => {
                    // onDelete is intentionally unpacked here to prevent it from being passed to the Chip component
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { key, onDelete, ...props } = getTagProps({ index });
                    return <ProductionCountryRiskChip country={country} label="name" {...props} key={key} />;
                })
            }
            disableCloseOnSelect
            slotProps={{
                listbox: { sx: { maxHeight: "200px", overflowY: "auto" } },
                popper: {
                    sx: {
                        [`&.${popperClasses.root}[data-popper-placement="bottom"] .${paperClasses.root}`]: {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginTop: 0,
                        },
                        [`&.${popperClasses.root}[data-popper-placement="top"] .${paperClasses.root}`]: {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                    },
                },
            }}
        />
    );
};
