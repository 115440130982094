import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const CustomTooltip: React.FC<{ igniteEnrichment: number; manualEnrichment: number; missing: number }> = ({
    igniteEnrichment,
    manualEnrichment,
    missing,
}) => {
    return (
        <Stack padding={1.5} spacing={1} borderRadius={8} width={194}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <Box
                        width={7}
                        height={16}
                        sx={{
                            backgroundColor: "#069CDF",
                            borderRadius: 8,
                        }}
                    />
                    <Typography variant="textXs">
                        <FormattedMessage defaultMessage="Ignite risk assessment" />
                    </Typography>
                </Stack>
                <Typography variant="textXs">{`${igniteEnrichment}%`}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <Box
                        width={7}
                        height={16}
                        sx={{
                            backgroundColor: "#26BF56",
                            borderRadius: 8,
                        }}
                    />
                    <Typography variant="textXs">
                        <FormattedMessage defaultMessage="Your risk assessment" />
                    </Typography>
                </Stack>
                <Typography variant="textXs">{`${manualEnrichment}%`}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <Box
                        width={7}
                        height={16}
                        sx={{
                            backgroundColor: "#E0E3E6",
                            borderRadius: 8,
                        }}
                    />
                    <Typography variant="textXs">
                        <FormattedMessage defaultMessage="Missing risk assessment" />
                    </Typography>
                </Stack>
                <Typography variant="textXs">{`${missing}%`}</Typography>
            </Stack>
        </Stack>
    );
};
