import { LicenseInfo } from "@mui/x-license";
import React from "react";
import { NavigateFunction } from "react-router-dom";

import { AppRoutes } from "./routes";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_TOKEN ?? "");

interface AppProps {
    navigate?: NavigateFunction;
}

const App: React.FC<AppProps> = ({ navigate }) => {
    return <AppRoutes navigate={navigate} />;
};
export default App;
