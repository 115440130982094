import { CircleSolid } from "@ignite-analytics/icons";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { CountrySelect_SupplierCountryFragment } from "@/gql/graphql";
import { track, TRACK_PREFIX } from "@/lib/track";

import { useCountries } from "./countries";
import { CountryItem } from "./CountryItem";
import { riskScoreToColor } from "./riskScoreToColor";

interface RiskAutocompleteProps {
    value: string;
    onChange: (countryId: string | null) => void;
    isEditor: boolean;
    onViewRiskDetails: (countryId: string) => void;
    active?: boolean;
}

graphql(`
    fragment CountrySelect_SupplierCountry on SupplierCountry {
        id
        name
        iso2Code
        risk {
            score
        }
        ...CountryItem_SupplierCountry
    }
`);

type Country = CountrySelect_SupplierCountryFragment;

export const CountrySelect: React.FC<RiskAutocompleteProps> = ({
    value,
    onChange,
    isEditor,
    onViewRiskDetails,
    active = true,
}) => {
    const handleChange = (_event: React.SyntheticEvent<Element, Event>, newValue: Country | null) => {
        track(`${TRACK_PREFIX}: Updated Country`, { initialValue: value, newValue });
        onChange(newValue?.id ?? null);
    };
    const { data } = useCountries();
    const { formatMessage } = useIntl();

    const countries: Country[] = data?.getCountries.countries ?? [];
    const country = countries.find((country) => country.id === value);
    if (country) {
        return (
            <CountryItem
                active={active}
                disabled={!isEditor}
                country={country}
                onClick={onViewRiskDetails}
                onRemove={() => onChange(null)}
            />
        );
    }

    return (
        <Autocomplete
            size="small"
            options={countries}
            value={countries.find((country) => country.id === value) ?? null}
            fullWidth
            getOptionLabel={(country) => `${country.iso2Code}: ${country.name}`}
            renderOption={(props, option) => (
                <Stack component="li" {...props} direction="row" spacing={1} fontSize="0.5rem">
                    <CircleSolid fontSize="inherit" color={riskScoreToColor(option.risk?.score) ?? "inherit"} />
                    <Typography variant="textSm">
                        {option.id}: {option.name}
                    </Typography>
                </Stack>
            )}
            disabled={!isEditor}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={formatMessage({
                        defaultMessage: "Add country",
                        description: "Placeholder for country select in detail risk view",
                    })}
                />
            )}
            onChange={(e, val) => handleChange(e, val)}
        />
    );
};
