import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { EnrichmentProgress } from "@/components/EnrichmentProgress";
import { EstimatedRiskScore } from "@/components/EstimatedRiskScore";
import { GroupCreationDialog } from "@/components/GroupCreationDialog";
import { GroupsList } from "@/components/GroupsList";

export const OverviewPage: React.FC = () => {
    const [groupCreationDialogOpen, setGroupCreationDialogOpen] = React.useState(false);

    const handleCreateGroup = () => {
        setGroupCreationDialogOpen(true);
    };

    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between" pb={3} alignContent="center">
                <Typography variant="displayXs">
                    <FormattedMessage defaultMessage="Social risk overview" description="Social risk overview header" />
                </Typography>
                <Button color="primary" onClick={handleCreateGroup}>
                    <FormattedMessage
                        defaultMessage="Create supplier group"
                        description="Create supplier group primary action"
                    />
                </Button>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <EstimatedRiskScore level="medium" />
                </Grid>
                <Grid item xs={6}>
                    <EnrichmentProgress progress={67} />
                </Grid>
                <Grid item xs={12}>
                    <GroupsList groups={[]} handleCreateGroup={handleCreateGroup} />
                </Grid>
            </Grid>
            <GroupCreationDialog open={groupCreationDialogOpen} onClose={() => setGroupCreationDialogOpen(false)} />
        </Stack>
    );
};
