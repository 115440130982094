import { ChevronDown } from "@ignite-analytics/icons";
import { IconButton } from "@mui/material";
import { useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";

type SelectIconButtonProps = {
    disabled?: boolean;
    onClick: () => void;
};
const SelectIconButton: React.FC<SelectIconButtonProps> = ({ disabled, onClick }) => {
    const { formatMessage } = useIntl();
    return (
        <NoPermissionTooltip
            hasPermission={!disabled}
            message={formatMessage({
                defaultMessage: "Your permissions do not allow editing",
            })}
        >
            <IconButton disabled={disabled} onClick={onClick}>
                <ChevronDown />
            </IconButton>
        </NoPermissionTooltip>
    );
};

export { SelectIconButton };
