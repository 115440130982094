import { Check } from "@ignite-analytics/icons";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { TreeItem2 } from "@mui/x-tree-view";
import { ComponentProps } from "react";

import { LOADING_CHILDREN_LABEL } from "./constants";

interface TreeItemProps extends ComponentProps<typeof TreeItem2> {
    selected: boolean;
    disabled: boolean;
    onSelectItem: (itemId: string) => void;
}

export const TreeItem: React.FC<TreeItemProps> = ({ itemId, label, selected, disabled, onSelectItem, ...props }) => {
    // Show loader when fetching data
    const loading = label === LOADING_CHILDREN_LABEL;

    function handleSelectItem() {
        if (disabled) return;
        if (loading) return;

        onSelectItem(itemId);
    }

    const title = (
        <Stack
            justifyContent="space-between"
            direction="row"
            onClick={handleSelectItem}
            padding={0.75}
            spacing={1}
            sx={disabled || loading ? { cursor: "not-allowed" } : undefined}
        >
            <Typography variant="textSm">{loading ? "" : label}</Typography>
            {selected && <Check />}
            {loading && <CircularProgress size={20} />}
        </Stack>
    );

    return <TreeItem2 itemId={itemId} label={title} {...props} />;
};
