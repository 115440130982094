import { getLocale } from "@ignite-analytics/locale";

/**
 * locale aware number formatting
 * @param value The number to format
 * @returns The formatted number string
 * @example
 * formatNumber(123456.789) // "123,456" if local is en-US
 * formatNumber(123456.789) // "123 456" if local is nb-NO
 */
export const formatNumber = (value: number): string =>
    value.toLocaleString(getLocale(), {
        maximumFractionDigits: 0,
    });

/**
 * Compact locale aware number formatting
 * @param value The number to format
 * @returns The formatted number string
 * @example
 * formatCurrency(1234567.89) // "1.23M" if local is en-US
 * formatCurrency(1234567.89) // "1,23 mill." if local is nb-NO
 */
export const formatCompactNumber = (value: number): string =>
    value.toLocaleString(getLocale(), {
        notation: "compact",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

/**
 * Compact formatting of a number as a currency
 * @param value The number to format
 * @param currency The ISO 4217 currency code "XXX" for "no currency"
 * @returns The formatted currency string
 * @example
 * formatCurrency(1234567.89, "NOK") // "NOK 1.23M" if local is en-US
 * formatCurrency(1234567.89, "NOK") // "NOK 1,23 mill." if local is nb-NO
 */
export const formatCompactCurrency = (value: number, currency: string | undefined): string => {
    if (!currency) {
        return formatCompactNumber(value);
    }

    return value.toLocaleString(getLocale(), {
        style: "currency",
        currency,
        notation: "compact",
        currencyDisplay: "code",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

/**
 * Format a number as a currency
 * @param value The number to format
 * @param currency The ISO 4217 currency code "XXX" for "no currency"
 * @returns The formatted currency string
 * @example
 * formatCurrency(123456.789, "NOK") // "NOK 123,456" if local is en-US
 * formatCurrency(123456.789, "NOK") // "kr 123 456" if local is nb-NO
 */
export const formatCurrency = (value: number, currency: string | undefined): string => {
    if (!currency) {
        return formatNumber(value);
    }

    return value.toLocaleString(getLocale(), {
        style: "currency",
        currency,
        currencyDisplay: "code",
        maximumFractionDigits: 0,
    });
};

/**
 * locale aware formatter from number to percent.
 * only signed if negative
 * @param value The number to format
 * @returns The formatted percent string
 * @example
 * formatPercent(0.1234) // "12.34%" if local is en-US
 * formatPercent(-0.1234) // "-12,34 %" if local is nb-NO
 */
export const formatPercent = (value: number): string =>
    value.toLocaleString(getLocale(), {
        style: "percent",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

/**
 * locale aware formatter from number to percent
 * always signed
 * @param value The number to format
 * @returns The formatted percent string
 * @example
 * formatPercent(0.1234) // "+12.34%" if local is en-US
 * formatPercent(-0.1234) // "-12,34 %" if local is nb-NO
 */
export const formatPercentSigned = (value: number): string =>
    value.toLocaleString(getLocale(), {
        style: "percent",
        signDisplay: "always",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
