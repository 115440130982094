import { InformationCircle } from "@ignite-analytics/icons";
import { Box, Button, Card, LinearProgress, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { EmptyState } from "../EmptyState";

export interface Group {
    id: string;
    name: string;
    description: string;
    progress: number;
}

interface GroupsListProps {
    groups: Group[];
    handleCreateGroup: () => void;
}

const demoGroupsList: Group[] = [
    { id: "1", name: "My top suppliers", description: "", progress: 70 },
    { id: "2", name: "Top supplier with high risk estimate", description: "", progress: 80 },
    { id: "3", name: "Largest category by Spend", description: "", progress: 90 },
    { id: "4", name: "High risk EMEA-only", description: "", progress: 0 },
];

export const GroupsList: React.FC<GroupsListProps> = ({ handleCreateGroup }) => {
    const { formatMessage } = useIntl();
    const [emptyState, setEmptyState] = React.useState(false);

    const handleEmptyState = () => {
        setEmptyState(!emptyState);
    };

    const navigate = useNavigate();

    return (
        <Paper sx={{ padding: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                <Typography variant="textMd" fontWeight={500}>
                    <FormattedMessage
                        defaultMessage="Active suppliers prioritized"
                        description="Supplier groups overview header"
                    ></FormattedMessage>
                </Typography>
                <Button color="ghostGray" size="2xsmall" onClick={handleEmptyState}>
                    <FormattedMessage defaultMessage="DEMO empty state" />
                </Button>
            </Stack>

            {emptyState ? (
                <EmptyState handleCreateGroup={handleCreateGroup} />
            ) : (
                <Stack>
                    <Stack direction="row" justifyContent="flex-start" px={2} pb={0.5}>
                        <Typography variant="caption" fontWeight={500} sx={{ width: "70%" }}>
                            <FormattedMessage defaultMessage="Name" description="Supplier group list naame header" />
                        </Typography>
                        <Typography variant="caption" fontWeight={500} sx={{ width: "30%" }}>
                            <FormattedMessage
                                defaultMessage="Enrichment progress"
                                description="Supplier group list naame header"
                            />
                        </Typography>
                    </Stack>
                    {demoGroupsList.map((group) => (
                        <Box padding={1} key={group.id}>
                            <Card>
                                <Stack
                                    key={group.id}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    px={2}
                                    py={1}
                                >
                                    <Typography variant="textLg" fontWeight={500}>
                                        {group.name}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        sx={{ width: "30%" }}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Stack sx={{ width: "50%" }} spacing={0.5}>
                                            <LinearProgress
                                                color="secondary"
                                                value={group.progress}
                                                variant="determinate"
                                                sx={{
                                                    height: 4,
                                                    borderRadius: 2,
                                                    "& .MuiLinearProgress-root": {
                                                        // what is the correct keyyyyy
                                                        backgroundColor: "black",
                                                    },
                                                }}
                                            />
                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                <Typography variant="text2xs" color="text.secondary">
                                                    {group.progress}%
                                                </Typography>
                                                <Typography variant="text2xs" color="text.secondary">
                                                    {100 - group.progress}%
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Tooltip
                                            title={formatMessage({
                                                defaultMessage:
                                                    "Share of estimate that is Ignite data vs. manual enrichments",
                                                description: "Enrichment progress tooltip",
                                            })}
                                            placement="top"
                                        >
                                            <InformationCircle fontSize="small" />
                                        </Tooltip>
                                        <Button
                                            color="secondary"
                                            size="small"
                                            onClick={() => navigate("/social-risk/demo")}
                                        >
                                            <FormattedMessage
                                                defaultMessage="View"
                                                description="Social Risk group view button"
                                            />
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Box>
                    ))}
                </Stack>
            )}
        </Paper>
    );
};
