import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { ChevronDown, Company, InformationCircle, LocationPin, Tag } from "@ignite-analytics/icons";
import { Grid2, IconButton, Menu, MenuItem, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { EstimatedRiskCard_SupplierFragment, SupplierDrawer_SupplierTableColumnFragment } from "@/gql/graphql";

import { getRiskValue } from "../GroupPageTable/Components/EstimatedRiskCell/utils";
import { NON_MISSING_RISK_STATUSES } from "../GroupPageTable/utils";
import { NoPermissionTooltip } from "../NoPermissionTooltip";
import { RiskChange, StatusChip, SuggestedStatusChip } from "../RiskStatus";

import { InfoRow } from "./InfoRow";
import { useCountryLanguage } from "./RiskPicker/countries";
import { CountryAutocomplete } from "./RiskPicker/CountryAutocomplete";
import { CountryRiskDialog } from "./RiskPicker/CountryRiskDialog";
import { CountrySelect } from "./RiskPicker/CountrySelect";
import { IndustryAutocomplete } from "./RiskPicker/IndustryAutocomplete";
import { ProdCountriesAutocomplete } from "./RiskPicker/ProdCountriesAutocomplete";
import { ProductionCountriesAutocomplete } from "./RiskPicker/ProductionCountriesAutocomplete";

graphql(`
    fragment EstimatedRiskCard_Supplier on Supplier {
        id
        name
        country
        productionCountries
        nace
        onboarding {
            approverId
            evaluatedAt
            status
        }
        customFields {
            id
            name
            dataJson
            fieldType
            fieldId
            dataType
        }
        risk {
            social
        }
        notes {
            id
            type
            metadata {
                prevValue
                newValue
            }
        }
        socialRiskScore {
            value
        }
    }
`);

interface EstimatedRiskCardProps {
    supplier: EstimatedRiskCard_SupplierFragment;
    columns: SupplierDrawer_SupplierTableColumnFragment[];
    onChange: (columnName: string, newValue: string[] | string | undefined) => void;
    onRiskChange: (value: string | undefined) => void;
    isEditor: boolean;
}

export const EstimatedRiskCard: React.FC<EstimatedRiskCardProps> = ({
    supplier,
    columns,
    onChange,
    onRiskChange,
    isEditor,
}) => {
    const { formatMessage } = useIntl();
    const [riskAnchorEl, setRiskAnchorEl] = useState<null | HTMLElement>(null);
    const openSelect = Boolean(riskAnchorEl);
    const [riskDialogCountryId, setRiskDialogCountryId] = useState<string | null>(null);
    const language = useCountryLanguage();

    const { data } = useQuery(
        graphql(`
            query EstimatedRiskCard_getCountry($id: ID!, $language: SupplierCountryLanguage) {
                getCountry(id: $id) {
                    country {
                        ...CountryRiskDialog_SupplierCountry
                    }
                }
            }
        `),
        {
            variables: {
                id: riskDialogCountryId ?? "",
                language,
            },
            skip: !riskDialogCountryId,
        }
    );

    const riskDialogCountry = data?.getCountry.country ?? null;

    // https://app.launchdarkly.com/projects/default/flags/suppliers-multiple-geography-risk-indices/targeting?env=production&env=test&selected-env=production
    const multipleRiskIndicesEnabled = useFeatureToggle("suppliers-multiple-geography-risk-indices");

    const handleRiskMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setRiskAnchorEl(event.currentTarget);
    };

    const handleRiskMenuClose = () => {
        setRiskAnchorEl(null);
    };

    const handleSelect = (value: string | undefined) => {
        onRiskChange(value);
        handleRiskMenuClose();
    };

    const countryColumn = columns.find((column) => column.id === "country");
    const riskNote = supplier.notes.find((n) => n.type === "risk");
    const prevVal = riskNote && riskNote.metadata?.prevValue ? getRiskValue(riskNote.metadata?.prevValue) : undefined;
    const newVal = riskNote && riskNote.metadata?.newValue ? getRiskValue(riskNote.metadata?.newValue) : undefined;

    return (
        <Paper>
            <Stack padding={3} spacing={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Estimated risk score" />
                        </Typography>
                        {/* <Tooltip
                            title={formatMessage({
                                defaultMessage: "TOOLTIP PLACEHOLDER",
                                description: "PLACEHOLDER",
                            })}
                        >
                            <InformationCircle fontSize="inherit" />
                        </Tooltip> */}
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {riskNote ? (
                            <RiskChange
                                prevVal={prevVal}
                                newVal={newVal}
                                socialRisk={supplier.risk?.social ?? undefined}
                                size="medium"
                            />
                        ) : (
                            <SuggestedStatusChip status={supplier.risk?.social ?? "MISSING"} />
                        )}
                        <NoPermissionTooltip hasPermission={isEditor}>
                            <IconButton
                                disabled={!isEditor}
                                size="xsmall"
                                color="secondary"
                                onClick={handleRiskMenuOpen}
                            >
                                <ChevronDown />
                            </IconButton>
                        </NoPermissionTooltip>
                    </Stack>
                    {riskAnchorEl && (
                        <Menu anchorEl={riskAnchorEl} open={openSelect} onClose={handleRiskMenuClose}>
                            {NON_MISSING_RISK_STATUSES.map((value) => (
                                <MenuItem key={value} onClick={() => handleSelect(value)}>
                                    <StatusChip status={value} />
                                </MenuItem>
                            ))}
                            {supplier.socialRiskScore?.value && (
                                <MenuItem
                                    onClick={() => handleSelect(undefined)}
                                    sx={{ borderTop: "1px solid", borderColor: "divider" }}
                                >
                                    <Typography variant="textSm">
                                        <FormattedMessage defaultMessage="Reset status" />
                                    </Typography>
                                </MenuItem>
                            )}
                        </Menu>
                    )}
                </Stack>
                <Grid2 container spacing={3} alignItems="center">
                    {multipleRiskIndicesEnabled && (
                        <InfoRow
                            icon={<LocationPin />}
                            name={countryColumn?.name ?? formatMessage({ defaultMessage: "Country" })}
                            infoComponent={
                                <Tooltip
                                    title={formatMessage({
                                        defaultMessage: "Registered headquarters country of a supplier",
                                    })}
                                >
                                    <InformationCircle fontSize="small" />
                                </Tooltip>
                            }
                            valueComponent={
                                <NoPermissionTooltip hasPermission={isEditor}>
                                    <CountrySelect
                                        active={supplier.productionCountries.length === 0}
                                        value={supplier.country ?? ""}
                                        isEditor={isEditor}
                                        onChange={(id) => onChange(countryColumn?.id ?? "", id ?? undefined)}
                                        onViewRiskDetails={(id) => setRiskDialogCountryId(id)}
                                    />
                                </NoPermissionTooltip>
                            }
                        />
                    )}
                    {!multipleRiskIndicesEnabled && (
                        <InfoRow
                            icon={<LocationPin />}
                            name={countryColumn?.name ?? formatMessage({ defaultMessage: "Country" })}
                            infoComponent={
                                <Tooltip
                                    title={formatMessage({
                                        defaultMessage: "Registered headquarters country of a supplier",
                                    })}
                                >
                                    <InformationCircle fontSize="small" />
                                </Tooltip>
                            }
                            valueComponent={
                                <NoPermissionTooltip hasPermission={isEditor}>
                                    <CountryAutocomplete
                                        columnId={countryColumn?.id ?? ""}
                                        value={supplier.country ?? ""}
                                        onChange={onChange}
                                        isEditor={isEditor}
                                    />
                                </NoPermissionTooltip>
                            }
                        />
                    )}
                    {multipleRiskIndicesEnabled && (
                        <ProductionCountriesAutocomplete
                            value={supplier.productionCountries ?? []}
                            onChange={(countryCodes) => onChange("production_countries", countryCodes)}
                            disabled={!isEditor}
                            onViewRiskDetails={(id) => setRiskDialogCountryId(id)}
                        />
                    )}
                    {!multipleRiskIndicesEnabled && (
                        <InfoRow
                            icon={<Tag />}
                            name="Production countries"
                            infoComponent={
                                <Tooltip
                                    title={formatMessage({
                                        defaultMessage:
                                            "Additional countries where the supplier is operating or producing",
                                    })}
                                >
                                    <InformationCircle fontSize="small" />
                                </Tooltip>
                            }
                            valueComponent={
                                <ProdCountriesAutocomplete
                                    value={supplier.productionCountries ?? []}
                                    onChange={onChange}
                                    isEditor={isEditor}
                                />
                            }
                        />
                    )}
                    <InfoRow
                        icon={<Company />}
                        name="Industry"
                        infoComponent={
                            <Tooltip title={formatMessage({ defaultMessage: "NACE code of the supplier" })}>
                                <InformationCircle fontSize="small" />
                            </Tooltip>
                        }
                        valueComponent={
                            <NoPermissionTooltip hasPermission={isEditor}>
                                <IndustryAutocomplete
                                    value={supplier.nace ?? ""}
                                    onChange={onChange}
                                    isEditor={isEditor}
                                />
                            </NoPermissionTooltip>
                        }
                    />
                </Grid2>
            </Stack>
            <CountryRiskDialog country={riskDialogCountry} onClose={() => setRiskDialogCountryId(null)} />
        </Paper>
    );
};
