import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { LicenseInfo } from "@mui/x-license";
import React from "react";
import { NavigateFunction } from "react-router-dom";

import { useUser } from "./providers/UserContext";
import { AppRoutes } from "./routes";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_TOKEN ?? "");

interface AppProps {
    navigate?: NavigateFunction;
}

const App: React.FC<AppProps> = ({ navigate }) => {
    const user = useUser();

    return (
        <FeatureToggleContextProvider userEmail={user.email} tenant={user.tenant}>
            <AppRoutes navigate={navigate} />
        </FeatureToggleContextProvider>
    );
};
export default App;
