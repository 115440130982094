import { InformationCircle } from "@ignite-analytics/icons";
import { Box, LinearProgress, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface EnrichmentProgressProps {
    progress: number;
}

export const EnrichmentProgress: React.FC<EnrichmentProgressProps> = ({ progress }) => {
    return (
        <Paper sx={{ padding: 2, height: 200 }}>
            <Stack spacing={5.5} alignContent="center">
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="textSm" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Enrichment progress"
                            description="Enrichment progress box title"
                        />
                    </Typography>
                    <Tooltip title="These are the risk factors that make up the estimated risk score">
                        <InformationCircle fontSize="inherit" />
                    </Tooltip>
                </Stack>
                <LinearProgress
                    sx={{ borderRadius: 2, height: 5 }}
                    variant="determinate"
                    color="secondary"
                    value={progress}
                />
                <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.secondary.main,
                                    height: 10,
                                    width: 10,
                                    borderRadius: "50%",
                                }}
                            />
                            <Typography variant="textSm">
                                <FormattedMessage
                                    defaultMessage="Ignite estimates"
                                    description="Enrichment progress legend - Ignite estimate"
                                />
                            </Typography>
                        </Stack>
                        <Typography variant="textSm" color="text.secondary">
                            {progress}%
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    height: 10,
                                    width: 10,
                                    borderRadius: "50%",
                                }}
                            />
                            <Typography variant="textSm">
                                <FormattedMessage
                                    defaultMessage="Manual enrichments"
                                    description="Enrichment progress legend - Manual enrichments"
                                />
                            </Typography>
                        </Stack>
                        <Typography variant="textSm" color="text.secondary">
                            {100 - progress}%
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};
