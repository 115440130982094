import { Link as MuiLink, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid-pro";
import { useRef } from "react";
import { Link } from "react-router-dom";

import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { TableData } from "../../utils";

const isEllipsisActive = (elementRef: React.RefObject<HTMLDivElement>) => {
    if (!elementRef?.current?.scrollWidth || !elementRef?.current?.clientWidth) {
        return false;
    }
    return elementRef.current.scrollWidth > elementRef.current.clientWidth;
};

interface SupplierNameFieldProps {
    params: GridRenderCellParams<TableData, TableData["supplier"]["name"], unknown, GridTreeNodeWithRender>;
}

// This component is used to display a tooltip when the text is too long to fit in the container
export const SupplierNameField: React.FC<SupplierNameFieldProps> = ({ params }) => {
    const value = params.value;
    const typographyRef = useRef<HTMLDivElement>(null);
    const navigate = useOutgoingNavigate();
    const ellipsisActive = isEllipsisActive(typographyRef);

    const supplierLink =
        params.row.supplier.onboarding?.status === "APPROVED"
            ? `/supplier-profile/${params.id}/overview/`
            : `${location.pathname}/onboarding/${params.id}`;

    return (
        <Tooltip
            disableHoverListener={!ellipsisActive}
            disableFocusListener={!ellipsisActive}
            disableTouchListener={!ellipsisActive}
            title={value}
        >
            <MuiLink
                color="inherit"
                onClick={(e) => {
                    navigate(supplierLink);
                    e.preventDefault(); // Don't follow the actual link as well (don't double navigate)
                }}
                to={supplierLink}
                noWrap
                sx={{
                    display: "inline-block",
                    width: "100%",
                }}
                component={Link}
            >
                <Typography
                    component="div"
                    sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        lineHeight: 3.5,
                    }}
                    noWrap
                    ref={typographyRef}
                    variant="textSm"
                >
                    {value}
                </Typography>
            </MuiLink>
        </Tooltip>
    );
};
